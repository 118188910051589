/* eslint-disable indent */
import store from '../index'
import logError from '../../utils/log'
import getValue from 'lodash/get'
import WidgetsServices from '../../services/widgets'
import LocalStorageServices from '../../services/localStorage'
import { get } from 'lodash'

const Types = {
  SET_TENSIOMETERS_STRUCTURE: 'widgets@setWidgetsStructure',
  SET_TENSIOMETERS_ACTIVATED: 'widgets@setTensiometersActivated',
  SET_PLUVIOMETERS_STRUCTURE: 'widgets@setPluviometersStructure',
  SET_PLUVIOMETERS_ACTIVATED: 'widgets@setPluviometersActivated',
  SET_WIDGETS_LIST: 'widgets@setWidgetsList',
  SET_WIDGETS_POSITIONS: 'widgets@getWidgetsPositions',
  SET_LOADING: 'widgets@setLoading',
  SET_ERROR: 'widgets@setError',
  SET_SUCCESS: 'widgets@setSuccess',
  CLEAR: 'widgets@clear'
}

const initialState = {
  tensiometerList: [],
  tensiometersActivated: [],
  pluviometersList: [],
  pluviometersActivated: [],
  list: [],
  widgetPosList: [],
  loading: false,
  errorMessage: '',
  successMessage: ''
}

const actions = {
  [Types.SET_TENSIOMETERS_STRUCTURE]: (state, action) => ({
    ...state,
    tensiometerList: action.payload
  }),
  [Types.SET_TENSIOMETERS_ACTIVATED]: (state, action) => ({
    ...state,
    tensiometersActivated: action.payload
  }),
  [Types.SET_PLUVIOMETERS_STRUCTURE]: (state, action) => ({
    ...state,
    pluviometersList: action.payload
  }),
  [Types.SET_PLUVIOMETERS_ACTIVATED]: (state, action) => ({
    ...state,
    pluviometersActivated: action.payload
  }),
  [Types.SET_WIDGETS_LIST]: (state, action) => ({
    ...state,
    list: action.payload
  }),
  [Types.SET_WIDGETS_POSITIONS]: (state, action) => ({
    ...state,
    widgetPosList: action.payload
  }),
  [Types.SET_LOADING]: (state, action) => ({
    ...state,
    loading: action.payload
  }),
  [Types.SET_ERROR]: (state, action) => ({
    ...state,
    errorMessage: action.payload
  }),
  [Types.SET_SUCCESS]: (state, action) => ({
    ...state,
    successMessage: action.payload
  }),
  [Types.CLEAR]: (state, action) => ({ ...state, ...action.payload })
}

const reducer = (state = initialState, action) => {
  if (actions[action.type]) {
    return actions[action.type](state, action)
  }

  return state
}

export const clearWidgetsReducer = () => async (dispatch) => {
  try {
    dispatch({
      type: Types.CLEAR,
      payload: initialState
    })
  } catch (error) {
    dispatch({ type: Types.SET_ERROR, payload: error.message })
  }
}

export const getTensiometersStructure = () => async (dispatch) => {
  try {
    dispatch({
      type: Types.SET_LOADING,
      payload: true
    })
    const currentStore = store.getState()
    const authToken = getValue(currentStore, 'auth.currentUser.token', null)

    const response = await WidgetsServices.getTensiometersStructure(authToken)

    const currentList = [...response]
    const activatedTensiometers = []

    currentList.forEach((client) => ({
      ...client,
      farms: client.farms.forEach((farm) => ({
        ...farm,
        projects: farm.projects.forEach((project) => ({
          ...project,
          sensors: project.sensors.forEach((sensor) => ({
            ...sensor,
            tensiometers: sensor.tensiometers.forEach((t) => {
              if (t.selected) return activatedTensiometers.push(t)
            })
          }))
        }))
      }))
    }))

    dispatch({
      type: Types.SET_LOADING,
      payload: false
    })

    dispatch({
      type: Types.SET_TENSIOMETERS_STRUCTURE,
      payload: response
    })
    dispatch({
      type: Types.SET_TENSIOMETERS_ACTIVATED,
      payload: activatedTensiometers
    })
  } catch (error) {
    logError('reducers -> widgets -> getTensiometersStructure', error)
    dispatch({
      type: Types.SET_LOADING,
      payload: false
    })
    dispatch({
      type: Types.SET_ERROR,
      payload: error.message
    })
  }
}

export const getPluviometersStructure = () => async (dispatch) => {
  try {
    dispatch({
      type: Types.SET_LOADING,
      payload: true
    })

    const currentStore = store.getState()
    const authToken = getValue(currentStore, 'auth.currentUser.token', null)

    const response = await WidgetsServices.getPluviometersStructure(authToken)

    const currentList = [...response]
    const activatedPluviometers = []

    currentList.forEach((client) => ({
      ...client,
      farms: client.farms.forEach((farm) => ({
        ...farm,
        pluviometers: farm?.pluviometer.forEach((pluviometer) => {
          if (pluviometer?.selected ?? false)
            return activatedPluviometers.push(pluviometer)
        })
      }))
    }))

    dispatch({
      type: Types.SET_LOADING,
      payload: false
    })

    dispatch({
      type: Types.SET_PLUVIOMETERS_STRUCTURE,
      payload: response
    })
    dispatch({
      type: Types.SET_PLUVIOMETERS_ACTIVATED,
      payload: activatedPluviometers
    })
  } catch (error) {
    logError('reducers -> widgets -> getPluviometersStructure', error)
    dispatch({
      type: Types.SET_LOADING,
      payload: false
    })
    dispatch({
      type: Types.SET_ERROR,
      payload: error.message
    })
  }
}

export const getWidgetsSnapshot = () => async (dispatch) => {
  try {
    dispatch({
      type: Types.SET_WIDGETS_LIST,
      payload: []
    })
    const currentStore = store.getState()
    const authToken = getValue(currentStore, 'auth.currentUser.token', null)
    const currentClient = getValue(currentStore, 'clients.currentClient.id', 0)

    if (currentClient) {
      dispatch({
        type: Types.SET_LOADING,
        payload: true
      })

      const response = await WidgetsServices.getSnapshot(
        authToken,
        currentClient
      )

      const widgetPosList = await LocalStorageServices.getJson(
        `${LocalStorageServices.KEYS.WIDGET_POSITIONS}-${currentClient}`,
        false
      )

      let finalList = []
      if (get(response, 'tensiometerWidget.length', 0)) {
        finalList = get(response, 'tensiometerWidget', []).map((item) => {
          const droppableId = `tensiometer-${item.sensorGroup.id}`
          return { ...item, activeStep: 0, droppableId }
        })
      }
      if (get(response, 'pluviometerWidget.length', 0)) {
        const pluvList = get(response, 'pluviometerWidget', []).map((item) => {
          const droppableId = `pluviometer-${item.pluviometer.id}`
          return { ...item, activeStep: 0, droppableId }
        })
        finalList = [...finalList, ...pluvList]
      }
      if (widgetPosList?.length || false) {
        const positionList = widgetPosList.map((i) => i.droppableId)

        finalList.sort((a, b) => {
          return (
            positionList.indexOf(a.droppableId) -
            positionList.indexOf(b.droppableId)
          )
        })
      }

      dispatch({
        type: Types.SET_LOADING,
        payload: false
      })
      dispatch({
        type: Types.SET_WIDGETS_LIST,
        payload: finalList
      })
    }
  } catch (error) {
    logError('reducers -> widgets -> getSnapshot', error)
    dispatch({
      type: Types.SET_LOADING,
      payload: false
    })
    dispatch({
      type: Types.SET_ERROR,
      payload: error.message
    })
  }
}

export const onSelectTensiometers = (tensiometer) => (dispatch) => {
  const currentStore = store.getState()
  const currentList = getValue(currentStore, 'widgets.tensiometerList', [])
  const activatedTensiometers = []
  const tensiometers = currentList.map((client) => {
    return {
      ...client,
      farms: client.farms.map((farm) => ({
        ...farm,
        projects: farm.projects.map((project) => {
          return {
            ...project,
            sensors: project.sensors.map((sensor) => {
              return {
                ...sensor,
                tensiometers: sensor.tensiometers.map((t) => {
                  if (t.id === tensiometer.id) {
                    if (!t.selected) activatedTensiometers.push(t)
                    return {
                      ...t,
                      selected: !t.selected
                    }
                  }
                  if (t.selected) {
                    activatedTensiometers.push(t)
                  }
                  return t
                })
              }
            })
          }
        })
      }))
    }
  })

  dispatch({
    type: Types.SET_TENSIOMETERS_STRUCTURE,
    payload: tensiometers
  })
  dispatch({
    type: Types.SET_TENSIOMETERS_ACTIVATED,
    payload: activatedTensiometers
  })
}

export const onSelectPluviometers = (pluviometer) => (dispatch) => {
  const currentStore = store.getState()
  const currentList = getValue(currentStore, 'widgets.pluviometersList', [])
  const activatedPluviometers = []
  const pluviometers = currentList.map((client) => {
    return {
      ...client,
      farms: client.farms.map((farm) => ({
        ...farm,
        pluviometer: farm.pluviometer.map((p) => {
          if (p.id === pluviometer.id) {
            if (!p.selected) activatedPluviometers.push(p)
            return {
              ...p,
              selected: !p.selected
            }
          }
          if (p.selected) {
            activatedPluviometers.push(p)
          }
          return p
        })
      }))
    }
  })

  dispatch({
    type: Types.SET_PLUVIOMETERS_STRUCTURE,
    payload: pluviometers
  })
  dispatch({
    type: Types.SET_PLUVIOMETERS_ACTIVATED,
    payload: activatedPluviometers
  })
}

export const getTensiometersStore = (tensiometers) => async (dispatch) => {
  try {
    dispatch({
      type: Types.SET_LOADING,
      payload: true
    })

    const currentStore = store.getState()
    const authToken = getValue(currentStore, 'auth.currentUser.token', null)

    const response = await WidgetsServices.getTensiometersStore(
      authToken,
      tensiometers
    )

    dispatch({
      type: Types.SET_LOADING,
      payload: false
    })

    dispatch({
      type: Types.SET_WIDGETS_LIST,
      payload: response
    })
    dispatch({
      type: Types.SET_SUCCESS,
      payload: 'Widgets de tensiometria salvos com sucesso'
    })
  } catch (error) {
    logError('reducers -> widgets -> getTensiometersStore', error)
    dispatch({
      type: Types.SET_LOADING,
      payload: false
    })
    dispatch({
      type: Types.SET_ERROR,
      payload: error.message
    })
  }
}

export const getPluviometersStore = (sensors) => async (dispatch) => {
  try {
    dispatch({
      type: Types.SET_LOADING,
      payload: true
    })

    const currentStore = store.getState()
    const authToken = getValue(currentStore, 'auth.currentUser.token', null)

    const response = await WidgetsServices.getPluviometersStore(
      authToken,
      sensors
    )

    dispatch({
      type: Types.SET_LOADING,
      payload: false
    })

    dispatch({
      type: Types.SET_WIDGETS_LIST,
      payload: response
    })
    dispatch({
      type: Types.SET_SUCCESS,
      payload: 'Widgets de pluviometria salvos com sucesso'
    })
  } catch (error) {
    logError('reducers -> widgets -> getPluviometerStore', error)
    dispatch({
      type: Types.SET_LOADING,
      payload: false
    })
    dispatch({
      type: Types.SET_ERROR,
      payload: error.message
    })
  }
}

export const handleNext =
  (index, list, next = true) =>
  (dispatch) => {
    const newList = [...list]
    const current = { ...newList[index] }
    if (next) {
      current.activeStep += 1
    } else {
      current.activeStep -= 1
    }
    newList[index] = current
    dispatch({
      type: Types.SET_WIDGETS_LIST,
      payload: newList
    })
  }

export const handleDragAndDrop = (list) => async (dispatch) => {
  try {
    const currentStore = store.getState()
    const currentClient = getValue(currentStore, 'clients.currentClient.id', 0)

    await LocalStorageServices.saveJson(
      `${LocalStorageServices.KEYS.WIDGET_POSITIONS}-${currentClient}`,
      list,
      false
    )

    dispatch({
      type: Types.SET_WIDGETS_LIST,
      payload: list
    })
  } catch (error) {
    dispatch({
      type: Types.SET_ERROR,
      payload: error.message
    })
  }
}

export const setErrorMessage =
  (message = '') =>
  async (dispatch) => {
    dispatch({
      type: Types.SET_ERROR,
      payload: message
    })
  }

export const setSuccessMessage =
  (message = '') =>
  async (dispatch) => {
    dispatch({
      type: Types.SET_SUCCESS,
      payload: message
    })
  }

export default reducer
