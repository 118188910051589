import React from 'react'
import { Switch, Route } from 'react-router-dom'

import AppRoutes from './routes'
import ProtectedRoute from './protectedRoute'

import Login from '../pages/login'
import Dashboard from '../pages/dashboard'
import Clients from '../pages/clients'
import Irrigation from '../pages/irrigation'
import Readings from '../pages/readings'
import Farm from '../pages/farm'
import Projects from '../pages/projects'
import SensorGroup from '../pages/sensorgroup'
import Users from '../pages/users'
import Tips from '../pages/tips'
import TensiometerCharts from '../pages/tensiometerCharts'
import ExtractorsCharts from '../pages/extractorsCharts'
import PhCharts from '../pages/phCharts'
import AccountSettings from '../pages/accountSettings'
import TemperatureCharts from '../pages/temperatureCharts'
import HumidityCharts from '../pages/humidityCharts'
import EvpCharts from '../pages/evpCharts'
import PluviometerReadings from '../pages/PluviometerReadings'
import Pluviometer from '../pages/Pluviometer'

export default function Routes() {
  return (
    <Switch>
      <Route exact path={AppRoutes.DEFAULT} component={Login} />
      <Route exact path={AppRoutes.LOGIN} component={Login} />
      <ProtectedRoute exact path={AppRoutes.DASHBOARD} component={Dashboard} />
      <ProtectedRoute exact path={AppRoutes.CLIENTS} component={Clients} />
      <ProtectedRoute
        exact
        path={AppRoutes.IRRIGATION}
        component={Irrigation}
      />
      <ProtectedRoute exact path={AppRoutes.READINGS} component={Readings} />
      <ProtectedRoute exact path={AppRoutes.FARM} component={Farm} />
      <ProtectedRoute exact path={AppRoutes.PROJECTS} component={Projects} />
      <ProtectedRoute
        exact
        path={AppRoutes.SENSORGROUP}
        component={SensorGroup}
      />
      <ProtectedRoute exact path={AppRoutes.USERS} component={Users} />
      <ProtectedRoute exact path={AppRoutes.TIPS} component={Tips} />
      <ProtectedRoute
        exact
        path={AppRoutes.TENSIOMETERCHARTS}
        component={TensiometerCharts}
      />
      <ProtectedRoute
        exact
        path={AppRoutes.EXTRACTORSCHARTS}
        component={ExtractorsCharts}
      />
      <ProtectedRoute exact path={AppRoutes.PHCHARTS} component={PhCharts} />
      <ProtectedRoute
        exact
        path={AppRoutes.ACCOUNTSETTINGS}
        component={AccountSettings}
      />
      <ProtectedRoute
        exact
        path={AppRoutes.TEMPERATURECHARTS}
        component={TemperatureCharts}
      />
      <ProtectedRoute
        exact
        path={AppRoutes.HUMIDITYCHARTS}
        component={HumidityCharts}
      />
      <ProtectedRoute exact path={AppRoutes.EVPCHARTS} component={EvpCharts} />
      <ProtectedRoute
        exact
        path={AppRoutes.PLUVIOMETER}
        component={Pluviometer}
      />
      <ProtectedRoute
        exact
        path={AppRoutes.PLUVIOMETRY}
        component={PluviometerReadings}
      />
    </Switch>
  )
}
