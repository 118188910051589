import { styled as styledMaterialUI } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Colors from '../../styles/colors'

export const MapButton = styledMaterialUI(Button)({
  width: 150,
  marginTop: 16,
  backgroundColor: Colors.AZUL_HYDRA,
  '&:hover': {
    backgroundColor: Colors.AZUL_HYDRA,
    boxShadow: '0 0 11px rgba(0,0,0,0.9)'
  }
})

export const AddIconButton = styledMaterialUI(IconButton)({
  marginRight: 8
})

export const CloseButton = styledMaterialUI(IconButton)({
  color: '#ffffff'
})

export const DialogContainer = styledMaterialUI(Dialog)({
  display: 'flex',
  flexDirection: 'column'
})

export const FarmInput = styledMaterialUI(TextField)({
  width: '90%',
  marginBotton: 16
})

export const TypoWarn = styledMaterialUI(Typography)({
  fontSize: 12,
  color: 'red',
  paddingLeft: 12,
  paddingTop: 4
})
