import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import DateFnsUtils from '@date-io/date-fns'
import { ptBR } from 'date-fns/locale'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import { DialogContainer, TypoHeader, ReadingsDateTimePicker } from './style'

const ReadingTime = ({ timestampValue, onChangeTime }) => {
  const dateValue = dayjs(timestampValue).toDate()

  return (
    <DialogContainer>
      <TypoHeader>Informe a data e a hora da leitura:</TypoHeader>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <ReadingsDateTimePicker
          fullWidth
          inputMode="numeric"
          label="Data e hora da leitura"
          value={dateValue}
          onChange={onChangeTime}
          format={'dd/MM/yyyy, HH:mm'}
          cancelLabel="Cancelar"
          okLabel="Confirmar"
          ampm={false}
          inputVariant="outlined"
        />
      </MuiPickersUtilsProvider>
    </DialogContainer>
  )
}

ReadingTime.propTypes = {
  timestampValue: PropTypes.number,
  onChangeTime: PropTypes.func.isRequired
}

ReadingTime.defaultProps = {
  onChangeTime: () => {}
}

export default ReadingTime
