import axios from 'axios'
import getValue from 'lodash/get'
import logError from '../utils/log'

const PLUVIOMETERREADINGS = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/pluviometer-readings`
})

const getList = async (authToken, clientId, startDate, endDate) => {
  try {
    const response = await PLUVIOMETERREADINGS.get(
      `/?client_id=${clientId}&startDate=${startDate}&endDate=${endDate}`,
      {
        headers: { Authorization: `bearer ${authToken}` }
      }
    )
    return response.data
  } catch (error) {
    logError('services@pluviometerReadings@getList', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao buscar a lista de leituras'
      )
    )
  }
}
const insertPluviometerReading = async (authToken, reading) => {
  try {
    const response = await PLUVIOMETERREADINGS.post('/', reading, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    console.log('response >', response)
    return response.data
  } catch (error) {
    logError('services@pluviometerReadings@insertPluviometerReading', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao registrar a leitura'
      )
    )
  }
}

const updatePluviometerReading = async (authToken, reading) => {
  try {
    const response = await PLUVIOMETERREADINGS.put('/', reading, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@pluviometerReadings@updatePluviometerReading', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao buscar a lista de leituras'
      )
    )
  }
}

export default {
  getList,
  insertPluviometerReading,
  updatePluviometerReading
}
