import dayjs from 'dayjs'

export const TIPS_TYPE_LIST = {
  DRYSOIL: 'dry_soil',
  DRYSOIL_TASK: 'dry_soil_task',
  TOOMUCHIRR: 'too_much_irr',
  IRRONLYWHENNECESSARY: 'irr_only_when_necessary',
  LONGIRRPULSE: 'long_irr_pulse',
  SHORTIRRPULSE: 'short_irr_pulse',
  LOWEC: 'low_ec',
  LOWECFERTI: 'low_ec_fert',
  HIGHEC: 'hich_ec',
  HIGHECFERTI: 'hich_ec_ferti',
  NOSOLUTION_WETSOIL: 'no_solution_wet_soil',
  NOSOLUTION_DRYSOIL: 'no_solution_dry_soil',
  ACIDIC_PH: 'acidic_pH',
  BASIC_PH: 'basic_pH'
}

export const PAGINTION_SIZE = 50

export const USER_ROLES = {
  ADMIN: 0,
  CLIENT: 1,
  SUPERVISOR: 2,
  NORMAL: 3
}

export const USER_ACCESS = {
  NOACCESS: 0,
  READ: 1,
  WRITE: 2
}

export const IRRIGATION_TYPES = {
  NONE: 0,
  IRRIGATION: 1,
  IRRIGATION_AND_FERTIRRIGATION: 2
}

export const TENSION_READING_VALUE = {
  MANUAL: 0,
  CAPSULE: 1
}

export const TENSION_READING_TYPE = [
  { label: 'Manual', value: TENSION_READING_VALUE.MANUAL },
  { label: 'Capsula', value: TENSION_READING_VALUE.CAPSULE }
]

export const USER_ROLES_LIST = [
  { value: USER_ROLES.ADMIN, label: 'Administrador' },
  { value: USER_ROLES.CLIENT, label: 'Cliente' },
  { value: USER_ROLES.SUPERVISOR, label: 'Supervisor' },
  { value: USER_ROLES.NORMAL, label: 'Operador' }
]

export const USER_PERMISSIONS_LIST = [
  { value: USER_ACCESS.NOACCESS, label: 'N' },
  { value: USER_ACCESS.READ, label: 'L' },
  { value: USER_ACCESS.WRITE, label: 'L, E' }
]

export const IRRIGATION_TYPE_LIST = [
  { value: IRRIGATION_TYPES.NONE, label: 'Não irrigou' },
  { value: IRRIGATION_TYPES.IRRIGATION, label: 'Irrigou' },
  {
    value: IRRIGATION_TYPES.IRRIGATION_AND_FERTIRRIGATION,
    label: 'Fertirrigou'
  }
]

export const IRRIGATION_SYSTEMS = {
  DRIPPING: 0,
  MICROSPRINKLING: 1,
  MICROJET: 2,
  SPRINKLING: 3
}

export const IRRIGATION_SYSTEMS_LIST = [
  { value: IRRIGATION_SYSTEMS.DRIPPING, label: 'Gotejamento' },
  { value: IRRIGATION_SYSTEMS.MICROSPRINKLING, label: 'Microaspersão' },
  { value: IRRIGATION_SYSTEMS.MICROJET, label: 'Microjet' },
  { value: IRRIGATION_SYSTEMS.SPRINKLING, label: 'Aspersão' }
]

export const PAGES_LABEL = {
  readings: 'Leituras',
  pluviometer: 'Chuvas',
  irrigations: 'Irrigações',
  tips: 'Dicas do Agrônomo',
  tensiometer_chart: 'Tensiômetros',
  extractor_chart: 'Extratores',
  ph_chart: 'pH',
  admin_clients: 'Clientes',
  admin_users: 'Usuários',
  admin_farms: 'Fazendas',
  admin_projects: 'Projetos',
  admin_sensor_group: 'Baterias',
  admin_pluviometer: 'Pluviometros'
}

export const PAGES_INI_VALUE = {
  readings: USER_ACCESS.NOACCESS,
  pluviometer: USER_ACCESS.NOACCESS,
  irrigations: USER_ACCESS.NOACCESS,
  tips: USER_ACCESS.NOACCESS,
  tensiometer_chart: USER_ACCESS.NOACCESS,
  extractor_chart: USER_ACCESS.NOACCESS,
  ph_chart: USER_ACCESS.NOACCESS,
  temperature_chart: USER_ACCESS.NOACCESS,
  humidity_chart: USER_ACCESS.NOACCESS,
  evapo_chart: USER_ACCESS.NOACCESS,
  admin_clients: USER_ACCESS.NOACCESS,
  admin_users: USER_ACCESS.NOACCESS,
  admin_farms: USER_ACCESS.NOACCESS,
  admin_projects: USER_ACCESS.NOACCESS,
  admin_sensor_group: USER_ACCESS.NOACCESS,
  admin_pluviometer: USER_ACCESS.NOACCESS
}

export const ChartTranslation = {
  lang: {
    loading: 'Carregando...',
    noData: 'Sem dados',
    resetZoom: 'Zoom inicial',
    resetZoomTitle: 'Voltar ao zoom inicial',
    printChart: 'Imprimir',
    downloadCSV: 'Salvar em CSV',
    downloadJPEG: 'Salvar em JPEG',
    downloadPDF: 'Salvar em PDF',
    downloadPNG: 'Salvar em PNG',
    downloadSVG: 'Salvar em SVG',
    downloadXLS: 'Salvar em XLS',
    viewFullscreen: 'Ver em tela cheia',
    exitFullscreen: 'Sair da tela cheia',
    decimalPoint: ',',
    thousandsSep: '.',
    months: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ],
    shortMonths: [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12'
    ],
    weekdays: [
      'Domingo',
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado'
    ]
  }
}

export const TensiometerChartOptions = {
  chart: {
    zoomType: 'xy',
    reflow: true
  },
  exporting: {
    width: 2000,
    sourceWidth: 2000,
    sourceHeight: 1000,
    printMaxWidth: 1200,
    buttons: {
      contextButton: {
        menuItems: [
          'viewFullscreen',
          'printChart',
          'separator',
          'downloadJPEG',
          'downloadPDF',
          'downloadPNG'
        ]
      }
    }
  },
  time: {
    useUTC: false
  },
  title: {
    text: ''
  },
  tooltip: {
    valueDecimals: 2,
    dateTimeLabelFormats: {
      millisecond: '%d/%m/%Y %H:%M',
      second: '%d/%m/%Y %H:%M',
      minute: '%d/%m/%Y %H:%M',
      hour: '%d/%m/%Y %H:%M',
      day: '%d/%m/%Y %H:%M',
      week: '%d/%m/%Y %H:%M',
      month: '%d/%m/%Y %H:%M',
      year: '%d/%m/%Y %H:%M'
    },
    headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
    style: {
      fontSize: '16px'
    },
    useHTML: true
  },
  legend: {
    itemStyle: {
      fontSize: '14px'
    }
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      millisecond: '%H:%M',
      second: '%H:%M',
      minute: '%H:%M',
      hour: '%H:%M',
      day: '%e/%m',
      week: '%e/%m',
      month: '%e/%m/%y',
      year: '%Y'
    },
    labels: {
      style: {
        fontSize: '12px'
      }
    }
  },
  yAxis: [
    {
      gridLineWidth: 0,
      labels: {
        format: '{value}',
        style: {
          fontSize: '12px'
        }
      },
      title: {
        text: 'Leitura (mba)'
      },
      min: 0,
      max: 700,
      tickPositioner: () => [
        0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700
      ],
      plotBands: [
        {
          // Saturado
          from: 0,
          to: 50,
          color: 'rgba(0, 0, 255, 0.2)',
          label: {
            text: 'Solo saturado',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        },
        {
          // Ideal
          from: 50,
          to: 150,
          color: 'rgba(0, 255, 0, 0.2)',
          label: {
            text: 'Ideal',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        },
        {
          // Seco
          from: 150,
          to: 300,
          color: 'rgba(255, 0, 0, 0.2)',
          label: {
            text: 'Solo seco',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        },
        {
          // Ponto de murcha
          from: 300,
          to: 800,
          color: 'rgba(200, 200, 200, 0.2)',
          label: {
            text: 'Ponto de murcha',
            style: {
              color: 'red',
              fontSize: '16px',
              fontWeight: 'bold',
              alignSelf: 'left'
            }
          }
        }
      ]
    },
    {
      gridLineWidth: 0,
      title: {
        text: 'Chuvas / Irrigação (mm)'
      },
      labels: {
        format: '{value}',
        style: {
          fontSize: '12px'
        }
      },
      min: 0,
      max: 50,
      opposite: true,
      reversed: true
    }
  ],
  plotOptions: {
    column: {
      grouping: true,
      groupXPadding: 1,
      pointWidth: 10
    },
    series: {
      turboThreshold: 10000
    }
  }
}

export const ExtractorChartOptions = {
  chart: {
    zoomType: 'xy',
    reflow: true
  },
  exporting: {
    width: 2000,
    sourceWidth: 2000,
    sourceHeight: 1000,
    printMaxWidth: 1200,
    buttons: {
      contextButton: {
        menuItems: [
          'viewFullscreen',
          'printChart',
          'separator',
          'downloadJPEG',
          'downloadPDF',
          'downloadPNG'
        ]
      }
    }
  },
  time: {
    useUTC: false
  },
  title: {
    text: ''
  },
  tooltip: {
    valueDecimals: 2,
    dateTimeLabelFormats: {
      millisecond: '%d/%m/%Y %H:%M',
      second: '%d/%m/%Y %H:%M',
      minute: '%d/%m/%Y %H:%M',
      hour: '%d/%m/%Y %H:%M',
      day: '%d/%m/%Y %H:%M',
      week: '%d/%m/%Y %H:%M',
      month: '%d/%m/%Y %H:%M',
      year: '%d/%m/%Y %H:%M'
    },
    headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
    style: {
      fontSize: '16px'
    },
    useHTML: true
  },
  legend: {
    align: 'center',
    itemDistance: 2,
    itemStyle: {
      fontSize: '14px'
    }
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      millisecond: '%H:%M',
      second: '%H:%M',
      minute: '%H:%M',
      hour: '%H:%M',
      day: '%e/%m',
      week: '%e/%m',
      month: '%e/%m/%y',
      year: '%Y'
    },
    labels: {
      style: {
        fontSize: '12px'
      }
    }
  },
  yAxis: [
    {
      gridLineWidth: 0,
      labels: {
        format: '{value}',
        style: {
          fontSize: '12px'
        }
      },
      title: {
        text: 'Leitura (µS/cm)'
      },
      min: 0,
      max: 3000,
      tickPositioner: () => [
        0, 250, 500, 750, 1000, 1250, 1500, 1750, 2000, 2250, 2500, 2750, 3000
      ],
      plotBands: [
        {
          // EC Baixa
          from: 0,
          to: 1000,
          color: 'rgba(0, 0, 255, 0.2)',
          label: {
            text: 'EC Baixa',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        },
        {
          // Ideal
          from: 1000,
          to: 1500,
          color: 'rgba(0, 255, 0, 0.2)',
          label: {
            text: 'EC Ideal',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        },
        {
          // EC Alta
          from: 1500,
          to: 3000,
          color: 'rgba(255, 0, 0, 0.2)',
          label: {
            text: 'EC Alta',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        }
      ]
    },
    {
      gridLineWidth: 0,
      title: {
        text: 'Chuvas / Irrigação (mm)'
      },
      labels: {
        format: '{value}',
        style: {
          fontSize: '12px'
        }
      },
      min: 0,
      max: 50,
      opposite: true,
      reversed: true
    }
  ],
  plotOptions: {
    column: {
      grouping: true,
      groupXPadding: 3,
      pointWidth: 10
    }
  }
}

export const PhChartOptions = {
  chart: {
    zoomType: 'xy',
    reflow: true
  },
  exporting: {
    width: 2000,
    sourceWidth: 2000,
    sourceHeight: 1000,
    printMaxWidth: 1200,
    buttons: {
      contextButton: {
        menuItems: [
          'viewFullscreen',
          'printChart',
          'separator',
          'downloadJPEG',
          'downloadPDF',
          'downloadPNG'
        ]
      }
    }
  },
  time: {
    useUTC: false
  },
  title: {
    text: ''
  },
  tooltip: {
    valueDecimals: 2,
    dateTimeLabelFormats: {
      millisecond: '%d/%m/%Y %H:%M',
      second: '%d/%m/%Y %H:%M',
      minute: '%d/%m/%Y %H:%M',
      hour: '%d/%m/%Y %H:%M',
      day: '%d/%m/%Y %H:%M',
      week: '%d/%m/%Y %H:%M',
      month: '%d/%m/%Y %H:%M',
      year: '%d/%m/%Y %H:%M'
    },
    headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
    style: {
      fontSize: '16px'
    },
    useHTML: true
  },
  legend: {
    align: 'center',
    itemDistance: 2,
    itemStyle: {
      fontSize: '14px'
    }
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      millisecond: '%H:%M',
      second: '%H:%M',
      minute: '%H:%M',
      hour: '%H:%M',
      day: '%e/%m',
      week: '%e/%m',
      month: '%e/%m/%y',
      year: '%Y'
    },
    labels: {
      style: {
        fontSize: '12px'
      }
    }
  },
  yAxis: [
    {
      gridLineWidth: 0,
      labels: {
        format: '{value}',
        style: {
          fontSize: '12px'
        }
      },
      title: {
        text: 'Leitura'
      },
      min: 3,
      max: 10,
      tickPositioner: () => [3, 4, 5, 6, 7, 8, 9, 10],
      plotBands: [
        {
          // Ácido
          from: 3,
          to: 6,
          color: 'rgba(0, 0, 255, 0.2)',
          label: {
            text: 'Ácido',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        },
        {
          // Ideal
          from: 6,
          to: 7,
          color: 'rgba(0, 255, 0, 0.2)',
          label: {
            text: 'Ideal',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        },
        {
          // Básico
          from: 7,
          to: 10,
          color: 'rgba(255, 0, 0, 0.2)',
          label: {
            text: 'Básico',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        }
      ]
    },
    {
      gridLineWidth: 0,
      title: {
        text: 'Chuvas / Irrigação (mm)'
      },
      labels: {
        format: '{value}',
        style: {
          fontSize: '12px'
        }
      },
      min: 0,
      max: 50,
      opposite: true,
      reversed: true
    }
  ],
  plotOptions: {
    column: {
      grouping: true,
      groupXPadding: 3,
      pointWidth: 10
    }
  }
}

export const GaugeWidgetOptions = {
  chart: {
    type: 'solidgauge',
    alignTicks: false,
    plotBackgroundColor: null,
    plotBackgroundImage: null,
    plotBorderWidth: 0,
    plotShadow: false
  },

  title: {
    text: 'P1S2',
    style: {
      fontSize: '20px',
      fontWeight: 'bold'
    },
    margin: 0
  },

  pane: {
    center: ['50%', '85%'],
    size: '140%',
    startAngle: -90,
    endAngle: 90,
    background: {
      backgroundColor: '#EEE',
      innerRadius: '60%',
      outerRadius: '100%',
      shape: 'arc'
    }
  },

  exporting: {
    enabled: false
  },

  tooltip: {
    enabled: false
  },

  // the value axis
  yAxis: {
    min: 0,
    max: 700,
    tickPositioner: () => [
      0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700
    ],
    stops: [
      [20, 'rgba(0, 0, 255, 0.2)'],
      [50, 'rgba(0, 0, 255, 0.2)'],
      [51, 'rgba(0, 255, 0, 0.2)'],
      [150, 'rgba(0, 255, 0, 0.2)'],
      [151, 'rgba(255, 0, 0, 0.2)'],
      [350, 'rgba(255, 0, 0, 0.2)'],
      [351, 'rgba(200, 200, 200, 0.2)'],
      [700, 'rgba(200, 200, 200, 0.2)']
    ],
    plotBands: [
      {
        from: 0,
        to: 50,
        color: 'rgba(0, 0, 255, 0.2)'
      },
      {
        from: 50,
        to: 150,
        color: 'rgba(0, 255, 0, 0.2)'
      },
      {
        from: 150,
        to: 350,
        color: 'rgba(255, 0, 0, 0.2)'
      },
      {
        from: 350,
        to: 700,
        color: 'rgba(200, 200, 200, 0.2)'
      }
    ],
    lineWidth: 0,
    tickWidth: 0,
    minorTickInterval: null,
    tickAmount: 2,
    title: {
      title: 'test'
    },
    labels: {},
    gridLineWidth: 0
  },
  credits: {
    enabled: false
  },
  plotOptions: {
    solidgauge: {
      dataLabels: {
        y: 5,
        borderWidth: 0,
        useHTML: true
      }
    }
  }
}

export const TemperatureChartOptions = {
  chart: {
    zoomType: 'xy',
    reflow: true
  },
  exporting: {
    width: 2000,
    sourceWidth: 2000,
    sourceHeight: 1000,
    printMaxWidth: 1200,
    buttons: {
      contextButton: {
        menuItems: [
          'viewFullscreen',
          'printChart',
          'separator',
          'downloadJPEG',
          'downloadPDF',
          'downloadPNG'
        ]
      }
    }
  },
  time: {
    useUTC: false
  },
  title: {
    text: ''
  },
  tooltip: {
    valueDecimals: 2,
    dateTimeLabelFormats: {
      millisecond: '%d/%m/%Y %H:%M',
      second: '%d/%m/%Y %H:%M',
      minute: '%d/%m/%Y %H:%M',
      hour: '%d/%m/%Y %H:%M',
      day: '%d/%m/%Y %H:%M',
      week: '%d/%m/%Y %H:%M',
      month: '%d/%m/%Y %H:%M',
      year: '%d/%m/%Y %H:%M'
    },
    headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
    style: {
      fontSize: '16px'
    },
    useHTML: true
  },
  legend: {
    itemStyle: {
      fontSize: '14px'
    }
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      millisecond: '%H:%M',
      second: '%H:%M',
      minute: '%H:%M',
      hour: '%H:%M',
      day: '%e/%m',
      week: '%e/%m',
      month: '%e/%m/%y',
      year: '%Y'
    },
    labels: {
      style: {
        fontSize: '12px'
      }
    }
  },
  yAxis: [
    {
      gridLineWidth: 0,
      labels: {
        format: '{value}',
        style: {
          fontSize: '12px'
        }
      },
      title: {
        text: 'Leitura (°C)'
      },
      min: 10,
      max: 45,
      tickPositioner: () => [10, 15, 20, 25, 30, 35, 40, 45],
      plotBands: [
        {
          from: 15,
          to: 15,
          color: 'rgba(0, 0, 0, 0.2)',
          label: {
            text: '',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        },
        {
          from: 20,
          to: 20,
          color: 'rgba(0, 0, 0, 0.2)',
          label: {
            text: '',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        },
        {
          from: 25,
          to: 25,
          color: 'rgba(0, 0, 0, 0.2)',
          label: {
            text: '',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        },
        {
          from: 30,
          to: 30,
          color: 'rgba(0, 0, 0, 0.2)',
          label: {
            text: '',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        },
        {
          from: 35,
          to: 35,
          color: 'rgba(0, 0, 0, 0.2)',
          label: {
            text: '',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        },
        {
          from: 40,
          to: 40,
          color: 'rgba(0, 0, 0, 0.2)',
          label: {
            text: '',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        },
        {
          from: 45,
          to: 45,
          color: 'rgba(0, 0, 0, 0.2)',
          label: {
            text: '',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        }
      ]
    }
  ],
  plotOptions: {
    column: {
      grouping: true,
      groupXPadding: 1,
      pointWidth: 10
    },
    series: {
      turboThreshold: 10000
    }
  }
}

export const HumidityChartOptions = {
  chart: {
    zoomType: 'xy',
    reflow: true
  },
  exporting: {
    width: 2000,
    sourceWidth: 2000,
    sourceHeight: 1000,
    printMaxWidth: 1200,
    buttons: {
      contextButton: {
        menuItems: [
          'viewFullscreen',
          'printChart',
          'separator',
          'downloadJPEG',
          'downloadPDF',
          'downloadPNG'
        ]
      }
    }
  },
  time: {
    useUTC: false
  },
  title: {
    text: ''
  },
  tooltip: {
    valueDecimals: 2,
    dateTimeLabelFormats: {
      millisecond: '%d/%m/%Y %H:%M',
      second: '%d/%m/%Y %H:%M',
      minute: '%d/%m/%Y %H:%M',
      hour: '%d/%m/%Y %H:%M',
      day: '%d/%m/%Y %H:%M',
      week: '%d/%m/%Y %H:%M',
      month: '%d/%m/%Y %H:%M',
      year: '%d/%m/%Y %H:%M'
    },
    headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
    style: {
      fontSize: '16px'
    },
    useHTML: true
  },
  legend: {
    itemStyle: {
      fontSize: '14px'
    }
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      millisecond: '%H:%M',
      second: '%H:%M',
      minute: '%H:%M',
      hour: '%H:%M',
      day: '%e/%m',
      week: '%e/%m',
      month: '%e/%m/%y',
      year: '%Y'
    },
    labels: {
      style: {
        fontSize: '12px'
      }
    }
  },
  yAxis: [
    {
      gridLineWidth: 0,
      labels: {
        format: '{value}',
        style: {
          fontSize: '12px'
        }
      },
      title: {
        text: 'Leitura (%)'
      },
      min: 50,
      max: 100,
      tickPositioner: () => [50, 60, 70, 80, 90, 100],
      plotBands: [
        {
          from: 60,
          to: 60,
          color: 'rgba(0, 0, 0, 0.2)',
          label: {
            text: '',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        },
        {
          from: 70,
          to: 70,
          color: 'rgba(0, 0, 0, 0.2)',
          label: {
            text: '',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        },
        {
          from: 80,
          to: 80,
          color: 'rgba(0, 0, 0, 0.2)',
          label: {
            text: '',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        },
        {
          from: 90,
          to: 90,
          color: 'rgba(0, 0, 0, 0.2)',
          label: {
            text: '',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        },
        {
          from: 100,
          to: 100,
          color: 'rgba(0, 0, 0, 0.2)',
          label: {
            text: '',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        }
      ]
    }
  ],
  plotOptions: {
    column: {
      grouping: true,
      groupXPadding: 1,
      pointWidth: 10
    },
    series: {
      turboThreshold: 10000
    }
  }
}

export const evpChartOptions = {
  chart: {
    zoomType: 'xy',
    reflow: true
  },
  exporting: {
    width: 2000,
    sourceWidth: 2000,
    sourceHeight: 1000,
    printMaxWidth: 1200,
    buttons: {
      contextButton: {
        menuItems: [
          'viewFullscreen',
          'printChart',
          'separator',
          'downloadJPEG',
          'downloadPDF',
          'downloadPNG'
        ]
      }
    }
  },
  time: {
    useUTC: false
  },
  title: {
    text: ''
  },
  tooltip: {
    valueDecimals: 2,
    dateTimeLabelFormats: {
      millisecond: '%d/%m/%Y %H:%M',
      second: '%d/%m/%Y %H:%M',
      minute: '%d/%m/%Y %H:%M',
      hour: '%d/%m/%Y %H:%M',
      day: '%d/%m/%Y %H:%M',
      week: '%d/%m/%Y %H:%M',
      month: '%d/%m/%Y %H:%M',
      year: '%d/%m/%Y %H:%M'
    },
    headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
    style: {
      fontSize: '16px'
    },
    useHTML: true
  },
  legend: {
    itemStyle: {
      fontSize: '14px'
    }
  },
  column: {},
  xAxis: {
    type: 'datetime',
    tickInterval: 24 * 3600 * 1000,
    labels: {
      formatter: function () {
        return dayjs(this.value).format('DD/MM')
      },
      style: {
        fontSize: '12px'
      }
    }
  },
  yAxis: [
    {
      gridLineWidth: 0,
      labels: {
        format: '{value}',
        style: {
          fontSize: '12px'
        }
      },
      title: {
        text: 'Leitura (mm)'
      },
      min: 0,
      max: 1,
      tickPositioner: () => [
        0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 4.5, 5, 5.5, 6, 6.5, 7, 7.5,
        8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12
      ],
      plotBands: [
        {
          from: 2,
          to: 2,
          color: 'rgba(0, 0, 0, 0.2)',
          label: {
            text: '',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        },
        {
          from: 4,
          to: 4,
          color: 'rgba(0, 0, 0, 0.2)',
          label: {
            text: '',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        },
        {
          from: 6,
          to: 6,
          color: 'rgba(0, 0, 0, 0.2)',
          label: {
            text: '',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        },
        {
          from: 8,
          to: 8,
          color: 'rgba(0, 0, 0, 0.2)',
          label: {
            text: '',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        },
        {
          from: 10,
          to: 10,
          color: 'rgba(0, 0, 0, 0.2)',
          label: {
            text: '',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        },
        {
          from: 12,
          to: 12,
          color: 'rgba(0, 0, 0, 0.2)',
          label: {
            text: '',
            style: {
              color: '#000',
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        }
      ]
    }
  ],
  plotOptions: {
    column: {
      pointWidth: 1,
      grouping: false
    },
    series: {
      turboThreshold: 10000
    }
  }
}
