import axios from 'axios'
import getValue from 'lodash/get'
import logError from '../utils/log'

const CLIENTS = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/client`
})

const getList = async (authToken, skip = 0, limit = 20) => {
  try {
    const response = await CLIENTS.get(`/?skip=${skip}&limit=${limit}`, {
      headers: { Authorization: `bearer ${authToken}` }
    })

    return response.data
  } catch (error) {
    logError('services@clients@getList', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao buscar a lista de clientes'
      )
    )
  }
}

const getClient = async (authToken, id) => {
  try {
    const response = await CLIENTS.get(`/${id}`, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@clients@getClient', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao buscar o cliente'
      )
    )
  }
}

const insertClient = async (authToken, client) => {
  try {
    const response = await CLIENTS.post(`/`, client, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@clients@insertClient', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao inserir um cliente'
      )
    )
  }
}

const updateClient = async (authToken, client) => {
  try {
    const response = await CLIENTS.put(`/`, client, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@clients@updateClient', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao inserir um cliente'
      )
    )
  }
}

export default {
  getList,
  getClient,
  insertClient,
  updateClient
}
