import axios from 'axios'
import getValue from 'lodash/get'
import logError from '../utils/log'

const FARMS = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/farm`
})

const getList = async (authToken, clientId, skip = 0, limit = 20) => {
  try {
    const response = await FARMS.get(
      `/?skip=${skip}&limit=${limit}&client_id=${clientId}`,
      {
        headers: { Authorization: `bearer ${authToken}` }
      }
    )

    return response.data
  } catch (error) {
    logError('services@farms@getList', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao buscar a lista de fazendas'
      )
    )
  }
}

const getFarm = async (authToken, id) => {
  try {
    const response = await FARMS.get(`/${id}`, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@farms@getFarm', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao buscar a fazenda'
      )
    )
  }
}

const insertFarm = async (authToken, farm) => {
  try {
    const response = await FARMS.post(`/`, farm, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@farms@insertFarm', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao inserir uma fazenda'
      )
    )
  }
}

const updateFarm = async (authToken, farm) => {
  try {
    const response = await FARMS.put(`/`, farm, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@farms@updateFarm', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao inserir uma fazenda'
      )
    )
  }
}

export default { getList, getFarm, insertFarm, updateFarm }
