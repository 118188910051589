import styled from 'styled-components'
import { styled as styledMaterialUI } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import List from '@material-ui/core/List'

export const SearchRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  width: 100px;
  left: 0px;
  top: 96px;
  margin: 0px 16px;
  z-index: 99999;
`

export const SearchInput = styledMaterialUI(TextField)({
  backgroundColor: '#ffff',
  minWidth: 250
})

export const SearchList = styledMaterialUI(List)({
  backgroundColor: '#fff'
})
