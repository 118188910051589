import styled from 'styled-components'
import { styled as styledMaterialUI } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TypoHeader = styledMaterialUI(Typography)({
  fontSize: '18px',
  fontWeight: 'bold',
  margin: 8
})

export const TypoTitle = styledMaterialUI(Typography)({
  fontSize: '16px',
  fontWeight: 'bold',
  margin: 8
})

export const TypoText = styledMaterialUI(Typography)({
  fontSize: '14px',
  margin: 8
})
