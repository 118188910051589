import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { DialogContainer, TypoHeader, Tensiometer, Text } from './styles'
import { validateTensiometerReading } from '../../../../utils/validators'

const labelProps = { shrink: true }

const TensiometerReadings = ({
  tensiometers,
  onChange,
  tensionReadType,
  hasCapsule
}) => {
  return (
    <DialogContainer>
      {!hasCapsule ? (
        <>
          <TypoHeader>Insira a leitura dos tensiômetros:</TypoHeader>
          {tensiometers.map((t, index) => {
            const id = get(t, 'tensiometer.id', 0)
            const depth = get(t, 'tensiometer.depth', 0)
            const label = `${depth} cm`
            const hasError = validateTensiometerReading(t.value, depth)

            return (
              <Tensiometer
                key={id}
                type="tel"
                fullwidth="true"
                variant="outlined"
                label={label}
                placeholder="leitura (mba)"
                value={t.value}
                onChange={(event) => onChange(event, index)}
                InputLabelProps={labelProps}
                error={!!hasError}
                helperText={hasError}
              />
            )
          })}
        </>
      ) : (
        <>
          <Text>
            Este setor faz registro de leituras automáticamente via capsula.
          </Text>
        </>
      )}
    </DialogContainer>
  )
}

TensiometerReadings.propTypes = {
  tensiometers: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  tensionReadType: PropTypes.number.isRequired,
  hasCapsule: PropTypes.bool.isRequired
}

TensiometerReadings.defaultProps = {
  onChange: () => {}
}

export default TensiometerReadings
