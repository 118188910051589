import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { matchSorter } from 'match-sorter'
import get from 'lodash/get'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import InputAdornment from '@material-ui/core/InputAdornment'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import Slide from '@material-ui/core/Slide'

import useTheme from '@material-ui/core/styles/useTheme'
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import LocationOnIcon from '@material-ui/icons/LocationOn'

import Geocode from 'react-geocode'

import AppBar from '../../Components/Appbar'
import DataTable from '../../Components/DataTable/index'

import LocationSelector from './LocationSelector'
import FarmsService from '../../services/farms'
import TimezoneService from '../../services/timezoneApi'
import { FarmInput, MapButton, TypoWarn } from './styles'
import {
  Column,
  Container,
  Content,
  ModalHeader,
  TypoHeader,
  CloseButton,
  Header,
  Row,
  OutlinedInput,
  IconButton,
  Button,
  TableWrapper,
  TableLoadingCompWrapper,
  Text,
  CustomLinearProgress
} from '../../styles/components'
import { validateFarmName, validateGeoLoc } from '../../utils/validators'
import { USER_ACCESS } from '../../utils/constants'
import { adminFarms } from '../../utils/pageAccess'
import ModalComponent from '../../Components/ModalComponent'
import ConfirmationDialog from '../../Components/ConfirmationDialog'
import CloseConfirmationDialog from '../../Components/CloseConfirmationDialog'

const searchOptions = {
  keys: ['name', 'lat', 'lng'],
  keepDiacritics: false
}

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
Geocode.setLanguage('pt-BR')
Geocode.setLocationType('APPROXIMATE')

const transitionDown = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const Farm = (props) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [openMap, setOpenMap] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [id, setId] = useState(0)
  const [farmName, setFarmName] = useState('')
  const [farmLocation, setFarmLocation] = useState({})
  const [farmTimezone, setFarmTimezone] = useState('')
  const [farmList, setFarmList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [farmAddress, setFarmAddress] = useState('')
  const [closeConfirmation, setCloseConfirmation] = useState(false)

  const currentAccess = get(props, 'currentUser.userAccess', null)
  const shouldDisable = adminFarms(currentAccess) === USER_ACCESS.READ

  useEffect(async () => {
    try {
      setIsLoading(true)
      const newList = await FarmsService.getList(
        props.currentUser.token,
        props.currentClient.id
      )
      setFarmList(newList)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setFarmList([])
    }
  }, [props.currentClient.id])

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => setSuccessMessage(''), 2000)
    }
  }, [successMessage])

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => setErrorMessage(''), 2000)
    }
  }, [errorMessage])

  const filteredFarmList = searchText.length
    ? matchSorter(farmList, searchText, searchOptions)
    : farmList

  const handleModalOpen = useCallback(() => {
    setId(0)
    setFarmName('')
    setFarmLocation({})
    setFarmTimezone('')
    setOpenDialog(true)
  }, [])

  const handleModalClose = useCallback(() => {
    setOpenDialog(false)
  }, [])

  const handleMapOpen = useCallback(() => {
    setOpenMap(true)
    setOpenDialog(false)
  }, [])

  const handleMapClose = useCallback(() => {
    setOpenMap(false)
    setOpenDialog(true)
  }, [])

  const _onAcceptCloseConfirmation = useCallback(() => {
    setCloseConfirmation(false)
  }, [])

  const _onRejectCloseConfirmation = useCallback(() => {
    setCloseConfirmation(false)
    setOpenDialog(true)
  }, [])

  const _onOpenCloseConfirmation = useCallback(() => {
    setOpenDialog(false)
    setCloseConfirmation(true)
  }, [])

  const getAddressFromCoord = useCallback(async (lat, lng) => {
    try {
      const response = await Geocode.fromLatLng(String(lat), String(lng))
      const address = get(response, 'results[0].formatted_address', '')

      setFarmAddress(address)
    } catch (error) {
      setFarmAddress('')
    }
  }, [])

  const getTimezoneFromCoord = useCallback(async (lat, lng) => {
    try {
      const timezone = await TimezoneService.getTimezoneFromLatLong(lat, lng)
      setFarmTimezone(timezone)
    } catch (error) {
      setFarmLocation({})
      setFarmTimezone('')
    }
  }, [])

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const _onSearch = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setSearchText(value)
  }, [])

  const _getColumns = useCallback(() => {
    return [
      {
        key: 'name',
        label: 'Fazenda',
        render_name: (item) => item.name
      },
      {
        key: 'latitude',
        label: 'Latitude',
        render_latitude: (item) => item.latitude
      },
      {
        key: 'longitude',
        label: 'Longitude',
        render_longitude: (item) => item.longitude
      }
    ]
  }, [])

  const _onChangeFarmName = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setFarmName(value)
  }, [])

  const onMapClick = useCallback(async (e) => {
    const newPosition = {
      lat: e.latLng.lat(''),
      lng: e.latLng.lng('')
    }
    await getAddressFromCoord(newPosition.lat, newPosition.lng)
    await getTimezoneFromCoord(newPosition.lat, newPosition.lng)
    setFarmLocation(newPosition)
  }, [])

  const _handleConfirmationOpen = useCallback(() => {
    setOpenDialog(false)
    setOpenConfirmation(true)
  }, [])

  const _handleConfirmationClose = useCallback(() => {
    setOpenConfirmation(false)
    setOpenDialog(true)
  }, [])

  const _handleSave = useCallback(async () => {
    try {
      setIsLoading(true)
      const payload = {
        client_id: props.currentClient.id,
        id,
        name: farmName,
        latitude: farmLocation.lat,
        longitude: farmLocation.lng,
        timezone: farmTimezone,
        farmAddress
      }
      const newList = [...farmList]
      const authToken = props.currentUser.token
      if (id) {
        const updatedFarm = await FarmsService.updateFarm(authToken, payload)
        const newItem = { ...payload, id: updatedFarm.id }
        const farmIndex = farmList.findIndex((item) => item.id === newItem.id)

        if (farmIndex > -1) {
          newList[farmIndex] = newItem
        }

        setSuccessMessage('Dados atualizados com sucesso!')
      } else {
        const newFarm = await FarmsService.insertFarm(authToken, payload)
        newList.push({ ...payload, id: newFarm.id })

        setSuccessMessage('Dados guardados com sucesso!')
      }
      setFarmList(newList)
      setOpenConfirmation(false)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setErrorMessage(error.message)
      setOpenConfirmation(false)
    }
  }, [farmList, id, farmName, farmLocation, farmTimezone, props.currentClient])

  const onClickItem = useCallback((item) => {
    getAddressFromCoord(item.latitude, item.longitude)
    setId(item.id)
    setFarmName(item.name)
    setFarmLocation({ lat: item.latitude, lng: item.longitude })
    setOpenDialog(true)
  }, [])

  const _renderAlert = () => {
    const alertMessage = successMessage || errorMessage
    const alertColor = successMessage ? 'success' : 'error'
    return (
      <Snackbar open={!!alertMessage} key={alertColor}>
        <Alert severity={alertColor}>{alertMessage}</Alert>
      </Snackbar>
    )
  }

  const _addFarm = () => {
    const nameError = validateFarmName(farmName)
    const locError = validateGeoLoc(farmLocation)
    const hasError = !!(nameError || locError || shouldDisable)

    return (
      <ModalComponent
        title="Cadastro de Fazendas"
        buttonLabel="Salvar"
        onClickClose={handleModalClose}
        onClickButton={_handleConfirmationOpen}
        disabled={hasError}
        open={openDialog}
        onClose={_onOpenCloseConfirmation}
      >
        <Column>
          <FarmInput
            value={farmName}
            variant="outlined"
            label="Fazenda"
            onChange={_onChangeFarmName}
            InputLabelProps={{ shrink: true }}
            placeholder="Insira o nome da fazenda"
            error={!!nameError}
            helperText={nameError}
            disabled={shouldDisable}
          />
          <MapButton
            variant="contained"
            color="primary"
            startIcon={<LocationOnIcon />}
            onClick={handleMapOpen}
            disabled={shouldDisable}
          >
            Localização
          </MapButton>
          {farmLocation.lat ? (
            <Column>
              <Typography variant="h6">Localização selecionada:</Typography>
              <Typography>{farmAddress || 'Carregando...'}</Typography>
            </Column>
          ) : (
            <TypoWarn>Por favor, selecione a localização da fazenda</TypoWarn>
          )}
        </Column>
      </ModalComponent>
    )
  }

  const _renderConfirmation = () => {
    return (
      <Dialog
        open={openConfirmation}
        onClose={_handleConfirmationClose}
        TransitionComponent={transitionDown}
        aria-labeledby="alert-dialog-title"
        aria-disbredby="alert-dialog-description"
      >
        <ConfirmationDialog
          onClickCancel={_handleConfirmationClose}
          onClickConfirm={_handleSave}
        />
      </Dialog>
    )
  }

  const _closeConfirmationDialog = () => {
    return (
      <Dialog
        open={closeConfirmation}
        TransitionComponent={transitionDown}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CloseConfirmationDialog
          onClickCancel={_onRejectCloseConfirmation}
          onClickConfirm={_onAcceptCloseConfirmation}
        />
      </Dialog>
    )
  }

  const _mapModal = () => {
    return (
      <Dialog fullScreen open={openMap}>
        <ModalHeader>
          <TypoHeader>Localização</TypoHeader>
          <CloseButton onClick={handleMapClose}>
            <CloseIcon />
          </CloseButton>
        </ModalHeader>
        <DialogContent style={{ padding: 0 }}>
          <LocationSelector
            markers={farmLocation}
            address={farmAddress}
            onMapClick={onMapClick}
          />
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Container>
      <AppBar history={props.history} />
      <Content>
        <Header
          display="flex"
          flexDirection="column"
          width="100%"
          height="10%"
          padding="3em 0 10em 0"
        >
          <Row justify="center" align="center">
            {isMobile ? (
              <IconButton
                color="primary"
                aria-label="add"
                onClick={handleModalOpen}
                disabled={shouldDisable}
              >
                <AddIcon />
              </IconButton>
            ) : (
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleModalOpen}
                disabled={shouldDisable}
              >
                Adicionar
              </Button>
            )}
            <OutlinedInput
              isMobile={isMobile}
              mobileWidth="60%"
              width="25%"
              id="outlined-adornment-search"
              label="Pesquisar"
              placeholder="Pesquisar"
              type="search"
              onChange={_onSearch}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              labelWidth={60}
            />
          </Row>
        </Header>
        {isLoading ? (
          <TableLoadingCompWrapper>
            <Text margin="12px" textColor="black">
              Carregando lista de fazendas...
            </Text>
            <CustomLinearProgress />
          </TableLoadingCompWrapper>
        ) : (
          <TableWrapper>
            <DataTable
              columns={_getColumns()}
              mobileLabel={(item) => item.name}
              mobileSubtitle={(item) => get(item, 'farmAddress', '')}
              data={filteredFarmList}
              onClickItem={onClickItem}
            />
          </TableWrapper>
        )}
      </Content>
      {_renderAlert()}
      {_addFarm()}
      {_mapModal()}
      {_renderConfirmation()}
      {_closeConfirmationDialog()}
    </Container>
  )
}

Farm.propTypes = {
  history: PropTypes.object,
  getFarms: PropTypes.func,
  currentClient: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired
  }),
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  setSuccessMessage: PropTypes.func,
  setErrorMessage: PropTypes.func
}

Farm.defaultProps = {
  getFarms: () => {}
}

const mapState = ({ auth, clients }) => {
  return {
    currentUser: auth.currentUser,
    currentClient: clients.currentClient
  }
}
export default connect(mapState)(Farm)
