import React, { useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { matchSorter } from 'match-sorter'

import PluviometerServices from '../../services/pluviometer'
import PluviometerReadingsService from '../../services/pluviometerReadings'

import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'
import TextField from '@material-ui/core/TextField'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'

import Snackbar from '@material-ui/core/Snackbar'
import InputAdornment from '@material-ui/core/InputAdornment'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import Alert from '@material-ui/lab/Alert'

import dayjs from 'dayjs'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { ptBR } from 'date-fns/locale'

import DataTable from '../../Components/DataTable'

import {
  Autocomplete,
  Button,
  Container,
  CustomLinearProgress,
  DateActions,
  DatePicker,
  DialogContent,
  Header,
  IconButton,
  OutlinedInput,
  PickersWrapper,
  Row,
  TableLoadingCompWrapper,
  Text,
  DateTimePicker,
  Input,
  NumericField,
  TableWrapper,
  Content
} from '../../styles/components'
import Appbar from '../../Components/Appbar'
import ModalComponent from '../../Components/ModalComponent'
import ConfirmationDialog from '../../Components/ConfirmationDialog'
import CloseConfirmationDialog from '../../Components/CloseConfirmationDialog'
import { validateNumberOrZero } from '../../utils/validators'
import { formatTimestamp, timestampToServer } from '../../utils/helpers'

const TransitionDown = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const defaultSearchState = {
  startDate: dayjs().subtract(1, 'week').valueOf(),
  endDate: dayjs().valueOf(),
  searchText: ''
}

const defaultReadingData = {
  id: 0,
  timestamp: dayjs().valueOf(),
  notes: '',
  value: 0,
  pluviometer: null
}
const defaultLists = {
  pluviometerList: [],
  readingList: []
}
const defaultPageManagerState = {
  tableIsLoading: false,
  showModal: false,
  showConfirmDiag: false,
  showCloseDiag: false
}

const alertDefaultState = {
  successMessage: '',
  errorMessage: ''
}

const searchOptions = {
  keys: [
    (item) => formatTimestamp(item.timestamp, 'DD/MM/YYYY'),
    (item) => formatTimestamp(item.timestamp, 'HH:mm')
  ],
  keepDiacritics: false,
  threshold: matchSorter.rankings.CONTAINS
}

const inputProps = {
  shrink: true
}

const columns = [
  {
    key: 'timestamp',
    label: 'Data',
    render_timestamp: (item) => formatTimestamp(item.timestamp, 'DD/MM/YYYY')
  },
  {
    key: 'timestamp',
    label: 'Hora',
    render_timestamp: (item) => formatTimestamp(item.timestamp, 'HH:mm')
  },
  {
    key: 'pluviometer',
    label: 'Pluviometro',
    render_pluviometer: (item) => item.pluviometer?.description ?? '-'
  },
  {
    key: 'value',
    label: 'Chuva(mm)',
    render_value: (item) => `${item.value} mm` || '-'
  }
]

const PluviometerReadings = (props) => {
  const [search, setSearch] = useState(defaultSearchState)
  const [reading, setReading] = useState(defaultReadingData)
  const [list, setList] = useState(defaultLists)
  const [alert, setAlert] = useState(alertDefaultState)
  const [pageManager, setPageManager] = useState(defaultPageManagerState)

  useEffect(() => {
    if (alert.successMessage) {
      setTimeout(() => setAlert({ successMessage: '' }), 2000)
    }
    if (alert.errorMessage) {
      setTimeout(() => setAlert({ errorMessage: '' }), 2000)
    }
  }, [alert])

  useEffect(async () => {
    try {
      const clientId = props.currentClient.id
      const authToken = props.currentUser.token
      if (clientId) {
        setPageManager((prev) => ({ ...prev, tableIsLoading: true }))
        const pluviometers = await PluviometerServices.getList(
          authToken,
          clientId
        )

        setList((prev) => ({ ...prev, pluviometerList: pluviometers }))
        setPageManager((prev) => ({ ...prev, tableIsLoading: false }))
      }
    } catch (error) {
      setList((prev) => ({ ...prev, pluviometerList: [] }))
      setPageManager((prev) => ({ ...prev, tableIsLoading: false }))
      setAlert((prev) => ({ ...prev, errorMessage: error.message }))
    }
  }, [props.currentClient])

  useEffect(async () => {
    try {
      const clientId = props.currentClient.id
      const authToken = props.currentUser.token
      if (clientId) {
        setPageManager((prev) => ({ ...prev, tableIsLoading: true }))
        const readings = await PluviometerReadingsService.getList(
          authToken,
          clientId,
          timestampToServer(search.startDate),
          timestampToServer(search.endDate)
        )

        setList((prev) => ({ ...prev, readingList: readings }))
        setPageManager((prev) => ({ ...prev, tableIsLoading: false }))
      }
    } catch (error) {
      setPageManager((prev) => ({ ...prev, tableIsLoading: false }))
      setAlert((prev) => ({ ...prev, errorMessage: error.message }))
    }
  }, [props.currentClient])

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const filteredList = search.searchText.length
    ? matchSorter(list.readingList, search.searchText, searchOptions)
    : list.readingList

  const _getMobileLabel = useCallback((item) => {
    const date = formatTimestamp(item.timestamp, 'DD/MM/YY')
    const time = formatTimestamp(item.timestamp, 'HH:mm')
    const pluviometer = item.pluviometer.description ?? ''

    return `${date} ${time} - ${pluviometer} `
  }, [])

  const _onChangeStartDate = useCallback(
    (newDate) => {
      setSearch((prevState) => ({
        ...prevState,
        startDate: newDate.valueOf()
      }))
    },
    [search.startDates]
  )

  const _onChangeEndDate = useCallback((newDate) => {
    setSearch((prevState) => ({
      ...prevState,
      endDate: newDate.valueOf()
    }))
  }, [])

  const _onClickSearch = useCallback(async () => {
    try {
      const clientId = props.currentClient.id
      const authToken = props.currentUser.token
      if (clientId) {
        setPageManager((prev) => ({ ...prev, tableIsLoading: true }))
        const readings = await PluviometerReadingsService.getList(
          authToken,
          clientId,
          timestampToServer(search.startDate),
          timestampToServer(search.endDate)
        )

        setList((prev) => ({ ...prev, readingList: readings }))
        setPageManager((prev) => ({ ...prev, tableIsLoading: false }))
      }
    } catch (error) {
      setPageManager((prev) => ({ ...prev, tableIsLoading: false }))
      setAlert((prev) => ({ ...prev, errorMessage: error.message }))
    }
  }, [props.currentClient, search.startDate, search.endDate])

  const _onChangeTableSearch = useCallback((event) => {
    const value = event.target.value ?? ''
    setSearch((prevState) => ({ ...prevState, searchText: value }))
  }, [])

  const _onSelectpluviometer = useCallback((event, value) => {
    setReading((prev) => ({ ...prev, pluviometer: value }))
  }, [])

  const _onChangeNotes = useCallback((event) => {
    const value = event.target.value ?? ''
    setReading((prev) => ({ ...prev, notes: value }))
  }, [])

  const _onChangeValue = useCallback(
    (event) => {
      const value = event.target.value ?? null
      setReading((prev) => {
        return { ...prev, value: value }
      })
    },
    [reading]
  )

  const _onChangeTimestamp = useCallback((newTimestamp) => {
    setReading((prev) => ({ ...prev, timestamp: newTimestamp }))
  }, [])

  const hasOnlyOnePluviometer = useMemo(() => {
    return list?.pluviometerList?.length === 1
  }, [list])

  const _handleModalOpen = useCallback(() => {
    setPageManager((prev) => ({
      ...prev,
      showModal: true
    }))
    setReading({
      ...defaultReadingData,
      pluviometer: hasOnlyOnePluviometer
        ? list?.pluviometerList?.[0]
        : undefined
    })
  }, [list, hasOnlyOnePluviometer])

  const _handleModalClose = useCallback(() => {
    setPageManager((prev) => ({ ...prev, showModal: false }))
  }, [])

  const _handleConfirmDiagOpen = useCallback(() => {
    setPageManager((prev) => ({
      ...prev,
      showConfirmDiag: true,
      showModal: false
    }))
  }, [])

  const _handleConfirmDiagClose = useCallback(() => {
    setPageManager((prev) => ({
      ...prev,
      showConfirmDiag: false,
      showModal: true
    }))
  }, [])

  const _handleCloseDiagConfirm = useCallback(() => {
    setPageManager((prev) => ({
      ...prev,
      showCloseDiag: false
    }))
  }, [])

  const _handleCloseDiagOpen = useCallback(() => {
    setPageManager((prev) => ({
      ...prev,
      showModal: false,
      showCloseDiag: true
    }))
  }, [])

  const _handleCloseDiagBack = useCallback(() => {
    setPageManager((prev) => ({
      ...prev,
      showModal: true,
      showCloseDiag: false
    }))
  }, [])

  const _handleClickItem = useCallback(
    (item) => {
      setReading((prev) => ({
        ...prev,
        id: item.id,
        timestamp: item.timestamp,
        value: item.value,
        notes: item.notes,
        pluviometer: item.pluviometer
      }))
      setPageManager((prev) => ({ ...prev, showModal: true }))
    },
    [reading, list]
  )

  const _onSave = useCallback(async () => {
    try {
      setPageManager((prev) => ({ ...prev, tableIsLoading: true }))
      const authToken = props.currentUser.token

      const newList = [...list.readingList]

      const payload = {
        id: reading.id,
        timestamp: timestampToServer(reading.timestamp),
        value: reading.value,
        notes: reading.notes,
        pluviometer: reading.pluviometer.id
      }

      if (!reading.id) {
        const newItem =
          await PluviometerReadingsService.insertPluviometerReading(
            authToken,
            payload
          )

        newList.unshift({
          ...payload,
          id: newItem.id,
          pluviometer: reading.pluviometer
        })
      } else {
        const updatedItem =
          await PluviometerReadingsService.updatePluviometerReading(
            authToken,
            payload
          )

        const index = newList.findIndex((i) => i.id === updatedItem.id)

        if (index > -1) {
          newList[index] = { ...payload, pluviometer: reading.pluviometer }
        }
      }

      setList((prev) => ({ ...prev, readingList: newList }))
      setAlert((prev) => ({
        ...prev,
        successMessage: 'Leitura salva com sucesso.'
      }))
      setPageManager((prev) => ({
        ...prev,
        tableIsLoading: false,
        showConfirmDiag: false
      }))
    } catch (error) {
      console.error(error.message)
      setAlert((prev) => ({ ...prev, errorMessage: error.message }))
      setPageManager((prev) => ({
        ...prev,
        tableIsLoading: false,
        showConfirmDiag: false
      }))
    }
  }, [reading, list.readingList])

  const _registryModal = () => {
    const readingValueHasError = validateNumberOrZero(reading.value)

    return (
      <ModalComponent
        title="Registro"
        buttonLabel="Salvar"
        onClickClose={_handleModalClose}
        open={pageManager.showModal}
        onClose={_handleCloseDiagOpen}
        onClickButton={_handleConfirmDiagOpen}
        disabled={!!readingValueHasError}
      >
        <DialogContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <DateTimePicker
              label="Data e Hora da Leitura"
              ampm={false}
              cancelLabel="Cancelar"
              okLabel="Confirmar"
              format={'dd/MM/yyyy, HH:mm'}
              inputMode="numeric"
              value={reading.timestamp}
              onChange={_onChangeTimestamp}
            />
          </MuiPickersUtilsProvider>

          <Autocomplete
            id="selector"
            options={list.pluviometerList}
            disableClearable
            noOptionsText="Sem porjetos para exibir"
            getOptionLabel={(op) => `${op?.description ?? ''}`}
            getOptionSelected={(op, current) => op.id === current.id}
            value={reading.pluviometer}
            onChange={_onSelectpluviometer}
            disabled={hasOnlyOnePluviometer}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Pluviometro(s)"
                placeholder="selecione o pluviometro"
                InputLabelProps={inputProps}
              />
            )}
          />

          <NumericField
            label="Leitura do pluviômetro(mm)"
            placeholder="insira a leitura do pluviometro."
            inputMode="decimal"
            InputLabelProps={inputProps}
            value={reading.value}
            error={!!readingValueHasError}
            helperText={readingValueHasError}
            onChange={(event) => _onChangeValue(event)}
          />
          <Input
            label="Observações"
            inputMode="text"
            placeholder="Opcional: insira observações acerca das leituras."
            variant="outlined"
            InputLabelProps={inputProps}
            multiline
            minRows={10}
            value={reading.notes}
            onChange={_onChangeNotes}
          />
        </DialogContent>
      </ModalComponent>
    )
  }

  const _confirmationDialog = () => {
    return (
      <Dialog
        open={pageManager.showConfirmDiag}
        TransitionComponent={TransitionDown}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ConfirmationDialog
          onClickCancel={_handleConfirmDiagClose}
          onClickConfirm={_onSave}
        />
      </Dialog>
    )
  }

  const _closeConfirmationDialog = () => {
    return (
      <Dialog
        open={pageManager.showCloseDiag}
        TransitionComponent={TransitionDown}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CloseConfirmationDialog
          onClickCancel={_handleCloseDiagBack}
          onClickConfirm={_handleCloseDiagConfirm}
        />
      </Dialog>
    )
  }

  const _renderAlert = () => {
    const alertMessage = alert.successMessage || alert.errorMessage
    const alertColor = alert.successMessage ? 'success' : 'error'
    return (
      <Snackbar open={!!alertMessage} key={alertColor}>
        <Alert severity={alertColor}>{alertMessage}</Alert>
      </Snackbar>
    )
  }

  return (
    <Container>
      <Appbar history={props.history} />
      <Content>
        <Header
          display="flex"
          flexDirection="column"
          width="100%"
          height="10%"
          padding="3em 0 15em 0"
        >
          <DateActions>
            <PickersWrapper isMobile={isMobile}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <DatePicker
                  isMobile={isMobile}
                  label="Data inicial"
                  value={search.startDate}
                  format="dd/MM/yyyy"
                  maxDate={dayjs(search.endDate).toDate()}
                  onChange={_onChangeStartDate}
                />
                <DatePicker
                  isMobile={isMobile}
                  label="Data final"
                  value={search.endDate}
                  format="dd/MM/yyyy"
                  minDate={dayjs(search.startDate).toDate()}
                  onChange={_onChangeEndDate}
                />
              </MuiPickersUtilsProvider>
            </PickersWrapper>
            {isMobile ? (
              <IconButton onClick={_onClickSearch}>
                <SearchIcon />
              </IconButton>
            ) : (
              <Button
                width="8em"
                height="2.5em"
                startIcon={<SearchIcon />}
                onClick={_onClickSearch}
              >
                Buscar
              </Button>
            )}
          </DateActions>
          <Row justify="center" align="center">
            <OutlinedInput
              isMobile={isMobile}
              width="25%"
              mobileWidth="60%"
              id="outlined-adornment-search"
              placeholder="Pesquisar na tabela"
              type="search"
              value={search.searchText}
              onChange={_onChangeTableSearch}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              labelWidth={60}
            />
            {isMobile ? (
              <IconButton onClick={_handleModalOpen}>
                <AddIcon />
              </IconButton>
            ) : (
              <Button
                margin="8px"
                width="9em"
                height="2.5em"
                startIcon={<AddIcon />}
                onClick={_handleModalOpen}
              >
                Registrar
              </Button>
            )}
          </Row>
        </Header>

        {pageManager.tableIsLoading ? (
          <TableLoadingCompWrapper>
            <Text margin="12px" textColor="black">
              Carregando registros de chuvas...
            </Text>
            <CustomLinearProgress />
          </TableLoadingCompWrapper>
        ) : (
          <TableWrapper>
            <DataTable
              columns={columns}
              data={filteredList}
              mobileLabel={_getMobileLabel}
              onClickItem={_handleClickItem}
            />
          </TableWrapper>
        )}
      </Content>
      {_renderAlert()}
      {_registryModal()}
      {_closeConfirmationDialog()}
      {_confirmationDialog()}
    </Container>
  )
}

PluviometerReadings.propTypes = {
  history: PropTypes.object,
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired
  }),
  currentClient: PropTypes.shape({
    id: PropTypes.number.isRequired
  })
}

const mapState = ({ auth, clients }) => {
  return {
    currentUser: auth.currentUser,
    currentClient: clients.currentClient
  }
}

export default connect(mapState)(PluviometerReadings)
