/* eslint-disable indent */
import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import get from 'lodash/get'
import InputMask from 'react-input-mask'
import { matchSorter } from 'match-sorter'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Slide from '@material-ui/core/Slide'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import DialogActions from '@material-ui/core/DialogActions'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'

import Autocomplete from '@material-ui/lab/Autocomplete'

import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

import AppBar from '../../Components/Appbar'
import DataTable from '../../Components/DataTable'
import UserService from '../../services/users'
import ProjectsSelector from './projectsmodal'
import {
  StyledAutocomplete,
  StyledCard,
  PageListText,
  TextInput,
  PermissionsTitle,
  RemoveButton,
  StyledFab,
  StyledSpan
} from './styles'
import {
  Column,
  Container,
  Content,
  ModalHeader,
  TypoHeader,
  CloseButton,
  Row,
  OutlinedInput,
  Header,
  TableWrapper,
  TableLoadingCompWrapper,
  Text,
  CustomLinearProgress,
  IconButton,
  Button
} from '../../styles/components'
import {
  USER_ACCESS,
  USER_ROLES,
  USER_ROLES_LIST,
  USER_PERMISSIONS_LIST,
  PAGES_INI_VALUE,
  PAGES_LABEL
} from '../../utils/constants'
import {
  validateRoles,
  validateUserName,
  validatePhone,
  validateEmail,
  validateClientName,
  validatePassword,
  validateProjectList
} from '../../utils/validators'
import {
  getRoleItemFromValue,
  getRoleLabelFromValue,
  getYesOrNoFromBoolean
} from '../../utils/helpers'
import { adminUsers } from '../../utils/pageAccess'
import ModalComponent from '../../Components/ModalComponent'
import ConfirmationDialog from '../../Components/ConfirmationDialog'
import HydraSwitch from '../../Components/GenericSwitch'
import CloseConfirmationDialog from '../../Components/CloseConfirmationDialog'

const transitionUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const transitionDown = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const inputLabel = {
  shrink: true
}

const phoneMaskProps = { mask: '(99) 99999-9999', maskChar: ' ' }

const searchOptions = {
  keys: [
    'name',
    (item) => getRoleLabelFromValue(item.role),
    (item) => getYesOrNoFromBoolean(item.active)
  ],
  keepDiacritics: false
}

const Users = (props) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [projectsModalOpen, setProjectsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [userAccess, setUserAccess] = useState(PAGES_INI_VALUE)
  const [active, setActive] = useState(true)
  const [assignedRole, setAssignedRole] = useState(USER_ROLES.ADMIN)
  const [id, setId] = useState(0)
  const [username, setUsername] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [phone, setPhone] = useState('')
  const [userList, setUserList] = useState([])
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [searchText, setSearchText] = useState('')
  const [selectedProjects, setSelectedProjects] = useState([])
  const [closeConfirmation, setCloseConfirmation] = useState(false)

  const currentAccess = get(props, 'currentUser.userAccess', null)
  const shouldDisable = adminUsers(currentAccess) === USER_ACCESS.READ

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const mobileCardWidth = isMobile ? '100%' : null

  useEffect(async () => {
    try {
      setIsLoading(true)

      const newUserList = await UserService.getList(
        props.currentUser.token,
        props.currentClient.id
      )

      setUserList(newUserList)
      setIsLoading(false)
    } catch (error) {
      setUserList([])
      setIsLoading(false)
    }
  }, [props.currentClient.id])

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => setSuccessMessage(''), 2000)
    }
  }, [successMessage])

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => setErrorMessage(''), 2000)
    }
  }, [errorMessage])

  const filteredUserList = searchText.length
    ? matchSorter(userList, searchText, searchOptions)
    : userList

  const _onChangeSearchText = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setSearchText(value)
  }, [])

  const _handleModalOpen = useCallback(() => {
    setId(0)
    setActive(true)
    setName('')
    setUsername('')
    setPhone('')
    setEmail('')
    setPassword('')
    setAssignedRole(USER_ROLES.ADMIN)
    setUserAccess(PAGES_INI_VALUE)
    setSelectedProjects([])
    setModalOpen(true)
  }, [])

  const _handleModalClose = useCallback(() => {
    setModalOpen(false)
  }, [])

  const _handleConfirmationOpen = useCallback(() => {
    setOpenConfirmation(true)
    setModalOpen(false)
  }, [])

  const _handleConfirmationClose = useCallback(() => {
    setOpenConfirmation(false)
    setModalOpen(true)
  }, [])

  const _handleProjectsModalOpen = useCallback(() => {
    setProjectsModalOpen(true)
  }, [])

  const _handleProjectsModalClose = useCallback(() => {
    setProjectsModalOpen(false)
  }, [])

  const _onChangeActive = useCallback((event, isChecked) => {
    setActive(isChecked)
  }, [])

  const _onChangeName = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setName(value)
  }, [])

  const _onChangeLogin = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setUsername(value)
  }, [])

  const _onChangePhone = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setPhone(value)
  }, [])

  const _onChangeEmail = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setEmail(value)
  }, [])

  const _onChangePassword = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setPassword(value)
  }, [])

  const _onChangeRole = useCallback((event, value) => {
    const roleValue = get(value, 'value', USER_ROLES.ADMIN)
    setAssignedRole(roleValue)
  }, [])

  const _onAcceptCloseConfirmation = useCallback(() => {
    setCloseConfirmation(false)
  }, [])

  const _onRejectCloseConfirmation = useCallback(() => {
    setCloseConfirmation(false)
    setModalOpen(true)
  }, [])

  const _onOpenCloseConfirmation = useCallback(() => {
    setModalOpen(false)
    setCloseConfirmation(true)
  }, [])

  const _onChangePermissions = useCallback(
    (value, key) => {
      const newState = { ...userAccess }
      newState[key] = value.value
      setUserAccess(newState)
    },
    [userAccess]
  )

  const onSelectProject = useCallback(
    (project) => {
      const newList = [...selectedProjects]
      const exists = selectedProjects.findIndex((p) => p.id === project.id)

      if (exists > -1) {
        newList.splice(exists, 1)
      } else {
        newList.push(project)
      }

      setSelectedProjects(newList)
    },
    [selectedProjects]
  )

  const _getColumns = useCallback(() => {
    return [
      { key: 'name', label: 'Nome', render_name: (item) => item.name },
      {
        key: 'role',
        label: 'Função',
        render_role: (item) => getRoleLabelFromValue(item.role)
      },
      {
        key: 'active',
        label: 'Ativo',
        render_active: (item) => (item.active ? 'Sim' : 'Não')
      }
    ]
  }, [])

  const _handleSave = useCallback(async () => {
    try {
      setIsLoading(true)

      const payload = {
        id,
        email,
        username,
        password,
        name,
        phone,
        role: assignedRole,
        projects: selectedProjects,
        userAccess,
        active,
        client_id: props.currentClient.id
      }

      const newList = [...userList]
      const authToken = props.currentUser.token

      if (id) {
        const updatedUser = await UserService.updateUser(authToken, payload)

        const userIndex = userList.findIndex(
          (item) => item.id === updatedUser.id
        )

        if (userIndex > -1) {
          newList[userIndex] = payload
        }

        setSuccessMessage('Usuário atualizado com sucesso!')
      } else {
        const newUser = await UserService.insertUser(authToken, payload)
        newList.push({ ...payload, id: newUser.id })

        setSuccessMessage('Usuário registrado com sucesso!')
      }
      setUserList(newList)
      setOpenConfirmation(false)
      setIsLoading(false)
    } catch (error) {
      setOpenConfirmation(false)
      setErrorMessage(error.message)
      setIsLoading(false)
    }
  }, [
    id,
    email,
    name,
    phone,
    password,
    username,
    assignedRole,
    userAccess,
    userList,
    active,
    selectedProjects
  ])

  const deleteUser = useCallback(async () => {
    try {
      setIsLoading(true)
      const deleteUser = await UserService.deleteUser(
        props.currentUser.token,
        id
      )
      const userIndex = userList.findIndex((item) => item.id === deleteUser.id)
      const newList = [...userList]
      if (userIndex < 0) newList.splice(userIndex, 1)
      setUserList(newList)
      setSuccessMessage('Usuário apagado com sucesso')
      setIsLoading(false)
      setModalOpen(false)
    } catch (error) {
      setIsLoading(false)
      setErrorMessage(error.message)
      setModalOpen(false)
    }
  }, [id, userList])

  const _onClickItem = useCallback((item) => {
    setId(item.id)
    setActive(!!item.active)
    setName(item.name)
    setUsername(item.username)
    setPhone(item.phone)
    setEmail(item.email)
    setAssignedRole(item.role)
    setUserAccess(item.userAccess)
    setSelectedProjects(item.projects)
    setPassword('')

    setModalOpen(true)
  }, [])

  const _closeConfirmationDialog = () => {
    return (
      <Dialog
        open={closeConfirmation}
        TransitionComponent={transitionDown}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CloseConfirmationDialog
          onClickCancel={_onRejectCloseConfirmation}
          onClickConfirm={_onAcceptCloseConfirmation}
        />
      </Dialog>
    )
  }

  const _usersModal = () => {
    const currentRole = getRoleItemFromValue(assignedRole)
    const shouldRenderPermissions = assignedRole !== USER_ROLES.ADMIN
    const shouldRenderProjects =
      assignedRole === USER_ROLES.SUPERVISOR ||
      assignedRole === USER_ROLES.NORMAL

    const shouldValidateProjectList =
      assignedRole === USER_ROLES.SUPERVISOR ||
      assignedRole === USER_ROLES.NORMAL
    const shouldRenderDeleteButton = !!id
    const roleHasError = validateRoles(assignedRole)

    const usernameHasError = validateUserName(username)
    const nameHasError = validateClientName(name)
    const phoneHasError = validatePhone(phone)
    const emailHasError = validateEmail(email)
    const passwordHasError =
      id && !password.length ? '' : validatePassword(password)
    const projectListHasError = shouldValidateProjectList
      ? validateProjectList(selectedProjects)
      : ''
    const hasError = !!(
      roleHasError ||
      usernameHasError ||
      nameHasError ||
      phoneHasError ||
      emailHasError ||
      passwordHasError ||
      shouldDisable ||
      projectListHasError
    )

    return (
      <ModalComponent
        title="Cadastro de usuários"
        buttonLabel="Salvar"
        onClickClose={_handleModalClose}
        onClickButton={_handleConfirmationOpen}
        disabled={hasError}
        open={modalOpen}
        onClose={_onOpenCloseConfirmation}
      >
        <Column>
          <Row justify="space-between">
            <FormControlLabel
              label="Ativo"
              labelPlacement="start"
              control={
                <HydraSwitch
                  checked={active}
                  onChange={_onChangeActive}
                  disabled={shouldDisable}
                />
              }
            />
            {shouldRenderDeleteButton ? (
              <RemoveButton onClick={deleteUser} disabled={shouldDisable}>
                <DeleteForeverIcon fontSize="large" />
              </RemoveButton>
            ) : null}
          </Row>
          <StyledAutocomplete
            required
            disableClearable
            options={USER_ROLES_LIST}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, v) => option.value === v.value}
            value={currentRole}
            onChange={_onChangeRole}
            disabled={!!id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Função"
                variant="outlined"
                InputLabelProps={inputLabel}
                placeholder="Selecione uma função"
                error={!!roleHasError}
                helperText={roleHasError}
              />
            )}
          />
          <TextInput
            required
            label="Nome de Usuário"
            variant="outlined"
            value={username}
            onChange={_onChangeLogin}
            InputLabelProps={inputLabel}
            disabled={shouldDisable}
            error={!!usernameHasError}
            helperText={usernameHasError}
          />
          <TextInput
            required
            label="Nome"
            variant="outlined"
            value={name}
            onChange={_onChangeName}
            disabled={shouldDisable}
            InputLabelProps={inputLabel}
            error={!!nameHasError}
            helperText={nameHasError}
          />
          <TextInput
            required
            label="E-mail"
            variant="outlined"
            value={email}
            disabled={shouldDisable}
            onChange={_onChangeEmail}
            InputLabelProps={inputLabel}
            error={!!emailHasError}
            helperText={emailHasError}
          />
          <TextInput
            required
            label="Telefone"
            type="tel"
            variant="outlined"
            value={phone}
            onChange={_onChangePhone}
            InputLabelProps={inputLabel}
            disabled={shouldDisable}
            error={!!phoneHasError}
            helperText={phoneHasError}
            InputProps={{
              inputComponent: InputMask
            }}
            inputProps={phoneMaskProps}
          />
          <TextInput
            required={!id}
            label="Senha"
            variant="outlined"
            value={password}
            disabled={shouldDisable}
            onChange={_onChangePassword}
            InputLabelProps={inputLabel}
            error={!!passwordHasError}
            helperText={passwordHasError}
          />
          {shouldRenderPermissions ? (
            <StyledCard width={mobileCardWidth}>
              <PermissionsTitle>Permissões:</PermissionsTitle>
              <List>
                {Object.keys(userAccess).map((key) => {
                  const label = get(PAGES_LABEL, `[${key}]`, '')
                  const value = get(
                    userAccess,
                    `[${key}]`,
                    USER_ACCESS.NOACCESS
                  )
                  const currentOption = USER_PERMISSIONS_LIST.find(
                    (item) => item.value === value
                  )
                  return (
                    <ListItem key={key}>
                      <ListItemText primary={`● ${label}`} />
                      <Autocomplete
                        disableClearable
                        options={USER_PERMISSIONS_LIST}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option, v) =>
                          option.value === v.value
                        }
                        value={currentOption}
                        onChange={(event, value) =>
                          _onChangePermissions(value, key)
                        }
                        disabled={shouldDisable}
                        renderInput={(params) => (
                          <PageListText
                            {...params}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    </ListItem>
                  )
                })}
              </List>
            </StyledCard>
          ) : null}
          {shouldRenderProjects ? (
            <>
              <StyledCard width={mobileCardWidth}>
                <PermissionsTitle>Projetos associados:</PermissionsTitle>
                <List>
                  {selectedProjects.map((item) => {
                    const itemLabel = `${get(
                      item,
                      'farm.client.name',
                      ''
                    )} - ${get(item, 'description', '')} - ${get(
                      item,
                      'farm.name',
                      ''
                    )}`
                    return (
                      <ListItem key={item.id}>
                        <ListItemText primary={itemLabel} />
                      </ListItem>
                    )
                  })}
                </List>
                <DialogActions>
                  <StyledFab
                    size="small"
                    color="secondary"
                    aria-label="add"
                    onClick={_handleProjectsModalOpen}
                  >
                    <AddIcon />
                  </StyledFab>
                </DialogActions>
              </StyledCard>
              <StyledSpan>{projectListHasError}</StyledSpan>
            </>
          ) : null}
        </Column>
      </ModalComponent>
    )
  }

  const _renderConfirmation = () => {
    return (
      <Dialog
        open={openConfirmation}
        onClose={_handleConfirmationClose}
        TransitionComponent={transitionDown}
        aria-labeledby="alert-dialog-title"
        aria-disbredby="alert-dialog-description"
      >
        <ConfirmationDialog
          onClickCancel={_handleConfirmationClose}
          onClickConfirm={_handleSave}
        />
      </Dialog>
    )
  }

  const _projectsModal = () => {
    const operatorIsSelected = assignedRole === USER_ROLES.NORMAL
    const selectedClientDefaultValue = operatorIsSelected
      ? props.currentClient
      : null

    return (
      <Dialog open={projectsModalOpen} onClose={_handleProjectsModalClose}>
        <ModalHeader>
          <TypoHeader>Cadastro de Projetos</TypoHeader>
          <CloseButton onClick={_handleProjectsModalClose}>
            <CloseIcon />
          </CloseButton>
        </ModalHeader>
        <ProjectsSelector
          onSelectProject={onSelectProject}
          selectedProjects={selectedProjects}
          assignedRole={assignedRole}
          clientSelectorDefaultValue={selectedClientDefaultValue}
        />
      </Dialog>
    )
  }

  const _renderAlert = () => {
    const alertMessage = successMessage || errorMessage
    const alertColor = successMessage ? 'success' : 'error'
    return (
      <Snackbar
        open={!!alertMessage}
        key={alertColor}
        TransitionComponent={transitionUp}
      >
        <Alert severity={alertColor}>{alertMessage}</Alert>
      </Snackbar>
    )
  }

  return (
    <Container>
      <AppBar history={props.history} />
      <Content>
        <Header
          display="flex"
          flexDirection="column"
          width="100%"
          height="10%"
          padding="3em 0 10em 0"
        >
          <Row justify="center" align="center" padding="0 0 2em 0">
            {isMobile ? (
              <IconButton
                color="primary"
                aria-label="add"
                onClick={_handleModalOpen}
                disabled={shouldDisable}
              >
                <AddIcon />
              </IconButton>
            ) : (
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={_handleModalOpen}
                disabled={shouldDisable}
              >
                Adicionar
              </Button>
            )}
            <OutlinedInput
              isMobile={isMobile}
              width="25%"
              mobileWidth="60%"
              id="outlined-adornment-search"
              value={searchText}
              onChange={_onChangeSearchText}
              label="Pesquisar"
              placeholder="Pesquisar"
              type="search"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              labelWidth={60}
            />
          </Row>
        </Header>
        {isLoading ? (
          <TableLoadingCompWrapper>
            <Text margin="12px" textColor="black">
              Carregando lista de usuários...
            </Text>
            <CustomLinearProgress />
          </TableLoadingCompWrapper>
        ) : (
          <TableWrapper height="90%">
            <DataTable
              columns={_getColumns()}
              data={filteredUserList}
              mobileLabel={(item) => item.name}
              mobileSubtitle={(item) =>
                `Função: ${getRoleLabelFromValue(item.role)}`
              }
              onClickItem={_onClickItem}
            />
          </TableWrapper>
        )}
      </Content>
      {_usersModal()}
      {_renderAlert()}
      {_renderConfirmation()}
      {_projectsModal()}
      {_closeConfirmationDialog()}
    </Container>
  )
}

Users.propTypes = {
  history: PropTypes.object,
  currentClient: PropTypes.object,
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired
  }),
  getProjects: PropTypes.func,
  clientsList: PropTypes.array.isRequired,
  getClients: PropTypes.func,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  setSuccessMessage: PropTypes.func,
  setErrorMessage: PropTypes.func
}

Users.defaultProps = {
  getProjects: () => {},
  getClients: () => {}
}

const mapState = ({ auth, clients }) => {
  return {
    currentUser: auth.currentUser,
    currentClient: clients.currentClient,
    clientsList: clients.list
  }
}

export default connect(mapState)(Users)
