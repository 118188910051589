import axios from 'axios'
import getValue from 'lodash/get'
import logError from '../utils/log'

const WIDGETS = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/widgets`
})

const getSnapshot = async (authToken, clientId) => {
  try {
    const response = await WIDGETS.get(`/snapshot?client_id=${clientId}`, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@widgets@getSnapshot', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Erro ao buscar os widgets de tensiometria'
      )
    )
  }
}

const getTensiometersStructure = async (authToken) => {
  try {
    const response = await WIDGETS.get(`/tensiometers/structure`, {
      headers: { Authorization: `bearer ${authToken}` }
    })

    return response.data
  } catch (error) {
    logError('services@widgets/structure@getTensiometersStructure', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao carregar a estrutura'
      )
    )
  }
}

const getPluviometersStructure = async (authToken) => {
  try {
    const response = await WIDGETS.get(`/Pluviometers/structure`, {
      headers: { Authorization: `bearer ${authToken}` }
    })

    return response.data
  } catch (error) {
    logError('services@widgets/structure@getPluviometersStructure', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao carregar a estrutura'
      )
    )
  }
}

const getTensiometersStore = async (authToken, tensiometers) => {
  try {
    const response = await WIDGETS.post(`/tensiometers`, tensiometers, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@widgets@getTensiometersStore', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao registrar os Widgets. '
      )
    )
  }
}

const getPluviometersStore = async (authToken, pluviometers) => {
  try {
    const response = await WIDGETS.post(`/pluviometers`, pluviometers, {
      headers: { Authorization: `bearer ${authToken}` }
    })

    return response.data
  } catch (error) {
    logError('services@widgets@getPluviometersStore', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao registrar os Widgets.'
      )
    )
  }
}

export default {
  getSnapshot,
  getTensiometersStructure,
  getPluviometersStructure,
  getTensiometersStore,
  getPluviometersStore
}
