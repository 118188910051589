const ROUTES = {
  DEFAULT: '/',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  CLIENTS: '/clients',
  IRRIGATION: '/irrigation',
  READINGS: '/readings',
  FARM: '/farm',
  PROJECTS: '/projects',
  SENSORGROUP: '/sensorgroup',
  USERS: '/users',
  TIPS: '/tips',
  TENSIOMETERCHARTS: '/tensiometercharts',
  EXTRACTORSCHARTS: '/extractorscharts',
  PHCHARTS: '/phcharts',
  ACCOUNTSETTINGS: '/accountsettings',
  TEMPERATURECHARTS: '/temperaturecharts',
  HUMIDITYCHARTS: '/humiditycharts',
  EVPCHARTS: '/evpcharts',
  PLUVIOMETER: '/pluviometer',
  PLUVIOMETRY: '/pluviometry'
}

export default ROUTES
