import React from 'react'
import { EmptyContainer } from './styles'

const EmptyData = () => {
  // const myStyles = makeStyles({
  //   card: {}
  // })

  return (
    <EmptyContainer elevation={0}>
      <h3>Sem dados</h3>
      <h5>Não existem dados para serem exibidos.</h5>
    </EmptyContainer>
  )
}

export default EmptyData
