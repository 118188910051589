import React from 'react'
import PropTypes from 'prop-types'

// import { makeStyles } from '@material-ui/core/styles'
import { Container, Cell } from './styles'

const TableHeader = ({ columns }) => {
  // const myStyles = makeStyles({
  //   card: {}
  // })

  return (
    <Container>
      {columns.map((c) => (
        <Cell key={c.label}>
          <h4>{c.label}</h4>
        </Cell>
      ))}
    </Container>
  )
}

TableHeader.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired
    })
  ).isRequired
}
TableHeader.defaultProps = {
  columns: []
}

export default TableHeader
