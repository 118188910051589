import axios from 'axios'
import getValue from 'lodash/get'
import logError from '../utils/log'

const IRRIGATIONS = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/irrigation`
})

const getList = async (authToken, clientId, startDate, endDate) => {
  try {
    const response = await IRRIGATIONS.get(
      `/?client_id=${clientId}&startDate=${startDate}&endDate=${endDate}`,
      {
        headers: { Authorization: `bearer ${authToken}` }
      }
    )

    return response.data
  } catch (error) {
    logError('services@irrigations@getList', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao buscar a lista de irrigações'
      )
    )
  }
}

const insertIrrigation = async (authToken, irrigation) => {
  try {
    const response = await IRRIGATIONS.post(`/`, irrigation, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@irrigations@insertIrrigation', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao inserir a irrigação'
      )
    )
  }
}

const updateIrrigation = async (authToken, irrigation) => {
  try {
    const response = await IRRIGATIONS.put(`/`, irrigation, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@irrigations@updateIrrigation', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao atualizar a irrigation'
      )
    )
  }
}

export default {
  getList,
  insertIrrigation,
  updateIrrigation
}
