import styled from 'styled-components'
import { styled as styledMaterialUI } from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'
export const Container = styled.div`
  display: flex;
  flex: 1;
  position: fixed;
  top: 0;
  width: 100%;
  height: 5%;
  flex-direction: row;
  align-items: center;
`

export const LogoContainer = styled.img`
  height: 50px;
  cursor: pointer;
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  float: right;
  margin-left: auto;
  cursor: pointer;
`

export const AccordionContainer = styled.div``

export const MenuSizeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 8%;
  @media (max-width: 1080px) {
    width: 40%;
  }
  @media (max-width: 720px) {
    width: 40%;
  }
`

export const StyledButton = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 128px;
  color: Black;
  background-color: white;
  list-style: none;
  margin: 0;
  font-size: 14px;

  &:hover:not(:disabled),
  &:active:not(:disabled),
  &focus {
    outline: 0;
    color: Black;
    background-color: #f0f2f5;
    cursor: pointer;
  }
`
export const HydraAppBar = styledMaterialUI(AppBar)({
  backgroundColor: (props) => props.background || null
})
