import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import TreeView from '@material-ui/lab/TreeView'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined'

import {
  Container,
  Body,
  WidgetSelector,
  SelectorContent,
  GaugeIcon,
  EditWidgetIcon,
  EditorText,
  EditorTextContainer,
  PowerIcon,
  PluviometerIcon,
  Title
} from './styles'
import ModalComponent from '../../../Components/ModalComponent'
import TensiometerWidgetEditor from '../../../Components/widgets/WidgetsModalContent/TensiometerWidgetEditor'
import PluviometerWidgetEditor from '../../../Components/widgets/WidgetsModalContent/PluviometerWidgetEditor'
import {
  getTensiometersStore,
  getPluviometersStore,
  getTensiometersStructure,
  getPluviometersStructure,
  setErrorMessage,
  setSuccessMessage
} from '../../../store/reducers/widgets'

function SelectWidgets(props) {
  const [tensiometerEditorOpen, setTensiometerEditorOpen] = useState(false)
  const [pluviometerEditorOpen, setPluviometerEditorOpen] = useState(false)
  const [expanded, setExpanded] = useState([])

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const mobileWidth = isMobile ? '90%' : '30%'

  useEffect(() => {
    props.getTensiometersStructure()
    props.getPluviometersStructure()
  }, [])

  useEffect(() => {
    if (props.successMessage) {
      setTimeout(props.setSuccessMessage, 2000)
    }
  }, [props.successMessage])

  useEffect(() => {
    if (props.errorMessage) {
      setTimeout(props.setErrorMessage, 2000)
    }
  }, [props.errorMessage])

  const _handleTensiometerModalOpen = useCallback(() => {
    setExpanded([])
    setTensiometerEditorOpen(true)
  }, [])

  const _handleTensiometerModalClose = useCallback(() => {
    setTensiometerEditorOpen(false)
  }, [])

  const _handlePluviometerModalOpen = useCallback(() => {
    setExpanded([])
    setPluviometerEditorOpen(true)
  }, [])

  const _handlePluviometerModalClose = useCallback(() => {
    setPluviometerEditorOpen(false)
  }, [])

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds)
  }

  const onClickSaveTensiometerWidgets = useCallback(() => {
    props.getTensiometersStore({
      client_id: props.currentClient,
      tensiometers: props.tensiometersActivated
    })
    setTensiometerEditorOpen(false)
  }, [props.tensiometersActivated, props.currentClient])

  const onClickSavePluviometerWidgets = useCallback(() => {
    props.getPluviometersStore({
      client_id: props.currentClient,
      pluviometers: props.pluviometersActivated
    })
    setPluviometerEditorOpen(false)
  }, [props.pluviometersActivated, props.currentClient])

  const _TensiometerEditorModal = () => {
    return (
      <ModalComponent
        onClose={_handleTensiometerModalClose}
        onClickClose={_handleTensiometerModalClose}
        title="Tensiômetros"
        open={tensiometerEditorOpen}
        onClickButton={onClickSaveTensiometerWidgets}
        buttonLabel="Salvar"
        o
      >
        <TreeView
          expanded={expanded}
          defaultCollapseIcon={<RemoveOutlinedIcon />}
          defaultExpandIcon={<AddOutlinedIcon />}
          onNodeToggle={handleToggle}
        >
          <TensiometerWidgetEditor />
        </TreeView>
      </ModalComponent>
    )
  }

  const _PluviometerEditorModal = () => {
    return (
      <ModalComponent
        onClose={_handlePluviometerModalClose}
        onClickClose={_handlePluviometerModalClose}
        title="Pluviômetros"
        open={pluviometerEditorOpen}
        onClickButton={onClickSavePluviometerWidgets}
        buttonLabel="Salvar"
      >
        <TreeView
          expanded={expanded}
          defaultCollapseIcon={<RemoveOutlinedIcon />}
          defaultExpandIcon={<AddOutlinedIcon />}
          onNodeToggle={handleToggle}
        >
          <PluviometerWidgetEditor />
        </TreeView>
      </ModalComponent>
    )
  }

  const _renderAlert = () => {
    const alertMessage = props.successMessage || props.errorMessage
    const alertColor = props.successMessage ? 'success' : 'error'
    return (
      <Snackbar open={!!alertMessage} key={alertMessage}>
        <Alert severity={alertColor}>{alertMessage}</Alert>
      </Snackbar>
    )
  }

  return (
    <Container width={mobileWidth}>
      <Body>
        <Title>Lista de Widgets</Title>

        <WidgetSelector>
          <SelectorContent>
            <EditorTextContainer>
              <GaugeIcon />
              <EditorText>Tensiômetros</EditorText>
            </EditorTextContainer>
            <EditorTextContainer>
              <PowerIcon />
              <EditorText>
                Ativos: {props.tensiometersActivated.length}
              </EditorText>
            </EditorTextContainer>
            <EditWidgetIcon button onClick={_handleTensiometerModalOpen} />
          </SelectorContent>
        </WidgetSelector>

        <WidgetSelector>
          <SelectorContent>
            <EditorTextContainer>
              <PluviometerIcon />
              <EditorText>Pluviômetros</EditorText>
            </EditorTextContainer>
            <EditorTextContainer>
              <PowerIcon />
              <EditorText>
                Ativos: {props.pluviometersActivated.length}
              </EditorText>
            </EditorTextContainer>
            <EditWidgetIcon button onClick={_handlePluviometerModalOpen} />
          </SelectorContent>
        </WidgetSelector>
      </Body>
      {_TensiometerEditorModal()}
      {_PluviometerEditorModal()}
      {_renderAlert()}
    </Container>
  )
}

SelectWidgets.propTypes = {
  getTensiometersStore: PropTypes.func,
  getPluviometersStore: PropTypes.func,
  setSuccessMessage: PropTypes.func,
  setErrorMessage: PropTypes.func,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  getTensiometersStructure: PropTypes.func,
  getPluviometersStructure: PropTypes.func,
  getWidgetsSnapshot: PropTypes.func,
  tensiometerWidgetList: PropTypes.array,
  tensiometersActivated: PropTypes.array,
  pluviometersActivated: PropTypes.array,
  currentClient: PropTypes.shape({
    id: PropTypes.number.isRequired
  })
}

SelectWidgets.defaultProps = {
  getTensiometersStore: () => {},
  getPluviometersStore: () => {},
  setSuccessMessage: () => {},
  setErrorMessage: () => {},
  getTensiometersStructure: () => {},
  getPluviometersStructure: () => {}
}

const mapState = ({ widgets, clients }) => {
  return {
    successMessage: widgets.successMessage,
    errorMessage: widgets.errorMessage,
    tensiometersActivated: widgets.tensiometersActivated,
    pluviometersActivated: widgets.pluviometersActivated,
    currentClient: clients.currentClient
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTensiometersStore,
      getPluviometersStore,
      setSuccessMessage,
      setErrorMessage,
      getTensiometersStructure,
      getPluviometersStructure
    },
    dispatch
  )

export default connect(mapState, mapDispatchToProps)(SelectWidgets)
