/* eslint-disable indent */
import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import get from 'lodash/get'
import lodashIsNumber from 'lodash/isNumber'
import { matchSorter } from 'match-sorter'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import InputAdornment from '@material-ui/core/InputAdornment'
import Step from '@material-ui/core/Step'
import StepContent from '@material-ui/core/StepContent'
import StepLabel from '@material-ui/core/StepLabel'
import Slide from '@material-ui/core/Slide'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import Dialog from '@material-ui/core/Dialog'

import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'

import DateFnsUtils from '@date-io/date-fns'
import { ptBR } from 'date-fns/locale'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import ReadingsService from '../../services/readings'
import ProjectsService from '../../services/projects'
import SensorGroupService from '../../services/sensorgroup'

import AppBar from '../../Components/Appbar'
import ProjectSelection from './ReadingsConfig/ProjectSelection/index'
import BaterySelection from './ReadingsConfig/BaterySelection/index'
import Tensiometer from './ReadingsConfig/TensiometerReadings/index'
import Extractors from './ReadingsConfig/ExtractorsReadings/index'
import ExtractorsPH from './ReadingsConfig/ExtractorPHReadings/index'
import Comments from './ReadingsConfig/ReadingComments/index'
import ReadingsReview from './ReadingsConfig/ReadingsReview/index'
import ReadingTime from './ReadingsConfig/ReadingTime/index'

import DataTable from '../../Components/DataTable'
import {
  DialogBox,
  ModalContent,
  StepperDivider,
  StepperDialog,
  HelpText
} from './styles'

import {
  Container,
  StepOptionalLabel,
  Header,
  DateActions,
  DatePicker,
  PickersWrapper,
  Button,
  IconButton,
  Row,
  OutlinedInput,
  TableLoadingCompWrapper,
  Text,
  CustomLinearProgress,
  StepperContainer,
  Stepper,
  TableWrapper,
  Content
} from '../../styles/components'
import {
  validateExtractorsPhReading,
  validateExtractorsReading,
  validateTensiometerReading
} from '../../utils/validators'
import StepperModal from '../../Components/StepperModal'
import ConfirmationDialog from '../../Components/ConfirmationDialog'
import HelpDialog from '../../Components/StepperModal/HelpDialog/index'
import { getNotifications } from '../../store/reducers/notifications'
import { bindActionCreators } from 'redux'
import { TENSION_READING_VALUE } from '../../utils/constants'
import CloseConfirmationDialog from '../../Components/CloseConfirmationDialog'
import { formatTimestamp, timestampToServer } from '../../utils/helpers'

const STEPS = {
  PROJECT_SELECTION: { value: 0, label: 'Projeto', isRequired: true },
  SENSOR_GROUP_SELECTION: { value: 1, label: 'Bateria', isRequired: true },
  DATETIME_SELECTION: { value: 2, label: 'Data e Hora', isRequired: true },
  TENSIOMETERS: { value: 3, label: 'Tensiômetros', isRequired: true },
  EXTRATORS: { value: 4, label: 'Extratores' },
  EXTRACTORS_PH: { value: 5, label: 'pH' },
  NOTES: { value: 6, label: 'Observações' },
  REVIEW: { value: 7, label: 'Resumo', isRequired: true }
}

const TransitionDown = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const searchOptions = {
  keys: [
    (item) => formatTimestamp(item.timestamp, 'DD/MM/YYYY'),
    (item) => formatTimestamp(item.timestamp, 'HH:mm'),
    (item) => get(item, 'sensorGroup.description', '-'),
    'notes'
  ],
  keepDiacritics: false,
  threshold: matchSorter.rankings.CONTAINS
}

const columns = [
  {
    key: 'timestamp',
    label: 'Data',
    render_timestamp: (item) => formatTimestamp(item.timestamp, 'DD/MM/YYYY')
  },
  {
    key: 'timestamp',
    label: 'Hora',
    render_timestamp: (item) => formatTimestamp(item.timestamp, 'HH:mm')
  },
  {
    key: 'sensorGroup',
    label: 'Bateria',
    render_sensorGroup: (item) => get(item, 'sensorGroup.description', '-')
  },
  {
    key: 'notes',
    label: 'Observações',
    render_notes: (item) => item.notes || '-'
  }
]

const Readings = (props) => {
  const [searchText, setSearchText] = useState('')
  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, 'week').valueOf()
  )
  const [endDate, setEndDate] = useState(dayjs().valueOf())
  const [isReadingModalOpen, setIsReadingModalOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(STEPS.PROJECT_SELECTION.value)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [projectsList, setProjectsList] = useState([])
  const [sensorGroupsList, setSensorGroupsList] = useState([])
  const [readingsList, setReadingsList] = useState([])
  const [id, setId] = useState(0)
  const [notes, setNotes] = useState('')
  const [timestamp, setTimestamp] = useState(dayjs().valueOf())
  const [project, setProject] = useState(null)
  const [sensorGroup, setSensorGroup] = useState(null)
  const [tensionReadType, setTensionReadType] = useState(0)
  const [tensiometers, setTensiometers] = useState([])
  const [extractors, setExtractors] = useState([])
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false)
  const [activeExtractors, setActiveExtractors] = useState(false)
  const [activePH, setActivePH] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const hasCapsule = tensionReadType === TENSION_READING_VALUE.CAPSULE

  const filteredList = searchText.length
    ? matchSorter(readingsList, searchText, searchOptions)
    : readingsList

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => setSuccessMessage(''), 2000)
    }
  }, [successMessage])

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => setErrorMessage(''), 2000)
    }
  }, [errorMessage])

  const getReadingsList = useCallback(async () => {
    try {
      const clientId = props.currentClient.id
      const authToken = props.currentUser.token
      if (clientId) {
        setIsLoading(true)
        const list = await ReadingsService.getList(
          authToken,
          clientId,
          timestampToServer(startDate),
          timestampToServer(endDate)
        )

        console.log('reading list', list)
        setReadingsList(list)
        setIsLoading(false)
      }
    } catch (error) {
      setReadingsList([])
      setIsLoading(false)
      setErrorMessage(error.message)
    }
  }, [props.currentClient, startDate, endDate])

  useEffect(() => {
    getReadingsList()
  }, [props.currentClient])

  useEffect(async () => {
    try {
      const clientId = props.currentClient.id
      const authToken = props.currentUser.token
      if (clientId) {
        setIsLoading(true)
        const projects = await ProjectsService.getList(authToken, clientId)
        setProjectsList(projects)
        setProject(null)
        setIsLoading(false)
      }
    } catch (error) {
      setProjectsList([])
      setProject(null)
      setIsLoading(false)
      setErrorMessage(error.message)
    }
  }, [props.currentClient])

  useEffect(async () => {
    try {
      const authToken = props.currentUser.token
      const clientId = props.currentClient.id
      const projectId = get(project, 'id', 0)
      if (projectId) {
        const list = await SensorGroupService.getList(
          authToken,
          clientId,
          projectId
        )
        const activeSensors = list.filter((s) => s.active)
        setSensorGroupsList(activeSensors)
      }
    } catch (error) {
      setSensorGroupsList([])
      setIsLoading(false)
      setErrorMessage(error.message)
    }
  }, [project])

  useEffect(() => {
    props.getNotifications()
  }, [readingsList])

  const _handleClickAdd = useCallback(() => {
    setId(0)
    setTimestamp(dayjs().valueOf())
    setProject(null)
    setSensorGroup(null)
    setTensiometers([])
    setExtractors([])
    setNotes('')
    setActiveExtractors(false)
    setActivePH(false)
    setActiveStep(STEPS.PROJECT_SELECTION.value)
    setIsReadingModalOpen(true)
  }, [])

  const _handleClickItem = useCallback((reading) => {
    setId(reading.id)
    setTimestamp(reading.timestamp)
    setProject(get(reading, 'sensorGroup.sector.project', null))
    setSensorGroup(get(reading, 'sensorGroup', null))
    setTensiometers(get(reading, 'readingsTensiometers', []))
    setExtractors(get(reading, 'readingsExtractors', []))
    setNotes(reading.notes)
    setActiveExtractors(!!get(reading, 'active_ec'))
    setActivePH(!!get(reading, 'active_ph'))
    setActiveStep(STEPS.PROJECT_SELECTION.value)
    setIsReadingModalOpen(true)
  }, [])

  const _handleClose = useCallback(() => {
    setActiveStep(STEPS.PROJECT_SELECTION.value)
    setIsReadingModalOpen(false)
  }, [])

  const _onSearch = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setSearchText(value)
  }, [])

  const _onChangeStartDate = useCallback((newDate) => {
    setStartDate(dayjs(newDate).valueOf())
  }, [])

  const _onChangeEndDate = useCallback((newDate) => {
    setEndDate(dayjs(newDate).valueOf())
  }, [])

  const _onChangeExtractorActive = useCallback((isChecked) => {
    setActiveExtractors(isChecked)
  }, [])

  const _onChangePhActive = useCallback((isChecked) => {
    setActivePH(isChecked)
  }, [])

  const _handleSave = useCallback(async () => {
    try {
      setIsLoading(true)
      const authToken = props.currentUser.token
      const payload = {
        id,
        notes,
        timestamp: timestampToServer(timestamp),
        readingsTensiometers: tensiometers,
        readingsExtractors: extractors,
        sensorGroup,
        sensor_group_id: sensorGroup.id,
        active_ec: activeExtractors,
        active_ph: activePH
      }
      const newReadingsList = [...readingsList]
      if (id) {
        const updatedReading = await ReadingsService.updateReading(
          authToken,
          payload
        )
        const index = newReadingsList.findIndex(
          (r) => r.id === updatedReading.id
        )

        if (index > -1) {
          newReadingsList[index] = payload
        }
      } else {
        const newReading = await ReadingsService.insertReading(
          authToken,
          payload
        )
        newReadingsList.unshift({ ...payload, id: newReading.id })
      }

      setReadingsList(newReadingsList)
      setSuccessMessage('Leitura enviada com sucesso')
      setIsLoading(false)
    } catch (error) {
      setErrorMessage(error.message)
      setIsLoading(false)
    }
  }, [
    id,
    notes,
    timestamp,
    tensiometers,
    extractors,
    sensorGroup,
    readingsList,
    activeExtractors,
    activePH
  ])

  const _onAcceptConfirmation = useCallback(async () => {
    _handleSave()
    setActiveStep(STEPS.PROJECT_SELECTION.value)
    setShowConfirmation(false)
  }, [_handleSave])

  const _onRejectConfirmation = useCallback(() => {
    setShowConfirmation(false)
    setIsReadingModalOpen(true)
  }, [])

  const _openCloseConfirmation = useCallback(() => {
    setIsReadingModalOpen(false)
    setShowCloseConfirmation(true)
  }, [])

  const _onAcceptCloseConfirmation = useCallback(async () => {
    setActiveStep(STEPS.PROJECT_SELECTION.value)
    setShowCloseConfirmation(false)
  }, [])

  const _onRejectCloseConfirmation = useCallback(() => {
    setShowCloseConfirmation(false)
    setIsReadingModalOpen(true)
  }, [])

  const _handleNextStep = useCallback(
    () => setActiveStep((prevActiveStep) => prevActiveStep + 1),
    []
  )

  const _handleBackStep = useCallback(
    () => setActiveStep((prevActiveStep) => prevActiveStep - 1),
    []
  )

  const _handleFinishSteps = useCallback(() => {
    setIsReadingModalOpen(false)
    setShowConfirmation(true)
  }, [])

  const _onChangeReadingDate = useCallback((newTimestamp) => {
    setTimestamp(newTimestamp)
  }, [])

  const _onChangeProject = useCallback((event, value) => {
    setProject(value)
  }, [])

  const _onChangeSensorGroup = useCallback((event, value) => {
    setSensorGroup(value)
    setTensionReadType(value.tension_read_type)
    setTensiometers(value.tensiometers.map((t) => ({ tensiometer: t })))
    setExtractors(value.extractors.map((e) => ({ extractor: e })))
  }, [])

  const _onChangeTensiometerReading = useCallback((event, index) => {
    const newValue = get(event, 'target.value', '')
    setTensiometers((oldState) => {
      const newList = [...oldState]
      const item = { ...newList[index] }
      item.value = newValue
      newList[index] = item

      return newList
    })
  }, [])

  const _onChangeExtractorReading = useCallback((event, index) => {
    const newValue = get(event, 'target.value', '')
    setExtractors((oldState) => {
      const newList = [...oldState]
      const item = { ...newList[index] }
      item.value = newValue
      newList[index] = item

      return newList
    })
  }, [])

  const _onChangeExtractorPhReading = useCallback((event, index) => {
    const newValue = get(event, 'target.value', '')
    setExtractors((oldState) => {
      const newList = [...oldState]
      const item = { ...newList[index] }
      item.ph_value = newValue
      newList[index] = item

      return newList
    })
  }, [])

  const _handleHelpDialogOpen = useCallback(() => {
    setHelpDialogIsOpen(true)
  }, [])

  const _handleHelpDialogClose = useCallback(() => {
    setHelpDialogIsOpen(false)
  }, [])

  const _onChangeNotes = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setNotes(value)
  }, [])

  const _canProceedNextStep = useCallback(() => {
    switch (activeStep) {
      case STEPS.PROJECT_SELECTION.value:
        return !!get(project, 'id', 0)
      case STEPS.SENSOR_GROUP_SELECTION.value:
        return !!get(sensorGroup, 'id', 0)
      case STEPS.DATETIME_SELECTION.value:
        return dayjs(timestamp).isValid()
      case STEPS.TENSIOMETERS.value: {
        const canProceed = !tensiometers.find((t) => {
          const depth = get(t, 'tensiometer.depth', 0)
          return validateTensiometerReading(t.value, depth)
        })
        return canProceed
      }
      case STEPS.EXTRATORS.value:
        return !extractors.find((e) => validateExtractorsReading(e.value))
      case STEPS.EXTRACTORS_PH.value:
        return !extractors.find(
          (e) =>
            validateExtractorsPhReading(e.ph_value) &&
            !lodashIsNumber(e.ph_value)
        )
      default:
        return true
    }
  }, [activeStep, project, sensorGroup, timestamp, tensiometers, extractors])

  const _getMobileLabel = useCallback((item) => {
    const date = formatTimestamp(
      item.timestamp,

      'DD/MM/YY'
    )
    const time = formatTimestamp(item.timestamp, 'HH:mm')
    const sensor = get(item, 'sensorGroup.description', '-')

    return `${date} ${time} - ${sensor}`
  }, [])

  const renderHelpText = () => {
    switch (activeStep) {
      case STEPS.PROJECT_SELECTION.value:
        return (
          <HelpText>
            Neste passo selecione o projeto referente das leituras a serem
            registradas.
          </HelpText>
        )
      case STEPS.SENSOR_GROUP_SELECTION.value:
        return (
          <HelpText>
            Neste passo selecione a bateria referente das leituras a serem
            registradas.
          </HelpText>
        )
      case STEPS.DATETIME_SELECTION.value:
        return (
          <HelpText>
            Neste passo selecione ou digite a data e hora das leituras a serem
            registradas.
          </HelpText>
        )
      case STEPS.TENSIOMETERS.value:
        return (
          <HelpText>
            Neste passo informe a leitura dos tensiômetros. As leituras devem
            ter no minimo um valor igual a profundidade do tensiômetro e não
            podem exceder 800cm.
          </HelpText>
        )
      case STEPS.EXTRATORS.value:
        return (
          <HelpText>
            Neste passo informe se deseja ou não registrar as leituras dos
            extratores e então preencher os espaços com os dados coleta. As
            leituras não podem exceder 10.000μS/cm. Este passo é opcional.
          </HelpText>
        )
      case STEPS.EXTRACTORS_PH.value:
        return (
          <HelpText>
            Neste passo informe se deseja ou não registrar as leituras do pH dos
            extratores e então preencher os espaços com os dados coleta. As
            leituras não podem exceder 14. Este passo é opcional.
          </HelpText>
        )
      case STEPS.NOTES.value:
        return (
          <HelpText>
            Neste passo temos um campo destinado a observações a respeito das
            leituras coletadas. Este passo é opcional.
          </HelpText>
        )
      case STEPS.REVIEW.value:
        return (
          <HelpText>
            Resumo contendo todas as informações registradas na irrigação.
          </HelpText>
        )
      default:
        return 'Passo desconhecido'
    }
  }

  const renderStepContent = () => {
    switch (activeStep) {
      case STEPS.PROJECT_SELECTION.value:
        return (
          <ProjectSelection
            options={projectsList}
            value={project}
            onChange={_onChangeProject}
          />
        )
      case STEPS.SENSOR_GROUP_SELECTION.value:
        return (
          <BaterySelection
            options={sensorGroupsList}
            value={sensorGroup}
            onChange={_onChangeSensorGroup}
          />
        )
      case STEPS.DATETIME_SELECTION.value:
        return (
          <ReadingTime
            timestampValue={timestamp}
            onChangeTime={_onChangeReadingDate}
          />
        )
      case STEPS.TENSIOMETERS.value:
        return (
          <Tensiometer
            tensiometers={tensiometers}
            onChange={_onChangeTensiometerReading}
            tensionReadType={tensionReadType}
            hasCapsule={hasCapsule}
          />
        )
      case STEPS.EXTRATORS.value:
        return (
          <Extractors
            extractors={extractors}
            onChange={_onChangeExtractorReading}
            isActive={activeExtractors}
            onChangeActive={_onChangeExtractorActive}
            isMobile={isMobile}
          />
        )
      case STEPS.EXTRACTORS_PH.value:
        return (
          <ExtractorsPH
            extractors={extractors}
            onChange={_onChangeExtractorPhReading}
            isActive={activePH}
            onChangeActive={_onChangePhActive}
          />
        )
      case STEPS.NOTES.value:
        return <Comments value={notes} onChange={_onChangeNotes} />
      case STEPS.REVIEW.value:
        return (
          <ReadingsReview
            farmName={project.farm.name}
            projectDescription={project.description}
            sensorGroupDescription={sensorGroup.description}
            readingTimestamp={timestamp}
            tensiometers={tensiometers}
            extractors={extractors}
            notes={notes}
          />
        )
      default:
        return null
    }
  }

  const _renderAlert = () => {
    const alertMessage = successMessage || errorMessage
    const alertColor = successMessage ? 'success' : 'error'
    return (
      <Snackbar open={!!alertMessage} key={alertColor}>
        <Alert severity={alertColor}>{alertMessage}</Alert>
      </Snackbar>
    )
  }

  const _renderModal = () => {
    return (
      <StepperModal
        title="LEITURAS"
        onClickClose={_handleClose}
        nextStepButtonLabel={
          activeStep === STEPS.REVIEW.value ? 'Finalizar' : 'Próximo'
        }
        backStepButtonLabel="Voltar"
        onClickNextStep={
          activeStep === STEPS.REVIEW.value
            ? _handleFinishSteps
            : _handleNextStep
        }
        onClickBackStep={_handleBackStep}
        backStepDisabled={activeStep === STEPS.PROJECT_SELECTION.value}
        nextStepDisabled={!_canProceedNextStep()}
        open={isReadingModalOpen}
        onClose={_openCloseConfirmation}
        onClickHelp={_handleHelpDialogOpen}
      >
        {isMobile ? (
          <Stepper activeStep={activeStep} orientation="vertical">
            {Object.keys(STEPS).map((key) => {
              const step = STEPS[key]

              return (
                <Step key={key}>
                  <StepLabel
                    optional={step.required ? <StepOptionalLabel /> : null}
                  >
                    {step.label}
                  </StepLabel>
                  <StepContent>
                    <StepperDialog>{renderStepContent()}</StepperDialog>
                  </StepContent>
                </Step>
              )
            })}
          </Stepper>
        ) : (
          <DialogBox>
            <StepperContainer>
              <Stepper activeStep={activeStep} orientation="vertical">
                {Object.keys(STEPS).map((key) => {
                  const step = STEPS[key]

                  return (
                    <Step key={key}>
                      <StepLabel
                        optional={
                          step.isRequired ? <StepOptionalLabel /> : null
                        }
                      >
                        {step.label}
                      </StepLabel>
                    </Step>
                  )
                })}
              </Stepper>
            </StepperContainer>
            <ModalContent>
              <StepperDivider
                orientation="vertical"
                style={{ marginRight: 30 }}
              />
              <StepperDialog>{renderStepContent()}</StepperDialog>
            </ModalContent>
          </DialogBox>
        )}
      </StepperModal>
    )
  }

  const _renderConfirmation = () => {
    return (
      <Dialog
        open={showConfirmation}
        onClose={_onRejectConfirmation}
        TransitionComponent={TransitionDown}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ConfirmationDialog
          onClickCancel={_onRejectConfirmation}
          onClickConfirm={_onAcceptConfirmation}
        />
      </Dialog>
    )
  }

  const _closeConfirmationDialog = () => {
    return (
      <Dialog
        open={showCloseConfirmation}
        TransitionComponent={TransitionDown}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CloseConfirmationDialog
          onClickCancel={_onRejectCloseConfirmation}
          onClickConfirm={_onAcceptCloseConfirmation}
        />
      </Dialog>
    )
  }

  const _renderHelpDialog = () => {
    return (
      <HelpDialog
        text={renderHelpText()}
        open={helpDialogIsOpen}
        onClickClose={_handleHelpDialogClose}
      />
    )
  }

  return (
    <Container>
      <AppBar history={props.history} />
      <Content>
        <Header
          display="flex"
          flexDirection="column"
          width="100%"
          height="10%"
          padding="3em 0 15em 0"
        >
          <DateActions>
            <PickersWrapper isMobile={isMobile}>
              <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                <DatePicker
                  isMobile={isMobile}
                  label="Data inicial"
                  value={dayjs(startDate).toDate()}
                  onChange={_onChangeStartDate}
                  format="dd/MM/yyyy"
                  maxDate={dayjs(endDate).toDate()}
                  cancelLabel="Cancelar"
                />

                <DatePicker
                  isMobile={isMobile}
                  label="Data final"
                  value={dayjs(endDate).toDate()}
                  onChange={_onChangeEndDate}
                  format="dd/MM/yyyy"
                  minDate={dayjs(startDate).toDate()}
                  cancelLabel="Cancelar"
                />
              </MuiPickersUtilsProvider>
            </PickersWrapper>

            {isMobile ? (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ) : (
              <Button
                width="8em"
                height="2.5em"
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={getReadingsList}
              >
                Buscar
              </Button>
            )}
          </DateActions>
          <Row justify="center" align="center">
            <OutlinedInput
              isMobile={isMobile}
              width="25%"
              mobileWidth="60%"
              id="outlined-adornment-search"
              placeholder="Pesquisar na tabela"
              type="search"
              value={searchText}
              onChange={_onSearch}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              labelWidth={60}
            />
            {isMobile ? (
              <IconButton
                color="primary"
                aria-label="add"
                onClick={_handleClickAdd}
              >
                <AddIcon />
              </IconButton>
            ) : (
              <Button
                margin="8px"
                width="9em"
                height="2.5em"
                startIcon={<AddIcon />}
                onClick={_handleClickAdd}
              >
                Registrar
              </Button>
            )}
          </Row>
        </Header>
        {isLoading ? (
          <TableLoadingCompWrapper>
            <Text margin="12px" textColor="black">
              Carregando registros de leituras...
            </Text>
            <CustomLinearProgress />
          </TableLoadingCompWrapper>
        ) : (
          <TableWrapper>
            <DataTable
              columns={columns}
              data={filteredList}
              mobileLabel={_getMobileLabel}
              onClickItem={_handleClickItem}
            />
          </TableWrapper>
        )}
      </Content>

      {_renderModal()}
      {_renderConfirmation()}
      {_renderAlert()}
      {_renderHelpDialog()}
      {_closeConfirmationDialog()}
    </Container>
  )
}

Readings.propTypes = {
  history: PropTypes.object,
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired
  }),
  currentClient: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  getNotifications: PropTypes.func
}

Readings.defaultProps = {
  getNotifications: () => {}
}

const mapState = ({ auth, clients }) => {
  return {
    currentUser: auth.currentUser,
    currentClient: clients.currentClient
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getNotifications
    },
    dispatch
  )

export default connect(mapState, mapDispatchToProps)(Readings)
