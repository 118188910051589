import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'
import { isNumber } from 'lodash'

const NumberInput = ({ decimal, value, onChange, ...otherProps }) => {
  const _format = useCallback((value) => {
    if (value || (isNumber(value) && value === 0)) {
      if (decimal) return value.toFixed(2).replace('.', ',')
      else return value.toFixed()
    } else return ''
  }, [])

  const _handleOnChange = useCallback((event) => {
    const fullStr = event.target.value.replace(',', '').replace(/\D/g, '')

    if (!fullStr || fullStr === '00') {
      return onChange({ target: { value: null } })
    }

    let output = ''
    if (decimal) {
      const position = fullStr.length - 2
      output = '0.00'
      if (fullStr.length === 1) {
        output = ['0.0', fullStr].join('')
      } else {
        output = [
          fullStr.slice(0, position),
          '.',
          fullStr.slice(position)
        ].join('')
      }
    } else output = fullStr

    onChange({ target: { value: Number(output) } })
  }, [])

  return (
    <TextField
      {...otherProps}
      type="tel"
      value={_format(value)}
      onChange={(evt) => _handleOnChange(evt)}
    />
  )
}

NumberInput.propTypes = {
  decimal: PropTypes.bool,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

NumberInput.defaultProps = {
  onChange: () => {}
}

export default NumberInput
