/* eslint-disable indent */
import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash'

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSwappingStrategy
} from '@dnd-kit/sortable'

import { restrictToVerticalAxis } from '@dnd-kit/modifiers'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'

import { getClients } from '../../store/reducers/clients'
import { getNotifications } from '../../store/reducers/notifications'
import {
  getWidgetsSnapshot,
  getTensiometersStructure,
  handleNext,
  handleDragAndDrop
} from '../../store/reducers/widgets'

import Appbar from '../../Components/Appbar'
import {
  Title,
  Subtitle,
  VersionText,
  BackgroundImg,
  // WidgetsGrid,
  DotsStepper
} from './styles'

import Button from '@material-ui/core/Button'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'

import { version } from '../../../package.json'
import TensiomterWidgets from './dashboardWidgets/TensiomterWidgets'
import WidgetsLoading from '../../Components/widgets//WidgetsLoading'
import PluviometerWidgets from './dashboardWidgets/PluviometerWidgets'
import WidgetContainer from './dashboardWidgets/widgetContainer'
import { formatTimestamp } from '../../utils/helpers'
import {
  WidgetsGrid,
  Container,
  Header,
  DashboardContent
} from '../../styles/components'

const PLUVIOMETERSTEPS = {
  DAY: { value: 0, label: 'Volume do dia' },
  WEEK: { value: 1, label: 'Volume da semana' },
  MONTH: { value: 2, label: 'Volume do mês' },
  YEAR: { value: 3, label: 'Volume do ano' }
}

const Dashboard = (props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const dndSensors = useSensors(
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    }),
    useSensor(TouchSensor, {})
  )

  useEffect(() => {
    props.getClients()
  }, [])

  useEffect(() => {
    props.getWidgetsSnapshot()
    props.getNotifications()
  }, [props.currentClient])

  const handleNextWidget = useCallback(
    (index, next = true) => {
      props.handleNext(index, props.snapshots, next)
    },
    [props.snapshots]
  )

  const readingValue = useCallback((activeStep, readings) => {
    switch (activeStep) {
      case PLUVIOMETERSTEPS.DAY.value:
        return readings.sumLastDay
      case PLUVIOMETERSTEPS.WEEK.value:
        return readings.sumLastWeek
      case PLUVIOMETERSTEPS.MONTH.value:
        return readings.sumLastMonth
      case PLUVIOMETERSTEPS.YEAR.value:
        return readings.sumLastYear
      default:
        return 0
    }
  }, [])

  const pluviometerLabel = useCallback((activeStep) => {
    switch (activeStep) {
      case PLUVIOMETERSTEPS.DAY.value:
        return PLUVIOMETERSTEPS.DAY.label
      case PLUVIOMETERSTEPS.WEEK.value:
        return PLUVIOMETERSTEPS.WEEK.label
      case PLUVIOMETERSTEPS.MONTH.value:
        return PLUVIOMETERSTEPS.MONTH.label
      case PLUVIOMETERSTEPS.YEAR.value:
        return PLUVIOMETERSTEPS.YEAR.label
      default:
        return 0
    }
  }, [])

  const percentValue = useCallback((activeStep, readings) => {
    switch (activeStep) {
      case PLUVIOMETERSTEPS.DAY.value:
        return (readings.sumLastDay * 100) / 300
      case PLUVIOMETERSTEPS.WEEK.value:
        return (readings.sumLastWeek * 100) / 500
      case PLUVIOMETERSTEPS.MONTH.value:
        return (readings.sumLastMonth * 100) / 800
      case PLUVIOMETERSTEPS.YEAR.value:
        return (readings.sumLastYear * 100) / 1500
      default:
        return 0
    }
    // regra de 3 dividindo pela média diária, semanal, mensal e anual de chuvas
  }, [])

  const handleDragEnd = useCallback(
    ({ active, over }) => {
      if (active.id !== over.id) {
        const currentList = [...props.snapshots]
        const oldIndex = currentList.findIndex(
          (item) => item.droppableId === active.id
        )
        const newIndex = currentList.findIndex(
          (item) => item.droppableId === over.id
        )

        if (oldIndex > -1 && newIndex > -1) {
          const newArray = arrayMove(currentList, oldIndex, newIndex)
          props.handleDragAndDrop(newArray)
        }
      }
    },
    [props.snapshots]
  )

  const _renderWidgets = () => {
    if (props.isLoading) return <WidgetsLoading />

    if (!props.snapshots.length) return null

    const dndList = props.snapshots.map((item) => item.droppableId)

    return (
      <DndContext
        sensors={dndSensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={isMobile ? [restrictToVerticalAxis] : null}
      >
        <SortableContext
          {...props}
          items={dndList}
          strategy={rectSwappingStrategy}
        >
          {props.snapshots.map((item, index) => {
            const tensiometers = get(item, 'tensiometers', [])
            const project = get(
              item,
              'sensorGroup.sector.project.description',
              ''
            )
            const farm = get(item, 'sensorGroup.sector.project.farm.name', '')
            const sensor = get(item, 'sensorGroup.description', '')

            const pluvfarm =
              item?.pluviometer?.pluviometerProjects[0]?.project?.farm?.name ??
              ''
            const pluvProject =
              item?.pluviometer?.pluviometerProjects
                .flatMap((p) => p.project.description)
                .join(', ') ?? ''

            const pluviometer = item?.pluviometer?.description

            if (item.droppableId?.includes('tensiometer') || false) {
              const readingTime = formatTimestamp(
                get(tensiometers[item.activeStep], 'timestamp', 0),
                'DD/MM/YYYY - HH:mm'
              )
              const tensiometerDepth = get(
                tensiometers[item.activeStep],
                'depth',
                ''
              )
              const value = get(tensiometers[item.activeStep], 'reading', 0)
              const valueAdjustedDecimals = Number(Number(value).toFixed(0))
              const parsedValue = value > 400 ? 400 : value
              const readingToPercentage = (parsedValue * 100) / 40000
              const disabledBackButton = item.activeStep === 0
              const maxsteps = tensiometers.length - 1
              const disabledNextButton = item.activeStep === maxsteps
              const numberOfSteps = get(item, 'tensiometers.length', 0)
              return (
                <WidgetContainer key={item.droppableId} id={item.droppableId}>
                  <TensiomterWidgets
                    projectDescription={project}
                    farmName={farm}
                    sensorGroupDescription={sensor}
                    readingTime={readingTime}
                    tensiometerDepth={tensiometerDepth}
                    value={readingToPercentage}
                    tensiometerReadings={valueAdjustedDecimals}
                  />
                  <DotsStepper
                    variant="dots"
                    steps={numberOfSteps}
                    activeStep={item.activeStep}
                    position="static"
                    isHidden={tensiometers.length === 1 ? 'hidden' : null}
                    nextButton={
                      <Button
                        onClick={() => handleNextWidget(index)}
                        disabled={!!disabledNextButton}
                      >
                        <ArrowRightIcon />
                      </Button>
                    }
                    backButton={
                      <Button
                        onClick={() => handleNextWidget(index, false)}
                        disabled={!!disabledBackButton}
                      >
                        <ArrowLeftIcon />
                      </Button>
                    }
                  />
                </WidgetContainer>
              )
            }
            if (item.droppableId?.includes('pluviometer') || false) {
              const readings = get(item, 'readings', [])
              const disabledBackButton = item.activeStep === 0
              const disabledNextButton = item.activeStep === 3
              return (
                <WidgetContainer key={item.droppableId} id={item.droppableId}>
                  <PluviometerWidgets
                    timeCourse={pluviometerLabel(item.activeStep)}
                    farm={pluvfarm}
                    project={pluvProject}
                    value={percentValue(item.activeStep, readings)}
                    reading={readingValue(item.activeStep, readings)}
                    sensor={pluviometer}
                  />
                  <DotsStepper
                    variant="dots"
                    steps={4}
                    activeStep={item.activeStep}
                    position="static"
                    nextButton={
                      <Button
                        onClick={() => handleNextWidget(index)}
                        disabled={!!disabledNextButton}
                      >
                        <ArrowRightIcon />
                      </Button>
                    }
                    backButton={
                      <Button
                        onClick={() => handleNextWidget(index, false)}
                        disabled={!!disabledBackButton}
                      >
                        <ArrowLeftIcon />
                      </Button>
                    }
                  />
                </WidgetContainer>
              )
            }
            return null
          })}
        </SortableContext>
      </DndContext>
    )
  }

  const hasSnapshot = props.snapshots?.length || false

  return (
    <Container
      display="flex"
      flexDirection="column"
      align="center"
      justify="center"
    >
      <Header zIndex="2">
        <Appbar history={props.history} />
      </Header>
      <BackgroundImg hasSnapshot={hasSnapshot}>
        {props.isLoading ? null : (
          <>
            <Title variant="h1">TECNOLOGIA</Title>
            <Subtitle variant="h4">alinhada à produtividade</Subtitle>
          </>
        )}
      </BackgroundImg>
      <DashboardContent isMobile={isMobile}>
        <WidgetsGrid isLoading={props.isLoading} isMobile={isMobile}>
          {_renderWidgets()}
        </WidgetsGrid>
      </DashboardContent>
      <VersionText variant="h1">version: {version} </VersionText>
    </Container>
  )
}

Dashboard.propTypes = {
  history: PropTypes.object,
  getClients: PropTypes.func,
  getNotifications: PropTypes.func,
  getWidgetsSnapshot: PropTypes.func,
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired
  }),
  currentClient: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  getTensiometersStructure: PropTypes.func,
  snapshots: PropTypes.array,
  currentWidgetPositions: PropTypes.array,
  isLoading: PropTypes.bool,
  handleNext: PropTypes.func,
  handleDragAndDrop: PropTypes.func
}

const mapState = ({ auth, widgets, clients }) => {
  return {
    currentUser: auth.currentUser,
    snapshots: widgets.list,
    currentWidgetPositions: widgets.widgetPosList,
    isLoading: widgets.loading,
    currentClient: clients.currentClient
  }
}

Dashboard.defaultProps = {
  getClients: () => {},
  getNotifications: () => {},
  getWidgetsSnapshot: () => {},
  getTensiometersStructure: () => {},
  handleNext: () => {},
  handleDragAndDrop: () => {}
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getClients,
      getNotifications,
      getWidgetsSnapshot,
      getTensiometersStructure,
      handleNext,
      handleDragAndDrop
    },
    dispatch
  )

export default connect(mapState, mapDispatchToProps)(Dashboard)
