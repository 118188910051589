import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { withStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import WarnComp from '../EmptyWidgetModal'

import { USER_ROLES } from '../../../../utils/constants'
import { onSelectTensiometers } from '../../../../store/reducers/widgets'

import {
  ItemText,
  FarmItemIcon,
  ProjectItemIcon,
  SensorItemIcon,
  ClientItemIcon,
  ItemLabelContainer,
  StyledTreeItem
} from '../styles'
import Colors from '../../../../styles/colors'

const WidgetCheckbox = withStyles({
  root: {
    color: Colors.AZUL_HYDRA,
    '&$checked': {
      color: Colors.AZUL_HYDRA
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />)

const TensiometerWidgetEditor = ({
  tensiometersList,
  currentUser,
  onSelectTensiometers
}) => {
  const currentRole = get(currentUser, 'role', null)
  const shouldRenderClient =
    currentRole === USER_ROLES.ADMIN || currentRole === USER_ROLES.SUPERVISOR

  const onSelectTensiometer = useCallback((tensiometer) => {
    onSelectTensiometers(tensiometer)
  }, [])

  const WidgetTreeView = () => {
    return (
      <>
        {shouldRenderClient ? (
          <>
            {tensiometersList.map((item, index) => {
              const clientName = get(item, 'name', '')
              const nodeId = `${get(item, 'name', '')} ${get(item, 'id', 0)} `
              return (
                <StyledTreeItem
                  key={index}
                  nodeId={nodeId}
                  label={
                    <ItemLabelContainer>
                      <ClientItemIcon />
                      <ItemText>{clientName}</ItemText>
                    </ItemLabelContainer>
                  }
                >
                  {get(item, 'farms', []).map((farm) => {
                    const farmName = get(farm, 'name', '')
                    const nodeId = `${get(farm, 'name', '')}${get(
                      farm,
                      'id',
                      0
                    )}`

                    return (
                      <StyledTreeItem
                        key={farm.id}
                        nodeId={nodeId}
                        label={
                          <ItemLabelContainer>
                            <FarmItemIcon />
                            <ItemText>{farmName}</ItemText>
                          </ItemLabelContainer>
                        }
                      >
                        {get(farm, 'projects', []).map((project) => {
                          const projectDescription = get(
                            project,
                            'description',
                            ''
                          )
                          const nodeId = `${get(
                            project,
                            'description',
                            ''
                          )} ${get(project, 'id', 0)}`
                          return (
                            <StyledTreeItem
                              key={project.id}
                              nodeId={nodeId}
                              label={
                                <ItemLabelContainer>
                                  <ProjectItemIcon />
                                  <ItemText>{projectDescription}</ItemText>
                                </ItemLabelContainer>
                              }
                            >
                              {get(project, 'sensors', []).map(
                                (sensor, index) => {
                                  const sensorDescription = get(
                                    sensor,
                                    'description',
                                    ''
                                  )
                                  const nodeId = `${get(
                                    sensor,
                                    'description',
                                    ''
                                  )} ${get(sensor, 'id', 0)}`
                                  return (
                                    <StyledTreeItem
                                      key={index}
                                      nodeId={nodeId}
                                      label={
                                        <ItemLabelContainer>
                                          <SensorItemIcon />
                                          <ItemText>
                                            {sensorDescription}
                                          </ItemText>
                                        </ItemLabelContainer>
                                      }
                                    >
                                      {get(sensor, 'tensiometers', []).map(
                                        (tensiometer, index) => {
                                          const tensiometerDepth = get(
                                            tensiometer,
                                            'depth',
                                            ''
                                          )
                                          const tensiometerIsSelected = get(
                                            tensiometer,
                                            'selected',
                                            0
                                          )
                                          const nodeId = `${get(
                                            tensiometer,
                                            'depth',
                                            0
                                          )}${get(tensiometer, 'id', 0)}`
                                          return (
                                            <StyledTreeItem
                                              key={index}
                                              nodeId={nodeId}
                                              label={
                                                <ItemLabelContainer>
                                                  <ItemText>
                                                    {tensiometerDepth}
                                                    cm
                                                  </ItemText>
                                                  <WidgetCheckbox
                                                    value={tensiometer}
                                                    checked={
                                                      tensiometerIsSelected
                                                    }
                                                    onClick={() => {
                                                      onSelectTensiometer(
                                                        tensiometer
                                                      )
                                                    }}
                                                  />
                                                </ItemLabelContainer>
                                              }
                                            />
                                          )
                                        }
                                      )}
                                    </StyledTreeItem>
                                  )
                                }
                              )}
                            </StyledTreeItem>
                          )
                        })}
                      </StyledTreeItem>
                    )
                  })}
                </StyledTreeItem>
              )
            })}
          </>
        ) : (
          <>
            {tensiometersList.map((item) => {
              return (
                <>
                  {get(item, 'farms', []).map((farm) => {
                    const farmName = get(farm, 'name', '')
                    const nodeId = `${get(farm, 'name', '')} ${get(
                      farm,
                      'id',
                      0
                    )} `
                    return (
                      <StyledTreeItem
                        key={farm.id}
                        nodeId={nodeId}
                        label={
                          <ItemLabelContainer>
                            <FarmItemIcon />
                            <ItemText>{farmName}</ItemText>
                          </ItemLabelContainer>
                        }
                      >
                        {get(farm, 'projects', []).map((project) => {
                          const projectDescription = get(
                            project,
                            'description',
                            ''
                          )
                          const nodeId = `${get(
                            project,
                            'description',
                            ''
                          )}${get(project, 'id', 0)}`
                          return (
                            <StyledTreeItem
                              key={project.id}
                              nodeId={nodeId}
                              label={
                                <ItemLabelContainer>
                                  <ProjectItemIcon />
                                  <ItemText>{projectDescription}</ItemText>
                                </ItemLabelContainer>
                              }
                            >
                              {get(project, 'sensors', []).map(
                                (sensor, index) => {
                                  const sensorDescription = get(
                                    sensor,
                                    'description',
                                    ''
                                  )
                                  const nodeId = `${get(
                                    sensor,
                                    'description',
                                    ''
                                  )} ${get(sensor, 'id', 0)}`
                                  return (
                                    <StyledTreeItem
                                      key={index}
                                      nodeId={nodeId}
                                      label={
                                        <ItemLabelContainer>
                                          <SensorItemIcon />
                                          <ItemText>
                                            {sensorDescription}
                                          </ItemText>
                                        </ItemLabelContainer>
                                      }
                                    >
                                      {get(sensor, 'tensiometers', []).map(
                                        (tensiometer, index) => {
                                          const tensiometerDepth = get(
                                            tensiometer,
                                            'depth',
                                            ''
                                          )
                                          const tensiometerIsSelected = get(
                                            tensiometer,
                                            'selected',
                                            0
                                          )
                                          const nodeId = `${get(
                                            tensiometer,
                                            'depth',
                                            0
                                          )}${get(tensiometer, 'id', 0)}`
                                          return (
                                            <StyledTreeItem
                                              key={index}
                                              nodeId={nodeId}
                                              label={
                                                <ItemLabelContainer>
                                                  <ItemText>
                                                    {tensiometerDepth}cm
                                                  </ItemText>
                                                  <WidgetCheckbox
                                                    value={tensiometer}
                                                    checked={
                                                      tensiometerIsSelected
                                                    }
                                                    onClick={() => {
                                                      onSelectTensiometer(
                                                        tensiometer
                                                      )
                                                    }}
                                                  />
                                                </ItemLabelContainer>
                                              }
                                            />
                                          )
                                        }
                                      )}
                                    </StyledTreeItem>
                                  )
                                }
                              )}
                            </StyledTreeItem>
                          )
                        })}
                      </StyledTreeItem>
                    )
                  })}
                </>
              )
            })}
          </>
        )}
      </>
    )
  }

  return <>{tensiometersList.length ? <WidgetTreeView /> : <WarnComp />}</>
}

TensiometerWidgetEditor.propTypes = {
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired
  }),
  tensiometersList: PropTypes.array,
  onSelectTensiometers: PropTypes.func
}

TensiometerWidgetEditor.defaultProps = {
  onSelectTensiometers: () => {}
}

const mapState = ({ auth, widgets }) => {
  return {
    currentUser: auth.currentUser,
    tensiometersList: widgets.tensiometerList
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSelectTensiometers
    },
    dispatch
  )

export default connect(mapState, mapDispatchToProps)(TensiometerWidgetEditor)
