import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  Fragment
} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import get from 'lodash/get'

import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import HcExportingData from 'highcharts/modules/export-data'
import HighchartsReact from 'highcharts-react-official'

import DateFnsUtils from '@date-io/date-fns'
import { ptBR } from 'date-fns/locale'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import ListItem from '@material-ui/core/ListItem'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import Checkbox from '@material-ui/core/Checkbox'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import Slide from '@material-ui/core/Slide'
import { Collapse, List, ListItemIcon, ListItemText } from '@material-ui/core'

import TimelineIcon from '@material-ui/icons/Timeline'
import PlaceIcon from '@material-ui/icons/Place'
import WifiTetheringIcon from '@material-ui/icons/WifiTethering'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import AssignmentIcon from '@material-ui/icons/Assignment'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

import AppBar from '../../Components/Appbar'
import {
  Container,
  Button,
  Header,
  PickersWrapper,
  DatePicker,
  DateActions,
  Content,
  Body,
  Footer,
  Paper,
  CustomListItemIcon,
  TableLoadingCompWrapper,
  Text,
  CustomLinearProgress
} from '../../styles/components'
import {
  ChartTranslation,
  ExtractorChartOptions,
  USER_ROLES
} from '../../utils/constants'
import {
  styles,
  CloseIconButton,
  ChartDialogActions,
  ChartModal,
  ChartModalHeader,
  ChartModalContent
} from './styles'
import ChartsService from '../../services/charts'
import Colors from '../../styles/colors'
import { timestampToServer } from '../../utils/helpers'

const transitionUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const oneWeekBefore = dayjs().subtract(1, 'week')

const DEFAULT_CHART_DATA = { series: [] }

HighchartsExporting(Highcharts)
HcExportingData(Highcharts)

const ExtractorsCharts = (props) => {
  const [isOpen, setIsOPen] = useState(false)
  const [structure, setStructure] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setStartDate] = useState(oneWeekBefore.valueOf())
  const [endDate, setEndDate] = useState(dayjs().valueOf())
  const [selectedSensorList, setSelectedSensorList] = useState([])
  const [extractorsData, setExtractorsData] = useState(DEFAULT_CHART_DATA)
  const [chartOptions, setChartOptions] = useState(ExtractorChartOptions)
  const [errorMessage, setErrorMessage] = useState('')

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const iconStyle = { marginRight: '8px' }

  const chartStyle = isMobile
    ? styles.chartContainer
    : styles.desktopChartContainer

  const shouldDisable = !selectedSensorList.length

  useEffect(() => {
    setExtractorsData(DEFAULT_CHART_DATA)
  }, [startDate, endDate, selectedSensorList])

  useEffect(async () => {
    try {
      setIsLoading(true)
      const response = await ChartsService.getStructure(
        props.currentUser.token,
        props.currentClient.id
      )
      const updatedResponse = response.farms.map((farm) => ({
        ...farm,
        isExpanded: false,
        projects: farm.projects.map((project) => ({
          ...project,
          isExpanded: false
        }))
      }))

      setSelectedSensorList([])
      setStructure(updatedResponse)
      setIsLoading(false)
    } catch (error) {
      setStructure([])
      setIsLoading(false)
    }
  }, [props.currentClient.id])

  useEffect(() => {
    if (
      get(props, 'currentUser.role', USER_ROLES.NORMAL) === USER_ROLES.ADMIN
    ) {
      setChartOptions((prevOptions) => {
        const exportOptions =
          prevOptions.exporting.buttons.contextButton.menuItems
        const hasDownloadXLS = exportOptions.includes('downloadXLS')

        if (!hasDownloadXLS) {
          const newExportOptions = [...exportOptions, 'downloadXLS']
          const newOptions = { ...prevOptions }
          newOptions.exporting.buttons.contextButton.menuItems =
            newExportOptions
          return newOptions
        }

        return prevOptions
      })
    }
  }, [props.currentUser])

  useEffect(() => {
    if (!selectedSensorList.length) {
      setExtractorsData(DEFAULT_CHART_DATA)
    }
  }, [selectedSensorList, startDate, endDate])

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => setErrorMessage(''), 3000)
    }
  }, [errorMessage])

  const selectedPluviometerList = useMemo(() => {
    return (structure || []).reduce((pluviometers, currentFarm) => {
      const newPluviometers = []
      currentFarm?.projects?.forEach((p) => {
        const sectorsIncluded = p.sectors.filter((sector) => {
          const isSensorOfSectorSelected = selectedSensorList.find(
            (sensor) => sensor.sector_id === sector.id
          )
          return !!isSensorOfSectorSelected
        })

        if (sectorsIncluded.length && p?.pluviometer?.id) {
          newPluviometers.push(p.pluviometer.id)
        }
      })
      return [...pluviometers, ...newPluviometers]
    }, [])
  }, [selectedSensorList, structure])

  const _handleOpen = useCallback(() => {
    setIsOPen(true)
  }, [])

  const _handleClose = useCallback(() => {
    setExtractorsData(DEFAULT_CHART_DATA)
    setIsOPen(false)
  }, [])

  const _onSelectSector = useCallback(
    (sensor) => {
      const newList = [...selectedSensorList]
      const e = selectedSensorList.findIndex((s) => s.id === sensor.id)
      if (e > -1) {
        newList.splice(e, 1)
      } else {
        newList.push(sensor)
      }
      setSelectedSensorList(newList)
    },
    [selectedSensorList]
  )

  const _getExtractorsData = useCallback(async () => {
    try {
      setIsLoading(true)
      const p = {
        startDate: timestampToServer(startDate),
        endDate: timestampToServer(endDate),
        sensors: selectedSensorList,
        pluviometers: selectedPluviometerList
      }

      const chartsData = await ChartsService.getExtractorsData(
        props.currentUser.token,
        p
      )
      if (!chartsData.series.length) {
        setErrorMessage('Sem dados para exibir o gráfico.')
      }
      setExtractorsData(chartsData)
      setIsLoading(false)
    } catch (error) {
      setExtractorsData(DEFAULT_CHART_DATA)
      setErrorMessage(error.message)
      setIsLoading(false)
    }
  }, [
    props.currentUser,
    extractorsData,
    startDate,
    endDate,
    selectedPluviometerList,
    selectedSensorList,
    errorMessage
  ])

  const MobileChartWindow = () => {
    if (!isOpen) {
      return null
    }
    return (
      <ChartModal open={isOpen}>
        <ChartModalHeader>
          <ChartDialogActions>
            <CloseIconButton onClick={_handleClose}>
              <CloseIcon fontSize="small" />
            </CloseIconButton>
          </ChartDialogActions>
        </ChartModalHeader>
        <ChartModalContent>
          <HighchartsReact
            containerProps={chartStyle}
            highcharts={Highcharts}
            options={{
              ...chartOptions,
              ...ChartTranslation,
              ...extractorsData
            }}
          />
        </ChartModalContent>
      </ChartModal>
    )
  }

  const ChartButton = () => {
    const isDisabled = !extractorsData.series.length
    return (
      <Button
        width="8em"
        height="2.5em"
        margin="4px"
        startIcon={<TimelineIcon />}
        backgroundColor={Colors.SUCCESS}
        variant="contained"
        onClick={_handleOpen}
        disabled={isDisabled}
      >
        Gráfico
      </Button>
    )
  }

  const _onChangeStartDate = useCallback((event) => {
    const startTime = dayjs(event).valueOf()
    setStartDate(startTime)
  }, [])

  const _onChangeEndDate = useCallback((event) => {
    const endTime = dayjs(event).valueOf()
    setEndDate(endTime)
  }, [])

  const onClickFarmItem = useCallback(
    (index) => {
      const list = [...structure]
      list[index].isExpanded = !list[index].isExpanded
      setStructure(list)
    },
    [structure]
  )

  const onClickProjectItem = useCallback(
    (item, farmIndex) => {
      const list = [...structure]
      const projectIndex = list[farmIndex].projects.findIndex(
        (p) => p.id === item.id
      )
      list[farmIndex].projects[projectIndex].isExpanded =
        !list[farmIndex].projects[projectIndex].isExpanded

      setStructure(list)
    },
    [structure]
  )

  const _renderAlert = () => {
    const alertMessage = errorMessage
    const alertColor = 'error'
    return (
      <Snackbar
        open={!!alertMessage}
        key={alertColor}
        TransitionComponent={transitionUp}
      >
        <Alert severity={alertColor}>{alertMessage}</Alert>
      </Snackbar>
    )
  }

  return (
    <Container overflow="hidden">
      <AppBar history={props.history} />
      <Content overflow="auto">
        <Header
          display="flex"
          flexDirection="column"
          width="100%"
          height="10%"
          padding="8em 0 3em 0"
          justify="center"
          align="center"
        >
          <DateActions>
            <PickersWrapper isMobile={isMobile}>
              <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                <DatePicker
                  isMobile={isMobile}
                  label="Data inicial"
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  cancelLabel="Cancelar"
                  value={dayjs(startDate).toDate()}
                  maxDate={dayjs(endDate).toDate()}
                  onChange={_onChangeStartDate}
                />
                <DatePicker
                  isMobile={isMobile}
                  label="Data final"
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  cancelLabel="Cancelar"
                  value={dayjs(endDate).toDate()}
                  minDate={dayjs(startDate).toDate()}
                  onChange={_onChangeEndDate}
                />
              </MuiPickersUtilsProvider>
            </PickersWrapper>
          </DateActions>
        </Header>
        <Body
          display="flex"
          flexDirection="column"
          position="sticky"
          width="100%"
          height="100%"
          justify="flex-start"
          align="center"
          padding="1em 0 0 0"
          margin="1em 0 0 0"
        >
          {isLoading ? (
            <TableLoadingCompWrapper>
              <Text margin="12px" textColor="black">
                Carregando fazendas...
              </Text>
              <CustomLinearProgress />
            </TableLoadingCompWrapper>
          ) : (
            <>
              {structure.length ? (
                <Paper isMobile={isMobile}>
                  <List>
                    {structure.map((farm, index) => {
                      return (
                        <Fragment key={farm.id}>
                          <ListItem
                            Button
                            onClick={() => onClickFarmItem(index)}
                          >
                            <ListItemIcon>
                              <PlaceIcon fontSize="small" style={iconStyle} />
                            </ListItemIcon>
                            <ListItemText>{farm.name}</ListItemText>
                            {farm.isExpanded ? <ExpandLess /> : <ExpandMore />}
                          </ListItem>
                          <Collapse
                            in={farm.isExpanded}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div">
                              {farm.projects.map((project) => {
                                const farmIndex = index

                                return (
                                  <>
                                    <ListItem
                                      key={project.id}
                                      Button
                                      onClick={() =>
                                        onClickProjectItem(project, farmIndex)
                                      }
                                    >
                                      <CustomListItemIcon>
                                        <AssignmentIcon
                                          fontSize="small"
                                          style={iconStyle}
                                        />
                                      </CustomListItemIcon>
                                      <ListItemText>
                                        {project.description}
                                      </ListItemText>
                                      {project.isExpanded ? (
                                        <ExpandLess />
                                      ) : (
                                        <ExpandMore />
                                      )}
                                    </ListItem>
                                    <Collapse
                                      in={project.isExpanded}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <List component="div">
                                        {project.sectors
                                          .filter(
                                            (sector) => !!sector.sensorGroup
                                          )
                                          .map((sector) => {
                                            const isSensorChecked =
                                              !!selectedSensorList.find(
                                                (s) =>
                                                  s.id === sector.sensorGroup.id
                                              )
                                            return (
                                              <ListItem
                                                key={sector.sensorGroup.id}
                                              >
                                                <CustomListItemIcon marginLeft="3em">
                                                  <WifiTetheringIcon
                                                    fontSize="small"
                                                    style={iconStyle}
                                                  />
                                                </CustomListItemIcon>
                                                <ListItemText>
                                                  {get(
                                                    sector,
                                                    'sensorGroup.description',
                                                    ''
                                                  )}
                                                  <Checkbox
                                                    color="primary"
                                                    value={sector}
                                                    checked={isSensorChecked}
                                                    onClick={() =>
                                                      _onSelectSector(
                                                        sector.sensorGroup
                                                      )
                                                    }
                                                  />
                                                </ListItemText>
                                              </ListItem>
                                            )
                                          })}
                                      </List>
                                    </Collapse>
                                  </>
                                )
                              })}
                            </List>
                          </Collapse>
                        </Fragment>
                      )
                    })}
                  </List>
                </Paper>
              ) : null}
            </>
          )}
        </Body>
        <Footer>
          <Button
            width="8em"
            height="2.5em"
            margin="4px"
            startIcon={<SearchIcon />}
            background={Colors.AZUL_HYDRA}
            variant="contained"
            onClick={_getExtractorsData}
            disabled={!!shouldDisable}
          >
            Buscar
          </Button>
          <ChartButton />
        </Footer>
      </Content>
      {MobileChartWindow()}
      {_renderAlert()}
    </Container>
  )
}

ExtractorsCharts.propTypes = {
  history: PropTypes.object,
  currentClient: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired
  })
}

const mapState = ({ auth, clients }) => {
  return {
    currentUser: auth.currentUser,
    currentClient: clients.currentClient
  }
}

export default connect(mapState)(ExtractorsCharts)
