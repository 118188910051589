import { styled as styledMaterialUI } from '@material-ui/core/styles'
import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import { Column } from '../../styles/components'
import Colors from '../../styles/colors'
import { ReportProblemOutlined } from '@material-ui/icons'

export const NotificationHeader = styled(Column)`
  justify-content: center;
  height: 50px;
  background-color: ${Colors.TABLE_HOVER};
  border-radius: 4px;
`

export const NotificationHeaderText = styledMaterialUI(Typography)({
  padding: '8px',
  fontFamily: 'Montserrat',
  fontWeight: 'bold'
})

export const ContentSeparator = styledMaterialUI(Divider)({
  heigh: 40,
  margin: '0 0 12px 0'
})

export const NotificationsCard = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width || '50vw'};
`

export const NotificationText = styledMaterialUI(Typography)({
  padding: '8px 8px 8px 8px'
})

export const WarningIcon = styledMaterialUI(ReportProblemOutlined)({
  fontSize: 90,
  color: Colors.LIGHT_GREY_TEXT,
  margin: '24px 0 0 0'
})

export const WarningContiner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const WarningText = styledMaterialUI(Typography)({
  fontSize: 16,
  fontFamily: 'Montserrat',
  color: Colors.LIGHT_GREY_TEXT,
  margin: '0 0 24px 0'
})
