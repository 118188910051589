import styled from 'styled-components'
import { styled as styledMaterialUI } from '@material-ui/core/styles'
import { KeyboardDatePicker } from '@material-ui/pickers'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import Colors from '../../styles/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  background-position: center;
  background-color: ${Colors.DEFAULT_BACKGROUND};
`

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 80px;
  width: 100%;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`
export const ModalContent = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 8px;
  overflow: auto;
  width: 30vw;
`

export const Actions = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 1%;
`

export const StepperDialog = styled.div`
  display: flex;
  flex-direction: column;
`

export const DatePickerSearch = styledMaterialUI(KeyboardDatePicker)({
  width: '100%',
  margin: 8
})

export const SearchInput = styled(OutlinedInput)`
  background-color: ${`${Colors.INPUTS_BACKGROUND}30`};
`
export const AddButton = styledMaterialUI(Button)({
  marginRight: '10px',
  width: '150px'
})

export const AddIconButton = styledMaterialUI(IconButton)({
  marginRight: '10px'
})

export const DialogContainer = styledMaterialUI(Dialog)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})

export const DialogBox = styledMaterialUI(DialogContent)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  margin: '10px',
  padding: '10px'
})

export const ModalHeader = styledMaterialUI(AppBar)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'sticky',
  flexWrap: 'wrap',
  height: '80px',
  marginBottom: '32px'
})

export const ModalTitle = styledMaterialUI(Typography)({
  fontSize: '20px',
  fontFamily: 'Montserrat',
  fontWeight: 700,
  fontStyle: 'bold',
  color: '#ffffff',
  marginRight: '16px',
  marginLeft: '24px',
  textTransform: 'uppercase'
})

export const CloseButton = styledMaterialUI(IconButton)({
  color: '#ffffff',
  cursor: 'pointer',
  marginRight: 8
})

export const StepperButton = styledMaterialUI(Button)({
  marginRight: '10px'
})

export const StepperDivider = styledMaterialUI(Divider)({
  Color: '#000000'
})

export const HelpText = styledMaterialUI(Typography)({
  fontSize: '18px',
  fontFamily: 'Montserrat'
})
