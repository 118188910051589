import { styled as styledMaterialUI } from '@material-ui/core/styles'
import styled from 'styled-components'

import { KeyboardDatePicker } from '@material-ui/pickers'
import List from '@material-ui/core/List'
import Button from '@material-ui/core/Button'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Accordion from '@material-ui/core/Accordion'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'

export const DatePickerSearch = styledMaterialUI(KeyboardDatePicker)({
  width: '75%',
  margin: 8
})

export const SelectorList = styledMaterialUI(List)({
  alignItems: 'center',
  width: '100%',
  height: '100%'
})

export const SelectorButton = styledMaterialUI(Button)({
  alignItems: (props) => props.align || null,
  justifyContent: (props) => props.justfy || null,
  width: (props) => props.width || null,
  margin: (props) => props.margin || 8,
  backgroundColor: (props) => props.background || '',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: (props) => props.background || '',
    boxShadow: '0 0 11px rgba(0,0,0,0.9)',
    transformStyle: ''
  }
})

export const SelectorDetails = styledMaterialUI(AccordionDetails)({
  display: 'flex',
  flexDirection: (props) => props.flexDirection || 'column'
})

export const SelectorAccordion = styledMaterialUI(Accordion)({
  margin: '2px'
})

export const CloseIconButton = styledMaterialUI(IconButton)({
  position: 'absolute',
  right: 5,
  top: 0,
  margin: '0 0 18px 0'
})

export const ChartDialogActions = styledMaterialUI(DialogActions)({})

export const styles = {
  chartContainer: {
    style: {
      width: '100%',
      heigh: '90%'
    }
  },
  desktopChartContainer: {
    style: {
      width: '98%',
      height: '90%',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }
}

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  margin: 90px 8px;
  width: 100%;
  height: 100% !important;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

export const View = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  align-items: ${(props) => props.align || 'flex-start'};
  justify-content: ${(props) => props.justify || 'flex-start'};
  width: 100%;
  height: ${(props) => props.height || null};
`

export const ChartModal = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  z-index: 999;
  overflow: auto;
  background-color: white;
  width: 100%;
  height: 100%;
`
export const ChartModalContent = styled.div`
  background-color: white;
  width: 100% !important;
  height: 90%;
  display: flex;
`

export const ChartModalHeader = styled.div`
  background-color: white;
  padding: 10px;
  margin: 0 0 8px 0;
`
export const SelectorColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`
