import React, { useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'

import MenuIcon from '@material-ui/icons/Menu'
import HomeIcon from '@material-ui/icons/Home'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import WidgetsIcon from '@material-ui/icons/Widgets'

import { Container, Content, Row, Column } from '../../styles/components'
import Colors from '../../styles/colors'
import {
  SettingsAppBar,
  LogoContainer,
  PermanentDrawer,
  SettingsList
} from './styles'
import LogoHydraConnect from '../../assets/images/logo-connect-branca.png'
import { navigateToPage } from '../../routes/navigate'
import Routes from '../../routes/routes'

import ChangePassword from './changePassword'
import Logout from './logout'
import SelectWidgets from './SelectWidgets'

const useStyles = makeStyles({
  paper: {
    background: Colors.DEFAULT_BACKGROUND
  },
  selectedOption: {
    '&:hover': {
      backgroundColor: '#b5dcf5'
    },
    backgroundColor: '#b5dcf5'
  }
})

const DRAWER_POSITION = 'left'

const AccountSettings = (props) => {
  const [currentSelectedOption, setCurrentSelectedOption] = useState(0)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const classes = useStyles()

  const drawerButtons = useMemo(
    () => [
      {
        label: 'Alterar Senha',
        icon: <VpnKeyIcon />,
        component: <ChangePassword />
      },
      {
        label: 'Widgets',
        icon: <WidgetsIcon />,
        component: <SelectWidgets />
      },
      {
        label: 'Logout',
        icon: <MeetingRoomIcon />,
        component: <Logout history={props.history} />
      }
    ],
    [props.history]
  )

  const _onClickSettings = useCallback(() => {
    navigateToPage(props.history, Routes.DASHBOARD)
  }, [])

  const _onClickListButton = useCallback((index) => {
    setCurrentSelectedOption(index)
    setIsDrawerOpen(false)
  }, [])

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setIsDrawerOpen(open)
  }

  const CurrentDetailComponent = get(
    drawerButtons,
    `[${currentSelectedOption}].component`,
    null
  )

  const DrawerOptions = () => (
    <>
      {drawerButtons.map((item, index) => {
        const SelectedStyle = {
          root: currentSelectedOption === index ? classes.selectedOption : null
        }
        return (
          <ListItem
            key={index}
            button
            onClick={() => _onClickListButton(index)}
            classes={SelectedStyle}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItem>
        )
      })}
    </>
  )

  const mobileDrawer = () => {
    return (
      <Drawer
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        anchor={DRAWER_POSITION}
      >
        <List>
          <Divider />
          <ListItem button onClick={_onClickSettings}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Início" />
          </ListItem>
          <DrawerOptions />
        </List>
      </Drawer>
    )
  }

  return (
    <Container>
      <SettingsAppBar background={Colors.AZUL_HYDRA}>
        <Toolbar>
          {isMobile ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          ) : null}

          <LogoContainer
            classname="Button"
            src={LogoHydraConnect}
            alt="Hydra Connect"
            onClick={_onClickSettings}
          />
        </Toolbar>
      </SettingsAppBar>
      <Content>
        <Row align="center" justify="center">
          {!isMobile ? (
            <Column width="12%">
              <PermanentDrawer
                variant="permanent"
                classes={{ paper: classes.paper }}
              >
                <SettingsList>
                  <Divider />
                  <ListItem button onClick={_onClickSettings}>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Início" />
                  </ListItem>
                  <DrawerOptions />
                </SettingsList>
              </PermanentDrawer>
            </Column>
          ) : null}
          <Column align="center" justify="center">
            {CurrentDetailComponent}
          </Column>
        </Row>
      </Content>
      {mobileDrawer()}
    </Container>
  )
}

AccountSettings.propTypes = {
  history: PropTypes.object
}

export default AccountSettings
