/* eslint-disable indent */
import NotificationsService from '../../services/notifications'
import store from '../index'
import logError from '../../utils/log'
import getValue from 'lodash/get'

const Types = {
  SET_NOTIFICATIONS_LIST: 'notifications@setNotificationsList',
  SET_LOADING: 'notifications@setLoading',
  SET_SUCCESS: 'notifications@setSuccess',
  SET_ERROR: 'notifications@setError',
  CLEAR: 'notifications@clear'
}

const initialState = {
  list: [],
  Loading: false,
  errorMessage: '',
  successMessage: ''
}

const actions = {
  [Types.SET_NOTIFICATIONS_LIST]: (state, action) => ({
    ...state,
    list: action.payload
  }),
  [Types.SET_LOADING]: (state, action) => ({
    ...state,
    loading: action.payload
  }),
  [Types.SET_SUCCESS]: (state, action) => ({
    ...state,
    successMessage: action.payload
  }),
  [Types.SET_ERROR]: (state, action) => ({
    ...state,
    errorMessage: action.payload
  }),
  [Types.CLEAR]: (state, action) => ({ ...state, ...action.payload })
}

const reducer = (state = initialState, action) => {
  if (actions[action.type]) {
    return actions[action.type](state, action)
  }

  return state
}

export const clearNotificationsReducer = () => async (dispatch) => {
  try {
    dispatch({
      type: Types.CLEAR,
      payload: initialState
    })
  } catch (error) {
    console.log(error)
  }
}

export const getNotifications = () => async (dispatch) => {
  try {
    const currentStore = store.getState()
    const authToken = getValue(currentStore, 'auth.currentUser.token', null)
    const currentClient = getValue(currentStore, 'clients.currentClient.id', 0)

    if (currentClient) {
      dispatch({
        type: Types.SET_LOADING,
        payload: true
      })
      const notificationsList = await NotificationsService.getNotificationsList(
        authToken,
        currentClient
      )

      dispatch({
        type: Types.SET_LOADING,
        payload: false
      })
      dispatch({
        type: Types.SET_NOTIFICATIONS_LIST,
        payload: notificationsList
      })
    }
  } catch (error) {
    logError('reducers -> notifications -> getNotifications', error)
    dispatch({
      type: Types.SET_LOADING,
      payload: false
    })
    dispatch({
      type: Types.SET_ERROR,
      payload: error.message
    })
  }
}

export const getNotificationsRead = (item) => async (dispatch) => {
  try {
    if (!item.read) {
      dispatch({
        type: Types.SET_LOADING,
        payload: true
      })

      const currentStore = store.getState()
      const authToken = getValue(currentStore, 'auth.currentUser.token', null)

      await NotificationsService.getNotificationsRead(authToken, item.id)

      const currentNotificationsList = getValue(
        currentStore,
        'notifications.list',
        []
      )
      const index = currentNotificationsList.findIndex((i) => i.id === item.id)
      const newList = [...currentNotificationsList]
      if (index > -1) {
        newList[index] = { ...item, read: true }
      }

      dispatch({
        type: Types.SET_LOADING,
        payload: false
      })

      dispatch({
        type: Types.SET_NOTIFICATIONS_LIST,
        payload: newList
      })
    }
  } catch (error) {
    logError('reducers -> notifications -> getNotificationsRead', error)
    dispatch({
      type: Types.SET_LOADING,
      payload: false
    })
    dispatch({
      type: Types.SET_ERROR,
      payload: error.message
    })
  }
}

export const setSuccessMessage =
  (message = '') =>
  async (dispatch) => {
    dispatch({
      type: Types.SET_SUCCESS,
      payload: message
    })
  }

export const setErrorMessage =
  (message = '') =>
  async (dispatch) => {
    dispatch({
      type: Types.SET_ERROR,
      payload: message
    })
  }

export default reducer
