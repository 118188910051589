import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import TextField from '@material-ui/core/TextField'

import { DialogContainer, TypoHeader, Selector } from './styles'

const ProjectSelection = ({ options, value, onChange }) => {
  const hasError = !get(value, 'id', 0) ? 'Projeto não selecionado' : ''

  return (
    <DialogContainer>
      <TypoHeader>Selecione um projeto:</TypoHeader>
      <Selector
        fullWidth
        id="Selecione um projeto"
        options={options}
        value={value}
        onChange={onChange}
        getOptionLabel={(op) =>
          `${get(op, 'description', '')} - ${get(op, 'farm.name', '')}`
        }
        getOptionSelected={(op, current) => op.id === current.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Projetos"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            placeholder="Selecione um projeto"
            error={!!hasError}
            helperText={hasError}
          />
        )}
      />
    </DialogContainer>
  )
}

ProjectSelection.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      farm: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired,
  value: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    farm: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  }),
  onChange: PropTypes.func.isRequired
}

ProjectSelection.defaultProps = {
  onChange: () => {}
}

export default ProjectSelection
