import React from 'react'
import PropTypes from 'prop-types'

import DialogActions from '@material-ui/core/DialogActions'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import Slide from '@material-ui/core/Slide'

import CloseIcon from '@material-ui/icons/Close'
import SaveIcon from '@material-ui/icons/Save'

import {
  ModalHeader,
  TypoHeader,
  ActionButton,
  CloseButton
} from '../../styles/components'
import { Content, Container, Body } from './styles'
import Colors from '../../styles/colors'

const transitionUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ModalComponent = ({
  onClickClose,
  title,
  buttonLabel,
  children,
  disabled,
  onClickButton,
  onClose,
  open
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const mobile = isMobile ? '100%' : null

  return (
    <Container
      TransitionComponent={transitionUp}
      onClose={onClose}
      fullScreen={isMobile}
      open={open}
    >
      <ModalHeader>
        <TypoHeader>{title}</TypoHeader>
        <CloseButton onClick={onClickClose}>
          <CloseIcon />
        </CloseButton>
      </ModalHeader>
      <Body width={mobile}>
        <Content>{children}</Content>
      </Body>
      <DialogActions position="sticky">
        <ActionButton
          background={Colors.SUCCESS}
          size="large"
          startIcon={<SaveIcon />}
          onClick={onClickButton}
          disabled={disabled}
        >
          {buttonLabel}
        </ActionButton>
      </DialogActions>
    </Container>
  )
}

ModalComponent.propTypes = {
  onClickClose: PropTypes.func,
  onClickButton: PropTypes.func,
  title: PropTypes.string,
  buttonLabel: PropTypes.string,
  children: PropTypes.element,
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool
}

ModalComponent.defaultProps = {
  onClickClose: () => {},
  onClickButton: () => {},
  onClose: () => {}
}

export default ModalComponent
