import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { withStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import WarnComp from '../EmptyWidgetModal'

import { USER_ROLES } from '../../../../utils/constants'
import { onSelectPluviometers } from '../../../../store/reducers/widgets'

import {
  ItemText,
  FarmItemIcon,
  PluviometerItemIcon,
  ItemLabelContainer,
  StyledTreeItem,
  ClientItemIcon
} from '../styles'
import Colors from '../../../../styles/colors'

const WidgetCheckbox = withStyles({
  root: {
    color: Colors.AZUL_HYDRA,
    '&$checked': {
      color: Colors.AZUL_HYDRA
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />)

const PluviometerWidgetEditor = ({
  pluviometersList,
  currentUser,
  onSelectPluviometers
}) => {
  const currentRole = get(currentUser, 'role', null)
  const shouldRenderClient =
    currentRole === USER_ROLES.ADMIN || currentRole === USER_ROLES.SUPERVISOR

  const _onSelectPluviometer = useCallback((pluviometer) => {
    onSelectPluviometers(pluviometer)
  }, [])

  const WidgetTreeView = () => {
    return (
      <>
        {shouldRenderClient ? (
          <>
            {pluviometersList.map((item, index) => {
              const nodeId = `${get(item, 'name', '')} ${get(item, 'id', 0)} `
              const clientName = get(item, 'name', '')
              return (
                <StyledTreeItem
                  key={index}
                  nodeId={nodeId}
                  label={
                    <ItemLabelContainer>
                      <ClientItemIcon />
                      <ItemText>{clientName}</ItemText>
                    </ItemLabelContainer>
                  }
                >
                  {get(item, 'farms', []).map((farm) => {
                    const farmName = get(farm, 'name', '')
                    const nodeId = `${get(farm, 'name', '')}${get(
                      farm,
                      'id',
                      0
                    )}`
                    return (
                      <StyledTreeItem
                        key={farm.id}
                        nodeId={nodeId}
                        label={
                          <ItemLabelContainer>
                            <FarmItemIcon />
                            <ItemText>{farmName}</ItemText>
                          </ItemLabelContainer>
                        }
                      >
                        {get(farm, 'pluviometer', []).map((pluviometer) => {
                          const pluviometerDescription = get(
                            pluviometer,
                            'description',
                            ''
                          )
                          const nodeId = `${get(
                            pluviometer,
                            'description',
                            ''
                          )} ${get(pluviometer, 'id', 0)}`
                          const isSelected = pluviometer?.selected ?? 0
                          return (
                            <StyledTreeItem
                              key={pluviometer.id}
                              nodeId={nodeId}
                              label={
                                <ItemLabelContainer>
                                  <PluviometerItemIcon />
                                  <ItemText>{pluviometerDescription}</ItemText>
                                  <WidgetCheckbox
                                    value={pluviometer}
                                    checked={isSelected}
                                    onClick={() =>
                                      _onSelectPluviometer(pluviometer)
                                    }
                                  />
                                </ItemLabelContainer>
                              }
                            />
                          )
                        })}
                      </StyledTreeItem>
                    )
                  })}
                </StyledTreeItem>
              )
            })}
          </>
        ) : (
          <>
            {pluviometersList.map((item) => {
              return (
                <>
                  {get(item, 'farms', []).map((farm) => {
                    const farmName = get(farm, 'name', '')
                    const nodeId = `${get(farm, 'name', '')} ${get(
                      farm,
                      'id',
                      0
                    )} `
                    return (
                      <StyledTreeItem
                        key={farm.id}
                        nodeId={nodeId}
                        label={
                          <ItemLabelContainer>
                            <FarmItemIcon />
                            <ItemText>{farmName}</ItemText>
                          </ItemLabelContainer>
                        }
                      >
                        {get(farm, 'pluviometer', []).map((pluviometer) => {
                          const pluviometerDescription = get(
                            pluviometer,
                            'description',
                            ''
                          )
                          const nodeId = `${get(
                            pluviometer,
                            'description',
                            ''
                          )}${get(pluviometer, 'id', 0)}`
                          return (
                            <StyledTreeItem
                              key={pluviometer.id}
                              nodeId={nodeId}
                              label={
                                <ItemLabelContainer>
                                  <PluviometerItemIcon />
                                  <ItemText>{pluviometerDescription}</ItemText>
                                  <WidgetCheckbox value={pluviometer} />
                                </ItemLabelContainer>
                              }
                            />
                          )
                        })}
                      </StyledTreeItem>
                    )
                  })}
                </>
              )
            })}
          </>
        )}
      </>
    )
  }

  return <>{pluviometersList.length ? <WidgetTreeView /> : <WarnComp />}</>
}

PluviometerWidgetEditor.propTypes = {
  currentClient: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired
  }),
  onSelectPluviometers: PropTypes.func,
  pluviometersList: PropTypes.array
}

PluviometerWidgetEditor.defaultProps = {
  onSelectPluviometers: () => {}
}

const mapState = ({ auth, widgets }) => {
  return {
    currentUser: auth.currentUser,
    pluviometersList: widgets.pluviometersList
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSelectPluviometers
    },
    dispatch
  )

export default connect(mapState, mapDispatchToProps)(PluviometerWidgetEditor)
