import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import getValue from 'lodash/get'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import DialogActions from '@material-ui/core/DialogActions'

import { CardContainer, Title, Content, StyledDivider, Text } from '../styles'
import { ActionButton } from '../../../styles/components'
import Colors from '../../../styles/colors'

import { navigateToPage } from '../../../routes/navigate'
import ROUTES from '../../../routes/routes'
import { submitLogout } from '../../../store/reducers/auth'

const Logout = ({ currentUser, history, submitLogout }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const userToken = getValue(currentUser, 'token', null)

  const desktop = !isMobile ? '50%' : '100%'

  const _onClickLogout = useCallback(async () => {
    submitLogout()
    if (!userToken) {
      navigateToPage(history, ROUTES.LOGIN)
    }
  }, [currentUser])

  return (
    <CardContainer width={desktop}>
      <Title>Logout</Title>
      <StyledDivider />
      <Content>
        <Text>Deseja sair do aplicativo?</Text>
      </Content>
      <StyledDivider />
      <DialogActions>
        <ActionButton
          variant="contained"
          background={Colors.DANGER}
          onClick={_onClickLogout}
        >
          Sair
        </ActionButton>
      </DialogActions>
    </CardContainer>
  )
}

Logout.propTypes = {
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.object.isRequired,
  submitLogout: PropTypes.func
}

Logout.defaultProps = {
  submitLogout: () => {}
}

const mapState = ({ auth }) => {
  return {
    currentUser: auth.currentUser
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      submitLogout
    },
    dispatch
  )

export default connect(mapState, mapDispatchToProps)(Logout)
