import styled from 'styled-components'
import { styled as styledMaterialUI } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import NumberInput from '../../../../Components/NumberInput'

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const TypoHeader = styledMaterialUI(Typography)({
  fontSize: '20px',
  fontFamily: 'Montserrat',
  margin: '8px 8px 16px 8px'
})

export const HidrometerInput = styledMaterialUI(NumberInput)({
  minwidth: 250,
  margin: 8
})
