/* eslint-disable react/prop-types */
import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { DraggableContents, DragHandler } from '../styles'

const WidgetContainer = ({ id, children }, ref) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id })

  const style = {
    display: 'flex',
    border: `solid 1px #ddd`,
    background: `rgba(216, 239, 254, 1)`,
    flexDirection: 'column',
    margin: 6,
    width: 350,
    height: 440,
    boxShadow: `0 0 10px rgba(0, 0, 0, 0.6)`,
    borderRadius: 24,
    transform: CSS.Transform.toString(transform),
    transition,
    touchAction: 'pan-y'
  }

  return (
    <div ref={setNodeRef} style={style}>
      <DraggableContents
        align="flex-end"
        justify="flex-end"
        flexDirection="row"
        margin="12px 8px 0 0"
      >
        <DragHandler {...attributes} {...listeners} />
      </DraggableContents>
      <DraggableContents align="center" justify="center" flexDirection="column">
        {children}
      </DraggableContents>
    </div>
  )
}

export default WidgetContainer
