import styled, { css } from 'styled-components'
import { styled as styledMaterialUI } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import MobileStepper from '@material-ui/core/MobileStepper'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'

import DashboardBackground from '../../assets/images/dashboard.jpg'
import Colors from '../../styles/colors'

export const DragHandler = styledMaterialUI(DragIndicatorIcon)({
  fontSize: 35,
  color: Colors.LIGHT_GRAY,
  cursor: 'grabbing',
  touchAction: 'pan-y'
})

export const Title = styledMaterialUI(Typography)({
  fontSize: 50,
  fontFamily: 'Montserrat',
  fontWeight: 'bold',
  color: '#fff',
  zIndex: 3,
  textAlign: 'center'
})

export const Subtitle = styledMaterialUI(Typography)({
  fontSize: 40,
  fontFamily: 'Montserrat',
  color: '#fff',
  zIndex: 3,
  textAlign: 'center'
})

export const VersionText = styledMaterialUI(Typography)({
  fontSize: 11,
  fontFamily: 'Montserrat',
  color: '#fff',
  position: 'absolute',
  bottom: 10,
  right: 10
})

export const DraggableContents = styled.div`
  display: flex;
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  flex-direction: ${(props) => props.flexDirection};
  margin: ${(props) => props.margin || null};
`

export const WidgetsGrid = styled.div`
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -57%);
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5%;
  height: 90%;
  width: 100%;
  overflow-y: auto;
  z-index: 1;
  justify-content: flex-start;
  margin-left: 2%;
  ${(props) =>
    props.isMobile &&
    css`
      justify-content: center;
      margin-left: 0;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `};
`
export const WidgetTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  text-justify: center;
  margin: 16px 8px 0 8px;
`
export const WidgetSubtitle = styled.p`
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  text-justify: center;
  margin: 2px 0 0 16px;
  color: ${Colors.LIGHT_GRAY};
`

export const WidgetDate = styled.p`
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  text-justify: center;
  margin: 2px 0 0 16px;
  color: ${Colors.LIGHT_GRAY};
`

export const WidgetReadings = styled.span`
  font-size: 34px;
  text-align: center;
  text-justify: center;
  font-weight: bold;
  color: ${(props) => props.color};
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  flex-direction: column;
  overflow: hidden;
  background-color: green;
`
export const BackgroundImg = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  background-image: url(${DashboardBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  ${(props) =>
    props.hasSnapshot &&
    css`
      -webkit-filter: blur(5px);
    `};
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const Header = styled.div`
  width: 100%;
  height: 6%;
  padding: 0 0 50px 0;
`

export const Bottom = styled.div`
  display: flex;
  width: 100%;
  height: 4%;
  position: absolute;
  bottom: 0;
`
export const StyledSpam = styled.span`
  font-family: 'Montserrat';
  font-size: ${(props) => props.fontSize};
`

export const DotsStepper = styledMaterialUI(MobileStepper)({
  background: 'rgba(216, 239, 254, 1)',
  width: '80%',
  borderRadius: '24px',
  visibility: (props) => props.isHidden
})
