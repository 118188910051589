import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { matchSorter } from 'match-sorter'

import FarmsService from '../../services/farms'
import ProjectsService from '../../services/projects'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'

import useTheme from '@material-ui/core/styles/useTheme'
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {
  StyledAutocomplete,
  DefaultTextField,
  DefaultNumericsField,
  StyledButton
} from './styles'
import AppBar from '../../Components/Appbar'
import DataTable from '../../Components/DataTable'
import {
  Column,
  Container,
  Content,
  Header,
  IconButton,
  Button,
  Row,
  OutlinedInput,
  TableWrapper,
  TableLoadingCompWrapper,
  Text,
  CustomLinearProgress
} from '../../styles/components'
import Colors from '../../styles/colors'
import {
  validateDescription,
  validateNumber,
  validateProjectSectors,
  validateSystems
} from '../../utils/validators'
import { IRRIGATION_SYSTEMS_LIST, USER_ACCESS } from '../../utils/constants'
import { adminProjects } from '../../utils/pageAccess'
import ModalComponent from '../../Components/ModalComponent'
import ConfirmationDialog from '../../Components/ConfirmationDialog'
import CloseConfirmationDialog from '../../Components/CloseConfirmationDialog'

const sectorInputprop = {
  shrink: true
}

const searchOptions = {
  keys: [
    (item) => get(item, 'description', ''),
    (item) => get(item, 'farm.name', ''),
    (item) => get(item, 'sectors.length', '')
  ],
  keepDiacritics: false
}

const transitionDown = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const Projects = (props, sectorDes) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [id, setId] = useState(0)
  const [farm, setFarm] = useState(null)
  const [description, setDescription] = useState('')
  const [notes, setNotes] = useState('')
  const [sectorList, setSectorList] = useState([])
  const [farmList, setFarmList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [projectList, setProjectList] = useState([])
  const [searchText, setSearchText] = useState('')
  const [numberOfPumps, setNumberOfPumps] = useState('')
  const [totalPotency, setTotalPotency] = useState('')
  const [irrigationSystem, setIrrigationSystem] = useState(null)
  const [closeConfirmation, setCloseConfirmation] = useState(false)

  const currentAccess = get(props, 'currentUser.userAccess', null)
  const shouldDisable = adminProjects(currentAccess) === USER_ACCESS.READ

  useEffect(async () => {
    try {
      setIsLoading(true)
      const newList = await FarmsService.getList(
        props.currentUser.token,
        props.currentClient.id
      )
      const newProjectList = await ProjectsService.getList(
        props.currentUser.token,
        props.currentClient.id
      )
      setFarmList(newList)
      setProjectList(newProjectList)
      setIsLoading(false)
    } catch (error) {
      setFarmList([])
      setIsLoading(false)
    }
  }, [props.currentClient.id])

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => setSuccessMessage(''), 2000)
    }
  }, [successMessage])

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => setErrorMessage(''), 2000)
    }
  }, [errorMessage])

  const filteredProjectList = searchText.length
    ? matchSorter(projectList, searchText, searchOptions)
    : projectList

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const mobile = isMobile ? '80%' : null

  const _getColumns = useCallback(() => {
    return [
      {
        key: 'description',
        label: 'Descrição',
        render_description: (item) => item.description
      },
      {
        key: 'farmName',
        label: 'Fazenda',
        render_farmName: (item) => get(item, 'farm.name', '')
      },
      {
        key: 'sectors',
        label: 'Número de Setores',
        render_sectors: (item) => item.sectors.length
      }
    ]
  }, [])

  const _onChangeFarm = useCallback((event, value) => {
    setFarm(value)
  }, [])

  const _onChangeIrrigationSystems = useCallback((event, value) => {
    setIrrigationSystem(value)
  }, [])

  const _onChangeDescription = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setDescription(value)
  }, [])

  const _onChangeNote = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setNotes(value)
  }, [])

  const _onSearch = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setSearchText(value)
  }, [])

  const _onChangeNumberOfPumps = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setNumberOfPumps(value)
  }, [])

  const _onChangeTotalPotency = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setTotalPotency(value)
  }, [])

  const handleModalOpen = useCallback(() => {
    setId(0)
    setFarm(null)
    setDescription('')
    setNotes('')
    setSectorList([])
    setNumberOfPumps('')
    setTotalPotency('')
    setIrrigationSystem(null)
    setModalOpen(true)
  }, [])

  const handleModalClose = useCallback(() => {
    setModalOpen(false)
  }, [])

  const _handleConfirmationOpen = useCallback(() => {
    setModalOpen(false)
    setOpenConfirmation(true)
  }, [])

  const _handleConfirmationClose = useCallback(() => {
    setOpenConfirmation(false)
    setModalOpen(true)
  }, [])

  const _onAcceptCloseConfirmation = useCallback(() => {
    setCloseConfirmation(false)
  }, [])

  const _onRejectCloseConfirmation = useCallback(() => {
    setCloseConfirmation(false)
    setModalOpen(true)
  }, [])

  const _onOpenCloseConfirmation = useCallback(() => {
    setModalOpen(false)
    setCloseConfirmation(true)
  }, [])

  const _onChangeSectorFields = useCallback((sectorIndex, field, event) => {
    setSectorList((oldList) => {
      const newList = [...oldList]
      const newItem = { ...newList[sectorIndex] }
      const value = get(event, 'target.value', '')
      if (newItem) {
        newItem[field] = value
        newList[sectorIndex] = newItem

        return newList
      }

      return oldList
    })
  }, [])

  const addSector = useCallback(() => {
    const newDescription = `Setor ${sectorList.length + 1}`
    let newItem
    if (sectorList.length) {
      const prevSector = { ...sectorList[sectorList.length - 1] }
      newItem = {
        idTemp: Date.now(),
        description: newDescription,
        area: prevSector.area,
        espLines: prevSector.espLines,
        espPlant: prevSector.espPlant,
        espLinesDripper: prevSector.espLinesDripper,
        espDripper: prevSector.espDripper,
        dripperFlowRate: prevSector.dripperFlowRate
      }
    } else {
      newItem = {
        idTemp: Date.now(),
        description: newDescription,
        area: null,
        espLines: null,
        espPlant: null,
        espLinesDripper: null,
        espDripper: null,
        dripperFlowRate: null
      }
    }

    const newSectorList = [...sectorList, newItem]

    setSectorList(newSectorList)
  }, [sectorList])

  const removeSector = useCallback(
    (index) => {
      const newSectorList = [...sectorList]
      if (newSectorList[index]) {
        newSectorList.splice(index, 1)

        setSectorList(newSectorList)
      }
    },
    [sectorList]
  )

  const _handleSave = useCallback(async () => {
    try {
      setIsLoading(true)
      const payload = {
        id,
        farm_id: farm.id,
        farm,
        notes,
        description,
        number_of_pumps: numberOfPumps,
        total_potency: totalPotency,
        irrigation_system: irrigationSystem.value,
        sectors: sectorList
      }
      const newList = [...projectList]
      const authToken = props.currentUser.token
      if (id) {
        const updatedProject = await ProjectsService.updateProject(
          authToken,
          payload
        )
        const newItem = {
          ...payload,
          id: updatedProject.id,
          sectors: updatedProject.sectors
        }

        const projectIndex = projectList.findIndex(
          (item) => item.id === newItem.id
        )

        if (projectIndex > -1) {
          newList[projectIndex] = newItem
        }

        setSuccessMessage('Informações de projeto atualizadas com sucesso!')
      } else {
        const newProject = await ProjectsService.insertProject(
          authToken,
          payload
        )
        newList.push({ ...payload, id: newProject.id })

        setSuccessMessage('Informações de projeto guardadas com sucesso!')
      }
      setProjectList(newList)
      setOpenConfirmation(false)
      setIsLoading(false)
    } catch (error) {
      setErrorMessage(error.message)
      setOpenConfirmation(false)
      setIsLoading(false)
    }
  }, [
    projectList,
    id,
    farm,
    description,
    sectorList,
    numberOfPumps,
    totalPotency,
    irrigationSystem,
    props.currentClient
  ])

  const onClickItem = useCallback((item) => {
    const irrigation = IRRIGATION_SYSTEMS_LIST.find(
      (i) => i.value === item.irrigation_system
    )
    setId(item.id)
    setFarm(item.farm)
    setDescription(item.description)
    setNotes(item.notes)
    setSectorList(item.sectors)
    setNumberOfPumps(item.number_of_pumps)
    setTotalPotency(item.total_potency)
    setIrrigationSystem(irrigation)
    setModalOpen(true)
  }, [])

  const _closeConfirmationDialog = () => {
    return (
      <Dialog
        open={closeConfirmation}
        TransitionComponent={transitionDown}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CloseConfirmationDialog
          onClickCancel={_onRejectCloseConfirmation}
          onClickConfirm={_onAcceptCloseConfirmation}
        />
      </Dialog>
    )
  }

  const SectorsAccordion = (sector, sectorIndex) => {
    const descriptionHasError = validateDescription(sector.description)
    const areaHasError = validateNumber(sector.area)
    const espLinesHasError = validateNumber(sector.espLines)
    const espPlantHasError = validateNumber(sector.espPlant)
    const espLinesDripperHasError = validateNumber(sector.espLinesDripper)
    const espDripperHasError = validateNumber(sector.espDripper)
    const dripperFlowRateHasError = validateNumber(sector.dripperFlowRate)

    return (
      <Column>
        <DefaultTextField
          label="Descrição do Setor"
          required
          value={sector.description}
          error={!!descriptionHasError}
          helperText={descriptionHasError}
          disabled={shouldDisable}
          onChange={(event) =>
            _onChangeSectorFields(sectorIndex, 'description', event)
          }
          variant="outlined"
          InputLabelProps={sectorInputprop}
        />
        <DefaultNumericsField
          label="Área (ha)"
          required
          decimal
          value={sector.area}
          error={!!areaHasError}
          helperText={areaHasError}
          disabled={shouldDisable}
          onChange={(event) =>
            _onChangeSectorFields(sectorIndex, 'area', event)
          }
          variant="outlined"
          InputLabelProps={sectorInputprop}
          placeholder="0,00 ha"
        />
        <DefaultNumericsField
          label="Esp.linhas (m)"
          required
          decimal
          value={sector.espLines}
          error={!!espLinesHasError}
          helperText={espLinesHasError}
          disabled={shouldDisable}
          onChange={(event) =>
            _onChangeSectorFields(sectorIndex, 'espLines', event)
          }
          variant="outlined"
          InputLabelProps={sectorInputprop}
          placeholder="0,00 m"
        />
        <DefaultNumericsField
          label="Esp. plantas (m)"
          required
          decimal
          value={sector.espPlant}
          error={!!espPlantHasError}
          helperText={espPlantHasError}
          disabled={shouldDisable}
          onChange={(event) =>
            _onChangeSectorFields(sectorIndex, 'espPlant', event)
          }
          variant="outlined"
          InputLabelProps={sectorInputprop}
          placeholder="0,00 m"
        />
        <DefaultNumericsField
          label="Esp. linhas de emissores (m)"
          required
          decimal
          value={sector.espLinesDripper}
          error={!!espLinesDripperHasError}
          helperText={espLinesDripperHasError}
          disabled={shouldDisable}
          onChange={(event) =>
            _onChangeSectorFields(sectorIndex, 'espLinesDripper', event)
          }
          variant="outlined"
          InputLabelProps={sectorInputprop}
          placeholder="0,00 m"
        />
        <DefaultNumericsField
          label="Esp. emissores (m)"
          required
          decimal
          value={sector.espDripper}
          error={!!espDripperHasError}
          helperText={espDripperHasError}
          disabled={shouldDisable}
          onChange={(event) =>
            _onChangeSectorFields(sectorIndex, 'espDripper', event)
          }
          variant="outlined"
          InputLabelProps={sectorInputprop}
          placeholder="0,00 l/h"
        />
        <DefaultNumericsField
          label="Vazão emissor (l/h)"
          required
          decimal
          value={sector.dripperFlowRate}
          error={!!dripperFlowRateHasError}
          helperText={dripperFlowRateHasError}
          disabled={shouldDisable}
          onChange={(event) =>
            _onChangeSectorFields(sectorIndex, 'dripperFlowRate', event)
          }
          variant="outlined"
          InputLabelProps={sectorInputprop}
          placeholder="0,00 l/h"
        />
        <StyledButton
          background={Colors.DANGER}
          startIcon={<CloseIcon />}
          onClick={() => removeSector(sectorIndex)}
          disabled={shouldDisable}
          width="50%"
        >
          Remover
        </StyledButton>
      </Column>
    )
  }

  const projectsModal = () => {
    const descriptionError = validateDescription(description)
    const farmError = !get(farm, 'id', 0) ? 'Fazenda não selecionada' : ''
    const irrigationSystemError = validateSystems(irrigationSystem)
    const sectorsError = validateProjectSectors(sectorList)
    const numberOfPumpsError = validateNumber(numberOfPumps)
    const totalPotencyError = validateNumber(totalPotency)
    const hasError = !!(
      descriptionError ||
      farmError ||
      sectorsError ||
      numberOfPumpsError ||
      totalPotencyError ||
      irrigationSystemError ||
      shouldDisable
    )
    return (
      <ModalComponent
        title="Cadastro de Projetos"
        buttonLabel="Salvar"
        onClickClose={handleModalClose}
        onClickButton={_handleConfirmationOpen}
        disabled={hasError}
        open={modalOpen}
        onClose={_onOpenCloseConfirmation}
      >
        <StyledAutocomplete
          value={farm}
          required
          onChange={_onChangeFarm}
          options={farmList}
          getOptionLabel={(option) => option.name || ''}
          getOptionSelected={(option, v) =>
            get(option, 'id', 0) === get(v, 'id', 0)
          }
          disabled={shouldDisable}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Fazendas"
              variant="outlined"
              placeholder="Selecione uma fazenda"
              error={!!farmError}
              helperText={farmError}
              InputLabelProps={sectorInputprop}
            />
          )}
        />
        <DefaultTextField
          label="Descrição"
          required
          variant="outlined"
          value={description}
          onChange={_onChangeDescription}
          error={!!descriptionError}
          helperText={descriptionError}
          InputLabelProps={sectorInputprop}
          disabled={shouldDisable}
          placeholder="Insira a descrição do projeto."
        />
        <DefaultTextField
          label="Observações"
          variant="outlined"
          multiline
          rows={6}
          value={notes}
          disabled={shouldDisable}
          onChange={_onChangeNote}
          InputLabelProps={sectorInputprop}
          placeholder="insira observações a respeito do projeto."
        />
        <DefaultNumericsField
          label="Número de bombas"
          variant="outlined"
          disabled={shouldDisable}
          value={numberOfPumps}
          onChange={_onChangeNumberOfPumps}
          InputLabelProps={sectorInputprop}
          error={!!numberOfPumpsError}
          helperText={numberOfPumpsError}
          placeholder="Insira nº de bombas."
        />

        <DefaultNumericsField
          label="Potência das bombas"
          decimal
          variant="outlined"
          value={totalPotency}
          onChange={_onChangeTotalPotency}
          disabled={shouldDisable}
          InputLabelProps={sectorInputprop}
          error={!!totalPotencyError}
          helperText={totalPotencyError}
          placeholder="Insira a potência das bombas."
        />
        <StyledAutocomplete
          value={irrigationSystem}
          required
          disableClearable
          onChange={_onChangeIrrigationSystems}
          options={IRRIGATION_SYSTEMS_LIST}
          getOptionLabel={(option) => (option ? option.label : '')}
          getOptionSelected={(option, v) =>
            get(option, 'value', 0) === get(v, 'value', 0)
          }
          disabled={shouldDisable}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Sistemas de Irrigação"
              variant="outlined"
              placeholder="Selecione um sistema de Irrigação"
              error={!!irrigationSystemError}
              helperText={irrigationSystemError}
              InputLabelProps={sectorInputprop}
            />
          )}
        />
        <StyledButton
          variant="contained"
          color="primary"
          style={{ marginLeft: 8 }}
          startIcon={<AddIcon />}
          onClick={addSector}
          disabled={shouldDisable}
          width={mobile}
        >
          Adicionar Setor
        </StyledButton>

        <List>
          {sectorList.map((s, i) => {
            return (
              <ListItem key={i}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">{s.description}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>{SectorsAccordion(s, i)}</AccordionDetails>
                </Accordion>
              </ListItem>
            )
          })}
        </List>
      </ModalComponent>
    )
  }

  const _renderConfirmation = () => {
    return (
      <Dialog
        open={openConfirmation}
        onClose={_handleConfirmationClose}
        TransitionComponent={transitionDown}
        aria-labeledby="alert-dialog-title"
        aria-disbredby="alert-dialog-description"
      >
        <ConfirmationDialog
          onClickCancel={_handleConfirmationClose}
          onClickConfirm={_handleSave}
        />
      </Dialog>
    )
  }

  const _renderAlert = () => {
    const alertMessage = successMessage || errorMessage
    const alertColor = successMessage ? 'success' : 'error'
    return (
      <Snackbar open={!!alertMessage} key={alertColor}>
        <Alert severity={alertColor}>{alertMessage}</Alert>
      </Snackbar>
    )
  }

  return (
    <Container>
      <AppBar history={props.history} />
      <Content>
        <Header
          display="flex"
          flexDirection="column"
          width="100%"
          height="10%"
          padding="3em 0 10em 0"
        >
          <Row justify="center" align="center">
            {isMobile ? (
              <IconButton
                color="primary"
                aria-label="add"
                onClick={handleModalOpen}
                disabled={shouldDisable}
              >
                <AddIcon />
              </IconButton>
            ) : (
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleModalOpen}
                disabled={shouldDisable}
              >
                Adicionar
              </Button>
            )}
            <OutlinedInput
              isMobile={isMobile}
              mobileWidth="60%"
              width="25%"
              id="outlined-adornment-search"
              label="Pesquisar"
              placeholder="Pesquisar"
              type="search"
              onChange={_onSearch}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              labelWidth={60}
            />
          </Row>
        </Header>
        {isLoading ? (
          <TableLoadingCompWrapper>
            <Text margin="12px" textColor="black">
              Carregando lista de projetos...
            </Text>
            <CustomLinearProgress />
          </TableLoadingCompWrapper>
        ) : (
          <TableWrapper>
            <DataTable
              columns={_getColumns()}
              data={filteredProjectList}
              mobileLabel={(item) => item.description}
              mobileSubtitle={(item) => get(item, 'farm.name', '')}
              onClickItem={onClickItem}
            />
          </TableWrapper>
        )}
      </Content>
      {_renderAlert()}
      {projectsModal()}
      {_renderConfirmation()}
      {_closeConfirmationDialog()}
    </Container>
  )
}

Projects.propTypes = {
  history: PropTypes.object,
  getFarms: PropTypes.func,
  currentClient: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired
  }),
  numberError: PropTypes.number,
  sectorDescriptionError: PropTypes.string
}

Projects.defaultProps = {
  getFarms: () => {}
}

const mapState = ({ auth, clients }) => {
  return {
    currentUser: auth.currentUser,
    currentClient: clients.currentClient
  }
}

export default connect(mapState)(Projects)
