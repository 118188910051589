import axios from 'axios'

const googleApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
const timezoneUrl = process.env.REACT_APP_GOOGLE_TIMEZONE_API_URL

const getTimezoneFromLatLong = async (lat, long) => {
  const queryParams = `location=${lat},${long}&timestamp=0&key=${googleApiKey}`
  const url = `${timezoneUrl}?${queryParams}`
  const response = await axios.get(url)

  return response.data.timeZoneId
}

export default { getTimezoneFromLatLong }
