import styled from 'styled-components'
import { styled as styledMaterialUI } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  KeyboardDateTimePicker
} from '@material-ui/pickers'

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const TypoHeader = styledMaterialUI(Typography)({
  fontSize: '20px',
  fontFamily: 'Montserrat',
  margin: '8px 8px 16px 8px'
})

export const DatePicker = styledMaterialUI(KeyboardDatePicker)({
  margin: 8,
  minWidth: 250
})

export const TimePicker = styledMaterialUI(KeyboardTimePicker)({
  margin: 8,
  minWidth: 250
})

export const ReadingsDateTimePicker = styledMaterialUI(KeyboardDateTimePicker)({
  margin: 8,
  minWidth: 250
})
