import styled from 'styled-components'
import { styled as styledMaterialUI } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItem from '@material-ui/core/ListItem'

export const SectorContainer = styled.div`
  background: #f8f5f0;
  border-color: #000000;
  box-shadow: 0px 0px 2px rgb(0, 0, 0);
`

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const TypoHeader = styledMaterialUI(Typography)({
  fontSize: '20px',
  fontFamily: 'Montserrat',
  margin: '8px 8px 16px 8px'
})

export const ListIcon = styledMaterialUI(ListItemIcon)({ alignItems: 'center' })

export const ListItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ListStyledItem = styledMaterialUI(ListItem)({
  display: 'flex',
  flex: 1,
  padding: '0 0 8px 0'
})

export const SectorName = styledMaterialUI(Typography)({
  fontSize: '16px',
  fontFamily: 'Montserrat',
  margin: '4px 8px 0 4px'
})
