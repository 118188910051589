import dayjs from 'dayjs'
import { IRRIGATION_TYPES } from './constants'
import uniq from 'lodash/uniq'

export const validateClientName = (name) => {
  let error = ''
  if (name.length <= 2) {
    error = 'O nome deve conter mais de 2 caracteres'
  }

  return error
}

export const validateUserName = (name) => {
  let error = ''
  if (name.length <= 2) {
    error = 'O nome de usuário deve conter mais de 2 caracteres'
  }
  if (name.includes(' ')) {
    error = 'O nome de usuário não pode conter espaços'
  }

  return error
}

export const validatePassword = (password) => {
  let error = ''
  if (password.length <= 2) {
    error = 'A senha deve conter mais de 2 caracteres'
  }

  return error
}

export const validatePhone = (phone) => {
  let error = ''
  const withoutSpaces = phone.replace(' ', '').trim()
  if (withoutSpaces.length !== 13 && withoutSpaces.length !== 14) {
    error = 'O telefone está incompleto'
  }

  return error
}

export const validateProjectList = (projects) => {
  let error = ''
  if (!projects.length) {
    error = 'Por favor, selecione um ou mais projetos.'
  }
  return error
}

export const validateEmail = (email) => {
  let error = ''
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const result = regex.test(String(email).toLowerCase())

  if (!result) {
    error = 'O email é inválido'
  }

  return error
}

export const validateAccessExpiresDate = (accessExpires) => {
  let error = ''

  const today = dayjs()
  const isValid = dayjs(accessExpires).isAfter(today)

  if (!isValid) {
    error = 'A data deve ser posterior a hoje'
  }

  return error
}

export const validateFarmName = (farmName) => {
  let error = ''
  if (farmName.length <= 2) {
    error = 'O nome deve conter mais de 2 caracteres'
  }

  return error
}

export const validateGeoLoc = (farmLocation) => {
  let error = ''
  if (!farmLocation.lat || !farmLocation.lng) {
    error = 'Por favor, selecione a localização da fazenda'
  }
  return error
}

export const validateDescription = (description) => {
  let error = ''
  if (description.length <= 1) {
    error = 'O campo deve conter mais de 2 caracteres'
  }

  return error
}

export const validateText = (text) => {
  let error = ''
  if (text.length <= 1) {
    error = 'O campo de texto de dicas não pode ser vazio.'
  }
  if (text.length > 500) {
    error = ' O campo de texto deve conter 500 ou menos caracteres.'
  }
  return error
}

export const validateNumber = (number) => {
  if (isNaN(Number(number))) {
    return 'O valor é inválido'
  }
  if (Number(number) <= 0) {
    return 'O valor deve ser maior que 0'
  }
  return ''
}

export const validateTensiometerDepth = (number, tensiometerList) => {
  const list = []
  tensiometerList.forEach((t) => list.push(t.depth))
  const hasDuplicate = uniq(list).length !== list.length

  if (isNaN(Number(number))) {
    return 'O valor é inválido.'
  }
  if (Number(number) <= 0) {
    return 'O valor deve ser maior que 0.'
  }
  if (hasDuplicate) {
    return 'Os valores não podem ser duplicados.'
  }
  return ''
}

export const validateSectorDuration = (number, type) => {
  if (
    (type !== IRRIGATION_TYPES.NONE && isNaN(Number(number))) ||
    (type !== IRRIGATION_TYPES.NONE && number === null) ||
    (type !== IRRIGATION_TYPES.NONE && number === undefined)
  ) {
    return 'O valor é inválido'
  }
  if (type !== IRRIGATION_TYPES.NONE && Number(number) <= 0) {
    return 'O valor deve ser maior que 0'
  }
  return ''
}

export const validateNumberOrZero = (number) => {
  if (isNaN(Number(number)) || number === null || number === undefined) {
    return 'O valor é inválido'
  }
  if (Number(number) <= 0) {
    return 'O valor deve ser maior que 0'
  }
  return ''
}

export const validateHidrometerNumberOrZero = (number) => {
  if (isNaN(Number(number))) {
    return 'O valor é inválido'
  }

  return ''
}

export const validateTensiometerReading = (number, depth) => {
  // if (isNaN(Number(number)) || number === null || number === undefined) {
  //   return 'O valor é inválido'
  // }
  if (Number(number) < depth) {
    return 'O valor deve ser maior ou igual a profundidade tensiômetro'
  }
  if (Number(number) > 800) {
    return 'O valor insediro não deve exceder 800cm'
  }

  return ''
}

export const validateExtractorsReading = (number) => {
  if (Number(number) < 0) {
    return 'O valor deve ser maior ou igual a 0'
  }
  if (Number(number) > 10000) {
    return 'O valor insediro não deve exceder 10.000μS/cm'
  }
  return ''
}

export const validateExtractorsPhReading = (number) => {
  if (Number(number) < 0) {
    return 'O valor deve ser maior ou igual a 0'
  }
  if (Number(number) > 14) {
    return 'O valor insediro não deve exceder 14'
  }
  return ''
}

export const validateProjectSectors = (sectors) => {
  const hasErrors = sectors.find((s) => {
    const descriptionHasError = validateDescription(s.description)
    const areaHasError = validateNumber(s.area)
    const espLinesHasError = validateNumber(s.espLines)
    const espPlantHasError = validateNumber(s.espPlant)
    const espLinesDripperHasError = validateNumber(s.espLinesDripper)
    const espDripperHasError = validateNumber(s.espDripper)
    const dripperFlowRateHasError = validateNumber(s.dripperFlowRate)

    return (
      !!descriptionHasError ||
      !!areaHasError ||
      !!espLinesHasError ||
      !!espPlantHasError ||
      !!espLinesDripperHasError ||
      !!espDripperHasError ||
      !!dripperFlowRateHasError
    )
  })

  return sectors.length === 0 || hasErrors
}

export const validateTensiometer = (tensiometer) => {
  const hasErrors = tensiometer.find((t) => {
    const tensiometerDepthHasError = validateNumber(t.depth)

    return !!tensiometerDepthHasError
  })

  return hasErrors || tensiometer.length === 0
}

export const validateExtractors = (extractors) => {
  const hasErrors = extractors.find((extractors) => {
    const extractorsDepthHasError = validateNumber(extractors.depth)

    return !!extractorsDepthHasError
  })

  return hasErrors || extractors.length === 0
}

export const validateRoles = (role) => {
  let error = ''
  if (role.length <= 2) {
    error = 'Por favor, selecione uma função.'
  }

  return error
}

export const validateSystems = (irrigationSystem) => {
  let error = ''
  if (irrigationSystem <= 0) {
    error = 'Por favor, selecione um sistema de irrigação.'
  }

  return error
}

export const validateProject = (project) => {
  let error = ''
  if (project.length < 1) {
    error = 'Por favor, selecione um ou mais projetos.'
  }
  return error
}

export const validateClient = (client) => {
  let error = ''
  if (client.length < 1) {
    error = 'Por favor, selecione um ou mais clientes.'
  }
  return error
}
