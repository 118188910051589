import { createGlobalStyle } from 'styled-components'
import { createTheme } from '@material-ui/core/styles'

const GlobalStyles = createGlobalStyle`

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
        font-family: 'Montserrat', sans-serif;
    }

    html, body, #root {
      min-height: 100% !important;
      height: 100%;
    }

    body {
      text-rendering: optimizeLegibility !important;
      -webkit-font-smoothing: antialiased !important;
    }

    button {
        cursor: pointer;
    }
`

export const myTheme = createTheme({
  overrides: {
    typography: {
      fontFamily: ['Montserrat', 'sans-serif'].join(',')
    },
    stepperdialog: {
      fontFamily: ['Montserrat', 'sans-serif'].join(',')
    },
    dialogcontenttext: {
      fontFamily: ['Montserrat', 'sans-serif'].join(',')
    },
    dialogtitle: {
      fontFamily: ['Montserrat', 'sans-serif'].join(',')
    }
  }
})

export default GlobalStyles
