import axios from 'axios'
import { urlB64ToUint8Array } from '../utils/helpers'
import getValue from 'lodash/get'
import logError from '../utils/log'

const NOTIFICATIONS_API = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/notifications`
})

const subscribeUserDevice = async (authToken, subscriptionInfo) => {
  try {
    await NOTIFICATIONS_API.post(
      '/register-device',
      { subscriptionInfo },
      {
        headers: { Authorization: `bearer ${authToken}` }
      }
    )
  } catch (error) {
    logError('services@notifications@subscribeUserDevice', error)
  }
}

const getSubscriptions = (authToken, swReg) => {
  swReg.pushManager
    .getSubscription()
    .catch((error) => {
      console.error(error)
    })
    .then((sub) => {
      if (!sub) {
        return swReg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlB64ToUint8Array(
            process.env.REACT_APP_PUSH_PUBLIC_KEY
          )
        })
      } else return null
    })
    .then((newSub) => {
      if (newSub) subscribeUserDevice(authToken, newSub)
    })
}

const askNotificationPermission = (authToken) => {
  try {
    if ('Notification' in window && 'serviceWorker' in navigator) {
      Notification.requestPermission()
        .then((res) => {
          if (res === 'granted') {
            navigator.serviceWorker.ready
              .then((swReg) => {
                getSubscriptions(authToken, swReg)
              })
              .catch((err) => {
                console.log(err)
              })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  } catch (error) {
    if (error instanceof TypeError) {
      Notification.requestPermission((res) => {
        if (res === 'granted') {
          navigator.serviceWorker.ready
            .then((swReg) => getSubscriptions(swReg))
            .catch((err) => {
              console.log(err)
            })
        }
      })
    }
  }
}

const getNotificationsList = async (authToken, clientId) => {
  try {
    const response = await NOTIFICATIONS_API.get(
      `/list?client_id=${clientId}`,
      {
        headers: { Authorization: `bearer ${authToken}` }
      }
    )

    return response.data
  } catch (error) {
    logError('services@notifications@getNotificationsList', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao buscar a lista de notificações'
      )
    )
  }
}

const getNotificationsRead = async (authToken, id) => {
  try {
    const response = await NOTIFICATIONS_API.get(`/read/${id}`, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@notifications@getNotificationsRead', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao buscar a notificação'
      )
    )
  }
}

export default {
  askNotificationPermission,
  getNotificationsList,
  getNotificationsRead
}
