import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import Zoom from '@material-ui/core/Zoom'
import dayjs from 'dayjs'
// eslint-disable-next-line
import 'dayjs/locale/pt-br'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import store from './store'

import GlobalStyles, { myTheme } from './styles/global'
import { Column } from './styles/components'

import Route from './routes'

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.locale('pt-br')

const transitionZoonIn = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />
})

const UpdateWarningAlert = ({ isOpen, handleClose }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={transitionZoonIn}
    >
      <DialogTitle>Atualização</DialogTitle>
      <DialogContent>
        <Typography>
          Existe uma atualização disponível. Por favor, reinicie o aplicativo
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
          color="primary"
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

UpdateWarningAlert.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func
}

UpdateWarningAlert.defaultProps = {
  handleClose: () => {}
}

export default function App() {
  const [isUpdateAlertOpen, setIsUpdateAlertOpen] = useState(false)

  const _closeUpdateAlert = useCallback(() => {
    setIsUpdateAlertOpen(false)
  }, [])

  useEffect(() => {
    window.appHasUpdateAvailable = () => {
      setIsUpdateAlertOpen(true)
    }
  }, [])

  return (
    <ThemeProvider theme={myTheme}>
      <BrowserRouter>
        <Provider store={store}>
          <GlobalStyles />
          <Column width="100%" height="100%">
            <Route />
            <UpdateWarningAlert
              isOpen={isUpdateAlertOpen}
              handleClose={_closeUpdateAlert}
            />
          </Column>
        </Provider>
      </BrowserRouter>
    </ThemeProvider>
  )
}
