import styled from 'styled-components'
import { styled as styledMaterialUI } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Colors from '../../styles/colors'
import { Typography } from '@material-ui/core'

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 99%;
  max-height: 80%;
  background-color: ${Colors.TABLE_CONTAINER};
  border-radius: 8px;
  margin-bottom: 8px;
`

export const HeaderContent = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
`

export const Body = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  border-radius: 8px;
`

export const EmptyContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 99%;
  height: 200px;
  background-color: ${Colors.TABLE_CONTAINER};
  border-radius: 8px;
  opacity: 0.6;
`
export const TipContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 320px;
  justify-content: space-between;
  margin: 8px;
`
export const StyledMobileText = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  color: ${Colors.SUBTEXT};
  font-weight: italic;
  margin: 8px;
  white-space: pre-line;
  text-align: justify;
`
export const StyledText = styledMaterialUI(Typography)({
  fontFamily: 'Montserrat',
  fontSize: '12px',
  color: Colors.SUBTEXT,
  fontWeight: 'italic',
  margin: '8px',
  whiteSpace: 'pre-line',
  textAlign: 'justify'
})
