import { styled as styledMaterialUI } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import AppBar from '@material-ui/core/AppBar'

import Colors from '../../styles/colors'

export const Container = styledMaterialUI(Dialog)({
  display: 'flex',
  flexDirection: 'column',

  '& .MuiPaper-root': {
    height: '100%',
    width: '100%'
  }
})

export const ModalTitle = styledMaterialUI(Typography)({
  fontSize: '20px',
  fontFamily: 'Montserrat',
  fontWeight: 700,
  fontStyle: 'bold',
  color: '#ffffff',
  margin: '0 16px 0 24px',
  textTransform: 'uppercase'
})

export const ModalHeader = styledMaterialUI(AppBar)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'static',
  flexWrap: 'wrap',
  backgroundColor: Colors.AZUL_HYDRA,
  '&&': {
    height: '80px',
    width: '100%'
  }
})

export const StepperButton = styledMaterialUI(Button)({
  marginRight: '10px',
  backgroundColor: (props) => props.backgroundColor,
  color: (props) => props.textColor,
  '&:hover': {
    backgroundColor: (props) => props.backgroundColor,
    boxShadow: '1.5px 1.5px 1px 1px rgba(0, 0, 0, 0.15)'
  }
})

export const HelpFloatingActionButton = styledMaterialUI(IconButton)({
  color: 'white',
  position: 'absolute',
  right: 45,
  top: 25
})
