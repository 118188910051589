/* eslint-disable indent */
import React from 'react'
import PropTypes from 'prop-types'

import LiquidFillGauge from 'react-liquid-gauge'

const LiquidWidget = ({ value, reading }) => {
  const radius = 200
  const fillColor =
    value <= 10
      ? 'rgba(22, 113, 196, 0.8)'
      : null || value <= 30
      ? 'rgba(19, 111, 194, 0.8)'
      : null || value <= 90
      ? 'rgba(16, 102, 181, 0.8)'
      : null || value <= 100
      ? 'rgba(11, 94, 170, 0.8)'
      : null

  return (
    <LiquidFillGauge
      value={value}
      width={radius}
      height={radius}
      riseAnimation
      waveAnimation
      waveFrequency={2}
      waveAmplitude={1}
      circleStyle={{
        fill: fillColor
      }}
      waveStyle={{
        fill: fillColor
      }}
      textSize={1}
      textOffsetX={0}
      textOffsetY={0}
      textRenderer={({ value, width, height, textSize }) => {
        value = Math.round(value)
        const radius = Math.min(height / 2, width / 2)
        const textPixels = (textSize * radius) / 3
        const valueStyle = {
          fontWeight: 'bold',
          fontSize: textPixels
        }
        const mm = {
          fontWeight: 'bold',
          fontSize: textPixels * 0.6
        }

        return (
          <tspan>
            <tspan className="value" style={valueStyle}>
              {reading}
            </tspan>
            <tspan style={mm}>mm</tspan>
          </tspan>
        )
      }}
    />
  )
}

LiquidWidget.propTypes = {
  value: PropTypes.number.isRequired,
  reading: PropTypes.string.isRequired
}

export default LiquidWidget
