import { styled as styledMaterialUI } from '@material-ui/core/styles'
import styled from 'styled-components'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import NumberInput from '../../Components/NumberInput'

import Colors from '../../styles/colors'

export const StyledButton = styledMaterialUI(Button)({
  margin: '8px 0 0 8px',
  width: (props) => props.width || '40%',
  backgroundColor: Colors.AZUL_HYDRA,
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: Colors.AZUL_HYDRA,
    boxShadow: '0 0 11px rgba(0,0,0,0.9)',
    transformStyle: ''
  }
})

export const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;
`

export const DefaultTextField = styledMaterialUI(TextField)({
  width: '100%',
  margin: '8px 0 8px 0'
})

export const DefaultNumericsField = styledMaterialUI(NumberInput)({
  width: '100%',
  margin: '8px 0 8px 0'
})

export const CloseButton = styledMaterialUI(IconButton)({
  color: '#ffffff',
  margin: 8
})
