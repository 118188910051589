/* eslint-disable indent */
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import Badge from '@material-ui/core/Badge'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import Popover from '@material-ui/core/Popover'

import Notifications from '@material-ui/icons/Notifications'

import { getNotificationsRead } from '../../store/reducers/notifications'

import {
  NotificationsCard,
  WarningIcon,
  WarningContiner,
  WarningText
} from './styles'
import NotificationItem from '../NotificationItem'
import { Tab, Tabs } from '@material-ui/core'

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'center'
}
const transformOrigin = {
  vertical: 'top',
  horizontal: 'center'
}

const NotificationBell = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [tab, setTab] = useState(0)
  const [unreadNotifications, setUnreadNotifications] = useState([])
  const [readNotifications, setReadNotifications] = useState([])

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const isOpen = Boolean(anchorEl)
  const id = isOpen ? 'notifications' : undefined
  const mobile = isMobile ? '80vw' : null
  const unreadItems = props.notificationsList.filter((i) => !i.read)
  const readItems = props.notificationsList.filter((i) => i.read)

  const _handleOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const _handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const _handleChangeTab = useCallback((_evt, newTab) => {
    setTab(newTab)
  }, [])

  const _handleReadNotification = useCallback((notification) => {
    props.getNotificationsRead(notification)
    setUnreadNotifications((oldList) =>
      oldList.map((item) => ({
        ...item,
        read: item.id === notification.id
      }))
    )
  }, [])

  useEffect(() => {
    if (isOpen) {
      setUnreadNotifications(unreadItems)
      setReadNotifications(readItems)
    }
  }, [isOpen])

  const notificationsEmpty = useMemo(() => {
    return (
      <WarningContiner>
        <WarningIcon />
        <WarningText>Não há notificações para exibir</WarningText>
      </WarningContiner>
    )
  }, [])

  const notificationsListContent = useMemo(() => {
    if (tab === 0) {
      if (!unreadNotifications.length) return notificationsEmpty

      return unreadNotifications.map((item) => (
        <NotificationItem
          key={item.created_at}
          title={item.title}
          type={item.type}
          text={item.text}
          read={item.read}
          timestamp={item.created_at}
          handleChange={() => _handleReadNotification(item)}
        />
      ))
    }

    if (tab === 1) {
      if (!readNotifications.length) return notificationsEmpty

      return readNotifications.map((item) => (
        <NotificationItem
          key={item.created_at}
          title={item.title}
          type={item.type}
          text={item.text}
          read={item.read}
          timestamp={item.created_at}
        />
      ))
    }

    return null
  }, [tab, unreadNotifications, readNotifications])

  return (
    <>
      <Badge
        button
        badgeContent={unreadItems.length}
        color="error"
        onClick={_handleOpen}
        style={{ margin: '0 10px 0 0' }}
      >
        <Notifications />
      </Badge>
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={_handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <NotificationsCard elevation={10} width={mobile}>
          <Tabs
            value={tab}
            onChange={_handleChangeTab}
            indicatorColor="primary"
            aria-label="tabs"
          >
            <Tab
              label={`Não Lidas (${unreadItems.length})`}
              id="tab1"
              aria-controls="simple-tabpanel-1"
              tabIndex={0}
            />
            <Tab
              label="Lidas"
              id="tab2"
              aria-controls="simple-tabpanel-2"
              tabIndex={1}
            />
          </Tabs>
          {notificationsListContent}
        </NotificationsCard>
      </Popover>
    </>
  )
}

NotificationBell.propTypes = {
  notificationsList: PropTypes.array,
  setErrorMessage: PropTypes.func,
  getNotificationsRead: PropTypes.func
}

NotificationBell.defaultProps = {
  setErrorMessage: () => {},
  getNotificationsRead: () => {}
}

const mapState = ({ notifications }) => {
  return {
    notificationsList: notifications.list,
    errorMessage: notifications.errorMessage
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getNotificationsRead
    },
    dispatch
  )
export default connect(mapState, mapDispatchToProps)(NotificationBell)
