import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { validateRouteAccess } from '../utils/pageAccess'
import { USER_ACCESS } from '../utils/constants'

import getValue from 'lodash/get'

import AppRoutes from './routes'

const ProtectedRoute = ({ currentUser, ...restOfProps }) => {
  const isAuthenticated = getValue(currentUser, 'token', null)
  const userAccess = getValue(currentUser, 'userAcess', null)
  const currentPath = getValue(restOfProps, 'path', null)

  const hasAccess =
    validateRouteAccess(userAccess, currentPath) !== USER_ACCESS.NOACCESS

  if (isAuthenticated && hasAccess) {
    return <Route {...restOfProps} />
  }
  return <Redirect to={AppRoutes.LOGIN} />
}

ProtectedRoute.propTypes = {
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired
  })
}

const mapState = ({ auth }) => {
  return {
    currentUser: auth.currentUser
  }
}

export default connect(mapState)(ProtectedRoute)
