import AuthService from '../../services/auth'
import LocalStorageService from '../../services/localStorage'
import NotificationsService from '../../services/notifications'
import logError from '../../utils/log'
import store from '../index'
import getValue from 'lodash/get'
import { clearWidgetsReducer } from './widgets'
import { clearNotificationsReducer } from './notifications'
import { clearClientsReducer } from './clients'

const Types = {
  SET_CURRENT_USER: 'auth@setCurrentUser',
  SET_LOADING: 'auth@setLoading',
  SET_ERROR: 'auth@setError',
  LOGIN: 'auth@login',
  LOGOUT: 'auth@logout',
  SET_CHECK_LOGGED_USER: 'auth@checkLoggedUser'
}

const initialState = {
  currentUser: null,
  loading: false,
  errorMessage: '',
  checkingLoggedUser: false
}

const actions = {
  [Types.SET_CURRENT_USER]: (state, action) => ({
    ...state,
    currentUser: action.payload
  }),
  [Types.SET_LOADING]: (state, action) => ({
    ...state,
    loading: action.payload
  }),
  [Types.SET_ERROR]: (state, action) => ({
    ...state,
    errorMessage: action.payload
  }),
  [Types.LOGIN]: (state, action) => ({ ...state, ...action.payload }),
  [Types.LOGOUT]: (state, action) => ({ ...state, ...action.payload }),
  [Types.SET_CHECK_LOGGED_USER]: (state, action) => ({
    ...state,
    checkingLoggedUser: action.payload
  })
}

const reducer = (state = initialState, action) => {
  if (actions[action.type]) {
    return actions[action.type](state, action)
  }

  return state
}

export const submitLogin = (username, password) => async (dispatch) => {
  try {
    dispatch({
      type: Types.SET_LOADING,
      payload: true
    })

    const result = await AuthService.login(username, password)
    NotificationsService.askNotificationPermission(result.token)
    dispatch({
      type: Types.LOGIN,
      payload: {
        loading: false,
        currentUser: result
      }
    })

    LocalStorageService.saveJson(LocalStorageService.KEYS.CURRENT_USER, result)
  } catch (error) {
    logError('reducers -> auth -> submitLogin', error)
    dispatch({
      type: Types.SET_LOADING,
      payload: false
    })
    dispatch({
      type: Types.SET_ERROR,
      payload: error.message
    })
  }
}

export const submitLogout = () => async (dispatch) => {
  try {
    dispatch({
      type: Types.SET_LOADING,
      payload: true
    })

    const currentStore = store.getState()
    const authToken = getValue(currentStore, 'auth.currentUser.token', null)

    await AuthService.logout(authToken)
    LocalStorageService.deleteItem(LocalStorageService.KEYS.CURRENT_USER)
    dispatch({
      type: Types.LOGOUT,
      payload: {
        loading: false,
        currentUser: null
      }
    })
    dispatch(
      clearWidgetsReducer(),
      clearNotificationsReducer(),
      clearClientsReducer()
    )
  } catch (error) {
    logError('reducers -> auth -> submitLogout', error)
    dispatch({
      type: Types.SET_LOADING,
      payload: false
    })
    dispatch({
      type: Types.SET_ERROR,
      payload: error.message
    })
  }
}

export const checkLoggedUser = () => async (dispatch) => {
  try {
    dispatch({
      type: Types.SET_CHECK_LOGGED_USER,
      payload: true
    })

    const user = LocalStorageService.getJson(
      LocalStorageService.KEYS.CURRENT_USER
    )

    if (user) {
      const result = await AuthService.authenticate(user.refreshToken, user.id)
      if (result) {
        NotificationsService.askNotificationPermission(result.token)
        const data = { ...user, ...result }
        dispatch({
          type: Types.LOGIN,
          payload: {
            loading: false,
            currentUser: data
          }
        })
        LocalStorageService.saveJson(
          LocalStorageService.KEYS.CURRENT_USER,
          data
        )
      }
    }
    dispatch({
      type: Types.SET_CHECK_LOGGED_USER,
      payload: false
    })
  } catch (error) {
    logError('reducers -> auth -> checkLoggedUser', error)
    dispatch({
      type: Types.SET_CHECK_LOGGED_USER,
      payload: false
    })
    dispatch({
      type: Types.SET_ERROR,
      payload: error.message
    })
  }
}

export const resetErrorMessage = () => async (dispatch) => {
  dispatch({
    type: Types.SET_ERROR,
    payload: ''
  })
}

export default reducer
