import axios from 'axios'
import getValue from 'lodash/get'
import logError from '../utils/log'

const PLUVIOMETER = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/pluviometer`
})

const getList = async (authToken, clientId) => {
  try {
    const response = await PLUVIOMETER.get(`/?client_id=${clientId}`, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@pluviometerReadings@getList', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao buscar a lista de leituras'
      )
    )
  }
}
const insertPluviometer = async (authToken, pluviometer) => {
  try {
    const response = await PLUVIOMETER.post('/', pluviometer, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@pluviometer@insertPluviometer', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao registrar a leitura'
      )
    )
  }
}

const updatePluviometer = async (authToken, pluviometer) => {
  try {
    const response = await PLUVIOMETER.put('/', pluviometer, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@pluviometer@updatePluviometer', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao buscar a lista de leituras'
      )
    )
  }
}

export default {
  getList,
  insertPluviometer,
  updatePluviometer
}
