/* eslint-disable indent */
import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { matchSorter } from 'match-sorter'
import dayjs from 'dayjs'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import InputAdornment from '@material-ui/core/InputAdornment'
import Step from '@material-ui/core/Step'
import StepContent from '@material-ui/core/StepContent'
import StepLabel from '@material-ui/core/StepLabel'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

import DateFnsUtils from '@date-io/date-fns'
import { ptBR } from 'date-fns/locale'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'

import ProjectsService from '../../services/projects'
import IrrigationsService from '../../services/irrigations'

import Appbar from '../../Components/Appbar'
import DataTable from '../../Components/DataTable/index'

import {
  DialogBox,
  ModalContent,
  StepperDivider,
  StepperDialog
} from './styles'

import {
  Container,
  StepOptionalLabel,
  Header,
  DateActions,
  PickersWrapper,
  IconButton,
  Button,
  Row,
  OutlinedInput,
  DatePicker,
  TableLoadingCompWrapper,
  Text,
  CustomLinearProgress,
  Column,
  Stepper,
  StepperContainer,
  TableWrapper,
  Content
} from '../../styles/components'

import ProjectSelection from './IrrigationConfig/ProjectSelection'
import IrrigationTimers from './IrrigationConfig/IrrigationTimers'
import IrrigationSectors from './IrrigationConfig/IrrigationSectors'
import HidrometerRead from './IrrigationConfig/HidrometerRead'
import IrrigationReview from './IrrigationConfig/IrrigationReview'
import IrrigationComments from './IrrigationConfig/IrrigationComments'

import {
  validateHidrometerNumberOrZero,
  validateSectorDuration
} from '../../utils/validators'
import { IRRIGATION_TYPES } from '../../utils/constants'
import StepperModal from '../../Components/StepperModal'
import ConfirmationDialog from '../../Components/ConfirmationDialog'
import HelpDialog from '../../Components/StepperModal/HelpDialog'
import { getNotifications } from '../../store/reducers/notifications'
import { bindActionCreators } from 'redux'
import CloseConfirmationDialog from '../../Components/CloseConfirmationDialog'
import { formatTimestamp, timestampToServer } from '../../utils/helpers'

const STEPS = {
  PROJECT_SELECTION: { value: 0, label: 'Projeto', isRequired: true },
  DATETIME_SELECTION: {
    value: 1,
    label: 'Data, hora e duração',
    isRequired: true
  },
  SECTORS: { value: 2, label: 'Setores', isRequired: true },
  HIDROMETER: { value: 3, label: 'Hidrômetro' },
  NOTES: { value: 4, label: 'Observações' },
  REVIEW: { value: 5, label: 'Resumo', isRequired: true }
}

const searchOptions = {
  keys: [
    (item) => formatTimestamp(item.timestamp, 'DD/MM/YY HH:mm'),
    (item) =>
      `${get(item, 'project.description', '')} - ${get(
        item,
        'project.farm.name',
        ''
      )}`,
    (item) =>
      item.irrigationsSectors
        .filter((s) => s.type !== IRRIGATION_TYPES.NONE)
        .map((s) => get(s, 'sector.description', ''))
        .join(', '),
    (item) => `${get(item, 'hidrometer', '-')}`,
    'notes'
  ],
  keepDiacritics: false,
  threshold: matchSorter.rankings.CONTAINS
}

const columns = [
  {
    key: 'timestamp',
    label: 'Inicio da irrigação',
    render_timestamp: (item) =>
      formatTimestamp(item.timestamp, 'DD/MM/YY HH:mm')
  },
  {
    key: 'project',
    label: 'Projeto',
    render_project: (item) =>
      `${get(item, 'project.description', '')} - ${get(
        item,
        'project.farm.name',
        ''
      )}`
  },
  {
    key: 'irrigationsSectors',
    label: 'Setores',
    render_irrigationsSectors: (item) =>
      item.irrigationsSectors
        .filter((s) => s.type !== IRRIGATION_TYPES.NONE)
        .map((s) => get(s, 'sector.description', ''))
        .join(', ')
  },
  {
    key: 'hidrometer',
    label: 'Leitura do hidrômetro',
    render_hidrometer: (item) => `${item.hidrometer}m³`
  }
]

const TransitionDown = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const Irrigation = (props) => {
  const [searchText, setSearchText] = useState('')
  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, 'week').valueOf()
  )
  const [endDate, setEndDate] = useState(dayjs().valueOf())
  const [isIrrigationModalOpen, setIsIrrigationModalOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(STEPS.PROJECT_SELECTION.value)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [projectsList, setProjectsList] = useState([])
  const [irrigationsSectors, setIrrigationsSectors] = useState([])
  const [irrigationsList, setIrrigationsList] = useState([])
  const [id, setId] = useState(0)
  const [notes, setNotes] = useState('')
  const [hidrometer, setHidrometer] = useState({})
  const [timestamp, setTimestamp] = useState(dayjs().valueOf())
  const [project, setProject] = useState(null)
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const filteredList = searchText.length
    ? matchSorter(irrigationsList, searchText, searchOptions)
    : irrigationsList

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => setSuccessMessage(''), 2000)
    }
  }, [successMessage])

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => setErrorMessage(''), 2000)
    }
  }, [errorMessage])

  const getIrrigationsList = useCallback(async () => {
    try {
      const clientId = props.currentClient.id
      const authToken = props.currentUser.token
      if (clientId) {
        setIsLoading(true)
        const list = await IrrigationsService.getList(
          authToken,
          clientId,
          timestampToServer(startDate),
          timestampToServer(endDate)
        )
        setIrrigationsList(list)
        setIsLoading(false)
      }
    } catch (error) {
      setIrrigationsList([])
      setIsLoading(false)
      setErrorMessage(error.message)
    }
  }, [props.currentClient, startDate, endDate])

  useEffect(async () => {
    try {
      const clientId = props.currentClient.id
      const authToken = props.currentUser.token
      if (clientId) {
        setIsLoading(true)
        const projects = await ProjectsService.getList(authToken, clientId)

        setProjectsList(projects)
        setProject(null)
        setIsLoading(false)
      }
    } catch (error) {
      setProjectsList([])
      setProject(null)
      setIsLoading(false)
      setErrorMessage(error.message)
    }
  }, [props.currentClient])

  useEffect(() => {
    getIrrigationsList()
  }, [props.currentClient])

  const _handleHelpDialogOpen = useCallback(() => {
    setHelpDialogIsOpen(true)
  }, [])

  const _handleHelpDialogClose = useCallback(() => {
    setHelpDialogIsOpen(false)
  }, [])

  const _onSearch = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setSearchText(value)
  }, [])

  const _handleClickAdd = useCallback(() => {
    setId(0)
    setProject(null)
    setTimestamp(dayjs().valueOf())
    setIrrigationsSectors([])
    setHidrometer(0)
    setNotes('')
    setActiveStep(STEPS.PROJECT_SELECTION.value)
    setIsIrrigationModalOpen(true)
  }, [])

  const _handleClickItem = useCallback((irrigation) => {
    setId(irrigation.id)
    setTimestamp(irrigation.timestamp)
    setProject(get(irrigation, 'project', null))
    setIrrigationsSectors(get(irrigation, 'irrigationsSectors', []))
    setHidrometer(irrigation.hidrometer)
    setNotes(irrigation.notes)
    setActiveStep(STEPS.PROJECT_SELECTION.value)
    setIsIrrigationModalOpen(true)
  }, [])

  const _handleClose = useCallback(() => {
    setActiveStep(STEPS.PROJECT_SELECTION.value)
    setIsIrrigationModalOpen(false)
  }, [])

  const _onChangeStartDate = useCallback((newDate) => {
    setStartDate(dayjs(newDate).valueOf())
  }, [])

  const _onChangeEndDate = useCallback((newDate) => {
    setEndDate(dayjs(newDate).valueOf())
  }, [])

  const _handleSave = useCallback(async () => {
    try {
      setIsLoading(true)
      const authToken = props.currentUser.token
      const payload = {
        id,
        timestamp: timestampToServer(timestamp),
        hidrometer,
        irrigationsSectors,
        project,
        notes,
        project_id: project.id
      }
      const newIrrigationsList = [...irrigationsList]
      if (id) {
        const updatedIrrigation = await IrrigationsService.updateIrrigation(
          authToken,
          payload
        )
        const index = newIrrigationsList.findIndex(
          (i) => i.id === updatedIrrigation.id
        )

        if (index > -1) {
          newIrrigationsList[index] = payload
        }
      } else {
        const newIrrigation = await IrrigationsService.insertIrrigation(
          authToken,
          payload
        )
        newIrrigationsList.unshift({ ...payload, id: newIrrigation.id })
      }

      props.getNotifications()

      setIrrigationsList(newIrrigationsList)
      setSuccessMessage('Irrigação registrada com sucesso')
      setIsLoading(false)
    } catch (error) {
      setErrorMessage(error.message)
      setIsLoading(false)
    }
  }, [
    id,
    notes,
    hidrometer,
    timestamp,
    irrigationsSectors,
    project,
    irrigationsList
  ])

  const _onAcceptConfirmation = useCallback(async () => {
    _handleSave()
    setActiveStep(STEPS.PROJECT_SELECTION.value)
    setShowConfirmation(false)
  }, [_handleSave])

  const _onAcceptCloseConfirmation = useCallback(async () => {
    setActiveStep(STEPS.PROJECT_SELECTION.value)
    setShowCloseConfirmation(false)
  }, [])

  const _onRejectConfirmation = useCallback(() => {
    setShowConfirmation(false)
    setIsIrrigationModalOpen(true)
  }, [])

  const _openCloseConfirmation = useCallback(() => {
    setIsIrrigationModalOpen(false)
    setShowCloseConfirmation(true)
  }, [])

  const _onRejectCloseConfirmation = useCallback(() => {
    setShowCloseConfirmation(false)
    setIsIrrigationModalOpen(true)
  }, [])

  const _handleNextStep = useCallback(
    () => setActiveStep((prevActiveStep) => prevActiveStep + 1),
    []
  )

  const _handleBackStep = useCallback(
    () => setActiveStep((prevActiveStep) => prevActiveStep - 1),
    []
  )

  const _handleFinishSteps = useCallback(() => {
    setIsIrrigationModalOpen(false)
    setShowConfirmation(true)
  }, [])

  const _onChangeIrrigationDate = useCallback((newTimestamp) => {
    setTimestamp(newTimestamp)
  }, [])

  const _onChangeProject = useCallback((event, value) => {
    const sectors = get(value, 'sectors', [])
    setProject(value)
    setIrrigationsSectors(
      sectors.map((s) => ({
        sector: s,
        type: IRRIGATION_TYPES.NONE,
        duration: 0
      }))
    )
  }, [])

  const _onChangeSectorType = useCallback((newType, index) => {
    setIrrigationsSectors((oldList) => {
      const newList = [...oldList]
      const newSector = { ...newList[index] }

      newSector.type = newType
      if (newType === IRRIGATION_TYPES.NONE) {
        newSector.duration = 0
      } else {
        const durationValue = newList.filter((s) => {
          return s.type !== IRRIGATION_TYPES.NONE
        })
        const lastItem = durationValue[durationValue.length - 1]
        const currentDuration = get(lastItem, 'duration', 0)
        newSector.duration = currentDuration
      }

      newList[index] = newSector
      return newList
    })
  }, [])

  const _onChangeSectorDuration = useCallback(
    (newDuration, index) => {
      setIrrigationsSectors((oldList) => {
        const newList = [...oldList]
        const newSector = { ...newList[index] }
        newSector.duration = newDuration
        newList[index] = newSector

        return newList
      })
    },
    [irrigationsSectors]
  )

  const _onReorderSectors = useCallback((newList) => {
    setIrrigationsSectors(newList)
  }, [])

  const _onChangeHidrometer = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setHidrometer(value)
  }, [])

  const _onChangeNotes = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setNotes(value)
  }, [])

  const _canProceedNextStep = useCallback(() => {
    switch (activeStep) {
      case STEPS.PROJECT_SELECTION.value:
        return !!get(project, 'id', 0)
      case STEPS.DATETIME_SELECTION.value:
        return dayjs(timestamp).isValid()
      case STEPS.SECTORS.value: {
        const isSectorsValid = !irrigationsSectors.find((s) =>
          validateSectorDuration(s.duration, s.type)
        )

        return isSectorsValid
      }
      case STEPS.HIDROMETER.value:
        return !validateHidrometerNumberOrZero(hidrometer)
      default:
        return true
    }
  }, [activeStep, project, timestamp, irrigationsSectors, hidrometer])

  const _getMobileLabel = useCallback((item) => {
    const project = get(item, 'project.description', '-')
    const date = formatTimestamp(item.timestamp, 'DD/MM/YY')
    const time = formatTimestamp(item.timestamp, 'HH:mm')

    return `${date} ${time} - ${project}`
  }, [])

  const renderHelpText = () => {
    const fontSize = '18px'
    const textColor = 'black'
    switch (activeStep) {
      case STEPS.PROJECT_SELECTION.value:
        return (
          <Text fontSize={fontSize} textColor={textColor}>
            Neste passo você deve selecionar o projeto a ser irrigado.
          </Text>
        )
      case STEPS.DATETIME_SELECTION.value:
        return (
          <Text fontSize={fontSize} textColor={textColor}>
            Neste passo selecione ou digite a data, hora de inicio.
          </Text>
        )
      case STEPS.SECTORS.value:
        return (
          <Text fontSize={fontSize} textColor={textColor}>
            Neste passo selecione os setores que deseja irrigar e fertirrigar,
            digite o tempo de rega e arraste os setores para reorganizar sua
            ordem.
          </Text>
        )
      case STEPS.HIDROMETER.value:
        return (
          <Text fontSize={fontSize} textColor={textColor}>
            Neste passo informe a leitura do hidrômetro. Este passo é opcional.
          </Text>
        )
      case STEPS.NOTES.value:
        return (
          <Text fontSize={fontSize} textColor={textColor}>
            Neste passo temos um campo destinado a observações a respeito da
            irrigação. Este passo é opcional.
          </Text>
        )
      case STEPS.REVIEW.value:
        return (
          <Text fontSize={fontSize} textColor={textColor}>
            Resumo contendo todas as informações registradas na irrigação.
          </Text>
        )
      default:
        return 'Passo desconhecido'
    }
  }

  const renderStepContent = () => {
    switch (activeStep) {
      case STEPS.PROJECT_SELECTION.value:
        return (
          <ProjectSelection
            options={projectsList}
            value={project}
            onChange={_onChangeProject}
          />
        )
      case STEPS.DATETIME_SELECTION.value:
        return (
          <IrrigationTimers
            timestamp={timestamp}
            // onChangeDate={_onChangeIrrigationDate}
            onChangeTime={_onChangeIrrigationDate}
          />
        )
      case STEPS.SECTORS.value:
        return (
          <IrrigationSectors
            sectors={irrigationsSectors}
            onChangeDuration={_onChangeSectorDuration}
            onChangeType={_onChangeSectorType}
            onReorderSectors={_onReorderSectors}
          />
        )
      case STEPS.HIDROMETER.value:
        return (
          <HidrometerRead value={hidrometer} onChange={_onChangeHidrometer} />
        )
      case STEPS.NOTES.value:
        return <IrrigationComments value={notes} onChange={_onChangeNotes} />
      case STEPS.REVIEW.value:
        return (
          <IrrigationReview
            farmName={get(project, 'farm.name', '')}
            projectDescription={get(project, 'description', '')}
            irrigationTimestamp={timestamp}
            hidrometerValue={hidrometer}
            sectors={irrigationsSectors}
            notes={notes}
          />
        )
      default:
        return 'Passo desconhecido'
    }
  }

  const _renderAlert = () => {
    const alertMessage = successMessage || errorMessage
    const alertColor = successMessage ? 'success' : 'error'
    return (
      <Snackbar open={!!alertMessage} key={alertColor}>
        <Alert severity={alertColor}>{alertMessage}</Alert>
      </Snackbar>
    )
  }

  const _renderModal = () => {
    return (
      <StepperModal
        title="IRRIGAÇÃO"
        onClickClose={_handleClose}
        nextStepButtonLabel={
          activeStep === STEPS.REVIEW.value ? 'Finalizar' : 'Próximo'
        }
        backStepButtonLabel="Voltar"
        onClickNextStep={
          activeStep === STEPS.REVIEW.value
            ? _handleFinishSteps
            : _handleNextStep
        }
        onClickBackStep={_handleBackStep}
        backStepDisabled={activeStep === STEPS.PROJECT_SELECTION.value}
        nextStepDisabled={!_canProceedNextStep()}
        open={isIrrigationModalOpen}
        onClose={_openCloseConfirmation}
        onClickHelp={_handleHelpDialogOpen}
      >
        {isMobile ? (
          <Stepper activeStep={activeStep} orientation="vertical">
            {Object.keys(STEPS).map((key) => {
              const step = STEPS[key]

              return (
                <Step key={key}>
                  <StepLabel
                    optional={step.required ? <StepOptionalLabel /> : null}
                  >
                    {step.label}
                  </StepLabel>
                  <StepContent>
                    <StepperDialog>{renderStepContent()}</StepperDialog>
                  </StepContent>
                </Step>
              )
            })}
          </Stepper>
        ) : (
          <DialogBox>
            <StepperContainer>
              <Stepper activeStep={activeStep} orientation="vertical">
                {Object.keys(STEPS).map((key) => {
                  const step = STEPS[key]

                  return (
                    <Step key={key}>
                      <StepLabel
                        optional={
                          step.isRequired ? <StepOptionalLabel /> : null
                        }
                      >
                        {step.label}
                      </StepLabel>
                    </Step>
                  )
                })}
              </Stepper>
            </StepperContainer>
            <ModalContent>
              <StepperDivider
                orientation="vertical"
                style={{ marginRight: 30 }}
              />
              <Column justify="null" align="center">
                {renderStepContent()}
              </Column>
            </ModalContent>
          </DialogBox>
        )}
      </StepperModal>
    )
  }

  const _renderConfirmation = () => {
    return (
      <Dialog
        open={showConfirmation}
        onClose={_onRejectConfirmation}
        TransitionComponent={TransitionDown}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ConfirmationDialog
          onClickCancel={_onRejectConfirmation}
          onClickConfirm={_onAcceptConfirmation}
        />
      </Dialog>
    )
  }

  const _closeConfirmationDialog = () => {
    return (
      <Dialog
        open={showCloseConfirmation}
        TransitionComponent={TransitionDown}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CloseConfirmationDialog
          onClickCancel={_onRejectCloseConfirmation}
          onClickConfirm={_onAcceptCloseConfirmation}
        />
      </Dialog>
    )
  }

  const _renderHelpDialog = () => {
    return (
      <HelpDialog
        text={renderHelpText()}
        open={helpDialogIsOpen}
        onClickClose={_handleHelpDialogClose}
      />
    )
  }

  return (
    <Container>
      <Appbar history={props.history} />
      <Content>
        <Header
          display="flex"
          flexDirection="column"
          width="100%"
          height="10%"
          padding="3em 0 15em 0"
        >
          <DateActions>
            <PickersWrapper isMobile={isMobile}>
              <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                <DatePicker
                  isMobile={isMobile}
                  label="Data inicial"
                  value={dayjs(startDate).toDate()}
                  onChange={_onChangeStartDate}
                  format="dd/MM/yyyy"
                  maxDate={dayjs(endDate).toDate()}
                  cancelLabel="Cancelar"
                />

                <DatePicker
                  isMobile={isMobile}
                  label="Data final"
                  value={dayjs(endDate).toDate()}
                  onChange={_onChangeEndDate}
                  format="dd/MM/yyyy"
                  minDate={dayjs(startDate).toDate()}
                  cancelLabel="Cancelar"
                />
              </MuiPickersUtilsProvider>
            </PickersWrapper>

            {isMobile ? (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ) : (
              <Button
                width="8em"
                height="2.5em"
                startIcon={<SearchIcon />}
                onClick={getIrrigationsList}
              >
                Buscar
              </Button>
            )}
          </DateActions>
          <Row justify="center" align="center">
            <OutlinedInput
              isMobile={isMobile}
              width="25%"
              mobileWidth="60%"
              id="outlined-adornment-search"
              placeholder="Pesquisar na tabela"
              type="search"
              value={searchText}
              onChange={_onSearch}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              labelWidth={60}
            />
            {isMobile ? (
              <IconButton
                color="primary"
                aria-label="add"
                onClick={_handleClickAdd}
              >
                <AddIcon />
              </IconButton>
            ) : (
              <Button
                margin="8px"
                width="9em"
                height="2.5em"
                startIcon={<AddIcon />}
                onClick={_handleClickAdd}
              >
                Registrar
              </Button>
            )}
          </Row>
        </Header>
        {isLoading ? (
          <TableLoadingCompWrapper>
            <Text margin="12px" textColor="black">
              Carregando registros de irrigações...
            </Text>
            <CustomLinearProgress />
          </TableLoadingCompWrapper>
        ) : (
          <TableWrapper>
            <DataTable
              columns={columns}
              data={filteredList}
              mobileLabel={_getMobileLabel}
              onClickItem={_handleClickItem}
            />
          </TableWrapper>
        )}
      </Content>

      {_renderModal()}
      {_renderConfirmation()}
      {_renderAlert()}
      {_renderHelpDialog()}
      {_closeConfirmationDialog()}
    </Container>
  )
}

Irrigation.propTypes = {
  history: PropTypes.object,
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired
  }),
  currentClient: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  getNotifications: PropTypes.func
}

Irrigation.defaultProps = {
  getNotifications: () => {}
}

const mapState = ({ auth, clients }) => {
  return {
    currentUser: auth.currentUser,
    currentClient: clients.currentClient
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getNotifications
    },
    dispatch
  )

export default connect(mapState, mapDispatchToProps)(Irrigation)
