import React from 'react'
import PropTypes from 'prop-types'

import Switch from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/core/styles'

import Colors from '../../styles/colors'

const CustomSwitch = withStyles({
  switchBase: {
    color: '#ffffff',
    '&$checked': {
      color: Colors.AZUL_HYDRA
    },
    '&$checked + $track': {
      backgroundColor: Colors.AZUL_HYDRA
    }
  },
  checked: {},
  track: {}
})(Switch)

const HydraSwitch = ({ checked, onChange, disabled }) => {
  return (
    <CustomSwitch checked={checked} disabled={disabled} onChange={onChange} />
  )
}

HydraSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

HydraSwitch.defaultProps = {
  onChange: () => {}
}

export default HydraSwitch
