import React from 'react'
import PropTypes from 'prop-types'

import { WidgetTitle, WidgetDate, WidgetSubtitle } from '../styles'
import { Column } from '../../../styles/components'
import LiquidWidget from '../../../Components/widgets/gaugeWidget/liquid'

const PluviometerWidgets = ({
  value,
  reading,
  timeCourse,
  project,
  farm,
  sensor
}) => {
  return (
    <>
      <Column>
        <WidgetSubtitle>{farm}</WidgetSubtitle>
        <WidgetSubtitle>{project}</WidgetSubtitle>
        <WidgetDate>{timeCourse}</WidgetDate>
      </Column>
      <WidgetTitle>{sensor}</WidgetTitle>
      <br />
      <LiquidWidget value={value} reading={reading} />
    </>
  )
}

PluviometerWidgets.propTypes = {
  value: PropTypes.number,
  reading: PropTypes.string,
  timeCourse: PropTypes.string,
  project: PropTypes.string,
  farm: PropTypes.string,
  sensor: PropTypes.string
}

export default PluviometerWidgets
