/* eslint-disable indent */
import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import InputMask from 'react-input-mask'
import { matchSorter } from 'match-sorter'

import Dialog from '@material-ui/core/Dialog'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import DateFnsUtils from '@date-io/date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import Snackbar from '@material-ui/core/Snackbar'

import Slide from '@material-ui/core/Slide'
import Alert from '@material-ui/lab/Alert'

import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'

import {
  getClients,
  addClient,
  updateClient,
  setSuccessMessage,
  setErrorMessage
} from '../../store/reducers/clients'

import Appbar from '../../Components/Appbar.js'

import { Input } from './styles'
import DataTable from '../../Components/DataTable/index.js'
import {
  Column,
  Container,
  Content,
  AddButton,
  AddIconButton,
  TableWrapper,
  Header,
  Row,
  OutlinedInput
} from '../../styles/components'
import {
  validateAccessExpiresDate,
  validateClientName,
  validateEmail,
  validatePhone
} from '../../utils/validators'
import {
  formatTimestamp,
  getYesOrNoFromBoolean,
  timestampToServer
} from '../../utils/helpers'
import { USER_ACCESS } from '../../utils/constants'
import { adminClients } from '../../utils/pageAccess'
import ModalComponent from '../../Components/ModalComponent'
import ConfirmationDialog from '../../Components/ConfirmationDialog'
import HydraSwitch from '../../Components/GenericSwitch'
import CloseConfirmationDialog from '../../Components/CloseConfirmationDialog'

const searchOptions = {
  keys: [
    'name',
    'phone',
    'email',
    (item) => getYesOrNoFromBoolean(item.active),
    (item) => formatTimestamp(item.access_expires_timestamp, 'DD/MM/YYYY')
  ],
  keepDiacritics: false
}

const transitionDown = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const Clients = (props) => {
  const [searchText, setSearchText] = useState('')
  const [id, setId] = useState(0)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [active, setActive] = useState(true)
  const [accessExpires, setAccessExpires] = useState(new Date())
  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [closeConfirmation, setCloseConfirmation] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const currentAccess = get(props, 'currentUser.userAccess', null)
  const shouldDisable = adminClients(currentAccess) === USER_ACCESS.READ

  const filteredClientsList = searchText.length
    ? matchSorter(props.clientsList, searchText, searchOptions)
    : props.clientsList

  useEffect(() => {
    props.getClients()
  }, [])

  useEffect(() => {
    if (props.successMessage) {
      setTimeout(props.setSuccessMessage, 2000)
    }
  }, [props.successMessage])

  useEffect(() => {
    if (props.errorMessage) {
      setTimeout(props.setErrorMessage, 2000)
    }
  }, [props.errorMessage])

  const _onSearch = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setSearchText(value)
  }, [])

  const _getColumns = useCallback(() => {
    return [
      { key: 'name', label: 'Nome', render_name: (item) => item.name },
      {
        key: 'phone',
        label: 'Telefone',
        render_phone: (item) => item.phone
      },
      {
        key: 'email',
        label: 'e-mail',
        render_email: (item) => item.email
      },
      {
        key: 'active',
        label: 'Status',
        render_active: (item) => (item.active ? 'Ativo' : 'Inativo')
      },
      {
        key: 'access_expires_timestamp',
        label: 'Data limite de acesso',
        render_access_expires_timestamp: (item) =>
          formatTimestamp(item.access_expires_timestamp, 'DD/MM/YYYY')
      }
    ]
  }, [])

  const _onClickAdd = useCallback(() => {
    const AccessDate = dayjs().add(1, 'year').toDate()
    setId(0)
    setName('')
    setEmail('')
    setPhone('')
    setActive(true)
    setAccessExpires(AccessDate)

    setDialogIsOpen(true)
  }, [])

  const _handleConfirmationOpen = useCallback(() => {
    setDialogIsOpen(false)
    setOpenConfirmation(true)
  }, [])

  const _handleConfirmationClose = useCallback(() => {
    setOpenConfirmation(false)
    setDialogIsOpen(true)
  }, [])

  const _onChangeName = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setName(value)
  }, [])

  const _onChangeEmail = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setEmail(value)
  }, [])

  const _onChangePhone = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setPhone(value)
  }, [])

  const _onChangeActive = useCallback((event, isChecked) => {
    setActive(isChecked)
  }, [])

  const _onChangeAccessExpires = useCallback((event) => {
    setAccessExpires(event)
  }, [])

  const _onClickClose = useCallback(() => {
    setDialogIsOpen(false)
  }, [])

  const _onAcceptCloseConfirmation = useCallback(() => {
    setCloseConfirmation(false)
  }, [])

  const _onRejectCloseConfirmation = useCallback(() => {
    setCloseConfirmation(false)
    setDialogIsOpen(true)
  }, [])

  const _onOpenCloseConfirmation = useCallback(() => {
    setDialogIsOpen(false)
    setCloseConfirmation(true)
  }, [])

  const _onClickItem = useCallback((item) => {
    const AccessDate = dayjs(item.access_expires_timestamp).toDate()
    setId(item.id)
    setName(item.name)
    setEmail(item.email)
    setPhone(item.phone)
    setActive(!!item.active)
    setAccessExpires(AccessDate)

    setDialogIsOpen(true)
  }, [])

  const _onClickSave = useCallback(() => {
    const c = {
      id,
      name,
      email,
      phone,
      active,
      access_expires_timestamp: timestampToServer(accessExpires)
    }

    if (!id) props.addClient(c)
    else props.updateClient(c)

    setOpenConfirmation(false)
  }, [
    id,
    name,
    email,
    phone,
    active,
    accessExpires,
    props.addClient,
    props.updateClient
  ])

  const _renderAlert = () => {
    const alertMessage = props.successMessage || props.errorMessage
    const alertColor = props.successMessage ? 'success' : 'error'
    return (
      <Snackbar open={!!alertMessage} key={alertMessage}>
        <Alert severity={alertColor}>{alertMessage}</Alert>
      </Snackbar>
    )
  }

  const _closeConfirmationDialog = () => {
    return (
      <Dialog
        open={closeConfirmation}
        TransitionComponent={transitionDown}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CloseConfirmationDialog
          onClickCancel={_onRejectCloseConfirmation}
          onClickConfirm={_onAcceptCloseConfirmation}
        />
      </Dialog>
    )
  }

  const _renderModal = () => {
    const nameError = validateClientName(name)
    const emailError = validateEmail(email)
    const phoneError = validatePhone(phone)
    const dateError = validateAccessExpiresDate(accessExpires)
    const hasError = !!(
      nameError ||
      emailError ||
      phoneError ||
      dateError ||
      shouldDisable
    )

    return (
      <ModalComponent
        title="Cadastro de cliente"
        buttonLabel="Salvar"
        onClickClose={_onClickClose}
        onClickButton={_handleConfirmationOpen}
        disabled={hasError}
        open={dialogIsOpen}
        onClose={_onOpenCloseConfirmation}
      >
        <Column>
          <Input
            fullWidth
            required
            label="Nome"
            variant="filled"
            value={name}
            onChange={_onChangeName}
            error={!!nameError}
            helperText={nameError}
            disabled={shouldDisable}
          />
          <Input
            fullWidth
            required
            type="email"
            label="Email"
            variant="filled"
            value={email}
            onChange={_onChangeEmail}
            error={!!emailError}
            helperText={emailError}
            disabled={shouldDisable}
          />
          <Input
            fullWidth
            required
            type="phone"
            label="Telefone"
            variant="filled"
            value={phone}
            onChange={_onChangePhone}
            error={!!phoneError}
            helperText={phoneError}
            disabled={shouldDisable}
            InputProps={{
              inputComponent: InputMask
            }}
            inputProps={{ mask: '(99) 99999-9999', maskChar: ' ' }}
          />
          <FormControlLabel
            value="start"
            control={
              <HydraSwitch
                checked={active}
                onChange={_onChangeActive}
                disabled={shouldDisable}
              />
            }
            label="Ativo"
            labelPlacement="start"
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <KeyboardDatePicker
              required
              margin="normal"
              label="Data limite de acesso"
              format="dd/MM/yyyy"
              value={accessExpires}
              onChange={_onChangeAccessExpires}
              disabled={shouldDisable}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              error={!!dateError}
              helperText={dateError}
            />
          </MuiPickersUtilsProvider>
        </Column>
      </ModalComponent>
    )
  }

  const _renderConfirmation = () => {
    return (
      <Dialog
        open={openConfirmation}
        onClose={_handleConfirmationClose}
        TransitionComponent={transitionDown}
        aria-labeledby="alert-dialog-title"
        aria-disbredby="alert-dialog-description"
      >
        <ConfirmationDialog
          onClickCancel={_handleConfirmationClose}
          onClickConfirm={_onClickSave}
        />
      </Dialog>
    )
  }

  return (
    <Container>
      <Appbar history={props.history} />
      <Content>
        <Header
          display="flex"
          flexDirection="column"
          width="100%"
          height="10%"
          padding="3em 0 10em 0"
        >
          <Row justify="center" align="center">
            {isMobile ? (
              <AddIconButton
                color="primary"
                aria-label="add"
                onClick={_onClickAdd}
                disabled={shouldDisable}
              >
                <AddIcon />
              </AddIconButton>
            ) : (
              <AddButton
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={_onClickAdd}
                disabled={shouldDisable}
              >
                Adicionar
              </AddButton>
            )}
            <OutlinedInput
              isMobile={isMobile}
              width="25%"
              mobileWidth="60%"
              id="outlined-adornment-search"
              label="Pesquisar"
              value={searchText}
              onChange={_onSearch}
              placeholder="Pesquisar"
              type="search"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              labelWidth={60}
            />
          </Row>
        </Header>

        <TableWrapper height="90%">
          <DataTable
            columns={_getColumns()}
            data={filteredClientsList}
            mobileLabel={(item) => item.name}
            mobileSubtitle={(item) => ` Ativo: ${item.active ? 'Sim' : 'Não'}`}
            onClickItem={_onClickItem}
          />
        </TableWrapper>
      </Content>
      {_renderModal()}
      {_renderAlert()}
      {_renderConfirmation()}
      {_closeConfirmationDialog()}
    </Container>
  )
}

Clients.propTypes = {
  history: PropTypes.object,
  clientsList: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  getClients: PropTypes.func,
  addClient: PropTypes.func,
  updateClient: PropTypes.func,
  setSuccessMessage: PropTypes.func,
  setErrorMessage: PropTypes.func
}

Clients.defaultProps = {
  getClients: () => {},
  addClient: () => {},
  updateClient: () => {},
  setSuccessMessage: () => {},
  setErrorMessage: () => {}
}

const mapState = ({ auth, clients }) => {
  return {
    currentUser: auth.currentUser,
    clientsList: clients.list,
    loading: clients.loading,
    successMessage: clients.successMessage,
    errorMessage: clients.errorMessage
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getClients,
      addClient,
      updateClient,
      setSuccessMessage,
      setErrorMessage
    },
    dispatch
  )

export default connect(mapState, mapDispatchToProps)(Clients)
