import styled, { css } from 'styled-components'
import { styled as styledMaterialUI } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import NumberInput from '../../../../Components/NumberInput'

export const DialogContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
`
export const TypoHeader = styledMaterialUI(Typography)({
  fontSize: '20px',
  fontFamily: 'Montserrat',
  margin: '8px 8px 32px 8px'
})

export const NoContentTypo = styledMaterialUI(Typography)({
  fontSize: '20px',
  fontFamily: 'Montserrat',
  margin: '8px 8px 16px 8px'
})

export const Extractor = styledMaterialUI(NumberInput)({
  margin: 8,
  width: '100%'
})

export const CustomFormControl = styled(FormControlLabel)`
  position: absolute;
  left: 2%;
  top: 65px;
  ${(props) => props.isMobile && css``}
`
