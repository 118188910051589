import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import get from 'lodash/get'
import dayjs from 'dayjs'
import { matchSorter } from 'match-sorter'
import ReactMarkdown from 'react-markdown'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import InputAdornment from '@material-ui/core/InputAdornment'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import DialogActions from '@material-ui/core/DialogActions'

import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'

import DateFnsUtils from '@date-io/date-fns'
import { ptBR } from 'date-fns/locale'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import DataTable from '../../Components/DataTable'
import AppBar from '../../Components/Appbar'
import ModalComponent from '../../Components/ModalComponent'
import ProjectsService from '../../services/projects'
import TipsService from '../../services/tips'
import {
  Container,
  ActionButton,
  Header,
  DatePicker,
  DateActions,
  PickersWrapper,
  Button,
  IconButton,
  Row,
  OutlinedInput,
  TableLoadingCompWrapper,
  Text,
  CustomLinearProgress,
  Column,
  Autocomplete,
  Input,
  Content,
  TableWrapper
} from '../../styles/components'
import {
  ReadingContainer,
  ReadingContent,
  Body,
  TipReadingText
} from './styles'
import { validateText } from '../../utils/validators'
import { tips } from '../../utils/pageAccess'
import { USER_ACCESS } from '../../utils/constants'
import ConfirmationDialog from '../../Components/ConfirmationDialog'
import Colors from '../../styles/colors'
import { formatTimestamp, timestampToServer } from '../../utils/helpers'

const transitionUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const transitionDown = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const inputLabel = {
  shrink: true
}

const searchOptions = {
  keys: [
    'project.description',
    'project.farm.name',
    (item) => formatTimestamp(item.created_at, 'DD/MM/YYYY')
  ]
}

const Tips = (props) => {
  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, 'week').valueOf()
  )
  const [endDate, setEndDate] = useState(dayjs().valueOf())
  const [modalOpen, setModalOpen] = useState(false)
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [readingDialogOpen, setReadingDialogOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [projectList, setProjectList] = useState([])
  const [project, setProject] = useState(null)
  const [text, setText] = useState('')
  const [tipsList, setTipsList] = useState([])
  const [id, setId] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const currentAccess = get(props, 'currentUser.userAccess', null)
  const shouldDisable = tips(currentAccess) === USER_ACCESS.READ

  const getTipsList = useCallback(async () => {
    try {
      const clientId = props.currentClient.id
      const authToken = props.currentUser.token
      setIsLoading(true)
      const list = await TipsService.getList(
        authToken,
        clientId,
        timestampToServer(startDate),
        timestampToServer(endDate)
      )
      setTipsList(list)
      setIsLoading(false)
    } catch (error) {
      setTipsList([])
      setIsLoading(false)
      setErrorMessage(error.message)
    }
  }, [props.currentClient.id, startDate, endDate])

  useEffect(() => {
    getTipsList()
  }, [props.currentClient.id])

  useEffect(async () => {
    try {
      setIsLoading(true)
      const projectList = await ProjectsService.getList(
        props.currentUser.token,
        props.currentClient.id
      )
      setProjectList(projectList)
      setIsLoading(false)
    } catch (error) {
      setProjectList([])
      setIsLoading(false)
    }
  }, [props.currentClient.id])

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => setSuccessMessage(''), 2000)
    }
  }, [successMessage])

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => setErrorMessage(''), 2000)
    }
  }, [errorMessage])

  const filteredTipsList = searchText.length
    ? matchSorter(tipsList, searchText, searchOptions)
    : tipsList

  const _handleModalOpen = useCallback(() => {
    setId(0)
    setText('')
    setProject(null)
    setModalOpen(true)
  }, [])

  const _handleModalClose = useCallback(() => {
    setModalOpen(false)
  }, [])

  const _handleConfirmationOpen = useCallback(() => {
    setModalOpen(false)
    setConfirmationOpen(true)
  }, [])

  const _handleConfirmationClose = useCallback(() => {
    setConfirmationOpen(false)
    setModalOpen(true)
  }, [])

  const _handleReadingDialogClose = useCallback(() => {
    setReadingDialogOpen(false)
  }, [])

  const _onChangeProject = useCallback((event, value) => {
    setProject(value)
  }, [])

  const _onChangeText = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setText(value)
  }, [])

  const _onChangeSearchText = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setSearchText(value)
  }, [])

  const _onChangeStartDate = useCallback((newDate) => {
    setStartDate(dayjs(newDate).valueOf())
  }, [])

  const _onChangeEndDate = useCallback((newDate) => {
    setEndDate(dayjs(newDate).valueOf())
  }, [])

  const _getColumns = useCallback(() => {
    return [
      {
        key: 'project',
        label: 'Projeto',
        render_project: (item) =>
          `${get(item, 'project.description', '')} - ${get(
            item,
            'project.farm.name',
            ''
          )}`
      },
      {
        key: 'date',
        label: 'Data',
        render_date: (item) => formatTimestamp(item.created_at, 'DD/MM/YYYY')
      }
    ]
  }, [])

  const _handleSave = useCallback(async () => {
    try {
      setIsLoading(true)

      const payload = {
        id,
        project_id: project.id,
        project,
        text
      }
      const newList = [...tipsList]
      const authToken = props.currentUser.token

      if (id) {
        const updatedTip = await TipsService.updateTip(authToken, payload)
        const tipsIndex = tipsList.findIndex(
          (item) => item.id === updatedTip.id
        )
        if (tipsIndex > -1) {
          newList[tipsIndex] = payload
        }
      } else {
        const newTip = await TipsService.insertTip(authToken, payload)
        newList.unshift({ ...payload, id: newTip.id })
      }
      setTipsList(newList)
      setConfirmationOpen(false)
      setSuccessMessage('Dica registrada com sucesso!')
      setIsLoading(false)
    } catch (error) {
      setConfirmationOpen(false)
      setErrorMessage(error.message)
      setIsLoading(false)
    }
  }, [tipsList, id, project, text])

  const _onClickItem = useCallback((item) => {
    if (tips(currentAccess) === USER_ACCESS.READ) {
      setText(item.text)
      setReadingDialogOpen(true)
    }
    if (tips(currentAccess) === USER_ACCESS.WRITE) {
      setId(item.id)
      setProject(item.project)
      setText(item.text)
      setModalOpen(true)
    }
  }, [])

  const _tipsModal = () => {
    const projectHasError = !get(project, 'id', 0)
      ? 'Projeto não selecionada'
      : ''
    const textHasError = validateText(text)
    const hasError = !!(projectHasError || textHasError || shouldDisable)

    return (
      <ModalComponent
        title="Cadastro de dicas"
        buttonLabel="Salvar"
        onClickClose={_handleModalClose}
        disabled={hasError}
        onClickButton={_handleConfirmationOpen}
        open={modalOpen}
        onClose={_handleModalClose}
      >
        <Column align="center">
          <Autocomplete
            required
            disableClearable
            value={project}
            options={projectList}
            getOptionLabel={(option) =>
              `${get(option, 'description', '')} - ${get(
                option,
                'farm.name',
                ''
              )}`
            }
            getOptionSelected={(option, v) => option.value === v.value}
            onChange={_onChangeProject}
            disabled={shouldDisable}
            width="75%"
            margin="0"
            renderInput={(params) => (
              <Input
                {...params}
                margin="18px 0 32px 0"
                label="Projeto"
                InputLabelProps={inputLabel}
                error={!!projectHasError}
                helperText={projectHasError}
                placeholder="Selecione um projeto"
              />
            )}
          />
          <Input
            label="Dica"
            variant="outlined"
            value={text}
            onChange={_onChangeText}
            InputLabelProps={inputLabel}
            multiline
            minRows={20}
            error={!!textHasError}
            disabled={shouldDisable}
            helperText={textHasError}
          />
        </Column>
      </ModalComponent>
    )
  }

  const _renderConfirmation = () => {
    return (
      <Dialog
        open={confirmationOpen}
        onClose={_handleConfirmationClose}
        TransitionComponent={transitionDown}
        aria-labeledby="alert-dialog-title"
        aria-disbredby="alert-dialog-description"
      >
        <ConfirmationDialog
          onClickCancel={_handleConfirmationClose}
          onClickConfirm={_handleSave}
        />
      </Dialog>
    )
  }

  const _tipReadingDialog = () => {
    return (
      <ReadingContainer
        open={readingDialogOpen}
        onClose={_handleReadingDialogClose}
      >
        <Body>
          <ReadingContent>
            <TipReadingText>
              <ReactMarkdown>{text}</ReactMarkdown>
            </TipReadingText>
          </ReadingContent>
        </Body>
        <DialogActions>
          <ActionButton
            background={Colors.DANGER}
            onClick={_handleReadingDialogClose}
          >
            Fechar
          </ActionButton>
        </DialogActions>
      </ReadingContainer>
    )
  }

  const _renderAlert = () => {
    const alertMessage = successMessage || errorMessage
    const alertColor = successMessage ? 'success' : 'error'
    return (
      <Snackbar
        open={!!alertMessage}
        key={alertColor}
        TransitionComponent={transitionUp}
      >
        <Alert severity={alertColor}>{alertMessage}</Alert>
      </Snackbar>
    )
  }

  return (
    <Container>
      <AppBar history={props.history} />
      <Content>
        <Header
          display="flex"
          flexDirection="column"
          width="100%"
          height="10%"
          padding="3em 0 15em 0"
        >
          <DateActions>
            <PickersWrapper isMobile={isMobile}>
              <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                <DatePicker
                  isMobile={isMobile}
                  label="Data inicial"
                  value={dayjs(startDate).toDate()}
                  onChange={_onChangeStartDate}
                  format="dd/MM/yyyy"
                  maxDate={dayjs(endDate).toDate()}
                  cancelLabel="Cancelar"
                />

                <DatePicker
                  isMobile={isMobile}
                  label="Data final"
                  value={dayjs(endDate).toDate()}
                  onChange={_onChangeEndDate}
                  format="dd/MM/yyyy"
                  minDate={dayjs(startDate).toDate()}
                  cancelLabel="Cancelar"
                />
              </MuiPickersUtilsProvider>
            </PickersWrapper>
            {isMobile ? (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ) : (
              <Button
                width="8em"
                height="2.5em"
                startIcon={<SearchIcon />}
                onClick={getTipsList}
              >
                Buscar
              </Button>
            )}
          </DateActions>
          <Row justify="center" align="center">
            <OutlinedInput
              isMobile={isMobile}
              width="25%"
              mobileWidth="60%"
              id="outlined-adornment-search"
              placeholder="Pesquisar na tabela"
              type="search"
              value={searchText}
              onChange={_onChangeSearchText}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              labelWidth={60}
            />
            {isMobile ? (
              <IconButton
                color="primary"
                aria-label="add"
                onClick={_handleModalOpen}
              >
                <AddIcon />
              </IconButton>
            ) : (
              <Button
                margin="8px"
                width="9em"
                height="2.5em"
                startIcon={<AddIcon />}
                onClick={_handleModalOpen}
              >
                Registrar
              </Button>
            )}
          </Row>
        </Header>

        {isLoading ? (
          <TableLoadingCompWrapper>
            <Text margin="12px" textColor="black">
              Carregando Dicas do agronomo..
            </Text>
            <CustomLinearProgress />
          </TableLoadingCompWrapper>
        ) : (
          <TableWrapper>
            <DataTable
              columns={_getColumns()}
              data={filteredTipsList}
              mobileLabel={(item) =>
                `${get(item, 'project.description', '')} - ${get(
                  item,
                  'project.farm.name'
                )}`
              }
              mobileSubtitle={(item) =>
                dayjs(item.created_at).format('DD/MM/YYYY')
              }
              // mobileTextTitle={'Dica:'}
              mobileText={(item) => item.text}
              onClickItem={_onClickItem}
            />
          </TableWrapper>
        )}
      </Content>
      {_tipsModal()}
      {_renderConfirmation()}
      {_renderAlert()}
      {_tipReadingDialog()}
    </Container>
  )
}

Tips.propTypes = {
  history: PropTypes.object,
  currentClient: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired
  }),
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  setSuccessMessage: PropTypes.func,
  setErrorMessage: PropTypes.func
}

const mapState = ({ auth, clients }) => {
  return {
    currentUser: auth.currentUser,
    currentClient: clients.currentClient
  }
}

export default connect(mapState)(Tips)
