import styled from 'styled-components'
import { styled as styledMaterialUI } from '@material-ui/core/styles'

import SpeedIcon from '@material-ui/icons/Speed'
import EditIcon from '@material-ui/icons/Edit'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import LocalDrinkIcon from '@material-ui/icons/LocalDrink'
import Typography from '@material-ui/core/Typography'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width || null};
  height: 80vh;
  border: solid 1px #ddd;
  background: #fff;
  z-index: 5;
  border-radius: 6px;
  margin: 10px 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
`
export const Body = styled.div`
  margin: 0 4px 20px 4px;
`

export const WidgetSelector = styled.div`
  width: 100%;
  height: 70%;
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  margin: 0 0 8px 0;
  :hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
  }
`
export const SelectorContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 10px 0 10px;
`
export const EditorTextContainer = styled.div`
  display: flex;
  align-items: center;
`

export const GaugeIcon = styledMaterialUI(SpeedIcon)({
  fontSize: '24px',
  color: 'rgba(0,0,0,0.7)'
})

export const PowerIcon = styledMaterialUI(PowerSettingsNewIcon)({
  fontSize: '20px',
  color: 'rgba(0,0,0,0.7)'
})

export const PluviometerIcon = styledMaterialUI(LocalDrinkIcon)({
  fontSize: '24px',
  color: 'rgba(0,0,0,0.7)'
})

export const EditWidgetIcon = styledMaterialUI(EditIcon)({
  fontSize: '24px',
  cursor: 'pointer',
  borderRadius: '4px',
  color: 'rgba(0,0,0,0.7)',
  '&:hover': {
    color: 'rgba(0,0,0,1)'
  },
  '&:active': {
    transform: 'translateY(0.8px)'
  }
})

export const EditorText = styledMaterialUI(Typography)({
  fontSize: '16px',
  fontFamily: 'Montserrat',
  color: 'rgba(0,0,0,0.7)',
  margin: '0 0 0 4px'
})

export const Title = styledMaterialUI(Typography)({
  display: 'flex',
  alignItems: (props) => props.align || 'flex-start',
  justifyContent: (props) => props.justify || 'flex-start',
  fontSize: 26,
  fontWeight: 'bold',
  margin: '8px 0 4px 8px',
  color: 'rgba(0,0,0,0.7)'
})
