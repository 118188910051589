// import { duration } from '@material-ui/core'
import get from 'lodash/get'
import dayjs from 'dayjs'
import { USER_ROLES_LIST, TENSION_READING_TYPE } from './constants'

export const getRoleItemFromValue = (rawValue) => {
  return USER_ROLES_LIST.find((r) => r.value === rawValue)
}

export const getRoleLabelFromValue = (rawValue) => {
  const role = USER_ROLES_LIST.find((r) => r.value === rawValue)

  return get(role, 'label', '')
}

export const getTensionRegistrationTypeByValue = (rawValue) => {
  return TENSION_READING_TYPE.find((t) => t.value === rawValue)
}

export const getYesOrNoFromBoolean = (boolValue) => (boolValue ? 'Sim' : 'Não')

export const getMinutesFromDurationText = (duration = '') => {
  const parts = duration.split(':')
  const hoursText = parts.length === 2 ? parts[0] : '00'
  const minutesText = parts.length === 2 ? parts[1] : '00'
  const h = Number(hoursText)
  const m = Number(minutesText)

  return h * 60 + m
}

export const getTextFromDurationMinutes = (duration = 0) => {
  const hours = Math.floor(duration / 60)
  const minutes = duration - hours * 60

  const hoursText = hours < 10 ? `0${hours}` : String(hours)
  const minutesText = minutes < 10 ? `0${minutes}` : String(minutes)

  return `${hoursText}:${minutesText}`
}

export const urlB64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')
  const rawData = atob(base64)
  const outputArray = new Uint8Array(rawData.length)
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export const timestampToServer = (timestamp) => {
  return dayjs(timestamp).format('YYYY-MM-DD[T]HH:mm')
}

export const formatTimestamp = (timestamp, pattern) => {
  return dayjs(timestamp).format(pattern)
}
