import axios from 'axios'
import getValue from 'lodash/get'
import logError from '../utils/log'

const READINGS = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/reading`
})

const getList = async (
  authToken,
  clientId,
  startDate,
  endDate,
  skip = 0,
  limit = 20
) => {
  try {
    const response = await READINGS.get(
      `/?client_id=${clientId}&startDate=${startDate}&endDate=${endDate}`,
      {
        headers: { Authorization: `bearer ${authToken}` }
      }
    )

    return response.data
  } catch (error) {
    logError('services@readings@getList', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao buscar a lista de leituras'
      )
    )
  }
}

const insertReading = async (authToken, reading) => {
  try {
    const response = await READINGS.post(`/`, reading, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@readings@insertReading', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao inserir a leitura'
      )
    )
  }
}

const updateReading = async (authToken, reading) => {
  try {
    const response = await READINGS.put(`/`, reading, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@readings@updateReading', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao atualizar a leitura'
      )
    )
  }
}

export default {
  getList,
  insertReading,
  updateReading
}
