import React from 'react'
import PropTypes from 'prop-types'

import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

import { ConfirmDialogWrapper } from '../../styles/components'

const ConfirmationDialog = ({ onClickCancel, onClickConfirm }) => {
  return (
    <ConfirmDialogWrapper>
      <DialogTitle>Deseja Confirmar este registro?</DialogTitle>
      <DialogActions>
        <Button onClick={onClickCancel} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={onClickConfirm}
          color="primary"
          autoFocus
          variant="contained"
        >
          Aceitar
        </Button>
      </DialogActions>
    </ConfirmDialogWrapper>
  )
}

ConfirmationDialog.propTypes = {
  onClickCancel: PropTypes.func,
  onClickConfirm: PropTypes.func
}

ConfirmationDialog.defaultProps = {
  onClickCancel: () => {},
  onClickConfirm: () => {}
}

export default ConfirmationDialog
