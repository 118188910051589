import { styled as styledMaterialUI } from '@material-ui/core/styles'
import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'
import TreeItem from '@material-ui/lab/TreeItem'

import PlaceIcon from '@material-ui/icons/Place'
import RateReviewIcon from '@material-ui/icons/RateReview'
import WifiTetheringIcon from '@material-ui/icons/WifiTethering'
import LocalDrinkIcon from '@material-ui/icons/LocalDrink'
import PersonIcon from '@material-ui/icons/Person'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'

const ICON_STYLES = {
  fontSize: '20px',
  color: 'rgba(0,0,0,0.7)'
}

export const StyledTreeItem = styledMaterialUI(TreeItem)({
  backgroundColor: '#fff',
  marginBottom: '20px'
})

export const ItemText = styledMaterialUI(Typography)({
  fontSize: '18px',
  fontFamily: 'Montserrat',
  color: 'rgba(0,0,0,0.7)',
  marginLeft: '4px'
})

export const FarmItemIcon = styledMaterialUI(PlaceIcon)(ICON_STYLES)

export const ProjectItemIcon = styledMaterialUI(RateReviewIcon)(ICON_STYLES)

export const ClientItemIcon = styledMaterialUI(PersonIcon)(ICON_STYLES)

export const SensorItemIcon = styledMaterialUI(WifiTetheringIcon)(ICON_STYLES)

export const PluviometerItemIcon = styledMaterialUI(LocalDrinkIcon)(ICON_STYLES)

export const WarnIcon = styledMaterialUI(ReportProblemOutlinedIcon)({
  fontSize: '240px',
  color: 'rgba(237, 145, 47, 0.9)'
})

export const WarnText = styledMaterialUI(Typography)({
  fontSize: '24px',
  fontFamily: 'Montserrat',
  textAlign: 'center'
})

export const WarnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ItemLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const StyledSpan = styled.span`
  font-size: 38px;
  font-weight: bold;
`
