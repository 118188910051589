import styled from 'styled-components'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core'

import Colors from '../../styles/colors'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${Colors.AZUL_HYDRA};
`

export const CardContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  height: 480px;
  padding: 20px 0 20px 0;

  @media (min-width: 800px) {
    width: 50%;
    height: 50%;
  }
`

export const LogoContainer = styled.img`
  width: 70%;
  height: auto;

  @media (min-width: 800px) {
    width: 50%;
  }
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;

  @media (min-width: 800px) {
    width: 50%;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 800px) {
    flex-direction: row;
  }
`

export const ForgotPasswordButton = withStyles((theme) => ({
  root: {
    backgroundColor: Colors.WARNING,
    '&:hover': {
      backgroundColor: Colors.WARNING_DARKER
    },
    marginBottom: 10
  }
}))(Button)

export const CustomStyles = {
  userInput: { marginBottom: 5 },
  passwordInput: { marginTop: 5 },
  loginButton: { marginRight: 5, marginBottom: 10 },
  forgotPassButton: { marginRight: 5, marginBottom: 10 }
}
