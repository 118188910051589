/* eslint-disable indent */
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import CircularProgress from '@material-ui/core/CircularProgress'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import LocationOnIcon from '@material-ui/icons/LocationOn'

// import AutoComplete from '@material-ui/lab/Autocomplete'
// import LocationOnIcon from '@material-ui/icons/LocationOn'
// import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import {
  useLoadScript,
  GoogleMap,
  Marker,
  InfoWindow
} from '@react-google-maps/api'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'
import { Column } from '../../../styles/components'
import { SearchInput, SearchList, SearchRow } from './styles'

const libraries = ['places']

const scriptLoad = {
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  libraries
}

const mapOptions = {
  disableDefaultUI: true,
  zoomControl: true,
  mapTypeId: 'satellite'
}

const mapContainerStyle = {
  display: 'flex',
  flex: 1,
  height: 'calc(100vh - 80px)',
  padding: '0px',
  margin: '0px'
}

const MapError = () => {
  return (
    <Column align="center" justify="center" style={mapContainerStyle}>
      Ocorreu um erro ao carregar o mapa
    </Column>
  )
}

const LoadingMap = () => {
  return (
    <Column align="center" justify="center" style={mapContainerStyle}>
      <CircularProgress />
    </Column>
  )
}

const SearchPlace = ({ panTo }) => {
  const [value, setValue] = useState('')

  const handleChange = useCallback((address) => {
    setValue(address)
  }, [])

  const handleSelect = useCallback(async (value) => {
    const results = await geocodeByAddress(value)
    const { lat, lng } = await getLatLng(results[0])
    setValue(value)
    panTo({ lat, lng })
  }, [])

  const renderOptions = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading
  }) => (
    <div>
      <SearchInput
        type="search"
        variant="outlined"
        label="Pesquisar"
        {...getInputProps()}
      />

      <div>
        {loading ? <div>Carregando...</div> : null}

        {suggestions.map((suggestion, id) => {
          return (
            <SearchList key={id} {...getSuggestionItemProps(suggestion)}>
              <ListItem>
                <ListItemIcon>
                  <LocationOnIcon />
                </ListItemIcon>
                {suggestion.description}
              </ListItem>
            </SearchList>
          )
        })}
      </div>
    </div>
  )

  return (
    <SearchRow>
      <PlacesAutocomplete
        value={value}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {renderOptions}
      </PlacesAutocomplete>
    </SearchRow>
  )
}

const LocationSelector = ({ onMapClick, markers, address }) => {
  const { isLoaded, loadError } = useLoadScript(scriptLoad)
  const [selected, setSelected] = useState(false)

  const mapRef = React.useRef()
  const onMapLoad = useCallback((map) => {
    mapRef.current = map
  }, [])

  const panTo = useCallback(
    ({ lat, lng }, zoom = 10) => {
      if (mapRef.current) {
        mapRef.current.panTo({ lat, lng })
        mapRef.current.setZoom(zoom)
      }
    },
    [mapRef]
  )

  useEffect(() => {
    if (!markers.lat) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          panTo({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          })
        },
        () => panTo({ lat: -19.402289149210425, lng: -40.06507155390624 }, 5)
      )
    }
  }, [mapRef])

  const onClick = useCallback((e) => {
    onMapClick(e)
    setSelected(true)
  }, [])

  const onMarkerClick = useCallback(() => {
    setSelected(true)
  }, [])

  const onMarkerClose = useCallback(() => {
    setSelected(false)
  }, [])

  if (loadError) return <MapError />

  if (!isLoaded) return <LoadingMap />

  return (
    <div>
      <SearchPlace panTo={panTo} />
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={8}
        center={markers}
        options={mapOptions}
        onClick={onClick}
        onLoad={onMapLoad}
      >
        <Marker position={markers} onClick={onMarkerClick}>
          {selected ? (
            <InfoWindow position={markers} onCloseClick={onMarkerClose}>
              <>
                <Typography variant="h6">Geolocalização </Typography>
                <Typography>
                  Endereço: {address || 'Carregando...'}
                  <br />
                  Latitude: {markers.lat}
                  <br />
                  Longetude: {markers.lng}
                </Typography>
              </>
            </InfoWindow>
          ) : null}
        </Marker>
      </GoogleMap>
    </div>
  )
}

LocationSelector.propTypes = {
  onMapClick: PropTypes.func.isRequired,
  markers: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  address: PropTypes.string
}

SearchPlace.propTypes = {
  panTo: PropTypes.func.isRequired
}

LocationSelector.defaultProps = {
  onMapClick: () => {}
}

SearchPlace.defaultProps = {
  panto: () => {}
}

export default LocationSelector
