import React from 'react'
import PropTypes from 'prop-types'

import PowerGauge from '../../../Components/widgets/gaugeWidget/powerGauge'
import {
  WidgetTitle,
  WidgetDate,
  WidgetReadings,
  WidgetSubtitle,
  StyledSpam
} from '../styles'
import { Column } from '../../../styles/components'

const TensiomterWidgets = ({
  projectDescription,
  tensiometerReadings,
  sensorGroupDescription,
  readingTime,
  tensiometerDepth,
  farmName,
  value
}) => {
  const colorChange = tensiometerReadings > 149 ? 'red' : 'black'
  return (
    <>
      <Column>
        <WidgetSubtitle>
          {projectDescription} - {farmName}
        </WidgetSubtitle>
        <WidgetDate>{readingTime}</WidgetDate>
      </Column>
      <WidgetTitle>
        {sensorGroupDescription} <br />
        <StyledSpam fontSize={'16px'}>{tensiometerDepth}cm</StyledSpam>
      </WidgetTitle>
      <div style={{ margin: '8px 0 8px 0' }}>
        <PowerGauge value={value} />
      </div>
      <WidgetReadings color={colorChange}>
        {tensiometerReadings}
        <StyledSpam fontSize={'20px'}>mbar</StyledSpam>
      </WidgetReadings>
    </>
  )
}

TensiomterWidgets.propTypes = {
  projectDescription: PropTypes.string,
  farmName: PropTypes.string,
  sensorGroupDescription: PropTypes.string,
  tensiometerDepth: PropTypes.string,
  readingTime: PropTypes.string,
  value: PropTypes.number,
  tensiometerReadings: PropTypes.number
}

export default TensiomterWidgets
