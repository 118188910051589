import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import TextField from '@material-ui/core/TextField'

import { DialogContainer, TypoHeader, Selector } from './styles'

const SensorGroupSelection = ({ options, value, onChange }) => {
  const hasError = !get(value, 'id', 0) ? 'Bateria não selecionada' : ''

  return (
    <DialogContainer>
      <TypoHeader>Selecione uma bateria:</TypoHeader>
      <Selector
        fullWidth
        id="Selecione uma bateria"
        options={options}
        value={value}
        onChange={onChange}
        getOptionLabel={(op) => get(op, 'description', '')}
        getOptionSelected={(op, current) => op.id === current.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Baterias"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            placeholder="Selecione uma bateria"
            error={!!hasError}
            helperText={hasError}
          />
        )}
      />
    </DialogContainer>
  )
}

SensorGroupSelection.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired
    })
  ).isRequired,
  value: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired
  }),
  onChange: PropTypes.func.isRequired
}

SensorGroupSelection.defaultProps = {
  onChange: () => {}
}

export default SensorGroupSelection
