import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import {
  submitLogin,
  checkLoggedUser,
  resetErrorMessage
} from '../../store/reducers/auth'
import { navigateToPage } from '../../routes/navigate'
import Pages from '../../routes/routes'

import AuthService from '../../services/auth'

import AccountCircle from '@material-ui/icons/AccountCircle'
import VpnKey from '@material-ui/icons/VpnKey'
import SendIcon from '@material-ui/icons/Send'
import LockIcon from '@material-ui/icons/Lock'
import InputAdornment from '@material-ui/core/InputAdornment'
import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import Slide from '@material-ui/core/Slide'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

import {
  ButtonsContainer,
  CardContainer,
  Container,
  ForgotPasswordButton,
  FormContainer,
  LogoContainer,
  CustomStyles
} from './styles'
import LogoHydraConnect from '../../assets/images/logo-connect.png'

class Login extends React.Component {
  _slideTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })

  state = {
    username: '',
    password: '',
    forgotPasswordEmail: '',
    forgotPasswordToken: '',
    newPassword: '',
    forgotPassIsOpen: false,
    informForgotTokenIsOpen: false,
    loadingForgotPassword: false,
    alertIsOpen: false,
    alertColor: 'success',
    alertMessage: ''
  }

  static propTypes = {
    history: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    currentUser: PropTypes.object,
    checkingLoggedUser: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string.isRequired,
    submitLogin: PropTypes.func.isRequired,
    checkLoggedUser: PropTypes.func.isRequired,
    resetErrorMessage: PropTypes.func.isRequired
  }

  static defaultProps = {
    submitLogin: () => {},
    checkLoggedUser: () => {},
    resetErrorMessage: () => {}
  }

  componentDidMount() {
    const { checkLoggedUser } = this.props
    checkLoggedUser()
  }

  componentDidUpdate(prevProps) {
    this._checkUpdateCurrentUser(prevProps)
    this._checkErrorMessage(prevProps)
  }

  _checkUpdateCurrentUser = (prevProps) => {
    const { history, currentUser } = this.props
    const oldToken = get(prevProps, 'currentUser.token', '')
    const newToken = get(currentUser, 'token', '')
    if (!oldToken.length && newToken.length) {
      navigateToPage(history, Pages.DASHBOARD)
    }
  }

  _checkErrorMessage = (prevProps) => {
    const { errorMessage, resetErrorMessage } = this.props
    if (!prevProps.errorMessage.length && errorMessage.length) {
      this.setState(
        {
          alertIsOpen: true,
          alertMessage: errorMessage,
          alertColor: 'error'
        },
        resetErrorMessage
      )
    }
  }

  _onSubmit = () => {
    const { submitLogin } = this.props
    const { username, password } = this.state

    submitLogin(username, password)
  }

  _sendForgotEmail = async () => {
    try {
      const { forgotPasswordEmail } = this.state

      this.setState({ loadingForgotPassword: true })
      const result = await AuthService.forgotPassword(forgotPasswordEmail)

      this.setState({
        forgotPassIsOpen: false,
        loadingForgotPassword: false,
        forgotPasswordEmail: '',
        alertIsOpen: true,
        alertColor: result.success ? 'success' : 'error',
        alertMessage: result.success
          ? 'Email enviado. Verifique sua caixa de entrada.'
          : 'Houve um erro no envio do email de recuperação de senha.'
      })
    } catch (error) {
      this.setState({
        forgotPassIsOpen: false,
        loadingForgotPassword: false,
        forgotPasswordEmail: '',
        alertIsOpen: true,
        alertColor: 'error',
        alertMessage: error.message
      })
    }
  }

  _sendNewPassword = async () => {
    try {
      const { newPassword, forgotPasswordToken } = this.state

      this.setState({ loadingForgotPassword: true })
      const result = await AuthService.resetPassword(
        newPassword,
        forgotPasswordToken
      )

      this.setState({
        informForgotTokenIsOpen: false,
        loadingForgotPassword: false,
        newPassword: '',
        forgotPasswordToken: '',
        alertIsOpen: true,
        alertColor: result.success ? 'success' : 'error',
        alertMessage: result.success
          ? 'Senha alterada'
          : 'Houve um erro na alteração da senha.'
      })
    } catch (error) {
      this.setState({
        informForgotTokenIsOpen: false,
        loadingForgotPassword: false,
        newPassword: '',
        forgotPasswordToken: '',
        alertIsOpen: true,
        alertColor: 'error',
        alertMessage: error.message
      })
    }
  }

  _onChangeUsername = (event) => {
    const value = get(event, 'target.value', '')
    this.setState({ username: value })
  }

  _onChangePassword = (event) => {
    const value = get(event, 'target.value', '')
    this.setState({ password: value })
  }

  _onChangeForgotPasswordEmail = (event) => {
    const value = get(event, 'target.value', '')
    this.setState({ forgotPasswordEmail: value })
  }

  _onChangeNewPassword = (event) => {
    const value = get(event, 'target.value', '')
    this.setState({ newPassword: value })
  }

  _onChangeForgotPassworrdToken = (event) => {
    const value = get(event, 'target.value', '')
    this.setState({ forgotPasswordToken: value })
  }

  _loginButtonContent = () => {
    const { loading } = this.props

    return loading ? (
      <CircularProgress color="secondary" size={15} />
    ) : (
      <SendIcon />
    )
  }

  _handleCloseDialog = () => {
    this.setState({ forgotPassIsOpen: false })
  }

  _handleOpenDialog = () => {
    this.setState({ forgotPassIsOpen: true })
  }

  _handleCloseAlert = () => {
    this.setState({ alertIsOpen: false })
  }

  _handleOpenNewPasswordDialog = () => {
    this.setState({
      forgotPassIsOpen: false,
      informForgotTokenIsOpen: true
    })
  }

  _handleCloseNewPasswordDialog = () => {
    this.setState({ informForgotTokenIsOpen: false })
  }

  _forgotPassDialog = () => {
    const { forgotPassIsOpen, forgotPasswordEmail, loadingForgotPassword } =
      this.state

    return (
      <Dialog
        open={forgotPassIsOpen}
        onClose={this._handleCloseDialog}
        aria-labelledby="form-dialog-title"
        TransitionComponent={this._slideTransition}
      >
        <DialogTitle id="form-dialog-title">Recuperar senha</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Digite seu email para receber a chave de recuperação
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email"
            type="email"
            fullWidth
            value={forgotPasswordEmail}
            onChange={this._onChangeForgotPasswordEmail}
            disabled={loadingForgotPassword}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this._handleCloseDialog} color="secondary">
            Cancelar
          </Button>
          <Button onClick={this._handleOpenNewPasswordDialog} color="primary">
            Já tenho a chave
          </Button>
          <Button
            onClick={this._sendForgotEmail}
            color="primary"
            disabled={!forgotPasswordEmail || loadingForgotPassword}
          >
            {loadingForgotPassword ? (
              <CircularProgress color="primary" size={30} />
            ) : (
              'Enviar'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  _resetPassDialog = () => {
    const {
      informForgotTokenIsOpen,
      forgotPasswordToken,
      newPassword,
      loadingForgotPassword
    } = this.state

    return (
      <Dialog
        open={informForgotTokenIsOpen}
        onClose={this._handleCloseNewPasswordDialog}
        aria-labelledby="form-dialog-title"
        TransitionComponent={this._slideTransition}
      >
        <DialogTitle id="form-dialog-title">Redefinir senha</DialogTitle>
        <DialogContent>
          <DialogContentText>Informe a chave e a nova senha</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="newPassword"
            label="Nova senha"
            type="password"
            fullWidth
            value={newPassword}
            onChange={this._onChangeNewPassword}
            disabled={loadingForgotPassword}
          />
          <TextField
            autoFocus
            margin="dense"
            id="newPassword"
            label="Chave"
            fullWidth
            value={forgotPasswordToken}
            onChange={this._onChangeForgotPassworrdToken}
            disabled={loadingForgotPassword}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this._handleCloseNewPasswordDialog}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            onClick={this._sendNewPassword}
            color="primary"
            disabled={
              !newPassword || !forgotPasswordToken || loadingForgotPassword
            }
          >
            {loadingForgotPassword ? (
              <CircularProgress color="primary" size={30} />
            ) : (
              'Alterar senha'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  _alert = () => {
    const { alertIsOpen, alertMessage, alertColor } = this.state

    return (
      <Snackbar
        open={alertIsOpen}
        onClose={this._handleCloseAlert}
        autoHideDuration={3000}
        TransitionComponent={this._slideTransition}
        key={alertMessage}
      >
        <Alert severity={alertColor}>{alertMessage}</Alert>
      </Snackbar>
    )
  }

  render() {
    const { loading, checkingLoggedUser } = this.props
    const { username, password, forgotPassIsOpen } = this.state
    const enableLogin = !loading && username.length && password.length

    if (checkingLoggedUser) {
      return (
        <Container>
          <CircularProgress color="secondary" size={50} />
        </Container>
      )
    }

    return (
      <Container>
        <CardContainer elevation={10}>
          <LogoContainer src={LogoHydraConnect} alt="logo" />
          <FormContainer>
            <form id="myform" onSubmit={this._onSubmit}>
              <Input
                id="user"
                fullWidth
                startAdornment={
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                }
                placeholder="Usuário"
                type="email"
                style={CustomStyles.userInput}
                value={username}
                onChange={this._onChangeUsername}
                disabled={loading}
              />
              <Input
                id="pass"
                fullWidth
                startAdornment={
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                }
                placeholder="Senha"
                type="password"
                style={CustomStyles.passwordInput}
                value={password}
                onChange={this._onChangePassword}
                disabled={loading}
              />
            </form>
          </FormContainer>
          <ButtonsContainer>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="medium"
              startIcon={this._loginButtonContent()}
              style={CustomStyles.loginButton}
              onClick={this._onSubmit}
              disabled={!enableLogin}
              form="myform"
            >
              Entrar
            </Button>
            <ForgotPasswordButton
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<VpnKey />}
              style={CustomStyles.forgotPassButton}
              disabled={loading || forgotPassIsOpen}
              onClick={this._handleOpenDialog}
            >
              Esqueci a senha
            </ForgotPasswordButton>
          </ButtonsContainer>
        </CardContainer>

        {this._forgotPassDialog()}
        {this._resetPassDialog()}
        {this._alert()}
      </Container>
    )
  }
}

const mapState = ({ auth }) => {
  return {
    loading: auth.loading,
    currentUser: auth.currentUser,
    checkingLoggedUser: auth.checkingLoggedUser,
    errorMessage: auth.errorMessage
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      submitLogin,
      checkLoggedUser,
      resetErrorMessage
    },
    dispatch
  )

export default connect(mapState, mapDispatchToProps)(Login)
