/* eslint-disable indent */
import getValue from 'lodash/get'
import ClientsService from '../../services/clients'
import store from '../index'
import logError from '../../utils/log'
import { USER_ROLES } from '../../utils/constants'

const Types = {
  SET_CURRENT_CLIENT: 'clients@setCurrentClient',
  SET_CLIENTS_LIST: 'clients@setClientsList',
  SET_LOADING: 'clients@setLoading',
  SET_ERROR: 'clients@setError',
  SET_SUCCESS: 'clients@setSuccess',
  CLEAR: 'clients@clear'
}

const initialState = {
  list: [],
  currentClient: null,
  loading: false,
  errorMessage: '',
  successMessage: ''
}

const actions = {
  [Types.SET_CURRENT_CLIENT]: (state, action) => ({
    ...state,
    currentClient: action.payload
  }),
  [Types.SET_CLIENTS_LIST]: (state, action) => ({
    ...state,
    list: action.payload
  }),
  [Types.SET_LOADING]: (state, action) => ({
    ...state,
    loading: action.payload
  }),
  [Types.SET_ERROR]: (state, action) => ({
    ...state,
    errorMessage: action.payload
  }),
  [Types.SET_SUCCESS]: (state, action) => ({
    ...state,
    successMessage: action.payload
  }),
  [Types.CLEAR]: (state, action) => ({
    ...state,
    ...action.payload
  })
}

const reducer = (state = initialState, action) => {
  if (actions[action.type]) {
    return actions[action.type](state, action)
  }

  return state
}

export const clearClientsReducer = () => async (dispatch) => {
  try {
    dispatch({
      type: Types.CLEAR,
      payload: initialState
    })
  } catch (error) {
    console.log(error)
  }
}

export const getClients =
  (skip = 0, limit = 100) =>
  async (dispatch) => {
    try {
      dispatch({
        type: Types.SET_LOADING,
        payload: true
      })

      const currentStore = store.getState()
      const authToken = getValue(currentStore, 'auth.currentUser.token', null)
      const userRole = getValue(currentStore, 'auth.currentUser.role', -1)
      const userClientsList = getValue(
        currentStore,
        'auth.currentUser.clients',
        []
      )
      const currentClientSelected = getValue(
        currentStore,
        'clients.currentClient',
        null
      )

      let finalList = userClientsList

      if (userRole === USER_ROLES.ADMIN) {
        const clientsList = await ClientsService.getList(authToken, skip, limit)
        finalList = clientsList
      }

      const currentClientExists = !!finalList.find(
        (item) => item.id === (currentClientSelected?.id || 0)
      )

      dispatch({
        type: Types.SET_LOADING,
        payload: false
      })
      dispatch({
        type: Types.SET_CLIENTS_LIST,
        payload: finalList
      })

      if (finalList.length && !currentClientExists) {
        dispatch({
          type: Types.SET_CURRENT_CLIENT,
          payload: finalList[0]
        })
      }
    } catch (error) {
      logError('reducers -> clients -> getClients', error)
      dispatch({
        type: Types.SET_LOADING,
        payload: false
      })
      dispatch({
        type: Types.SET_ERROR,
        payload: error.message
      })
    }
  }

export const setClient = (client) => async (dispatch) => {
  dispatch({
    type: Types.SET_CURRENT_CLIENT,
    payload: client
  })
}

export const addClient = (client) => async (dispatch) => {
  try {
    dispatch({
      type: Types.SET_LOADING,
      payload: true
    })

    const currentStore = store.getState()
    const authToken = getValue(currentStore, 'auth.currentUser.token', null)
    const newClient = await ClientsService.insertClient(authToken, client)
    const newItem = { ...client, id: newClient.id }

    const currentList = getValue(currentStore, 'clients.list', [])
    const newList = [...currentList, newItem].sort((a, b) => a.name - b.name)

    dispatch({
      type: Types.SET_CLIENTS_LIST,
      payload: newList
    })
    dispatch({
      type: Types.SET_LOADING,
      payload: false
    })
    dispatch({
      type: Types.SET_SUCCESS,
      payload: 'Cliente adicionado'
    })
  } catch (error) {
    logError('reducers -> clients -> addClient', error)
    dispatch({
      type: Types.SET_LOADING,
      payload: false
    })
    dispatch({
      type: Types.SET_ERROR,
      payload: error.message
    })
  }
}

export const updateClient = (client) => async (dispatch) => {
  try {
    dispatch({
      type: Types.SET_LOADING,
      payload: true
    })

    const currentStore = store.getState()
    const authToken = getValue(currentStore, 'auth.currentUser.token', null)
    const currentSelectedClient = getValue(
      currentStore,
      'clients.currentClient',
      null
    )
    const newClient = await ClientsService.updateClient(authToken, client)
    const newItem = { ...client, id: newClient.id }

    const currentList = getValue(currentStore, 'clients.list', [])
    const clientIndex = currentList.findIndex((item) => item.id === newItem.id)

    if (clientIndex > -1) {
      const newList = [...currentList]
      newList[clientIndex] = newItem

      dispatch({
        type: Types.SET_CLIENTS_LIST,
        payload: newList
      })
      dispatch({
        type: Types.SET_LOADING,
        payload: false
      })
      dispatch({
        type: Types.SET_SUCCESS,
        payload: 'Cliente atualizado'
      })
      if (currentSelectedClient.id === newItem.id) {
        dispatch(setClient(newItem))
      }
    }
  } catch (error) {
    logError('reducers -> clients -> updateClient', error)
    dispatch({
      type: Types.SET_LOADING,
      payload: false
    })
    dispatch({
      type: Types.SET_ERROR,
      payload: error.message
    })
  }
}

export const setSuccessMessage =
  (message = '') =>
  async (dispatch) => {
    dispatch({
      type: Types.SET_SUCCESS,
      payload: message
    })
  }

export const setErrorMessage =
  (message = '') =>
  async (dispatch) => {
    dispatch({
      type: Types.SET_ERROR,
      payload: message
    })
  }

export default reducer
