/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import get from 'lodash/get'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'

import { DialogContainer, TypoHeader, TypoTitle, TypoText } from './styles'
import { getTextFromDurationMinutes } from '../../../../utils/helpers'
import {
  IRRIGATION_TYPES,
  IRRIGATION_TYPE_LIST
} from '../../../../utils/constants'

const bullStyle = {
  display: 'inline-block',
  paddingBottom: 6,
  marginRight: 8,
  transform: 'scale(3)'
}

const bull = <span style={bullStyle}>•</span>

const IrrigationReview = ({
  farmName,
  projectDescription,
  irrigationTimestamp,
  hidrometerValue,
  sectors,
  notes
}) => {
  const timestamp = dayjs(irrigationTimestamp)
  const timeSum = sectors.reduce(
    (total, current) => (total += get(current, 'duration', 0)),
    0
  )
  return (
    <DialogContainer>
      <Card>
        <CardContent>
          <TypoHeader>Resumo da irrigação:</TypoHeader>
          <Divider />
          <TypoTitle>Projeto:</TypoTitle>
          <TypoText>
            {bull}
            {farmName} - {projectDescription}
          </TypoText>
          <TypoTitle>Data, hora e duração da irrigação:</TypoTitle>
          <TypoText>
            {bull}
            {timestamp.format('DD/MM/YY')}
          </TypoText>
          <TypoText>
            {bull}
            {timestamp.format('HH:mm')}
          </TypoText>
          <TypoText>
            {bull}
            Duração: {getTextFromDurationMinutes(timeSum)}
          </TypoText>
          <TypoTitle>Hidrômetro:</TypoTitle>
          <TypoText>
            {bull}
            {hidrometerValue}m³
          </TypoText>
          <TypoTitle>Setores:</TypoTitle>
          {sectors.map((s) => {
            const id = get(s, 'sector.id', 0)
            const irrigationType = IRRIGATION_TYPE_LIST.find(
              (t) => t.value === s.type
            )
            const sectorDurationText =
              s.type !== IRRIGATION_TYPES.NONE
                ? getTextFromDurationMinutes(s.duration)
                : ''
            const desc = get(s, 'sector.description', '')
            return (
              <TypoText key={id}>
                {bull}
                {desc}: {irrigationType.label} {sectorDurationText}
              </TypoText>
            )
          })}
          <TypoTitle>Observações:</TypoTitle>
          <TypoText>
            {bull}
            {notes || 'Nenhuma observação foi registrada.'}
          </TypoText>
        </CardContent>
      </Card>
    </DialogContainer>
  )
}

IrrigationReview.propTypes = {
  farmName: PropTypes.string.isRequired,
  projectDescription: PropTypes.string.isRequired,
  irrigationTimestamp: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  hidrometerValue: PropTypes.number.isRequired,
  sectors: PropTypes.array.isRequired,
  notes: PropTypes.string.isRequired
}

IrrigationReview.defaultProps = {}

export default IrrigationReview
