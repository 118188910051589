import React from 'react'
import PropTypes from 'prop-types'

// import { makeStyles } from '@material-ui/core/styles'
import { Cell, Container } from './styles'

const TableRow = ({ columns, item }) => {
  // const myStyles = makeStyles({
  //   card: {}
  // })

  return (
    <Container>
      {columns.map((col) => {
        const renderData = col[`render_${col.key}`]
        return renderData ? (
          <Cell key={col.label}>{renderData(item)}</Cell>
        ) : null
      })}
    </Container>
  )
}

TableRow.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired
    })
  ).isRequired,
  item: PropTypes.object.isRequired
}
TableRow.defaultProps = {
  columns: []
}

export default TableRow
