/* eslint-disable indent */
import get from 'lodash/get'
import ROUTES from '../routes/routes'
import { USER_ACCESS } from '../utils/constants'

export const readings = (userAccess) => {
  return get(userAccess, 'readings', USER_ACCESS.WRITE)
}
export const pluviometry = (userAccess) => {
  return get(userAccess, 'pluviometer', USER_ACCESS.WRITE)
}

export const irrigations = (userAccess) => {
  return get(userAccess, 'irrigations', USER_ACCESS.WRITE)
}

export const tips = (userAccess) => {
  return get(userAccess, 'tips', USER_ACCESS.WRITE)
}

export const tensiometerChart = (userAccess) => {
  return get(userAccess, 'tensiometer_chart', USER_ACCESS.WRITE)
}

export const extractorChart = (userAccess) => {
  return get(userAccess, 'extractor_chart', USER_ACCESS.WRITE)
}

export const phChart = (userAccess) => {
  return get(userAccess, 'ph_chart', USER_ACCESS.WRITE)
}
export const temperatureChart = (userAccess) => {
  return get(userAccess, 'temperature_chart', USER_ACCESS.WRITE)
}
export const humidityChart = (userAccess) => {
  return get(userAccess, 'humidity_chart', USER_ACCESS.WRITE)
}
export const evpChart = (userAccess) => {
  return get(userAccess, 'evp_chart', USER_ACCESS.WRITE)
}

export const adminClients = (userAccess) => {
  return get(userAccess, 'admin_clients', USER_ACCESS.WRITE)
}

export const adminUsers = (userAccess) => {
  return get(userAccess, 'admin_users', USER_ACCESS.WRITE)
}

export const adminFarms = (userAccess) => {
  return get(userAccess, 'admin_farms', USER_ACCESS.WRITE)
}

export const adminProjects = (userAccess) => {
  return get(userAccess, 'admin_projects', USER_ACCESS.WRITE)
}

export const adminSensorGroup = (userAccess) => {
  return get(userAccess, 'admin_sensor_group', USER_ACCESS.WRITE)
}

export const adminPluviometer = (userAccess) => {
  return get(userAccess, 'admin_pluviometer', USER_ACCESS.WRITE)
}

export const registers = (userAccess) => {
  if (
    readings(userAccess) === USER_ACCESS.NOACCESS &&
    irrigations(userAccess) === USER_ACCESS.NOACCESS &&
    pluviometry(userAccess) === USER_ACCESS.NOACCESS
  ) {
    return USER_ACCESS.NOACCESS
  }
  return USER_ACCESS.READ
}

export const charts = (userAccess) => {
  if (
    tensiometerChart(userAccess) === USER_ACCESS.NOACCESS &&
    extractorChart(userAccess) === USER_ACCESS.NOACCESS &&
    phChart(userAccess) === USER_ACCESS.NOACCESS &&
    humidityChart(userAccess) === USER_ACCESS.NOACCESS &&
    temperatureChart(userAccess) === USER_ACCESS.NOACCESS &&
    evpChart(userAccess) === USER_ACCESS.NOACCESS
  ) {
    return USER_ACCESS.NOACCESS
  }
  return USER_ACCESS.READ
}

export const administration = (userAccess) => {
  if (
    adminClients(userAccess) === USER_ACCESS.NOACCESS &&
    adminUsers(userAccess) === USER_ACCESS.NOACCESS &&
    adminFarms(userAccess) === USER_ACCESS.NOACCESS &&
    adminProjects(userAccess) === USER_ACCESS.NOACCESS &&
    adminSensorGroup(userAccess) === USER_ACCESS.NOACCESS &&
    adminPluviometer(userAccess) === USER_ACCESS.NOACCESS
  ) {
    return USER_ACCESS.NOACCESS
  }
  return USER_ACCESS.READ
}

export const validateRouteAccess = (userAccess, currentPath) => {
  switch (currentPath) {
    case ROUTES.CLIENTS:
      return adminClients(userAccess)
    case ROUTES.IRRIGATION:
      return irrigations(userAccess)
    case ROUTES.READINGS:
      return readings(userAccess)
    case ROUTES.FARM:
      return adminFarms(userAccess)
    case ROUTES.PROJECTS:
      return adminProjects(userAccess)
    case ROUTES.USERS:
      return adminUsers(userAccess)
    case ROUTES.SENSORGROUP:
      return adminSensorGroup(userAccess)
    case ROUTES.PLUVIOMETER:
      return adminPluviometer(userAccess)
    case ROUTES.TIPS:
      return tips(userAccess)
    case ROUTES.TENSIOMETERCHARTS:
      return tensiometerChart(userAccess)
    case ROUTES.EXTRACTORSCHARTS:
      return extractorChart(userAccess)
    case ROUTES.PHCHARTS:
      return phChart(userAccess)
    case ROUTES.TEMPERATURECHARTS:
      return temperatureChart(userAccess)
    case ROUTES.HUMIDITYCHARTS:
      return humidityChart(userAccess)
    case ROUTES.EVPCHARTS:
      return evpChart(userAccess)
    case ROUTES.PLUVIOMETRY:
      return pluviometry(userAccess)
    case ROUTES.DASHBOARD:
      return USER_ACCESS.WRITE
    case ROUTES.ACCOUNTSETTINGS:
      return USER_ACCESS.WRITE
    default:
      return USER_ACCESS.NOACCESS
  }
}
