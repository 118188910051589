import styled from 'styled-components'
import { styled as styledMaterialUI } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Typography from '@material-ui/core/Typography'

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const TypoHeader = styledMaterialUI(Typography)({
  fontSize: '20px',
  fontFamily: 'Montserrat',
  margin: '8px 8px 16px 8px'
})

export const Selector = styledMaterialUI(Autocomplete)({
  margin: 8,
  minWidth: 250
})
