import { styled as styledMaterialUI } from '@material-ui/core/styles'
import styled from 'styled-components'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

export const Container = styledMaterialUI(Dialog)({
  display: 'flex',
  flexDirection: 'column'
})

export const Content = styledMaterialUI(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  margin: '12px 0px 0px 0px',
  width: (props) => props.width || '100%'
})

export const Body = styled.div`
  width: ${(props) => props.width || '500px'};
  height: ${(props) => props.width || '650px'};
  overflow: auto;
`
