import React from 'react'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'

import { BouncingDots, StyledText } from './styles'
import { Column } from '../../../styles/components'

const WidgetsLoading = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Column align="center" justify="center">
      <StyledText isMobile={isMobile}>
        Carregando Widgets
        <BouncingDots />
      </StyledText>
    </Column>
  )
}

export default WidgetsLoading
