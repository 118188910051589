import { styled as styledMaterialUI } from '@material-ui/core/styles'
import styled from 'styled-components'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import Autocomplete from '@material-ui/lab/Autocomplete'

import NumberInput from '../../Components/NumberInput'

import Colors from '../../styles/colors'

export const NumericField = styled(NumberInput)`
  width: 90%;
  margin: '8px 0 8px 0';
`

export const StyledAutocomplete = styledMaterialUI(Autocomplete)({
  width: '100%',
  margin: '8px 0 12px 0'
})

export const CloseButton = styledMaterialUI(IconButton)({
  color: '#ffffff',
  cursor: 'pointer'
})

export const DescriptionField = styled(TextField)({
  width: '100%',
  margin: '8px 0 8px 0'
})

export const NewItemButton = styledMaterialUI(Button)({
  margin: 8,
  minWidth: 150,
  backgroundColor: Colors.AZUL_HYDRA
})

export const RemoveButton = styledMaterialUI(IconButton)({
  marginTop: 8,
  cursor: 'pointer',
  color: Colors.DANGER
})

export const StyledCard = styledMaterialUI(Card)({
  margin: 8
})
