import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  transition: padding ease-in 0.1s;
  border-radius: 8px;
  overflow: auto;

  :hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    padding: 25px;
    cursor: pointer;
  }
`

export const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-radius: 8px;
`
