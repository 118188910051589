import { styled as styledMaterialUI } from '@material-ui/core/styles'
import styled from 'styled-components'

import { KeyboardDatePicker } from '@material-ui/pickers'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

export const DateActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

export const DatePickerSearch = styledMaterialUI(KeyboardDatePicker)({
  width: '100%',
  margin: 8
})

export const ProjectsAutocomplete = styled(Autocomplete)`
  width: 100%;
  margin: 0 0 8px 0;
`

export const TipTextField = styledMaterialUI(TextField)({
  width: '90%'
})

export const TipReadingText = styledMaterialUI(Typography)({
  fontSize: 16,
  fontFamily: 'Montserrat',
  whiteSpace: 'pre-line',
  margin: '24px'
})

export const ReadingContainer = styledMaterialUI(Dialog)({
  display: 'flex',
  flexDirection: 'column'
})

export const ReadingContent = styledMaterialUI(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  margin: '12px 0px 0px 0px',
  width: '100%'
})

export const Body = styled.div`
  width: '30vw';
  overflow: auto;
`
