import React from 'react'
import PropTypes from 'prop-types'

import { DialogContainer, TypoHeader, HidrometerInput } from './styles'
import { validateHidrometerNumberOrZero } from '../../../../utils/validators'

const labelProps = { shrink: true }

const HidrometerRead = ({ value, onChange }) => {
  const hasErrors = validateHidrometerNumberOrZero(value)
  return (
    <DialogContainer>
      <TypoHeader>Hidrômetro:</TypoHeader>
      <HidrometerInput
        fullWidth
        label="Hidrômetro(m³)"
        variant="outlined"
        placeholder="Leitura do hidrômetro (m³)"
        value={value}
        onChange={onChange}
        InputLabelProps={labelProps}
        error={!!hasErrors}
        helperText={hasErrors}
      />
    </DialogContainer>
  )
}

HidrometerRead.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

HidrometerRead.defaultProps = {
  onChange: () => {}
}

export default HidrometerRead
