import React from 'react'
import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'

import { DialogContainer, TypoHeader } from './styles'

const style = { marginTop: 40 }
const labelProps = {
  shrink: true
}

const Comments = ({ value, onChange }) => {
  return (
    <DialogContainer>
      <TypoHeader>Observações sobre airrigação:</TypoHeader>
      <TextField
        fullWidth
        id="outlined-multiline-static"
        label="Observações"
        multiline
        rows={9}
        placeholder="Digite aqui observações a respeito dairrigação."
        variant="outlined"
        value={value}
        onChange={onChange}
        style={style}
        InputLabelProps={labelProps}
      />
    </DialogContainer>
  )
}

Comments.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

Comments.defaultProps = {
  onChange: () => {}
}

export default Comments
