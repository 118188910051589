import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { matchSorter } from 'match-sorter'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Slide from '@material-ui/core/Slide'
import Snackbar from '@material-ui/core/Snackbar'
import Dialog from '@material-ui/core/Dialog'

import Alert from '@material-ui/lab/Alert'

import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

import AppBar from '../../Components/Appbar'
import DataTable from '../../Components/DataTable'
import ProjectsService from '../../services/projects'
import SensorsService from '../../services/sensorgroup'

import {
  NumericField,
  NewItemButton,
  DescriptionField,
  RemoveButton,
  StyledCard,
  StyledAutocomplete
} from './styles'
import {
  Row,
  Container,
  Content,
  Input,
  Autocomplete,
  Header,
  OutlinedInput,
  Button,
  IconButton,
  TableWrapper,
  CustomLinearProgress,
  Text,
  TableLoadingCompWrapper
} from '../../styles/components'
import {
  validateTensiometer,
  validateDescription,
  validateTensiometerDepth
} from '../../utils/validators'
import {
  getTensionRegistrationTypeByValue,
  getYesOrNoFromBoolean
} from '../../utils/helpers'
import { adminSensorGroup } from '../../utils/pageAccess'
import {
  TENSION_READING_TYPE,
  TENSION_READING_VALUE,
  USER_ACCESS
} from '../../utils/constants'
import ModalComponent from '../../Components/ModalComponent'
import ConfirmationDialog from '../../Components/ConfirmationDialog'
import { TextField } from '@material-ui/core'
import HydraSwitch from '../../Components/GenericSwitch'
import CloseConfirmationDialog from '../../Components/CloseConfirmationDialog'

const textFieldInputProp = {
  shrink: true
}

const transitionDown = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const searchOptions = {
  keys: [
    'description',
    'project',
    'sectors',
    (item) => getYesOrNoFromBoolean(item.active)
  ],
  keepDiacritics: false
}

const SensorGroup = (props) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [id, setId] = useState(0)
  const [projectList, setProjectList] = useState([])
  const [sensorDescription, setSensorDescription] = useState('')
  const [sectorList, setSectorList] = useState([])
  const [currentProject, setCurrentProject] = useState(null)
  const [currentSector, setCurrentSector] = useState(null)
  const [active, setActive] = useState(true)
  const [tensiometerList, setTensiometerList] = useState([])
  const [extractorsList, setExtractorsList] = useState([])
  const [sensorList, setSensorList] = useState([])
  const [tensionReadingType, setTensionReadingType] = useState(
    TENSION_READING_VALUE.MANUAL
  )
  const [capsuleId, setCapsuleId] = useState('')
  const [activeEnviromentReading, setActiveEnviromentReadings] = useState(false)
  const [closeConfirmation, setCloseConfirmation] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const currentAccess = get(props, 'currentUser.userAccess', null)
  const shouldDisable = adminSensorGroup(currentAccess) === USER_ACCESS.READ
  const isAutoTensionReading =
    tensionReadingType === TENSION_READING_VALUE.CAPSULE

  const filteredList = searchText.length
    ? matchSorter(sensorList, searchText, searchOptions)
    : sensorList

  useEffect(async () => {
    try {
      setIsLoading(true)
      const projectList = await ProjectsService.getList(
        props.currentUser.token,
        props.currentClient.id
      )

      const sensorList = await SensorsService.getList(
        props.currentUser.token,
        props.currentClient.id
      )

      setProjectList(projectList)
      setSensorList(sensorList)
      setIsLoading(false)
    } catch (error) {
      setProjectList([])
      setSensorList([])
      setIsLoading(false)
    }
  }, [props.currentClient.id, currentProject])

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => setSuccessMessage(''), 2000)
    }
  }, [successMessage])

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => setErrorMessage(''), 2000)
    }
  }, [errorMessage])

  const _onSearch = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setSearchText(value)
  }, [])

  const _onChangeProject = useCallback((event, value) => {
    const newSectorList = get(value, 'sectors', [])
    setCurrentProject(value)
    setSectorList(newSectorList)
  }, [])

  const _onChangeSector = useCallback((event, value) => {
    setCurrentSector(value)
  }, [])

  const _onChangeTensionReadingType = useCallback((event, value) => {
    const readType = get(value, 'value', TENSION_READING_VALUE.MANUAL)
    setTensionReadingType(readType)
  }, [])

  const _handleConfirmationOpen = useCallback(() => {
    setOpenConfirmation(true)
    setModalOpen(false)
  }, [])

  const _handleConfirmationClose = useCallback(() => {
    setOpenConfirmation(false)
    setModalOpen(true)
  }, [])

  const _onAcceptCloseConfirmation = useCallback(() => {
    setCloseConfirmation(false)
  }, [])

  const _onRejectCloseConfirmation = useCallback(() => {
    setCloseConfirmation(false)
    setModalOpen(true)
  }, [])

  const _onOpenCloseConfirmation = useCallback(() => {
    setModalOpen(false)
    setCloseConfirmation(true)
  }, [])

  const _getColumns = useCallback(() => {
    return [
      {
        key: 'sensorDescription',
        label: 'Descrição',
        render_sensorDescription: (item) => item.description
      },
      {
        key: 'project',
        label: 'Projeto',
        render_project: (item) =>
          `${get(item, 'sector.project.description', '')} - ${get(
            item,
            'sector.project.farm.name',
            ''
          )}  `
      },
      {
        key: 'sector',
        label: 'Setor',
        render_sector: (item) => get(item, 'sector.description', '')
      },
      {
        key: 'tensiometers',
        label: 'Tensiômetro',
        render_tensiometers: (item) => item.tensiometers.length
      },
      {
        key: 'extractors',
        label: 'Extratores',
        render_extractors: (item) => item.extractors.length
      },
      {
        key: 'tensiometerType',
        label: 'Tensiômetro',
        render_tensiometerType: (item) =>
          item.tension_read_type ? 'Capsula' : 'Manual'
      },
      {
        key: 'active',
        label: 'Ativo',
        render_active: (item) => (item.active ? 'Sim' : 'Não')
      }
    ]
  }, [])

  const handleModalOpen = useCallback(() => {
    setId(0)
    setCurrentProject(null)
    setCurrentSector(null)
    setSensorDescription('')
    setTensiometerList([])
    setExtractorsList([])
    setActive(true)
    setCapsuleId('')
    setTensionReadingType(TENSION_READING_VALUE.MANUAL)
    setModalOpen(true)
  }, [])

  const handleModalClose = useCallback(() => {
    setModalOpen(false)
  }, [])

  const _onChangeDescription = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setSensorDescription(value)
  }, [])

  const _onChangeCapsuleId = useCallback((event) => {
    const value = event ? event.target.value : ''
    setCapsuleId(value)
  }, [])

  const _onChangeActive = useCallback((event, isChecked) => {
    setActive(isChecked)
  }, [])

  const _onChangeActiveEnvReading = useCallback((event, isChecked) => {
    setActiveEnviromentReadings(isChecked)
  }, [])

  const addTensiometer = useCallback(() => {
    const newItem = {
      idTemp: Date.now(),
      depth: ''
    }
    const newList = [...tensiometerList, newItem]
    setTensiometerList(newList)
  }, [tensiometerList])

  const removeTensiometer = useCallback(
    (index) => {
      const newList = [...tensiometerList]
      if (newList[index]) {
        newList.splice(index, 1)
        setTensiometerList(newList)
      }
    },
    [tensiometerList]
  )

  const addExtrator = useCallback(() => {
    const newItem = {
      idTemp: Date.now(),
      depth: ''
    }
    const newList = [...extractorsList, newItem]
    setExtractorsList(newList)
  }, [extractorsList])

  const removeExtrator = useCallback(
    (index) => {
      const newList = [...extractorsList]
      if (newList[index]) {
        newList.splice(index, 1)
        setExtractorsList(newList)
      }
    },
    [extractorsList]
  )

  const onChangeTensiometerFields = useCallback(
    (tensiometerIndex, field, event) => {
      const newList = [...tensiometerList]
      const newItem = { ...newList[tensiometerIndex] }
      const value = get(event, 'target.value', '')
      if (newItem) {
        newItem[field] = value
        newList[tensiometerIndex] = newItem
        setTensiometerList(newList)
      }
    },
    [tensiometerList]
  )
  const onChangeExtractorsFields = useCallback(
    (extractorsIndex, field, event) => {
      const newList = [...extractorsList]
      const newItem = { ...newList[extractorsIndex] }
      const value = get(event, 'target.value', '')
      if (newItem) {
        newItem[field] = value
        newList[extractorsIndex] = newItem
        setExtractorsList(newList)
      }
    },
    [extractorsList]
  )

  const tensiometers = (tensiometer, tensiometerIndex) => {
    const tensiometerDepthHasError = validateTensiometerDepth(
      tensiometer.depth,
      tensiometerList
    )
    return (
      <Row>
        <NumericField
          label="profundidade(cm)"
          variant="outlined"
          value={tensiometer.depth}
          error={!!tensiometerDepthHasError}
          helperText={tensiometerDepthHasError}
          disabled={shouldDisable}
          onChange={(event) =>
            onChangeTensiometerFields(tensiometerIndex, 'depth', event)
          }
          InputLabelProps={textFieldInputProp}
        />
        <RemoveButton
          disabled={shouldDisable}
          onClick={() => removeTensiometer(tensiometerIndex)}
        >
          <DeleteForeverIcon />
        </RemoveButton>
      </Row>
    )
  }

  const extractors = (extractors, extractorsIndex) => {
    return (
      <Row>
        <NumericField
          label="profundidade(cm)"
          variant="outlined"
          value={extractors.depth}
          disabled={shouldDisable}
          onChange={(event) =>
            onChangeExtractorsFields(extractorsIndex, 'depth', event)
          }
          InputLabelProps={textFieldInputProp}
        />
        <RemoveButton
          disabled={shouldDisable}
          onClick={() => removeExtrator(extractorsIndex)}
        >
          <DeleteForeverIcon />
        </RemoveButton>
      </Row>
    )
  }

  const _handleSave = useCallback(async () => {
    try {
      setIsLoading(true)

      const payload = {
        id,
        sector_id: currentSector.id,
        description: sensorDescription,
        active,
        capsule_id: capsuleId,
        tension_read_type: tensionReadingType,
        active_enviroment_read: activeEnviromentReading,
        tensiometers: tensiometerList,
        extractors: extractorsList,
        sector: { ...currentSector, project: currentProject }
      }
      // montei esse sector só para que o app consiga refletir corretamente a farm e o sector
      // na hora do save
      const newList = [...sensorList]
      const authToken = props.currentUser.token

      if (id) {
        const updatedSensor = await SensorsService.updateSensorGroup(
          authToken,
          payload
        )
        const sensorIndex = sensorList.findIndex((item) => item.id === id)

        const newItem = {
          ...sensorList[sensorIndex],
          description: sensorDescription,
          active,
          capsule_id: capsuleId,
          tension_read_type: tensionReadingType,
          tensiometers: updatedSensor.tensiometers,
          extractors: updatedSensor.extractors,
          active_enviroment_read: activeEnviromentReading
        }

        if (sensorIndex > -1) {
          newList[sensorIndex] = newItem
        }

        setSuccessMessage('Informações do sensor atualizadas com sucesso')
      } else {
        const newSensor = await SensorsService.insertSensorGroup(
          authToken,
          payload
        )
        newList.unshift({ ...payload, id: newSensor.id })

        setSuccessMessage('Informações do sensor guardadas com sucesso')
      }
      setSensorList(newList)
      setOpenConfirmation(false)
      setIsLoading(false)
    } catch (error) {
      setErrorMessage(error.message)
      setOpenConfirmation(false)
      setIsLoading(false)
    }
  }, [
    id,
    sensorList,
    currentSector,
    currentProject,
    active,
    sensorDescription,
    tensiometerList,
    extractorsList,
    capsuleId,
    tensionReadingType,
    activeEnviromentReading
  ])

  const _closeConfirmationDialog = () => {
    return (
      <Dialog
        open={closeConfirmation}
        TransitionComponent={transitionDown}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CloseConfirmationDialog
          onClickCancel={_onRejectCloseConfirmation}
          onClickConfirm={_onAcceptCloseConfirmation}
        />
      </Dialog>
    )
  }

  const onClickItem = useCallback((item) => {
    setId(item.id)
    setCurrentProject(item.sector.project)
    setCurrentSector(item.sector)
    setSensorDescription(item.description)
    setTensiometerList(item.tensiometers)
    setExtractorsList(item.extractors)
    setActive(!!item.active)
    setCapsuleId(item.capsule_id)
    setTensionReadingType(item.tension_read_type)
    setActiveEnviromentReadings(!!item.active_enviroment_read)
    setModalOpen(true)
  }, [])

  const sensorsModal = () => {
    const currentType = getTensionRegistrationTypeByValue(tensionReadingType)
    const tensiometerHasError = validateTensiometer(tensiometerList)
    const projectHasError = !get(currentProject, 'id', 0)
      ? 'Projeto não selecionado'
      : ''
    const sectorHasError = !get(currentSector, 'id', 0)
      ? 'Projeto não selecionado'
      : ''
    const capsuleIdHasError = !capsuleId
      ? 'O campo deve conter mais de 2 caracteres'
      : ''
    const descriptionHasError = validateDescription(sensorDescription)
    const shouldValidateCapsuleId = isAutoTensionReading
      ? capsuleIdHasError
      : ''

    const hasError = !!(
      shouldValidateCapsuleId ||
      descriptionHasError ||
      projectHasError ||
      sectorHasError ||
      shouldDisable ||
      tensiometerHasError
    )
    return (
      <ModalComponent
        title="Detalhes das Baterias"
        buttonLabel="Salvar"
        onClickClose={handleModalClose}
        onClickButton={_handleConfirmationOpen}
        disabled={hasError}
        open={modalOpen}
        onClose={_onOpenCloseConfirmation}
      >
        <StyledAutocomplete
          required
          disableClearable
          value={currentProject}
          onChange={_onChangeProject}
          options={projectList}
          disabled={!!id}
          getOptionLabel={(option) =>
            `${get(option, 'description', '')} - ${get(
              option,
              'farm.name',
              ''
            )}`
          }
          getOptionSelected={(option, v) =>
            option.description === v.description
          }
          noOptionsText="Sem Projetos para exibir"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Projetos"
              variant="outlined"
              error={!!projectHasError}
              helperText={projectHasError}
              placeholder="Selecione um projeto"
              InputLabelProps={textFieldInputProp}
            />
          )}
        />
        <StyledAutocomplete
          required
          disableClearable
          value={currentSector}
          onChange={_onChangeSector}
          options={sectorList}
          getOptionLabel={(option) => get(option, 'description', '')}
          getOptionSelected={(option, v) =>
            option.description === v.description
          }
          getOptionDisabled={(option, index) =>
            get(option, 'sensorGroup.active', false) === true
          }
          noOptionsText="Sem setores para exibir"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Setores"
              variant="outlined"
              error={!!sectorHasError}
              helperText={sectorHasError}
              placeholder="Selecione um setor"
              InputLabelProps={textFieldInputProp}
            />
          )}
        />
        <DescriptionField
          variant="outlined"
          value={sensorDescription}
          error={!!descriptionHasError}
          helperText={descriptionHasError}
          onChange={_onChangeDescription}
          label="Descrição"
          InputLabelProps={textFieldInputProp}
          disabled={shouldDisable}
        />

        <Autocomplete
          width="100%"
          margin="8px 0 8px 0"
          required
          disableClearable
          value={currentType}
          onChange={_onChangeTensionReadingType}
          options={TENSION_READING_TYPE}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, v) => option.value === v.value}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Registro de leituras de tensão"
              variant="outlined"
              placeholder="Modo de leitura de tensão"
              InputLabelProps={textFieldInputProp}
            />
          )}
        />

        {isAutoTensionReading ? (
          <>
            <Input
              width="100%"
              margin="8px 0 8px 0"
              variant="outlined"
              value={capsuleId}
              error={!!capsuleIdHasError}
              helperText={capsuleIdHasError}
              onChange={_onChangeCapsuleId}
              label="Id da Capsula"
              placeholder="Digite a id da capsula"
              InputLabelProps={textFieldInputProp}
              disabled={shouldDisable}
            />
            <FormControlLabel
              label="Leituras de Ambiente"
              labelPlacement="start"
              control={
                <HydraSwitch
                  checked={activeEnviromentReading}
                  onChange={_onChangeActiveEnvReading}
                />
              }
            />
          </>
        ) : null}
        <FormControlLabel
          label="Ativo"
          labelPlacement="start"
          control={
            <HydraSwitch
              disabled={shouldDisable}
              checked={active}
              onChange={_onChangeActive}
            />
          }
        />

        <StyledCard>
          <NewItemButton
            variant="contained"
            color="primary"
            disabled={shouldDisable}
            onClick={addTensiometer}
            startIcon={<AddIcon />}
          >
            Tensiômetro
          </NewItemButton>
          <List>
            {tensiometerList.map((tensiometer, i) => {
              return <ListItem key={i}>{tensiometers(tensiometer, i)}</ListItem>
            })}
          </List>
        </StyledCard>

        <StyledCard>
          <NewItemButton
            variant="contained"
            color="primary"
            disabled={shouldDisable}
            onClick={addExtrator}
            startIcon={<AddIcon />}
          >
            Extrator
          </NewItemButton>
          <List>
            {extractorsList.map((extractor, i) => {
              return <ListItem key={i}>{extractors(extractor, i)}</ListItem>
            })}
          </List>
        </StyledCard>
      </ModalComponent>
    )
  }

  const _renderConfirmation = () => {
    return (
      <Dialog
        open={openConfirmation}
        onClose={_handleConfirmationClose}
        TransitionComponent={transitionDown}
        aria-labeledby="alert-dialog-title"
        aria-disbredby="alert-dialog-description"
      >
        <ConfirmationDialog
          onClickCancel={_handleConfirmationClose}
          onClickConfirm={_handleSave}
        />
      </Dialog>
    )
  }

  const _renderAlert = () => {
    const alertMessage = successMessage || errorMessage
    const alertColor = successMessage ? 'success' : 'error'
    return (
      <Snackbar open={!!alertMessage} key={alertColor}>
        <Alert severity={alertColor}>{alertMessage}</Alert>
      </Snackbar>
    )
  }

  return (
    <Container>
      <AppBar history={props.history} />
      <Content>
        <Header
          display="flex"
          flexDirection="column"
          width="100%"
          height="10%"
          padding="3em 0 10em 0"
        >
          <Row justify="center" align="center">
            {isMobile ? (
              <IconButton
                color="primary"
                aria-label="add"
                disabled={shouldDisable}
                onClick={handleModalOpen}
              >
                <AddIcon />
              </IconButton>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={shouldDisable}
                startIcon={<AddIcon />}
                onClick={handleModalOpen}
              >
                Adicionar
              </Button>
            )}
            <OutlinedInput
              isMobile={isMobile}
              width="25%"
              mobileWidth="60%"
              id="outlined-adornment-search"
              label="Pesquisar"
              value={searchText}
              onChange={_onSearch}
              placeholder="Pesquisar"
              type="search"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              labelWidth={60}
            />
          </Row>
        </Header>

        {isLoading ? (
          <TableLoadingCompWrapper>
            <Text margin="12px" textColor="black">
              Carregando lista de baterias...
            </Text>
            <CustomLinearProgress />
          </TableLoadingCompWrapper>
        ) : (
          <TableWrapper>
            <DataTable
              columns={_getColumns()}
              data={filteredList}
              mobileLabel={(item) => item.description}
              mobileSubtitle={(item) =>
                get(item, 'sector.project.farm.name', '')
              }
              onClickItem={onClickItem}
            />
          </TableWrapper>
        )}
      </Content>
      {sensorsModal()}
      {_renderAlert()}
      {_renderConfirmation()}
      {_closeConfirmationDialog()}
    </Container>
  )
}

SensorGroup.propTypes = {
  history: PropTypes.object,
  currentClient: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired
  }),
  getProjects: PropTypes.func
}

SensorGroup.defaultProps = {
  getProjects: () => {}
}

const mapState = ({ auth, clients }) => {
  return {
    currentUser: auth.currentUser,
    currentClient: clients.currentClient
  }
}

export default connect(mapState)(SensorGroup)
