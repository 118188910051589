import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import {
  DialogContainer,
  TypoHeader,
  Extractor,
  NoContentTypo,
  CustomFormControl
} from './styles'
import { validateExtractorsReading } from '../../../../utils/validators'
import { HydraSwitch } from '../../../../styles/components'

const labelProps = { shrink: true }

const ExtractorReadings = ({
  extractors,
  onChange,
  onChangeActive,
  isActive,
  isMobile
}) => {
  const _onChangeActive = useCallback((event, isChecked) => {
    onChangeActive(isChecked)
  }, [])

  return (
    <DialogContainer>
      {!extractors.length ? (
        <NoContentTypo>
          A bateria não possui extratores registrados.
        </NoContentTypo>
      ) : (
        <>
          <TypoHeader> Ative e Insira a leitura dos Extratores:</TypoHeader>
          <CustomFormControl
            isMobile={isMobile}
            control={
              <HydraSwitch checked={isActive} onChange={_onChangeActive} />
            }
          />
          {isActive ? (
            <>
              {extractors.map((e, index) => {
                const id = get(e, 'extractor.id', 0)
                const depth = get(e, 'extractor.depth', 0)
                const label = `${depth} cm`
                const hasErrors = validateExtractorsReading(e.value)

                return (
                  <Extractor
                    key={id}
                    fullwidth="true"
                    variant="outlined"
                    label={label}
                    placeholder="leitura (μS/cm)"
                    value={e.value}
                    onChange={(event) => onChange(event, index)}
                    error={!!hasErrors}
                    helperText={hasErrors}
                    InputLabelProps={labelProps}
                  />
                )
              })}
            </>
          ) : null}
        </>
      )}
    </DialogContainer>
  )
}

ExtractorReadings.propTypes = {
  extractors: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeActive: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired
}

ExtractorReadings.defaultProps = {
  onChange: () => {},
  onChangeActive: () => {}
}

export default ExtractorReadings
