import CryptoJS from 'crypto-js'

const saveJson = (key, data, mustEncrypt = true) => {
  const item = JSON.stringify(data)
  if (mustEncrypt) {
    const cipherData = CryptoJS.AES.encrypt(
      item,
      process.env.REACT_APP_ENCRYPT_KEY
    ).toString()
    return localStorage.setItem(key, cipherData)
  }
  return localStorage.setItem(key, item)
}

const getJson = (key, mustDecrypt = true) => {
  try {
    const str = localStorage.getItem(key)
    if (mustDecrypt) {
      const bytes = CryptoJS.AES.decrypt(str, process.env.REACT_APP_ENCRYPT_KEY)
      const item = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))

      return item
    }
    const result = JSON.parse(str)
    return result
  } catch (error) {
    return null
  }
}

const deleteItem = (key) => {
  localStorage.removeItem(key)
}

const KEYS = {
  CURRENT_USER: 'currentUser',
  WIDGET_POSITIONS: `widgetPositions`
}

export default {
  saveJson,
  getJson,
  deleteItem,
  KEYS
}
