import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import ReactInputMask from 'react-input-mask'

import TextField from '@material-ui/core/TextField'

import usePrevious from '../../hooks/usePrevious'

import {
  getMinutesFromDurationText,
  getTextFromDurationMinutes
} from '../../utils/helpers'

const timeMask = { mask: '99:99' }

const DurationInput = ({ duration, onChangeDuration, ...otherProps }) => {
  const prevDuration = usePrevious(duration)
  const [durationState, setDurationState] = useState(
    duration ? getTextFromDurationMinutes(duration) : ''
  )

  const _onChangeDuration = useCallback((event) => {
    const value = get(event, 'target.value', '')
    const minutes = getMinutesFromDurationText(value)
    setDurationState(value)
    onChangeDuration(minutes || 0)
  }, [])

  useEffect(() => {
    if (prevDuration && !duration) {
      setDurationState('')
    }

    if (!prevDuration && duration) {
      setDurationState(getTextFromDurationMinutes(duration))
    }
  }, [prevDuration, duration])

  return (
    <TextField
      {...otherProps}
      value={durationState}
      type="phone"
      onChange={_onChangeDuration}
      InputLabelProps={{
        shrink: true
      }}
      onFocus={(event) => event.target.select()}
      InputProps={{
        inputComponent: ReactInputMask
      }}
      inputProps={timeMask}
    />
  )
}

DurationInput.propTypes = {
  duration: PropTypes.number,
  onChangeDuration: PropTypes.func.isRequired
}

DurationInput.defaultProps = {
  onChangeDuration: () => {}
}

export default DurationInput
