import React from 'react'
import PropTypes from 'prop-types'

import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Zoom from '@material-ui/core/Zoom'

import { Body, Content, Container } from './styles'

const transitionZoom = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />
})

const HelpDialog = ({ onClickClose, text, open }) => {
  return (
    <Container
      open={open}
      onClose={onClickClose}
      TransitionComponent={transitionZoom}
    >
      <Content>
        <Body>{text}</Body>
        <DialogActions>
          <Button onClick={onClickClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Content>
    </Container>
  )
}

HelpDialog.propTypes = {
  onClickClose: PropTypes.func,
  open: PropTypes.bool,
  text: PropTypes.string
}

HelpDialog.defaultprops = {
  onClickClose: () => {}
}

export default HelpDialog
