import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

import DateFnsUtils from '@date-io/date-fns'
import ptBR from 'date-fns/locale/pt-BR'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import { Text, Body, DateTimePicker } from '../../../../styles/components'

const IrrigatoionTimers = ({ timestamp, onChangeTime }) => {
  const date = dayjs(timestamp).toDate()

  return (
    <Body display="flex" flexDirection="column" align="flex-start">
      <Text fontSize="20px" textColor="black" margin="8px">
        Data e hora:
      </Text>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <DateTimePicker
          fullWidth
          inputMode="numeric"
          label="Data e hora da irrigação"
          value={date}
          onChange={onChangeTime}
          format={'dd/MM/yyyy, HH:mm'}
          cancelLabel="Cancelar"
          okLabel="Confirmar"
          ampm={false}
          inputVariant="outlined"
        />
      </MuiPickersUtilsProvider>
    </Body>
  )
}

IrrigatoionTimers.propTypes = {
  timestamp: PropTypes.number,
  onChangeTime: PropTypes.func.isRequired
}

IrrigatoionTimers.defaultProps = {
  onChangeTime: () => {}
}

export default IrrigatoionTimers
