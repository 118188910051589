import { combineReducers } from 'redux'
import auth from './auth'
import clients from './clients'
import notifications from './notifications'
import widgets from './widgets'

export default combineReducers({
  auth,
  clients,
  notifications,
  widgets
})
