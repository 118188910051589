import React, { Fragment, useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import getValue from 'lodash/get'

import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Collapse from '@material-ui/core/Collapse'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'

import { makeStyles } from '@material-ui/core/styles'

import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'

import MenuIcon from '@material-ui/icons/Menu'
import ArchiveIcon from '@material-ui/icons/Archive'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import AssignmentIcon from '@material-ui/icons/Assignment'
import BarChartIcon from '@material-ui/icons/BarChart'
import WifiTetheringIcon from '@material-ui/icons/WifiTethering'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import InsertCommentIcon from '@material-ui/icons/InsertComment'
import PlaceIcon from '@material-ui/icons/Place'
import RateReviewIcon from '@material-ui/icons/RateReview'
import CardTravelIcon from '@material-ui/icons/CardTravel'
import OpacityIcon from '@material-ui/icons/Opacity'
import SettingsIcon from '@material-ui/icons/Settings'
import FilterDramaIcon from '@material-ui/icons/FilterDrama'

import { setClient } from '../store/reducers/clients'
import { getNotifications } from '../store/reducers/notifications'

import LogoHydraConnect from '../assets/images/logo-connect-branca.png'
import {
  Container,
  LogoContainer,
  ButtonsContainer,
  HydraAppBar
} from './styles'
import { navigateToPage } from '../routes/navigate'
import { USER_ACCESS, USER_ROLES } from '../utils/constants'
import Routes from '../routes/routes'
import {
  readings,
  irrigations,
  tips,
  tensiometerChart,
  extractorChart,
  phChart,
  adminClients,
  adminUsers,
  adminFarms,
  adminProjects,
  adminSensorGroup,
  adminPluviometer,
  registers,
  administration,
  charts,
  temperatureChart,
  humidityChart,
  evpChart,
  pluviometry
} from '../utils/pageAccess'
import Colors from '../styles/colors'
import NotificationBell from './NotificationBell'

const DRAWER_POSITION = 'left'

const MENU_ITEMS_LIST = [
  {
    isExpanded: false,
    label: 'Registros',
    icon: <ArchiveIcon />,
    allowAccess: (userAccess) => registers(userAccess),
    options: [
      {
        label: 'Leituras',
        icon: <AssignmentIcon />,
        route: Routes.READINGS,
        allowAccess: (userAccess) =>
          readings(userAccess) !== USER_ACCESS.NOACCESS
      },
      {
        label: 'Irrigações',
        icon: <OpacityIcon />,
        route: Routes.IRRIGATION,
        allowAccess: (userAccess) =>
          irrigations(userAccess) !== USER_ACCESS.NOACCESS
      },
      {
        label: 'Pluviometria',
        icon: <AssignmentIcon />,
        route: Routes.PLUVIOMETRY,
        allowAccess: (userAccess) =>
          pluviometry(userAccess) !== USER_ACCESS.NOACCESS
      }
    ]
  },
  {
    isExpanded: false,
    label: 'Gráficos',
    icon: <BarChartIcon />,
    allowAccess: (userAccess) => charts(userAccess),
    options: [
      {
        label: 'Tensiômetro',
        icon: <BarChartIcon />,
        route: Routes.TENSIOMETERCHARTS,
        allowAccess: (userAccess) =>
          tensiometerChart(userAccess) !== USER_ACCESS.NOACCESS
      },
      {
        label: 'EC',
        icon: <BarChartIcon />,
        route: Routes.EXTRACTORSCHARTS,
        allowAccess: (userAccess) =>
          extractorChart(userAccess) !== USER_ACCESS.NOACCESS
      },
      {
        label: 'pH',
        icon: <BarChartIcon />,
        route: Routes.PHCHARTS,
        allowAccess: (userAccess) =>
          phChart(userAccess) !== USER_ACCESS.NOACCESS
      },
      {
        label: 'Temperatura',
        icon: <BarChartIcon />,
        route: Routes.TEMPERATURECHARTS,
        allowAccess: (userAccess) =>
          temperatureChart(userAccess) !== USER_ACCESS.NOACCESS
      },
      {
        label: 'Humidade',
        icon: <BarChartIcon />,
        route: Routes.HUMIDITYCHARTS,
        allowAccess: (userAccess) =>
          humidityChart(userAccess) !== USER_ACCESS.NOACCESS
      },
      {
        label: 'Evapotranspiração',
        icon: <BarChartIcon />,
        route: Routes.EVPCHARTS,
        allowAccess: (userAccess) =>
          evpChart(userAccess) !== USER_ACCESS.NOACCESS
      }
    ]
  },
  {
    isExpanded: false,
    label: 'Dicas do agrônomo',
    icon: <InsertCommentIcon />,
    options: [],
    route: Routes.TIPS,
    allowAccess: (userAccess) => tips(userAccess) !== USER_ACCESS.NOACCESS
  },
  {
    isExpanded: false,
    label: 'Administração',
    icon: <CardTravelIcon />,
    allowAccess: (userAccess) =>
      administration(userAccess) !== USER_ACCESS.NOACCESS,
    options: [
      {
        label: 'Clientes',
        icon: <PersonAddIcon />,
        route: Routes.CLIENTS,
        allowAccess: (userAccess) =>
          adminClients(userAccess) !== USER_ACCESS.NOACCESS
      },
      {
        label: 'Usuários',
        icon: <GroupAddIcon />,
        route: Routes.USERS,
        allowAccess: (userAccess) =>
          adminUsers(userAccess) !== USER_ACCESS.NOACCESS
      },
      {
        label: 'Fazendas',
        icon: <PlaceIcon />,
        route: Routes.FARM,
        allowAccess: (userAccess) =>
          adminFarms(userAccess) !== USER_ACCESS.NOACCESS
      },
      {
        label: 'Projetos',
        icon: <RateReviewIcon />,
        route: Routes.PROJECTS,
        allowAccess: (userAccess) =>
          adminProjects(userAccess) !== USER_ACCESS.NOACCESS
      },
      {
        label: 'Baterias',
        icon: <WifiTetheringIcon />,
        route: Routes.SENSORGROUP,
        allowAccess: (userAccess) =>
          adminSensorGroup(userAccess) !== USER_ACCESS.NOACCESS
      },
      {
        label: 'Pluviometro',
        icon: <FilterDramaIcon />,
        route: Routes.PLUVIOMETER,
        allowAccess: (userAccess) =>
          adminPluviometer(userAccess) !== USER_ACCESS.NOACCESS
      }
    ]
  }
]

const useStyles = makeStyles((theme) => ({
  paper: {
    mobile: { width: '90%' },
    desktop: { width: '40%' }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33,33%',
    flexShrink: 0
  },
  list: {
    marginLeft: 10
  },
  nested: {
    marginLeft: '20px'
  },
  clientsSelector: {
    padding: '0 10px 0 10px'
  }
}))

const AppBar = (props) => {
  const classes = useStyles()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [menuList, setMenuList] = useState(MENU_ITEMS_LIST)
  const userName = getValue(props, 'currentUser.name', 'Sem Nome')
  const userAccess = getValue(props, 'currentUser.userAccess', null)
  const userRole = getValue(props, 'currentUser.role', null)
  const shouldRenderClientsAutoComplete =
    userRole === USER_ROLES.ADMIN || userRole === USER_ROLES.SUPERVISOR

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    props.getNotifications()
  }, [props.currentClient])

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setIsDrawerOpen(open)
  }

  const _onClickLogo = useCallback(() => {
    navigateToPage(props.history, Routes.DASHBOARD)
  }, [props.history])

  const _onclickMenuItem = (itemIndex, route = null) => {
    let newMenuList = [...menuList]
    if (!route) {
      newMenuList = menuList.map((item, i) => {
        const newItem = { ...item }
        if (itemIndex === i) {
          newItem.isExpanded = !item.isExpanded
        } else {
          newItem.isExpanded = false
        }

        return newItem
      })
      setMenuList(newMenuList)
    } else {
      navigateToPage(props.history, route)
    }
  }

  const _onClickSettings = useCallback(() => {
    navigateToPage(props.history, Routes.ACCOUNTSETTINGS)
  }, [])

  const _onSelectClient = useCallback(
    (event, value) => {
      props.setClient(value)
    },
    [props.setClient]
  )
  const _drawerMenu = () => (
    <div
      className={classes.paper.mobile}
      role="presentation"
      // onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {menuList.map((menuItem, itemIndex) => (
          <Fragment key={menuItem.label}>
            {menuItem.allowAccess(userAccess) ? (
              <ListItem
                button
                onClick={() => _onclickMenuItem(itemIndex, menuItem.route)}
              >
                <ListItemIcon>{menuItem.icon}</ListItemIcon>
                <ListItemText primary={menuItem.label} />
                {menuItem.options.length ? (
                  menuItem.isExpanded ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : null}
              </ListItem>
            ) : null}
            <Collapse in={menuItem.isExpanded} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className={classes.nested}>
                {menuItem.options.map((op) =>
                  op.allowAccess(userAccess) ? (
                    <ListItem
                      key={op.label}
                      button
                      onClick={() => _onclickMenuItem(itemIndex, op.route)}
                    >
                      <ListItemIcon>{op.icon}</ListItemIcon>
                      <ListItemText primary={op.label} />
                    </ListItem>
                  ) : null
                )}
              </List>
            </Collapse>
          </Fragment>
        ))}
      </List>
    </div>
  )

  const _clientsSelector = () => (
    <Autocomplete
      fullWidth
      disableClearable
      className={classes.clientsSelector}
      options={props.clientsList}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option) =>
        option.id === getValue(props, 'currentClient.id', null)
      }
      value={props.currentClient}
      onChange={_onSelectClient}
      disabled={props.loadingClients || !props.clientsList.length}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Cliente"
          required
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {props.loadingClients ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  )

  return (
    <Container>
      <HydraAppBar background={Colors.AZUL_HYDRA}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <div>
              <MenuIcon onClick={toggleDrawer(true)} />
              <Drawer
                anchor={DRAWER_POSITION}
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
              >
                {_drawerMenu()}
                {shouldRenderClientsAutoComplete ? _clientsSelector() : null}
              </Drawer>
            </div>
          </IconButton>
          <LogoContainer
            className="Logo"
            src={LogoHydraConnect}
            alt="Hydra Connect"
            onClick={_onClickLogo}
          />
          <ButtonsContainer>
            <NotificationBell />
            {isMobile ? null : <div>Olá, {userName}</div>}
            <IconButton
              color="inherit"
              aria-label="more"
              aria-controls="long-menu"
              aria-hasPopup="true"
              onClick={_onClickSettings}
            >
              <SettingsIcon />
            </IconButton>
          </ButtonsContainer>
        </Toolbar>
      </HydraAppBar>
    </Container>
  )
}

AppBar.propTypes = {
  history: PropTypes.object,
  clientsList: PropTypes.array,
  currentClient: PropTypes.object,
  loadingClients: PropTypes.bool,
  setClient: PropTypes.func.isRequired,
  getNotifications: PropTypes.func
}

AppBar.defaultProps = {
  setClient: () => {},
  getNotifications: () => {}
}

const mapState = ({ auth, clients }) => {
  return {
    currentUser: auth.currentUser,
    currentClient: clients.currentClient,
    clientsList: clients.list,
    loadingClients: clients.loading
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setClient,
      getNotifications
    },
    dispatch
  )

export default connect(mapState, mapDispatchToProps)(AppBar)
