import axios from 'axios'
import getValue from 'lodash/get'
import logError from '../utils/log'

const TIP = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/tip`
})

const getList = async (authToken, clientId, startDate, endDate) => {
  try {
    const response = await TIP.get(
      `/?client_id=${clientId}&startDate=${startDate}&endDate=${endDate}`,
      {
        headers: { Authorization: `bearer ${authToken}` }
      }
    )

    return response.data
  } catch (error) {
    logError('services@tip@getList', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao buscar a lista de dicas'
      )
    )
  }
}

const getTip = async (authToken, id) => {
  try {
    const response = await TIP.get(`/${id}`, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@tip@getTip', error)
    throw Error(
      getValue(error, 'response.data.error', 'Ocorreu um erro ao buscar a dica')
    )
  }
}

const insertTip = async (authToken, tip) => {
  try {
    const response = await TIP.post(`/`, tip, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@tip@insertTip', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao inserir uma dica'
      )
    )
  }
}

const updateTip = async (authToken, tip) => {
  try {
    const response = await TIP.put(`/`, tip, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@tip@updateTip', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao atualuizar a dica'
      )
    )
  }
}

export default { getList, getTip, insertTip, updateTip }
