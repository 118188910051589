import { styled as styledMaterialUI } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import CheckIcon from '@material-ui/icons/Check'

import Colors from '../../styles/colors'

export const NotificationDate = styledMaterialUI(Typography)({
  fontFamily: 'Montserrat',
  color: Colors.LIGHT_GREY_TEXT,
  fontSize: 12
})

export const NotificationTitle = styledMaterialUI(Typography)({
  fontFamily: 'Montserrat',
  fontSize: 16,
  fontWeight: 1000,
  fontStyle: 'bold'
})

export const NotificationText = styledMaterialUI(Typography)({
  display: 'flex',
  flexWrap: 'wrap',
  fontFamily: 'Montserrat',
  margin: '12px',
  whiteSpace: 'pre-line',
  textJustify: 'auton'
})

export const NewNotificationIcon = styledMaterialUI(NewReleasesIcon)({
  color: Colors.WARNING,
  margin: '0 4px 0 0'
})

export const ReadNotificationIcon = styledMaterialUI(CheckIcon)({
  color: Colors.SUCCESS,
  margin: '0 4px 0 0'
})
