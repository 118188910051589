import styled from 'styled-components'
import { styled as styledMaterialUI } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { KeyboardDatePicker } from '@material-ui/pickers'

export const DateActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 80px;
  width: 100%;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

export const ModalContent = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 8px;
  overflow: auto;
  width: 30vw;
`
export const StepperDialog = styled.div`
  display: flex;
  flex-direction: column;
`

export const DialogBox = styledMaterialUI(DialogContent)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
})

export const ModalTitle = styledMaterialUI(Typography)({
  fontSize: '20px',
  fontFamily: 'Montserrat',
  fontWeight: 700,
  fontStyle: 'bold',
  color: '#ffffff',
  marginRight: '16px',
  marginLeft: '24px',
  textTransform: 'uppercase'
})

export const StepperButton = styledMaterialUI(Button)({
  marginRight: '10px'
})

export const StepperDivider = styledMaterialUI(Divider)({
  Color: '#000000'
})

export const DatePickerSearch = styledMaterialUI(KeyboardDatePicker)({
  width: '100%',
  margin: 8
})

export const HelpText = styledMaterialUI(Typography)({
  fontSize: '18px',
  fontFamily: 'Montserrat'
})
