import styled from 'styled-components'
import { styled as styledMaterialUI } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
export const Body = styled.div`
  margin: 32px 16px 8px 16px;
`
export const Container = styledMaterialUI(Dialog)({
  width: '99%'
})
