import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { matchSorter } from 'match-sorter'

import ProjectsService from '../../services/projects'
import PluviometerService from '../../services/pluviometer'

import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'
import TextField from '@material-ui/core/TextField'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'

import Snackbar from '@material-ui/core/Snackbar'
import InputAdornment from '@material-ui/core/InputAdornment'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import Alert from '@material-ui/lab/Alert'

import dayjs from 'dayjs'

import DataTable from '../../Components/DataTable'

import {
  Autocomplete,
  Button,
  Container,
  CustomLinearProgress,
  DialogContent,
  Header,
  IconButton,
  OutlinedInput,
  Row,
  TableLoadingCompWrapper,
  Text,
  Input,
  Content,
  TableWrapper
} from '../../styles/components'
import Appbar from '../../Components/Appbar'
import ModalComponent from '../../Components/ModalComponent'
import ConfirmationDialog from '../../Components/ConfirmationDialog'
import CloseConfirmationDialog from '../../Components/CloseConfirmationDialog'
import { validateDescription, validateProject } from '../../utils/validators'

const TransitionDown = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const defaultSearchState = {
  startDate: dayjs().subtract(1, 'week').valueOf(),
  endDate: dayjs().valueOf(),
  searchText: ''
}

const defaultPluviometerState = {
  id: 0,
  description: '',
  notes: '',
  projects: []
}

const defaultLists = {
  projectList: [],
  pluviometerList: []
}
const defaultPageManagerState = {
  tableIsLoading: false,
  showModal: false,
  showConfirmDiag: false,
  showCloseDiag: false
}

const alertDefaultState = {
  successMessage: '',
  errorMessage: ''
}

const inputProps = {
  shrink: true
}

const searchOptions = {
  keys: [
    (item) => item.description,
    (item) =>
      item.pluviometerProjects.map((p) => p.project.description).join(', '),
    (item) => item.notes
  ],
  keepDiacritics: false,
  threshold: matchSorter.rankings.CONTAINS
}

const columns = [
  {
    key: 'description',
    label: 'Pluviômetro',
    render_description: (item) => item.description
  },
  {
    key: 'projects',
    label: 'Projetos',
    render_projects: (item) =>
      item.pluviometerProjects.map((p) => p.project.description).join(', ')
  },
  {
    key: 'notes',
    label: 'Observações',
    render_notes: (item) => item.notes ?? '-'
  }
]

const Pluviometer = (props) => {
  const [search, setSearch] = useState(defaultSearchState)
  const [pluviometer, setPluviometer] = useState(defaultPluviometerState)
  const [list, setList] = useState(defaultLists)
  const [alert, setAlert] = useState(alertDefaultState)
  const [pageManager, setPageManager] = useState(defaultPageManagerState)

  useEffect(() => {
    if (alert.successMessage) {
      setTimeout(() => setAlert({ successMessage: '' }), 2000)
    }
    if (alert.errorMessage) {
      setTimeout(() => setAlert({ errorMessage: '' }), 2000)
    }
  }, [alert])

  useEffect(async () => {
    try {
      const clientId = props.currentClient.id
      const authToken = props.currentUser.token
      if (clientId) {
        setPageManager((prev) => ({ ...prev, tableIsLoading: true }))
        const projects = await ProjectsService.getList(authToken, clientId)

        setList((prev) => ({ ...prev, projectList: projects }))
        setPageManager((prev) => ({ ...prev, tableIsLoading: false }))
      }
    } catch (error) {
      setList((prev) => ({ ...prev, projectList: [] }))
      setPageManager((prev) => ({ ...prev, tableIsLoading: false }))
      setAlert((prev) => ({ ...prev, errorMessage: error.message }))
    }
  }, [props.currentClient])

  useEffect(async () => {
    try {
      const clientId = props.currentClient.id
      const authToken = props.currentUser.token
      if (clientId) {
        setPageManager((prev) => ({ ...prev, tableIsLoading: true }))
        const newList = await PluviometerService.getList(authToken, clientId)

        setList((prev) => ({ ...prev, pluviometerList: newList }))
        setPageManager((prev) => ({ ...prev, tableIsLoading: false }))
      }
    } catch (error) {
      setPageManager((prev) => ({ ...prev, tableIsLoading: false }))
      setAlert((prev) => ({ ...prev, errorMessage: error.message }))
    }
  }, [props.currentClient, search.startDate, search.endDate])

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const filteredList = search.searchText.length
    ? matchSorter(list.pluviometerList, search.searchText, searchOptions)
    : list.pluviometerList

  const _onChangeTableSearch = useCallback((event) => {
    const value = event.target?.value ?? ''
    setSearch((prevState) => ({ ...prevState, searchText: value }))
  }, [])

  const _onChangeNotes = useCallback((event) => {
    const value = event.target?.value ?? ''
    setPluviometer((prev) => ({ ...prev, notes: value }))
  }, [])

  const _onChangeDescription = useCallback(
    (event) => {
      const value = event.target?.value ?? ''
      setPluviometer((prev) => ({ ...prev, description: value }))
    },
    [pluviometer]
  )

  const _onSelectProject = useCallback(
    (event, value) => {
      setPluviometer((prev) => ({ ...prev, projects: value }))
    },
    [pluviometer.projects]
  )

  const _handleModalOpen = useCallback(() => {
    setPluviometer(defaultPluviometerState)
    setPageManager((prev) => ({
      ...prev,
      showModal: true
    }))
  }, [])

  const _handleModalClose = useCallback(() => {
    setPageManager((prev) => ({ ...prev, showModal: false }))
  }, [])

  const _handleConfirmDiagOpen = useCallback(() => {
    setPageManager((prev) => ({
      ...prev,
      showConfirmDiag: true,
      showModal: false
    }))
  }, [])

  const _handleConfirmDiagClose = useCallback(() => {
    setPageManager((prev) => ({
      ...prev,
      showConfirmDiag: false,
      showModal: true
    }))
  }, [])

  const _handleCloseDiagConfirm = useCallback(() => {
    setPageManager((prev) => ({
      ...prev,
      showCloseDiag: false
    }))
  }, [])

  const _handleCloseDiagOpen = useCallback(() => {
    setPageManager((prev) => ({
      ...prev,
      showModal: false,
      showCloseDiag: true
    }))
  }, [])

  const _handleCloseDiagBack = useCallback(() => {
    setPageManager((prev) => ({
      ...prev,
      showModal: true,
      showCloseDiag: false
    }))
  }, [])

  const _handleClickItem = useCallback((item) => {
    setPluviometer((prev) => ({
      ...prev,
      id: item.id,
      description: item.description,
      notes: item.notes,
      projects:
        item.pluviometerProjects?.map((p) => p?.project) ?? pluviometer.projects
    }))
    setPageManager((prev) => ({ ...prev, showModal: true }))
  }, [])

  const _onSave = useCallback(async () => {
    try {
      setPageManager((prev) => ({ ...prev, tableIsLoading: true }))
      const authToken = props.currentUser.token
      const payload = {
        id: pluviometer.id,
        description: pluviometer.description,
        notes: pluviometer.notes,
        projects: pluviometer.projects.map((p) => p.id)
      }

      const newList = [...list.pluviometerList]
      const tableObj = {
        description: pluviometer.description,
        notes: pluviometer.notes,
        pluviometerProjects: pluviometer.projects.map((p) => ({
          pluviometer_id: pluviometer.id,
          project_id: p.id,
          project: p
        }))
      }

      if (!pluviometer.id) {
        const newItem = await PluviometerService.insertPluviometer(
          authToken,
          payload
        )

        newList.unshift({ ...tableObj, id: newItem.id })
      } else {
        const updatedItem = await PluviometerService.updatePluviometer(
          authToken,
          payload
        )
        const index = newList.findIndex((i) => i.id === updatedItem.id)

        console.log('projects after update >>', pluviometer.projects)

        if (index > -1) {
          newList[index] = { ...tableObj, id: updatedItem.id }
        }
      }

      setList((prev) => ({ ...prev, pluviometerList: newList }))
      setAlert((prev) => ({
        ...prev,
        successMessage: 'Dados salvos com sucesso.'
      }))
      setPageManager((prev) => ({
        ...prev,
        tableIsLoading: false,
        showConfirmDiag: false
      }))
    } catch (error) {
      console.error(error.message)
      console.log(error.message)
      setAlert((prev) => ({ ...prev, errorMessage: error.message }))
      setPageManager((prev) => ({
        ...prev,
        tableIsLoading: false,
        showConfirmDiag: false
      }))
    }
  }, [pluviometer, list.pluviometerList])

  const _registryModal = () => {
    const inputHasError = validateDescription(pluviometer.description)
    const autocompleteHasError = validateProject(pluviometer.projects)

    const hasError = inputHasError || autocompleteHasError

    return (
      <ModalComponent
        title="Pluviômetro"
        buttonLabel="Salvar"
        onClickClose={_handleModalClose}
        open={pageManager.showModal}
        onClose={_handleCloseDiagOpen}
        onClickButton={_handleConfirmDiagOpen}
        disabled={!!hasError}
      >
        <DialogContent>
          <Input
            type="text"
            label="Pluviômetro"
            placeholder="ex: Pluviômetro projetos café 1 a 5"
            value={pluviometer.description}
            onChange={_onChangeDescription}
            InputLabelProps={inputProps}
            error={!!inputHasError}
            helperText={inputHasError}
          />
          <Autocomplete
            id="projects-selector"
            options={list.projectList}
            disableClearable
            multiple
            noOptionsText="Sem porjetos para exibir"
            getOptionLabel={(op) =>
              `${op.description ?? ''} - ${op.farm.name ?? ''}`
            }
            getOptionSelected={(op, current) => op.id === current.id}
            value={pluviometer.projects ?? []}
            onChange={_onSelectProject}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Projeto(s) vinculado(s)"
                placeholder="selecine os projetos"
                InputLabelProps={inputProps}
                error={!!autocompleteHasError}
                helperText={autocompleteHasError}
              />
            )}
          />
          <Input
            label="Observações"
            inputMode="text"
            placeholder="Opcional: insira observações acerca do equipamento."
            variant="outlined"
            InputLabelProps={inputProps}
            multiline
            minRows={10}
            value={pluviometer.notes}
            onChange={_onChangeNotes}
          />
        </DialogContent>
      </ModalComponent>
    )
  }

  const _confirmationDialog = () => {
    return (
      <Dialog
        open={pageManager.showConfirmDiag}
        TransitionComponent={TransitionDown}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={_handleConfirmDiagClose}
      >
        <ConfirmationDialog
          onClickConfirm={_onSave}
          onClickCancel={_handleConfirmDiagClose}
        />
      </Dialog>
    )
  }

  const _closeConfirmationDialog = () => {
    return (
      <Dialog
        open={pageManager.showCloseDiag}
        TransitionComponent={TransitionDown}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CloseConfirmationDialog
          onClickCancel={_handleCloseDiagBack}
          onClickConfirm={_handleCloseDiagConfirm}
        />
      </Dialog>
    )
  }

  const _renderAlert = () => {
    const alertMessage = alert.successMessage || alert.errorMessage
    const alertColor = alert.successMessage ? 'success' : 'error'
    return (
      <Snackbar open={!!alertMessage} key={alertColor}>
        <Alert severity={alertColor}>{alertMessage}</Alert>
      </Snackbar>
    )
  }

  return (
    <Container>
      <Appbar history={props.history} />
      <Content>
        <Header
          isplay="flex"
          flexDirection="column"
          width="100%"
          height="10%"
          padding="3em 0 10em 0"
        >
          <Row justify="center" align="center">
            {isMobile ? (
              <IconButton onClick={_handleModalOpen}>
                <AddIcon />
              </IconButton>
            ) : (
              <Button
                margin="8px"
                width="10em"
                height="2.5em"
                startIcon={<AddIcon />}
                onClick={_handleModalOpen}
              >
                Registrar
              </Button>
            )}
            <OutlinedInput
              isMobile={isMobile}
              width="25%"
              mobileWidth="60%"
              id="outlined-adornment-search"
              placeholder="Pesquisar na tabela"
              type="search"
              value={search.searchText}
              onChange={_onChangeTableSearch}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              labelWidth={60}
            />
          </Row>
        </Header>
        {pageManager.tableIsLoading ? (
          <TableLoadingCompWrapper>
            <Text margin="12px" textColor="black">
              Carregando lista de pluviometros...
            </Text>
            <CustomLinearProgress />
          </TableLoadingCompWrapper>
        ) : (
          <TableWrapper height="90%">
            <DataTable
              columns={columns}
              data={filteredList}
              mobileLabel={(item) => item.description}
              mobileSubtitle={(item) =>
                item.pluviometerProjects
                  .map((p) => p.project.description)
                  .join(', ')
              }
              onClickItem={_handleClickItem}
            />
          </TableWrapper>
        )}
      </Content>
      {_renderAlert()}
      {_registryModal()}
      {_closeConfirmationDialog()}
      {_confirmationDialog()}
    </Container>
  )
}

Pluviometer.propTypes = {
  history: PropTypes.object,
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired
  }),
  currentClient: PropTypes.shape({
    id: PropTypes.number.isRequired
  })
}

const mapState = ({ auth, clients }) => {
  return {
    currentUser: auth.currentUser,
    currentClient: clients.currentClient
  }
}

export default connect(mapState)(Pluviometer)
