/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import get from 'lodash/get'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'

import { DialogContainer, TypoHeader, TypoTitle, TypoText } from './styles'

const bullStyle = {
  display: 'inline-block',
  paddingBottom: 6,
  marginRight: 8,
  transform: 'scale(3)'
}

const bull = <span style={bullStyle}>•</span>

const ReadingsReview = ({
  farmName,
  projectDescription,
  sensorGroupDescription,
  readingTimestamp,
  tensiometers,
  extractors,
  notes
}) => {
  const timestamp = dayjs(readingTimestamp)

  return (
    <DialogContainer>
      <Card>
        <CardContent>
          <TypoHeader>Resumo das leituras:</TypoHeader>
          <Divider />
          <TypoTitle>Projeto:</TypoTitle>
          <TypoText>
            {bull}
            {farmName} - {projectDescription}
          </TypoText>
          <TypoTitle>Bateria:</TypoTitle>
          <TypoText>
            {bull}
            {sensorGroupDescription}
          </TypoText>
          <TypoTitle>Data e hora da leitura:</TypoTitle>
          <TypoText>
            {bull}
            {timestamp.format('DD/MM/YY')}
          </TypoText>
          <TypoText>
            {bull}
            {timestamp.format('HH:mm')}
          </TypoText>
          <TypoTitle>Tensiômetros:</TypoTitle>
          {tensiometers.map((t) => {
            const id = get(t, 'tensiometer.id', 0)
            const value = get(t, 'value', '')
            const depth = get(t, 'tensiometer.depth', '')
            return (
              <TypoText key={id}>
                {bull}
                {depth}cm: {value}(mba)
              </TypoText>
            )
          })}
          <TypoTitle>EC:</TypoTitle>
          {extractors.map((e) => {
            const id = get(e, 'extractor.id', 0)
            const value = get(e, 'value', '')
            const depth = get(e, 'extractor.depth', '')
            return (
              <TypoText key={id}>
                {bull}
                {depth}cm:{' '}
                {value ? ` ${value}(μS/cm)` : ' Dados não informados'}
              </TypoText>
            )
          })}
          <TypoTitle>pH dos Extratores:</TypoTitle>
          {extractors.map((e) => {
            const id = get(e, 'extractor.id', 0)
            const ph_value = get(e, 'ph_value', '')
            const depth = get(e, 'extractor.depth', '')
            return (
              <TypoText key={id}>
                {bull}
                {depth}cm:
                {ph_value ? ` ${ph_value}` : ' Dados não informados'}
              </TypoText>
            )
          })}
          <TypoTitle>Observações:</TypoTitle>
          <TypoText>
            {bull}
            {notes || 'Nenhuma observação foi registrada.'}
          </TypoText>
        </CardContent>
      </Card>
    </DialogContainer>
  )
}

ReadingsReview.propTypes = {
  farmName: PropTypes.string.isRequired,
  projectDescription: PropTypes.string.isRequired,
  sensorGroupDescription: PropTypes.string.isRequired,
  readingTimestamp: PropTypes.number.isRequired,
  tensiometers: PropTypes.array.isRequired,
  extractors: PropTypes.array.isRequired,
  notes: PropTypes.string.isRequired
}

ReadingsReview.defaultProps = {}

export default ReadingsReview
