import React from 'react'

import { StyledSpan, WarnContainer, WarnIcon, WarnText } from '../styles'

const WarnComp = () => {
  return (
    <WarnContainer>
      <WarnIcon />
      <WarnText>
        <StyledSpan>Algo deu errado!</StyledSpan>
        <br />
        Se o problema persistir, porfavor, contate um administrador.
      </WarnText>
    </WarnContainer>
  )
}

export default WarnComp
