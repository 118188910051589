const Colors = {
  DEFAULT_BACKGROUND: '#D8EFFE',
  AZUL_HYDRA: '#0564a0',
  TABLE_HOVER: '#A4C6DC',
  TABLE_ODD_ROW: '#E8F0F6',
  TABLE_ROW_SELECTED: '#87A3B5',
  WARNING: '#F49D37',
  WARNING_DARKER: '#E9820C',
  SUCCESS: `#3D8C08`,
  DANGER: `#D33131`,
  TABLE_CONTAINER: '#F0F2F5',
  INPUTS_BACKGROUND: '#D6D6D6',
  SUBTEXT: '#3f3d3b',
  LIGHT_GREY_TEXT: '#b4b4b4',
  LIGHT_GRAY: '#787a7a'
}

export default Colors
