/* eslint-disable indent */
import React from 'react'
import styled, { css } from 'styled-components'
import {
  styled as styledMaterialUI,
  withStyles
} from '@material-ui/core/styles'

import Switch from '@material-ui/core/Switch'

import Skeleton from '@material-ui/lab/Skeleton'

import { Autocomplete as MUIAutocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker
} from '@material-ui/pickers'
import TextField from '@material-ui/core/TextField'
import {
  Button as MUIButton,
  IconButton as MUIIconButton,
  OutlinedInput as MUIOutlinedInput,
  DialogContent as MUIDialogContent,
  Stepper as MUIStepper,
  Accordion as MUIAccordion,
  Paper as MUIPaper,
  ListItemIcon
} from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import AppBar from '@material-ui/core/AppBar'
import NumberInput from '../Components/NumberInput'

import Colors from './colors'

export const SearchInputSkeleton = styledMaterialUI(Skeleton)({
  width: '100%',
  height: 100
})

export const DataTableSkeleton = styledMaterialUI(Skeleton)({
  width: '100%',
  height: '80%'
})

export const ChartInputSkeleton = styledMaterialUI(Skeleton)({
  width: '75%',
  height: 100,
  marginLeft: 8
})

export const ChartSearchButtonSkeleton = styledMaterialUI(Skeleton)({
  width: '40%',
  height: 70,
  marginLeft: 8
})

export const ChartSkeleton = styledMaterialUI(Skeleton)({
  width: '100%',
  height: '100%'
})

export const Actions = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 1%;
`

export const SearchInput = styled(MUIOutlinedInput)`
  background-color: ${`${Colors.INPUTS_BACKGROUND}30`};
`

export const AddButton = styledMaterialUI(MUIButton)({
  marginRight: '10px',
  color: 'white',
  backgroundColor: Colors.AZUL_HYDRA,
  '&:hover': {
    backgroundColor: Colors.AZUL_HYDRA,
    boxShadow: '0 0 11px rgba(0,0,0,0.9)'
  }
})

export const AddIconButton = styledMaterialUI(MUIIconButton)({
  marginRight: '10px',
  color: 'white',
  backgroundColor: Colors.AZUL_HYDRA,
  '&:hover': {
    backgroundColor: Colors.AZUL_HYDRA,
    boxShadow: '0 0 11px rgba(0,0,0,0.9)'
  }
})

export const ModalHeader = styledMaterialUI(AppBar)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'sticky',
  flexWrap: 'wrap',
  height: '80px',
  width: '100%',
  backgroundColor: Colors.AZUL_HYDRA
})

export const TypoHeader = styledMaterialUI(Typography)({
  fontSize: 26,
  fontWeight: '500',
  padding: '8px 8px 8px 18px'
})

export const ActionButton = styledMaterialUI(MUIButton)({
  margin: 0,
  backgroundColor: (props) => props.background || '',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: (props) => props.background || '',
    boxShadow: '0 0 11px rgba(0,0,0,0.9)',
    transformStyle: ''
  }
})

export const CloseButton = styledMaterialUI(MUIIconButton)({
  color: '#ffffff',
  cursor: 'pointer',
  padding: '0 18px 0 0'
})

export const StepOptionalLabel = () => (
  <Typography variant="caption" style={{ color: 'red', fontWeight: 'bold' }}>
    *
  </Typography>
)

export const SearchButton = styledMaterialUI(MUIButton)({
  margin: 10,
  width: 220,
  backgroundColor: Colors.AZUL_HYDRA,
  color: 'white',
  '&:hover': {
    backgroundColor: '#045182',
    boxShadow: '0 0 11px rgba(0,0,0,0.9)',
    transformStyle: ''
  }
})

export const HydraSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: Colors.AZUL_HYDRA
    },
    '&$checked + $track': {
      backgroundColor: Colors.AZUL_HYDRA
    }
  },
  checked: {},
  track: {}
})(Switch)
// ----------------------------------------------------------------------------------------
// Wrappers:

export const Paper = styled(MUIPaper)`
  width: 30%;
  height: 30em;
  overflow-y: auto;

  @media (min-width: 300px) and (max-width: 389px) {
    width: 90%;
    height: 20em;
  }

  @media (min-width: 390px) and (max-width: 766px) {
    width: 90%;
    height: 30em;
  }
  @media (min-width: 767px) and (max-width: 899px) {
    width: 50%;
    height: 25em;
  }

  @media (min-width: 900px) and (max-width: 1024px) {
    width: 40%;
    height: 25em;
  }
`

export const Container = styled.div`
  display: ${(props) => props.display};
  flex-direction: ${(props) => props.flexDirection};
  position: ${(props) => props.position};
  width: 100%;
  height: 100%;
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  background-position: center;
  background-color: ${Colors.DEFAULT_BACKGROUND};
  overflow: ${(props) => props.overflow || 'hidden'};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: ${(props) => props.position};
  margin: ${(props) => props.margin};
  align-items: center;
  padding: 65px 0 0 0;
  width: 100%;
  height: 100% !important;
  overflow-y: ${(props) => props.overflow || 'hidden'};
  overflow-x: hidden;
`

export const Header = styled.div`
  display: ${(props) => props.display};
  flex-direction: ${(props) => props.flexDirection};
  height: ${(props) => (props.isMobile && props.mobHeight) || props.height};
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  background-color: ${(props) => props.backgroundColor};
  z-index: ${(props) => props.zIndex};
`

export const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: ${(props) => props.height || '60%'};
  width: 95%;
`

export const WidgetWrapper = styled.div`
  position: ${(props) => props.position};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100% !important;
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  overflow-y: ${(props) => props.overflow || 'hidden'};
`

export const Body = styled.div`
  display: ${(props) => props.display};
  flex-direction: ${(props) => props.flexDirection};
  position: ${(props) => props.position};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  background-color: ${(props) => props.backgroundColor};
  z-index: ${(props) => props.zIndex};
  overflow-y: ${(props) => props.overflowY};
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  right: ${(props) => props.right};
  left: ${(props) => props.left};
  transform: translate(${(props) => props.translate});
`

export const Footer = styled.div`
  display: flex;
  height: 80px;
  width: 100%;
  justify-content: center;
`

export const DashboardContent = styled.div`
  display: flex;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 68px 10px 10px 0;
  flex-direction: row;
  overflow-y: auto;

  ${(props) =>
    props.isMobile &&
    css`
      flex-direction: column;
    `};
`

export const WidgetsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  justify-content: flex-start;
  ${(props) =>
    props.isLoading &&
    css`
      height: 100%;
      justify-content: center;
    `}
  ${(props) =>
    props.isMobile &&
    css`
      justify-content: center;
      align-items: center;
    `}
`

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: ${(props) => props.align || 'center'};
  justify-content: ${(props) => props.justify || null};
  background-position: center;
  background-color: ${Colors.DEFAULT_BACKGROUND};
  overflow: ${(props) => props.overflow || 'hidden'};
`
export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70%;
  align-items: center;
  padding: 1% 1% 0 1%;
`
export const DateActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justify || 'center'};
  align-items: ${(props) => props.align || 'center'};
  width: 100%;
  height: ${(props) => props.height || null};
  margin: 8px;
  ${(props) =>
    props.isMobile &&
    css`
      height: 25%;
    `};
`
export const PickersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30%;

  @media (max-width: 390px) {
    flex-direction: column;
    width: 80%;
  }

  @media (min-width: 391px) and (max-width: 766px) {
    flex-direction: column;
    width: 80%;
  }
  @media (min-width: 767px) and (max-width: 899px) {
    width: 50%;
  }

  @media (min-width: 900px) and (max-width: 1024px) {
    width: 45%;
  }
`
export const TableLoadingCompWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
  margin: 16px;
`
export const DialogContent = styled(MUIDialogContent)`
  width: 80%;
  height: 80%;
  align-items: center;
  align-self: center;
  justify-content: center;
  justify-self: center;
`

export const ConfirmDialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${(props) => props.align || 'flex-start'};
  justify-content: ${(props) => props.justify || 'flex-start'};
  width: 100%;
  height: ${(props) => props.height || null};
  position: ${(props) => props.position || null};
  bottom: ${(props) => props.bottom || null};
  right: ${(props) => props.right || null};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
  align-items: ${(props) => props.align || 'flex-start'};
  justify-content: ${(props) => props.justify || 'flex-start'};
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || null};
  flex: ${(props) => props.flex || null};
  margin: ${(props) => props.margin || null};
  overflow-y: ${(props) => props.overflowY || null};
  overflow-x: ${(props) => props.overflowX || null};
`

export const StepperContainer = styled.div`
  width: 40%;
  height: 100%;
  padding: 10px;
`

export const Accordion = styled(MUIAccordion)`
  width: 30%;
  ${(props) =>
    props.isMobile &&
    css`
      width: 90%;
    `}
`
export const CustomListItemIcon = styled(ListItemIcon)`
  margin-left: ${(props) => props.marginLeft ?? '1.5em'};
`

// ----------------------------------------------------------------------------------------
// Text and titles:

export const Text = styled.p`
  font-size: ${(props) => props.fontSize || '26px'};
  font-weight: ${(props) => props.fontWeight || 500};
  padding: ${(props) => props.padding};
  font-family: Montserrat;
  margin: ${(props) => props.margin};
  color: ${(props) => props.textColor || '#fff'};
  text-align: ${(props) => props.textAlign};
  text-justify: ${(props) => props.textJustify};
  ${(props) =>
    props.isMobile &&
    css`
      font-size: ${(props) => props.fontSizeMob};
      font-weight: ${(props) => props.fontWeightMob};
    `};
`
// ----------------------------------------------------------------------------------------
// Inputs and pickers:

export const Input = styled(TextField)`
  width: ${(props) => props.width || '90%'};
  && {
    margin: ${(props) => props.margin || '12px'};
  }
`
export const Autocomplete = styled(MUIAutocomplete)`
  width: ${(props) => props.width || '90%'};
  && {
    margin: ${(props) => props.margin || '12px'};
  }
`
export const NumericField = styled(NumberInput)`
  width: ${(props) => props.width || '90%'};
  margin: ${(props) => props.margin || '8px'} !important;
`
export const DatePicker = styled(KeyboardDatePicker)`
  width: 100%;
  && {
    margin: 8px;
  }
  ${(props) =>
    props.isMobile &&
    css`
      width: 80%;
    `}
`

export const DateTimePicker = styled(KeyboardDateTimePicker)`
  && {
    width: ${(props) => props.width || '90%'};
    margin: 12px;
  }
`

export const OutlinedInput = styled(MUIOutlinedInput)`
  width: ${(props) => props.width || '100%'};
  background-color: ${`${Colors.INPUTS_BACKGROUND}30`};
  && {
    margin: 8px;
  }
  ${(props) =>
    props.isMobile &&
    css`
      width: ${(props) => props.mobileWidth};
    `}
`

// ---------------------------------------------------------------------------------------
// Buttons, switches and checkboxes:

export const Button = styled(MUIButton)`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  cursor: pointer;
  // na proxima linha uso o && para sobrescrever o estilo com uma sintaxe de especificidade maior
  && {
    background-color: ${(props) => props.backgroundColor || Colors.AZUL_HYDRA};
    color: ${(props) => props.textColor || '#fff'};
    margin: ${(props) => props.margin || null};
  }
  &:hover {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
    transform-style: preserve-3d;
    && {
      background-color: ${(props) =>
        props.backgroundColor || Colors.AZUL_HYDRA};
    }
  }
`

export const IconButton = styled(MUIIconButton)`
  margin: ${(props) => props.margin || null};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  cursor: pointer;
  && {
    background-color: ${Colors.AZUL_HYDRA};
    color: ${(props) => props.textColor || '#fff'};
  }
  &:hover {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
    transform-style: preserve-3d;
  }
`

// ---------------------------------------------------------------------------------------
// Spinners, progress bars, icons, etc:

export const CustomLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    width: '80%',
    borderRadius: 2
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    backgroundColor: Colors.AZUL_HYDRA
  }
}))(LinearProgress)

// ---------------------------------------------------------------------------------------
// Misc

export const Stepper = styled(MUIStepper)({
  '& .MuiStepIcon-root': {
    fontSize: '25px',
    color: Colors.LIGHT_GRAY
  },
  '& .MuiStepIcon-root.MuiStepIcon-active': {
    color: Colors.AZUL_HYDRA
  },
  '& .MuiStep-completed .MuiStepIcon-root ': {
    color: Colors.SUCCESS
  },
  '& .MuiStep-root': {
    height: '100%'
  },
  '& .MuiStepConnector-line': {
    borderBottomColor: `${Colors.LIGHT_GRAY} !important`
  },
  '& .MuiStepConnector-root': {
    display: 'flex',
    height: '100%'
  }
})
