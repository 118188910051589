import { styled as styledMaterialUI } from '@material-ui/core/styles'
import styled from 'styled-components'

import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'

export const LogoContainer = styled.img`
  height: 50px;
  cursor: pointer;
`

export const SettingsAppBar = styledMaterialUI(AppBar)({
  position: 'fixed',
  backgroundColor: (props) => props.background || null,
  zIndex: 3
})

export const PermanentDrawer = styledMaterialUI(Drawer)({
  zIndex: 1
})

export const SettingsList = styledMaterialUI(List)({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 65,
  alignItems: 'center',
  justifyContent: 'center'
})

export const CardContainer = styledMaterialUI(Paper)({
  display: 'flex',
  flexDirection: 'column',
  width: (props) => props.width || '100%',
  height: '100%',
  padding: '20px 0 20px 0'
})

export const PasswordInput = styledMaterialUI(TextField)({
  width: 250,
  margin: 8
})

export const Title = styledMaterialUI(Typography)({
  display: 'flex',
  alignItems: (props) => props.align || 'flex-start',
  justifyContent: (props) => props.justify || 'flex-start',
  fontSize: 26,
  fontWeight: 'bold',
  marginRight: '5%',
  margin: 12
})

export const Content = styledMaterialUI(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: (props) => props.align || 'center',
  justifyContent: (props) => props.justify || 'center'
})

export const StyledDivider = styledMaterialUI(Divider)({
  width: '100%',
  height: 2
})

export const Text = styledMaterialUI(Typography)({
  display: 'flex',
  alignItems: (props) => props.align || 'flex-start',
  justifyContent: (props) => props.justify || 'flex-start',
  fontSize: 18,
  marginRight: '5%',
  margin: 12
})
