import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import List from '@material-ui/core/List'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import DragIndicatorIcon from '@material-ui/icons/DragIndicator'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import DurationInput from '../../../../Components/DurationInput'

import {
  DialogContainer,
  TypoHeader,
  ListIcon,
  ListItemContainer,
  ListStyledItem,
  SectorName
} from './styles'

import {
  IRRIGATION_TYPE_LIST,
  IRRIGATION_TYPES
} from '../../../../utils/constants'
import { validateSectorDuration } from '../../../../utils/validators'

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,

  ...(isDragging && {
    background: 'rgb(235,235,235)'
  })
})

const IrrigationSectors = ({
  sectors,
  onChangeDuration,
  onChangeType,
  onReorderSectors
}) => {
  const onDragEnd = useCallback(
    ({ destination, source }) => {
      if (
        !destination ||
        (destination.droppableId === source.droppableId &&
          destination.index === source.index)
      ) {
        return
      }

      const startIndex = source.index
      const endIndex = destination.index

      const newList = [...sectors]
      const [itemMoved] = newList.splice(startIndex, 1)
      newList.splice(endIndex, 0, itemMoved)

      onReorderSectors(newList)
    },
    [sectors, onReorderSectors]
  )

  return (
    <DialogContainer>
      <TypoHeader>Setores disponíveis:</TypoHeader>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(droppableProvided) => (
            <List
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {sectors.map((s, index) => {
                const typeValue = IRRIGATION_TYPE_LIST.find(
                  (t) => t.value === s.type
                )
                const durationHasError = validateSectorDuration(
                  s.duration,
                  s.type
                )

                return (
                  <Draggable
                    key={s.sector.id}
                    draggableId={`${s.sector.id}`}
                    index={index}
                  >
                    {(draggableProvided, snapshot) => (
                      <ListItemContainer
                        key={s.sector.id}
                        dense
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        ref={draggableProvided.innerRef}
                        style={getItemStyle(
                          snapshot.isDragging,
                          draggableProvided.draggableProps.style
                        )}
                      >
                        <ListStyledItem>
                          <ListIcon>
                            <DragIndicatorIcon />
                            <SectorName color="textPrimary">
                              {s.sector.description}
                            </SectorName>
                          </ListIcon>

                          <Autocomplete
                            fullWidth
                            id="combo-box-demo"
                            options={IRRIGATION_TYPE_LIST}
                            getOptionLabel={(op) => op.label}
                            getOptionSelected={(op, current) =>
                              op.value === current.value
                            }
                            value={typeValue}
                            onChange={(event, selected) =>
                              onChangeType(selected.value, index)
                            }
                            closeIcon={false}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                label="Irrigar"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                disabled
                                variant="outlined"
                              />
                            )}
                          />
                        </ListStyledItem>
                        {s.type !== IRRIGATION_TYPES.NONE ? (
                          <ListStyledItem>
                            <DurationInput
                              fullWidth
                              label="Duração"
                              size="small"
                              variant="outlined"
                              duration={s.duration}
                              onChangeDuration={(newDuration) =>
                                onChangeDuration(newDuration, index)
                              }
                              disabled={s.type === IRRIGATION_TYPES.NONE}
                              error={!!durationHasError}
                              helperText={durationHasError}
                            />
                          </ListStyledItem>
                        ) : null}
                      </ListItemContainer>
                    )}
                  </Draggable>
                )
              })}
              {droppableProvided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </DialogContainer>
  )
}

IrrigationSectors.propTypes = {
  sectors: PropTypes.arrayOf(
    PropTypes.shape({
      duration: PropTypes.number,
      type: PropTypes.number,
      sector: PropTypes.shape({
        id: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired,
  onChangeDuration: PropTypes.func.isRequired,
  onChangeType: PropTypes.func.isRequired,
  onReorderSectors: PropTypes.func.isRequired
}

IrrigationSectors.defaultProps = {
  onChangeType: () => {},
  onReorderSectors: () => {},
  onChangeDuration: () => {}
}

export default IrrigationSectors
