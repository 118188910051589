import axios from 'axios'
import getValue from 'lodash/get'
import logError from '../utils/log'

const USER = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/user`
})

const getList = async (authToken, clientId, skip = 0, limit = 100) => {
  try {
    const response = await USER.get(
      `/?skip=${skip}&limit=${limit}&client_id=${clientId}`,
      {
        headers: { Authorization: `bearer ${authToken}` }
      }
    )

    return response.data
  } catch (error) {
    logError('services@user@getList', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao buscar a lista de usuários'
      )
    )
  }
}

const getUser = async (authToken, id) => {
  try {
    const response = await USER.get(`/${id}`, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@user@getUser', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao buscar o usuario'
      )
    )
  }
}

const insertUser = async (authToken, user) => {
  try {
    const response = await USER.post(`/`, user, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@user@insertUser', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao inserir um usuário'
      )
    )
  }
}

const updateUser = async (authToken, user) => {
  try {
    const response = await USER.put(`/`, user, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@user@updateUser', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao inserir um usuário'
      )
    )
  }
}

const deleteUser = async (authToken, id) => {
  try {
    const response = await USER.delete(`/${id}`, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@user@deleteUser', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao deletar um usuário'
      )
    )
  }
}

export default { getList, getUser, insertUser, updateUser, deleteUser }
