import React, { useCallback, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import DialogActions from '@material-ui/core/DialogActions'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import Slide from '@material-ui/core/Slide'

import AccountServices from '../../../services/account'

import {
  CardContainer,
  PasswordInput,
  Title,
  Content,
  StyledDivider
} from '../styles'
import { ActionButton } from '../../../styles/components'
import Colors from '../../../styles/colors'

const textProps = { shrink: true }

const transitionUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ChangePassword = (props) => {
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => setSuccessMessage(''), 3000)
    }
  }, [successMessage])

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => setErrorMessage(''), 3000)
    }
  }, [errorMessage])

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const desktop = !isMobile ? '50%' : '100%'

  const confirmationError =
    password !== passwordConfirmation
      ? 'A senha e a confirmação não coincidem'
      : ''
  const emptyPassword = password === '' || passwordConfirmation === ''
  const hasError = !!(confirmationError || emptyPassword)

  const _onChangePassword = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setPassword(value)
  }, [])

  const _onChangePasswordConfirmation = useCallback((event) => {
    const value = get(event, 'target.value', '')
    setPasswordConfirmation(value)
  }, [])

  const _onClickSave = useCallback(async () => {
    try {
      const p = { newPassword: password }
      console.log('>>>>>>>>>>>>', props.currentUser.token)
      await AccountServices.changePassword(props.currentUser.token, p)
      setSuccessMessage('Senha alterada com sucesso')
      setPassword('')
      setPasswordConfirmation('')
    } catch (error) {
      setErrorMessage(error.message)
      setPassword('')
      setPasswordConfirmation('')
    }
  }, [password, setErrorMessage, setSuccessMessage])

  const _renderAlert = () => {
    const alertMessage = successMessage || errorMessage
    const alertColor = successMessage ? 'success' : 'error'
    return (
      <Snackbar
        open={!!alertMessage}
        key={alertColor}
        TransitionComponent={transitionUp}
      >
        <Alert severity={alertColor}>{alertMessage}</Alert>
      </Snackbar>
    )
  }

  return (
    <CardContainer width={desktop}>
      <Title>Alterar Senha</Title>
      <StyledDivider />
      <Content>
        <PasswordInput
          variant="outlined"
          label="Nova Senha"
          value={password}
          onChange={_onChangePassword}
          InputLabelProps={textProps}
          placeholder="Digite a nova senha"
        />
        <PasswordInput
          variant="outlined"
          label="Confirmação da nova senha"
          value={passwordConfirmation}
          onChange={_onChangePasswordConfirmation}
          InputLabelProps={textProps}
          placeholder="Digite a nova senha"
          error={!!confirmationError}
          helperText={confirmationError}
        />
      </Content>
      <StyledDivider />
      <DialogActions>
        <ActionButton
          variant="contained"
          disabled={hasError}
          background={Colors.SUCCESS}
          onClick={_onClickSave}
        >
          Gravar
        </ActionButton>
      </DialogActions>
      {_renderAlert()}
    </CardContainer>
  )
}

ChangePassword.propTypes = {
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired
  })
}

const mapState = ({ auth }) => {
  return {
    currentUser: auth.currentUser
  }
}

export default connect(mapState)(ChangePassword)
