import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'

import rootReducer from './reducers'

const middlewareEnhancer = applyMiddleware(thunkMiddleware)

const composedEnhancers = compose(
  middlewareEnhancer
)

export default createStore(rootReducer, composedEnhancers)
