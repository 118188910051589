import axios from 'axios'
import getValue from 'lodash/get'
import logError from '../utils/log'

const ACCOUNT = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`
})

const changePassword = async (authToken, newPassword) => {
  try {
    const response = await ACCOUNT.post(`/change-password`, newPassword, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@account@insertPassword', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao alterar a senha '
      )
    )
  }
}

export default { changePassword }
