/* eslint-disable indent */
import React from 'react'
import { PropTypes } from 'prop-types'
import GaugeChart from 'react-gauge-chart'

const colorList = [
  'rgba(11, 94, 170, 0.8)',
  'rgba(47, 135, 29, 0.8)',
  'rgba(125, 1, 1, 0.8)',
  'rgba(115, 120, 115, 0.8)'
]

const arcLengthList = [0.2, 0.2, 0.3, 0.3]

function PowerGauge({ value }) {
  return (
    <GaugeChart
      id="1"
      percent={value}
      colors={colorList}
      arcsLength={arcLengthList}
      arcWidth={0.3}
      arcPadding={0.01}
      cornerRadius={4}
      needleColor={'rgb(23, 22, 22, 1)'}
      needleBaseColor={'rgb(23, 22, 22, 1)'}
      hideText
      textColor={'rgb(23, 22, 22, 1)'}
    />
  )
}

PowerGauge.propTypes = {
  text: PropTypes.string,
  value: PropTypes.number,
  reading: PropTypes.string,
  project: PropTypes.string,
  sensor: PropTypes.string,
  readingTime: PropTypes.number
}

export default PowerGauge
