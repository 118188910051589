import axios from 'axios'
import getValue from 'lodash/get'
import logError from '../utils/log'

const CHART = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`
})

const getStructure = async (authToken, clientId) => {
  try {
    const response = await CHART.get(`/chart-structure?client_id=${clientId}`, {
      headers: { Authorization: `bearer ${authToken}` }
    })

    return response.data
  } catch (error) {
    logError('services@chart-structure@getStructure', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao carregar a estrutura'
      )
    )
  }
}

const getEnviromentStructure = async (authToken, clientId) => {
  try {
    const response = await CHART.get(
      `/chart-enviromentStructure?client_id=${clientId}`,
      {
        headers: { Authorization: `bearer ${authToken}` }
      }
    )

    return response.data
  } catch (error) {
    logError('services@chart-structure@getStructure', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao carregar a estrutura'
      )
    )
  }
}

const getTensiometersData = async (authToken, chartTensiometers) => {
  try {
    const response = await CHART.post(
      `/chart-tensiometers`,
      chartTensiometers,
      {
        headers: { Authorization: `bearer ${authToken}` }
      }
    )
    return response.data
  } catch (error) {
    logError('services@chart@insertTensiometers', error)
    throw Error(getValue(error, 'response.data.error', 'Ocorreu um erro '))
  }
}

const getExtractorsData = async (authToken, chartExtractors) => {
  try {
    const response = await CHART.post(`/chart-extractors`, chartExtractors, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@chart@insertExtractors', error)
    throw Error(getValue(error, 'response.data.error', 'Ocorreu um erro '))
  }
}

const getPhData = async (authToken, chartPh) => {
  try {
    const response = await CHART.post(`/chart-ph`, chartPh, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@chart@insertPh', error)
    throw Error(getValue(error, 'response.data.error', 'Ocorreu um erro '))
  }
}

const getTemperatureData = async (authToken, chartTemperature) => {
  try {
    const response = await CHART.post(`/chart-temperature`, chartTemperature, {
      headers: { Authorization: `bearer ${authToken}` }
    })

    return response.data
  } catch (error) {
    logError('services@chart@insertTemperature', error)
    throw Error(getValue(error, 'response.data.error', 'Ocorreu um erro '))
  }
}

const getHumidityData = async (authToken, chartHumidity) => {
  try {
    const response = await CHART.post(`/chart-humidity`, chartHumidity, {
      headers: { Authorization: `bearer ${authToken}` }
    })

    return response.data
  } catch (error) {
    logError('services@chart@insertHumidity', error)
    throw Error(getValue(error, 'response.data.error', 'Ocorreu um erro '))
  }
}

const getEvpData = async (authToken, chartEvapo) => {
  try {
    const response = await CHART.post(`/chart-evapotranspiration`, chartEvapo, {
      headers: { Authorization: `bearer ${authToken}` }
    })

    return response.data
  } catch (error) {
    logError('services@chart@insertEvapotranspiration', error)
    throw Error(getValue(error, 'response.data.error', 'Ocorreu um erro '))
  }
}

export default {
  getStructure,
  getTensiometersData,
  getExtractorsData,
  getPhData,
  getTemperatureData,
  getHumidityData,
  getEvpData,
  getEnviromentStructure
}
