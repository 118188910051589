import axios from 'axios'
import getValue from 'lodash/get'
import logError from '../utils/log'

const SENSORGROUP = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/sensor_group`
})

const getList = async (
  authToken,
  clientId,
  projectId,
  skip = 0,
  limit = 20
) => {
  try {
    const response = await SENSORGROUP.get(
      `/?skip=${skip}&limit=${limit}&client_id=${clientId}&project_id=${projectId}`,
      {
        headers: { Authorization: `bearer ${authToken}` }
      }
    )

    return response.data
  } catch (error) {
    logError('services@sensorGroup@getList', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao buscar a lista de baterias'
      )
    )
  }
}

const getSensorGroup = async (authToken, id) => {
  try {
    const response = await SENSORGROUP.get(`/${id}`, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@sensorGroup@getSensorGroup', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao buscar as baterias'
      )
    )
  }
}

const insertSensorGroup = async (authToken, sensorGroup) => {
  try {
    const payload = {
      sector_id: sensorGroup.sector_id,
      sector: sensorGroup.sector,
      project: sensorGroup.project,
      description: sensorGroup.description,
      capsule_id: sensorGroup.capsule_id,
      tension_read_type: sensorGroup.tension_read_type,
      active_enviroment_read: sensorGroup.active_enviroment_read,
      tensiometers: sensorGroup.tensiometers.map((item) => ({
        depth: item.depth
      })),
      extractors: sensorGroup.extractors.map((item) => ({
        depth: item.depth
      }))
    }
    const response = await SENSORGROUP.post(`/`, payload, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@sensorGroup@insertSensorGroup', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao inserir uma bateria'
      )
    )
  }
}

const updateSensorGroup = async (authToken, sensorGroup) => {
  try {
    const payload = {
      id: sensorGroup.id,
      sector_id: sensorGroup.sector_id,
      sector: sensorGroup.sector,
      project: sensorGroup.project,
      description: sensorGroup.description,
      capsule_id: sensorGroup.capsule_id,
      tension_read_type: sensorGroup.tension_read_type,
      active_enviroment_read: sensorGroup.active_enviroment_read,
      tensiometers: sensorGroup.tensiometers.map((item) => ({
        depth: item.depth,
        id: item.id
      })),
      extractors: sensorGroup.extractors.map((item) => ({
        depth: item.depth,
        id: item.id
      })),
      active: sensorGroup.active
    }
    const response = await SENSORGROUP.put(`/`, payload, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@sensorGroup@updateSensorGroup', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao inserir uma bateria'
      )
    )
  }
}

export default { getList, getSensorGroup, insertSensorGroup, updateSensorGroup }
