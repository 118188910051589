import { styled as styledMaterialUI } from '@material-ui/core/styles'
import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import IconButton from '@material-ui/core/IconButton'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Fab from '@material-ui/core/Fab'
import { DialogContent } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

import Colors from '../../styles/colors'

export const StyledAutocomplete = styledMaterialUI(Autocomplete)({
  width: (props) => props.width || '90%',
  margin: 8
})

export const StyledCircularProgress = styledMaterialUI(CircularProgress)({
  margin: 8
})

export const StyledCard = styledMaterialUI(Card)({
  width: (props) => props.width || '65%',
  display: 'flex',
  flexDirection: 'column',
  margin: '8px 0 4px 0 '
})

export const StyledClientCard = styledMaterialUI(Card)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 8,
  minWidth: 230
})

export const StyledDialogContent = styledMaterialUI(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
})

export const PageListText = styledMaterialUI(TextField)({
  width: 80
})

export const TextInput = styledMaterialUI(TextField)({
  width: '90%',
  margin: 8
})

export const PermissionsTitle = styledMaterialUI(Typography)({
  fontSize: 20,
  margin: '4px 0 0 8px',
  fontWeight: '600'
})

export const RemoveButton = styledMaterialUI(IconButton)({
  cursor: 'pointer',
  color: Colors.DANGER,
  padding: '0 0 10px 0'
})

export const StyledFab = styledMaterialUI(Fab)({
  backgroundColor: 'blue',
  margin: 8
})

export const StyledSpan = styled.span`
  color: #ff0000;
  font-size: 14px;
`
