import React from 'react'
import PropTypes from 'prop-types'

import DialogActions from '@material-ui/core/DialogActions'
import Slide from '@material-ui/core/Slide'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import Toolbar from '@material-ui/core/Toolbar'

import HelpIcon from '@material-ui/icons/Help'
import CloseIcon from '@material-ui/icons/Close'

import {
  Container,
  ModalTitle,
  StepperButton,
  HelpFloatingActionButton,
  ModalHeader
} from './styles'
import { CloseButton } from '../../styles/components'
import Colors from '../../styles/colors'

const TransitionUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const StepperModal = ({
  onClickClose,
  title,
  children,
  backStepDisabled,
  nextStepButtonLabel,
  backStepButtonLabel,
  onClickBackStep,
  onClickNextStep,
  nextStepDisabled,
  onClose,
  open,
  onClickHelp
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Container
      onClose={onClose}
      open={open}
      TransitionComponent={TransitionUp}
      fullScreen={isMobile}
    >
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
        <HelpFloatingActionButton onClick={onClickHelp} size="small">
          <HelpIcon />
        </HelpFloatingActionButton>
        <CloseButton onClick={onClickClose}>
          <CloseIcon />
        </CloseButton>
      </ModalHeader>
      <Toolbar></Toolbar>
      {children}
      <DialogActions position="sticky">
        <StepperButton
          disabled={backStepDisabled}
          onClick={onClickBackStep}
          textColor="#000"
        >
          {backStepButtonLabel}
        </StepperButton>
        <StepperButton
          type="submit"
          variant="contained"
          backgroundColor={Colors.AZUL_HYDRA}
          textColor="#ffff"
          disabled={nextStepDisabled}
          onClick={onClickNextStep}
        >
          {nextStepButtonLabel}
        </StepperButton>
      </DialogActions>
    </Container>
  )
}

StepperModal.propTypes = {
  onClickClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.element,
  backStepButtonLabel: PropTypes.string,
  nextStepButtonLabel: PropTypes.string,
  onClickBackStep: PropTypes.func,
  onClickNextStep: PropTypes.func,
  backStepDisabled: PropTypes.bool,
  nextStepDisabled: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onClickHelp: PropTypes.func
}

StepperModal.defaultProps = {
  onClickClose: () => {},
  onClickBackStep: () => {},
  onClickNextStep: () => {},
  onClose: () => {},
  onClickHelp: () => {}
}

export default StepperModal
