import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { connect } from 'react-redux'
import get from 'lodash/get'

import Divider from '@material-ui/core/Divider'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'

import TableHeader from './TableHeader'
import TableRow from './TableRow'
import { Container, HeaderContent, Body, StyledText } from './styles'
import { Column, Row } from '../../styles/components'
import EmptyData from './EmptyData'

import { USER_ACCESS } from '../../utils/constants'
import { tips } from '../../utils/pageAccess'

const DataTable = ({
  columns,
  data,
  onClickItem,
  mobileLabel,
  mobileSubtitle,
  mobileText,
  mobileTextTitle,
  currentUser
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const currentAccess = get(currentUser, 'userAccess', null)
  const renderReadingIcon = tips(currentAccess) === USER_ACCESS.READ

  if (!data.length) {
    return <EmptyData />
  }

  if (isMobile) {
    return (
      <Container elevation={10}>
        <Body>
          {data.map((item) => (
            <Accordion key={item.id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Column justify="flex-start">
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    {mobileLabel(item)}
                  </Typography>
                  <Typography variant="subtitle2" style={{ fontSize: 12 }}>
                    {mobileSubtitle(item)}
                  </Typography>
                </Column>
                <Row align="center" justify="flex-end">
                  {!renderReadingIcon ? (
                    <IconButton
                      aria-label="delete"
                      onClick={() => onClickItem(item)}
                    >
                      <EditIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="delete"
                      onClick={() => onClickItem(item)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  )}
                </Row>
              </AccordionSummary>
              <AccordionDetails>
                <Column>
                  {columns.map((col) => {
                    const renderData = col[`render_${col.key}`]
                    return renderData ? (
                      <Column key={col.label} style={{ overflow: 'auto' }}>
                        <ListItem>
                          <ListItemText>
                            {col.label} : {renderData(item)}
                          </ListItemText>
                        </ListItem>
                      </Column>
                    ) : null
                  })}
                  <Typography
                    variant="h5"
                    style={{ margin: 8, fontWeight: 'bold' }}
                  >
                    {mobileTextTitle}
                  </Typography>
                  <StyledText>
                    <ReactMarkdown>{mobileText(item)}</ReactMarkdown>
                  </StyledText>
                </Column>
              </AccordionDetails>
            </Accordion>
          ))}
        </Body>
      </Container>
    )
  }

  return (
    <Container elevation={10}>
      <HeaderContent>
        <TableHeader columns={columns} />
        <Divider />
      </HeaderContent>
      <Body>
        {data.map((item) => (
          <Column key={item.id} onClick={() => onClickItem(item)}>
            <TableRow columns={columns} item={item} />
            <Divider />
          </Column>
        ))}
      </Body>
    </Container>
  )
}

DataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  onClickItem: PropTypes.func,
  mobileLabel: PropTypes.func,
  mobileSubtitle: PropTypes.func,
  mobileText: PropTypes.func,
  mobileTextTitle: PropTypes.string,
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired
  })
}

DataTable.defaultProps = {
  columns: [],
  data: [],
  mobileLabel: () => {},
  onClickItem: () => {},
  mobileSubtitle: () => {},
  mobileText: () => {}
}

const mapState = ({ auth }) => {
  return {
    currentUser: auth.currentUser
  }
}

export default connect(mapState)(DataTable)
