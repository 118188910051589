import axios from 'axios'
import getValue from 'lodash/get'
import logError from '../utils/log'

const LOGIN = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

const login = async (username, password) => {
  try {
    const response = await LOGIN.post('/login', { username, password })
    return response.data
  } catch (error) {
    logError('services@auth@login', error)
    throw Error(getValue(error, 'response.data.error', 'Ocorreu um erro'))
  }
}

const authenticate = async (token, userId) => {
  try {
    const response = await LOGIN.post('/refresh', { token, userId })
    return response.data
  } catch (error) {
    logError('services@auth@authenticate', error)
    throw Error(getValue(error, 'response.data.error', 'Ocorreu um erro'))
  }
}

const forgotPassword = async (email) => {
  try {
    const response = await LOGIN.post('/forgot', { email })
    return response.data
  } catch (error) {
    logError('services@auth@forgotPassword', error)
    throw Error(getValue(error, 'response.data.error', 'Ocorreu um erro'))
  }
}

const resetPassword = async (password, token) => {
  try {
    const response = await LOGIN.post('/resetPass', { password, token })
    return response.data
  } catch (error) {
    logError('services@auth@forgotPassword', error)
    throw Error(getValue(error, 'response.data.error', 'Ocorreu um erro'))
  }
}

const logout = async (authToken) => {
  try {
    const response = await LOGIN.get(`/logout`, {
      headers: { Authorization: `bearer ${authToken}` }
    })

    return response.data
  } catch (error) {
    logError('services@auth@logout', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao carregar a estrutura'
      )
    )
  }
}

export default {
  login,
  authenticate,
  forgotPassword,
  resetPassword,
  logout
}
