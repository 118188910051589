import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import AssignmentLateOutlinedIcon from '@material-ui/icons/AssignmentLateOutlined'

import ProjectsService from '../../../services/projects'
import { USER_ROLES } from '../../../utils/constants'
import {
  StyledAutocomplete,
  StyledCircularProgress,
  StyledClientCard,
  StyledDialogContent
} from '../styles'

const ProjectsSelector = ({
  selectedProjects,
  onSelectProject,
  currentUser,
  clientsList,
  assignedRole,
  clientSelectorDefaultValue
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedClient, setSelectedClient] = useState(
    clientSelectorDefaultValue
  )
  const [projectList, setProjectList] = useState([])

  const shoulDisableSelector = assignedRole === USER_ROLES.NORMAL

  useEffect(async () => {
    try {
      setIsLoading(true)
      const selectedProjectList = await ProjectsService.getList(
        currentUser.token,
        selectedClient.id
      )
      setProjectList(selectedProjectList)
      setIsLoading(false)
    } catch (error) {
      setProjectList([])
      setIsLoading(false)
    }
  }, [selectedClient])

  const _onChangeClient = useCallback(async (event, value) => {
    setSelectedClient(value)
  }, [])

  const _renderProjectList = () => {
    if (isLoading) {
      return <StyledCircularProgress />
    } else if (projectList.length < 1) {
      return (
        <StyledClientCard>
          <AssignmentLateOutlinedIcon
            fontSize="large"
            style={{ marginTop: 24 }}
          />
          <Typography variant="h6" style={{ margin: 8, marginBottom: 24 }}>
            Sem projetos para exibir!
          </Typography>
        </StyledClientCard>
      )
    } else {
      return (
        <StyledClientCard>
          <List style={{ overflowY: 'auto' }}>
            {projectList.map((item) => {
              const isProjectSelected = selectedProjects.find(
                (p) => p.id === item.id
              )

              return (
                <ListItem key={item.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={item}
                        checked={!!isProjectSelected}
                        onClick={() => {
                          const projectPayload = { ...item }
                          projectPayload.farm.client = selectedClient
                          onSelectProject(projectPayload)
                        }}
                        style={{ color: 'green' }}
                      />
                    }
                    label={`${item.description} - ${item.farm.name}`}
                  />
                </ListItem>
              )
            })}
          </List>
        </StyledClientCard>
      )
    }
  }

  return (
    <StyledDialogContent>
      <StyledAutocomplete
        disableClearable
        width={'230px'}
        options={clientsList}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, v) => option.id === v.id}
        value={selectedClient}
        onChange={_onChangeClient}
        disabled={shoulDisableSelector}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Clientes"
            variant="outlined"
            placeholder="Selecione um cliente"
            InputLabelProps={{ shrink: true }}
          />
        )}
      />

      {_renderProjectList()}
    </StyledDialogContent>
  )
}

ProjectsSelector.propTypes = {
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired
  }),
  clientsList: PropTypes.func,
  getClients: PropTypes.func,
  onSelectProject: PropTypes.func,
  selectedProjects: PropTypes.array,
  assignedRole: PropTypes.number,
  clientSelectorDefaultValue: PropTypes.object
}

ProjectsSelector.defaultProps = {
  getClients: () => {}
}

const mapState = ({ auth, clients }) => {
  return {
    currentUser: auth.currentUser,
    clientsList: clients.list
  }
}

export default connect(mapState)(ProjectsSelector)
