import styled, { css } from 'styled-components'

export const StyledText = styled.p`
  font-family: 'Montserrat';
  font-size: 36px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 8px;
  ${(props) =>
    props.isMobile &&
    css`
      font-size: 24px;
    `}
`

export const BouncingDots = styled.span`
  &::after {
    display: inline-block;
    animation-timing-function: linear;
    animation: ellipsis 3s linear 0.1s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    45% {
      content: '..';
    }
    75% {
      content: '...';
    }
  }
`
