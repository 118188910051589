import axios from 'axios'
import getValue from 'lodash/get'
import logError from '../utils/log'

const PROJECTS = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/project`
})

const getList = async (authToken, clientId, skip = 0, limit = 20) => {
  try {
    const response = await PROJECTS.get(
      `/?skip=${skip}&limit=${limit}&client_id=${clientId}`,
      {
        headers: { Authorization: `bearer ${authToken}` }
      }
    )

    return response.data
  } catch (error) {
    logError('services@projects@getList', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao buscar a lista de projetos'
      )
    )
  }
}

const getProjects = async (authToken, id) => {
  try {
    const response = await PROJECTS.get(`/${id}`, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@projects@getProject', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao buscar o projeto'
      )
    )
  }
}

const insertProject = async (authToken, project) => {
  try {
    const payload = {
      farm_id: project.farm_id,
      notes: project.notes,
      description: project.description,
      number_of_pumps: project.number_of_pumps,
      total_potency: project.total_potency,
      irrigation_system: project.irrigation_system,
      sectors: project.sectors.map((item) => ({
        description: item.description,
        area: item.area,
        espLines: item.espLines,
        espPlant: item.espPlant,
        espLinesDripper: item.espLinesDripper,
        espDripper: item.espDripper,
        dripperFlowRate: item.dripperFlowRate
      }))
    }
    const response = await PROJECTS.post(`/`, payload, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@projects@insertProject', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao inserir um projeto'
      )
    )
  }
}

const updateProject = async (authToken, project) => {
  try {
    const payload = {
      id: project.id,
      farm_id: project.farm_id,
      notes: project.notes,
      description: project.description,
      number_of_pumps: project.number_of_pumps,
      total_potency: project.total_potency,
      irrigation_system: project.irrigation_system,
      sectors: project.sectors.map((item) => ({
        id: item.id,
        description: item.description,
        area: item.area,
        espLines: item.espLines,
        espPlant: item.espPlant,
        espLinesDripper: item.espLinesDripper,
        espDripper: item.espDripper,
        dripperFlowRate: item.dripperFlowRate
      }))
    }
    const response = await PROJECTS.put(`/`, payload, {
      headers: { Authorization: `bearer ${authToken}` }
    })
    return response.data
  } catch (error) {
    logError('services@projects@updateProject', error)
    throw Error(
      getValue(
        error,
        'response.data.error',
        'Ocorreu um erro ao atualizar um projeto'
      )
    )
  }
}

export default { getList, getProjects, insertProject, updateProject }
