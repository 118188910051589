import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import dayjs from 'dayjs'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'

import { Row } from '../../styles/components'
import {
  NotificationDate,
  NotificationTitle,
  NotificationText,
  NewNotificationIcon,
  ReadNotificationIcon
} from './styles'

import { TIPS_TYPE_LIST } from '../../utils/constants'

const getTitleWithEmojiFromNotificationType = (title, type) => {
  if (type === TIPS_TYPE_LIST.DRYSOIL_TASK) return `${title} 🟠`
  if (type === TIPS_TYPE_LIST.TOOMUCHIRR) return `${title} 🔵`
  if (type === TIPS_TYPE_LIST.IRRONLYWHENNECESSARY) return `${title} 🔵`
  if (type === TIPS_TYPE_LIST.LONGIRRPULSE) return `${title} 🔵`
  if (type === TIPS_TYPE_LIST.NOSOLUTION_WETSOIL) return `${title} 🔵`
  if (type === TIPS_TYPE_LIST.NOSOLUTION_DRYSOIL) return `${title} 🟠`

  return title
}

const NotificationItem = ({
  text,
  timestamp,
  read,
  title,
  type,
  handleChange
}) => {
  const formatterTime = dayjs(timestamp).fromNow()

  return (
    <Accordion onChange={handleChange}>
      <AccordionSummary>
        {!read ? <NewNotificationIcon /> : <ReadNotificationIcon />}
        <Row align="space-between" justify="space-between">
          <NotificationTitle>
            {getTitleWithEmojiFromNotificationType(title, type)}
          </NotificationTitle>
          <NotificationDate>{formatterTime}</NotificationDate>
        </Row>
      </AccordionSummary>
      <AccordionDetails>
        <NotificationText>
          <ReactMarkdown>{text}</ReactMarkdown>
        </NotificationText>
      </AccordionDetails>
    </Accordion>
  )
}

NotificationItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
  read: PropTypes.bool,
  handleChange: PropTypes.func
}

export default NotificationItem
